<div class="container">
  <div class="hide-mobile">
    <div class="name-page">
      <span class="name">{{ 'CONTACTS.contactUs' | translate }}</span>
    </div>
  </div>
    <div class="padding-block contact-page">
      <ul class="social-network social-circle">
        <li><a href="mailto:info@equinemedirecord.com" target="_blank" class="icoMail" title="E-mail"><i class="fa fa-envelope-o"></i></a></li>
        <li><a href="https://www.facebook.com/EquineMediRecord/" target="_blank" class="icoFacebook" title="Facebook"><i class="fa fa-facebook"></i></a></li>
        <li><a href="https://twitter.com/EquineMediRec" target="_blank" class="icoTwitter" title="Twitter"><i class="fa fa-twitter"></i></a></li>
        <li><a href="https://ie.linkedin.com/company/equine-medirecord" target="_blank" class="icoLinkedin" title="Linkedin"><i class="fa fa-linkedin"></i></a></li>
        <li><a href="https://www.instagram.com/equine.medirecord/" target="_blank" class="icoInstagram" title="Instagram"><i class="fa fa-instagram"></i></a></li>
      </ul>
      <div class="block-text" style="margin-top: 30px;">
        <div class="name-box" style="margin-bottom: 15px;">
          {{ 'CONTACTS.feedbackForm' | translate }}
        </div>
      </div>
    <div class="contact-form">
      <form class="form modal-form" #createFeedback="ngForm" novalidate (ngSubmit)="sendFeedback(createFeedback)" *ngIf="!isRequestSuccess">
        <div class="box-control" id="feedback-subject-box" [ngClass]="{'input--filled--model' : feedback.title}">
          <input type="text"
                 class="form-control input__field"
                 [ngClass]="{'error': createFeedback.submitted && (feedbackSubject.errors?.required || feedbackSubject.errors?.pattern)}"
                 name="note_title"
                 maxlength="255"
                 [(ngModel)]="feedback.subject"
                 #feedbackSubject="ngModel"
                 autocomplete="off"
                 required>
          <label class="input__label">
            <span *ngIf="!createFeedback.submitted || (createFeedback.submitted && feedbackSubject.valid)">{{ 'CONTACTS.subject' | translate }}</span>
            <span *ngIf="createFeedback.submitted && feedbackSubject.errors?.required">{{ 'CONTACTS.subjectIsRequired' | translate }}</span>
            <span *ngIf="createFeedback.submitted && feedbackSubject.errors?.pattern">{{ 'CONTACTS.subject' | translate }} {{ 'CORE.isInvalid' | translate }}</span>
          </label>
        </div>

        <div class="box-control" id="feedback-text-box" [ngClass]="{'input--filled--model' : feedback.subject}">
          <textarea class="form-control input__field"
                    [ngClass]="{'error': createFeedback.submitted && (feedbackText.errors?.required || feedbackText.errors?.pattern)}"
                    name="note_descr"
                    #feedbackText="ngModel"
                    [(ngModel)]="feedback.text"
                    autocomplete="off"
                    maxlength="1000"
                    spellcheck="false"
                    required>
          </textarea>
          <label class="input__label">
            <span *ngIf="!createFeedback.submitted || (createFeedback.submitted && feedbackText.valid)">{{ 'CONTACTS.message' | translate }}</span>
            <span *ngIf="createFeedback.submitted && feedbackText.errors?.required">{{ 'CONTACTS.messageRequired' | translate }}</span>
            <span *ngIf="createFeedback.submitted && feedbackText.errors?.pattern">{{ 'CONTACTS.message' | translate }} {{ 'CORE.isInvalid' | translate }}</span>
          </label>
        </div>

        <div *ngIf="serverError">
          <div *ngFor="let err of serverError">
            <div class="box-btn text-center text-danger">{{err}}</div>
          </div>
        </div>

        <div class="box-btn text-center">
          <button type="submit"
                  class="btn btn-green btn-full"
                  [disabled]="createFeedback.submitted && (feedbackSubject.errors || feedbackText.errors) || isRequestInProgress">
                  {{ 'CONTACTS.send' | translate }}
          </button>
        </div>
      </form>
      <div *ngIf="isRequestSuccess">
        <div class="text-success">{{ 'CONTACTS.messageSentSuccessfully' | translate }}</div>
      </div>
    </div>
  </div>

</div>
