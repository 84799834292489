<!-- only for the Aus -->
<div class="box-control">
  <div class="box-control">
    <label class="label ng-select-label">Limb(s) if Intra-Articular</label>
    <ng-select
      class="farms-selector"
      [allowClear]="false"
      [items]="ausIntraArticularAdditionalInfosA"
      #ausIntraInfoASelector
      [active]="getActiveInfo('limb')"
      (selected)="setIntraInfo($event, 'limb')"
    >
    </ng-select>
  </div>
  <div class="box-control">
    <label class="label ng-select-label">Joint if Intra-Articular</label>
    <ng-select
      class="farms-selector"
      [allowClear]="false"
      [items]="ausIntraArticularAdditionalInfosB"
      #ausIntraInfoBSelector
      [active]="getActiveInfo('joint')"
      (selected)="setIntraInfo($event, 'joint')"
    >
    </ng-select>
  </div>
</div>
