<div class="stripe-component-page">
  <div *ngIf="!paid">
    <form class="form" novalidate (ngSubmit)="buy($event)" [formGroup]="stripeTest" >
      
      <label class="head-subscription">
        <span>{{ 'CORE.subscription' | translate }}</span>
        <div>
          {{'BILLING.premiumWarning' | translate}}
        </div>
      </label>
      <hr class="gray-line">
      <app-loader *ngIf="isRequestInProgress"></app-loader>
      <div [ngStyle]="{'visibility': isRequestInProgress ? 'hidden': 'visible'}" class="box-control input--filled title">
          <label class="input__label">
            <span>{{ 'CORE.title' | translate }}</span>
          </label>
          <div class="label-value input__field">{{stats?.plan?.title}}</div>
      </div>
      <div [ngStyle]="{'visibility': isRequestInProgress ? 'hidden': 'visible'}" class="box-control input--filled period">
        <label class="input__label">
          <span>{{ 'CORE.period' | translate }}</span>
        </label>
        <div class="label-value input__field capitalize">{{stats?.plan?.period}}</div>
    </div>
    <div [ngStyle]="{'visibility': isRequestInProgress ? 'hidden': 'visible'}" class="box-control input--filled price">
      <label class="input__label">
        <span>{{ 'CORE.price' | translate }}</span>
      </label>
      <div [ngStyle]="{'visibility': isRequestInProgress ? 'hidden': 'visible'}" class="label-value input__field">{{stats?.overage}}</div>
      </div>
      <div [ngStyle]="{'visibility': isRequestInProgress ? 'hidden': 'visible'}" class="box-control input--filled horse-number">
        <label class="input__label">
          <span>{{ 'CORE.horseNumber' | translate }}</span>
        </label>
        <div class="label-value input__field">{{stats?.horses}}</div>
      </div>
      <div [ngStyle]="{'visibility': isRequestInProgress ? 'hidden': 'visible'}" id="card-element" class="field"></div>
      <hr class="gray-line">
      <div class="pay-button">
        <button [disabled]="isRequestInProgress" class="btn btn-green btn-full" type="submit">
          {{ 'BILLING.proceed' | translate }}
        </button>
      </div>
      <div class="error" role="alert" *ngIf="errorStripe">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
        <path class="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"></path>
        <path class="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"></path>
      </svg>
      <span class="message">{{errorStripe}}</span>
      </div>
    </form>
  </div>
  <div class="success" *ngIf="paid && !isRequestInProgress">
      <span>{{ 'CORE.successSubscription' | translate }}</span>
      <button (click)="billingService.setShow(false)" class="btn btn-green btn-full close-modal" type="button">
        Ok
      </button>
    </div>
</div>