<span class="circle-container" [ngStyle]="renderTooltipPosition()">
  <button class="circle" (click)="showTooltip(key, '')"></button>
  <div class="horse-content" [ngClass]="key">
    <span class="before" [ngStyle]="renderTooltipStyle()"></span>
    <div class="background">
      <video controls autoplay *ngIf="key === 'add-horse'">
        <source src="/videos/addhorse.mp4" type="video/mp4">
        <embed src="/videos/addhorse.mp4" type="video/mp4" width="{#}" height="{#}">
      </video>
      <video controls autoplay *ngIf="key === 'add-treatment'">
        <source src="/videos/addtreatment.mp4" type="video/mp4">
        <embed src="/videos/addtreatment.mp4" type="video/mp4" width="{#}" height="{#}">
      </video>
      <video controls autoplay *ngIf="key === 'invite-user'">
        <source src="/videos/inviteuser.mp4" type="video/mp4">
        <embed src="/videos/inviteuser.mp4" type="video/mp4" width="{#}" height="{#}">
      </video>
      <video controls autoplay *ngIf="key === 'add-vaccination'">
        <source src="/videos/addvaccination.mp4" type="video/mp4">
        <embed src="/videos/addvaccination.mp4" type="video/mp4" width="{#}" height="{#}">
      </video>
      <video controls autoplay *ngIf="key === 'add-report'">
        <source src="/videos/report.mp4" type="video/mp4">
        <embed src="/videos/report.mp4" type="video/mp4" width="{#}" height="{#}">
      </video>
    </div>
    <div class="title">{{ tooltip?.title | translate}}</div>
    <div class="description">{{ tooltip?.description | translate}}</div>
    <div class="buttons-section">
      <button class="btn" (click)="hideTooltip()">{{ 'TOOLTIPS.got_it' | translate }}</button>
    </div>
  </div>
</span>
