<div class="modal" id="add-note">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createNoteForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(createNoteForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.addNote" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #createNoteForm="ngForm"
      novalidate
      (ngSubmit)="
        createNote(
          appMemory.roles[appMemory.user?.roles[0]?.id],
          createNoteForm
        )
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <div class="box-nav">
            <div class="label">{{ "HORSES.noteType" | translate }}</div>
            <ul class="nav-type line">
              <li
                class="change-active"
                *ngFor="let noteType of noteTypes"
                [ngClass]="{
                  active: noteType == newNote.type || hasSignNoteType
                }"
              >
                <a
                  class="btn"
                  (click)="
                    newNote.type = noteType;
                    noteType == newNote.type
                      ? void
                      : (newNote.weight = undefined)
                  "
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "HORSES." + noteType | translate }}
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div *ngIf="!hasSignNoteType">
            <div
              class="box-control"
              id="note-title-box"
              [ngClass]="{ 'input--filled--model': newNote?.title }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    createNoteForm.submitted &&
                    (newNoteTitle.errors?.required ||
                      newNoteTitle.errors?.pattern)
                }"
                name="note_title"
                #newNoteTitle="ngModel"
                [(ngModel)]="newNote.title"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !createNoteForm.submitted ||
                    (createNoteForm.submitted && newNoteTitle.valid)
                  "
                  >{{ "CORE.title" | translate }}</span
                >
                <span
                  *ngIf="
                    createNoteForm.submitted && newNoteTitle.errors?.required
                  "
                  >{{ "CORE.title" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="
                    createNoteForm.submitted && newNoteTitle.errors?.pattern
                  "
                  >{{ "CORE.title" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
              </label>
            </div>
            <div
              class="box-control"
              id="note-descr-box"
              [ngClass]="{ 'input--filled--model': newNote?.description }"
              *ngIf="newNote.type !== 'weight'"
            >
              <textarea
                class="form-control input__field"
                [ngClass]="{
                  error:
                    createNoteForm.submitted &&
                    (noteDescr.errors?.required || noteDescr.errors?.pattern)
                }"
                name="note_descr"
                #noteDescr="ngModel"
                [(ngModel)]="newNote.description"
                autocomplete="off"
                spellcheck="false"
                required
              >
              </textarea>
              <label class="input__label">
                <span
                  *ngIf="
                    !createNoteForm.submitted ||
                    (createNoteForm.submitted && noteDescr.valid)
                  "
                  >{{ "HORSES.description" | translate }}</span
                >
                <span
                  *ngIf="createNoteForm.submitted && noteDescr.errors?.required"
                  >{{ "HORSES.description" | translate
                  }}{{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="createNoteForm.submitted && noteDescr.errors?.pattern"
                  >{{ "HORSES.description" | translate
                  }}{{ "CORE.isInvalid" | translate }}</span
                >
              </label>
            </div>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              style="user-select: none"
              [ngClass]="{ danger: serverErrorValidate?.note_date }"
            >
              <label class="label">
                <span>{{ "HORSES.dateOfNote" | translate }} </span>
              </label>
              <div class="code relative">
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePickerAddNote.currentDate_input"
                  autocomplete="off"
                  name="note_date"
                  (change)="setNoteDateInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="
                    datePickerAddNote.isActive = !datePickerAddNote.isActive
                  "
                  aria-hidden="true"
                ></i>
              </div>
            </a>
            <span class="text-danger" *ngIf="serverErrorValidate?.note_date">{{
              serverErrorValidate?.note_date | translate
            }}</span>
          </div>
          <div class="box-control" *ngIf="datePickerAddNote.isActive">
            <datepicker
              class="datepicker"
              name="note-date-datepicker"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePickerAddNote.minDate"
              [maxDate]="datePickerAddNote.maxDate"
              [showWeeks]="false"
              [initDate]="datePickerAddNote.currentDate"
              [dateDisabled]="dateDisabled"
              (selectionDone)="
                setNoteDate($event);
                datePickerAddNote.isActive = !datePickerAddNote.isActive
              "
            >
            </datepicker>
          </div>

          <div
            class="clearfix"
            *ngIf="newNote.type === 'weight'"
            style="margin-bottom: 45px"
          >
            <div
              class="box-control"
              id="note-title-box"
              [ngClass]="{ 'input--filled--model': newNote?.description }"
              *ngIf="newNote.type == 'weight'"
              style="
                width: calc(50% - 10px);
                float: left;
                margin: 0;
                margin-right: 20px;
              "
            >
              <input
                type="text"
                class="form-control input__field only-number"
                maxlength="7"
                [ngClass]="{
                  error:
                    createNoteForm.submitted &&
                    (noteWeightDescr.errors?.required ||
                      noteWeightDescr.errors?.pattern)
                }"
                name="note_weight"
                #noteWeightDescr="ngModel"
                [(ngModel)]="newNote.description"
                autocomplete="off"
                spellcheck="false"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !createNoteForm.submitted ||
                    (createNoteForm.submitted && noteWeightDescr.valid)
                  "
                  >{{ "HORSES.weight" | translate }}</span
                >
                <span
                  *ngIf="
                    createNoteForm.submitted && noteWeightDescr.errors?.required
                  "
                  >{{ "HORSES.weight" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="
                    createNoteForm.submitted && noteWeightDescr.errors?.pattern
                  "
                  >{{ "HORSES.weight" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
              </label>
            </div>
            <div
              class="box-control"
              [ngClass]="{ 'input--filled--model': newNote.weight }"
              style="width: calc(50% - 10px); float: left; margin: 0"
            >
              <ng-select
                [allowClear]="false"
                id="weights-selector"
                class="weights-selector"
                [ngClass]="{
                  danger:
                    createNoteForm.submitted &&
                    (!newNote.weight || newNote.weight == undefined)
                }"
                required
                #weightsSelector
                [active]="weightsSelectorModel"
                (selected)="setWeight($event)"
                [items]="appMemory.weights"
                name="weights-s"
                placeholder="{{
                  'HORSES.' +
                    (createNoteForm.submitted
                      ? 'weightMeasure'
                      : 'weightMeasure') | translate
                }}"
              >
              </ng-select>
            </div>
          </div>

          <div
            *ngFor="let field of generateNoteFields(newNote?.type)"
            class="box-control clearfix"
            [ngClass]="{ 'input--filled--model': newNote[field.name] }"
          >
            <div *ngIf="field.type === 'checkbox'">
              <div class="toggle__text__inline">
                {{
                  field.label ||
                    "HORSES." +
                      (newHorse.is_in_food_chain
                        ? "inFoodChain"
                        : "outFoodChain") | translate
                }}
              </div>
              <div>
                <input
                  type="checkbox"
                  id="{{ field.uuid }}"
                  name="{{ field.name }}"
                  [checked]="!!newNote[field.name]"
                  (change)="newNote[field.name] = !newNote[field.name]"
                />
                <div class="toggle">
                  <label for="{{ field.uuid }}">
                    <i></i>
                  </label>
                </div>
                <span class="toggle__label">
                  <span *ngIf="!!newNote[field.name]">Yes</span>
                  <span *ngIf="!newNote[field.name]">No</span>
                </span>
              </div>
            </div>

            <div *ngIf="field.type !== 'checkbox'">
              <input
                type="{{ field.type }}"
                class="form-control input__field"
                style="display: block"
                name="{{ field?.name }}"
                autocomplete="off"
                spellcheck="false"
                [(ngModel)]="newNote[field.name]"
                value="{{ newNote[field.name] }}"
                [checked]="field.type === 'checkbox' && !!newNote[field.name]"
              />
              <label class="input__label">
                <span *ngIf="field.label">{{
                  field.label || "" | translate
                }}</span>
              </label>
            </div>
          </div>

          <div
            class="box-control"
            *ngIf="newNote.type == 'other' && !hasSignNoteType"
          >
            <div class="border-control with_description">
              <!-- 'HORSES.dragOrSelectPhoto' | translate -->
              <image-upload
                #imageLoader23
                [max]="maxImgCount"
                [maxFileSize]="maxImgSize"
                [buttonCaption]="'HORSES.dragSelectPhoto' | translate"
                [extensions]="[
                  'jpeg',
                  'png',
                  'pdf',
                  'doc',
                  'docx',
                  'csv',
                  'xls',
                  'xlsx',
                  'txt',
                  'mp4',
                  '3gp',
                  '3gp2'
                ]"
                (onFileUploadFinish)="
                  setNoteImages(imageLoader23.files); debug(imageLoader23)
                "
                (onRemove)="debug(imageLoader23)"
              >
              </image-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            (createNoteForm.submitted &&
              (noteDescr?.errors ||
                (this.newNote.type == 'weight' && !this.newNote.weight))) ||
            isRequestInProgress
          "
        >
          {{ "HORSES.addNote" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="add-vaccination">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createVaccinationForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(createVaccinationForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.addVacs" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #createVaccinationForm="ngForm"
      novalidate
      (ngSubmit)="createVaccination(createVaccinationForm)"
    >
      <div class="modal-body" style="margin-bottom: 50px">
        <div class="padding-block">
          <div class="box-control">
            <div
              *ngIf="oneHorseId === false"
              class="btn btn-dotted btn-full open-modal"
            >
              {{ "HORSES.selectedHorses" | translate }}
              <br />
              <a
                target="_blank"
                [routerLink]="['/horses', sh.id]"
                *ngFor="let sh of getSelectedHorses()"
                >{{ sh?.name }} ,
              </a>
            </div>
            <div
              *ngIf="oneHorseId !== false"
              class="btn btn-dotted btn-full open-modal"
            >
              {{ "HORSES.selectedHorses" | translate }}
              <br />
              <a target="_blank" [routerLink]="['/horses', oneHorseId]">
                {{ oneHorseName }} ,
              </a>
            </div>
          </div>
          <div class="box-nav">
            <div class="label">
              <span
                *ngIf="
                  (!createVaccinationForm.submitted ||
                    (createVaccinationForm.submitted &&
                      (!!newVaccination.influenza ||
                        !!newVaccination.herpes ||
                        !!newVaccination.other))) &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.vaccinationName" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createVaccinationForm.submitted &&
                  !newVaccination.influenza &&
                  !newVaccination.herpes &&
                  !newVaccination.other &&
                  invalidVaccinationForm
                "
                >Vaccination Name {{ "CORE.isRequired" | translate }}</span
              >
            </div>

            <ul class="nav-type line">
              <li
                class="change-active"
                [ngClass]="{ active: newVaccination.influenza }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.influenza = true;
                    newVaccination.herpes = false;
                    newVaccination.other = false;
                    newVaccination.name = 'Equine Influenza';
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted &&
                      !newVaccination.influenza &&
                      !newVaccination.herpes &&
                      !newVaccination.other &&
                      invalidVaccinationForm
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "HORSES.equineInfluenza" | translate }}
                  </div>
                </a>
              </li>
              <li
                class="change-active"
                [ngClass]="{ active: newVaccination.herpes }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.herpes = true;
                    newVaccination.influenza = false;
                    newVaccination.other = false;
                    newVaccination.name = 'Equine Herpes';
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted &&
                      !newVaccination.influenza &&
                      !newVaccination.herpes &&
                      !newVaccination.other &&
                      invalidVaccinationForm
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "HORSES.equineHerpes" | translate }}
                  </div>
                </a>
              </li>
              <li
                class="change-active"
                [ngClass]="{ active: newVaccination.other }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.other = true;
                    newVaccination.herpes = false;
                    newVaccination.influenza = false;
                    newVaccination.name = '';
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted &&
                      !newVaccination.influenza &&
                      !newVaccination.herpes &&
                      !newVaccination.other &&
                      invalidVaccinationForm
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "HORSES.other" | translate }}
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="box-control"
            id="vaccination-title-box"
            [ngClass]="{ 'input--filled--model': newVaccination?.name }"
            *ngIf="newVaccination.other"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createVaccinationForm.submitted &&
                  (newVaccinationName.errors?.required ||
                    newVaccinationName.errors?.pattern) &&
                  invalidVaccinationForm
              }"
              name="other-vacc"
              #newVaccinationName="ngModel"
              [(ngModel)]="newVaccination.name"
              (ngModelChange)="invalidVaccinationForm = false"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span>{{ "HORSES.otherVacs" | translate }}</span>
              <span
                *ngIf="
                  (!createVaccinationForm.submitted ||
                    (createVaccinationForm.submitted &&
                      newVaccinationName.valid)) &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.otherVacs" | translate }}</span
              >
              <span
                *ngIf="
                  createVaccinationForm.submitted &&
                  newVaccinationName.errors?.required &&
                  invalidVaccinationForm &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.otherVacs" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  createVaccinationForm.submitted &&
                  newVaccinationName.errors?.pattern &&
                  invalidVaccinationForm &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.otherVacs" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
            </label>
          </div>

          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (((createVaccinationForm.submitted && !newVaccination.date) ||
                    serverErrorValidate?.vaccination_date) &&
                    invalidVaccinationForm) ||
                  dateRangeErrorMessage.from
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !createVaccinationForm.submitted ||
                    (createVaccinationForm.submitted && !!newVaccination.date)
                  "
                  >{{ "HORSES.dateOfAdmin" | translate }}</span
                >
              </label>
              <div class="code relative">
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker3.currentDate_input"
                  (ngModelChange)="invalidVaccinationForm = false"
                  autocomplete="off"
                  name="vaccination_date"
                  (ngModelChange)="
                    setVaccinationDateInput(); checknewVaccinationValidity()
                  "
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker3.isActive = !datePicker3.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span class="text-danger" *ngIf="dateRangeErrorMessage.from"
                >{{ dateRangeErrorMessage.from | translate
                }}{{ dateRangeErrorMessage.date }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createVaccinationForm.submitted &&
                  !newVaccination.date &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.dateOfAdmin" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  !dateRangeErrorMessage.from &&
                  serverErrorValidate?.vaccination_date &&
                  invalidVaccinationForm
                "
                >{{ serverErrorValidate?.vaccination_date | translate }}</span
              >
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker3.isActive">
            <datepicker
              class="datepicker"
              name="vaccination-datepicker"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker3.minDate"
              [maxDate]="datePicker3.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker3.currentDate"
              [dateDisabled]="dateDisabled"
              (selectionDone)="
                setVaccinationDate($event);
                datePicker3.isActive = !datePicker3.isActive
              "
            >
            </datepicker>
          </div>

          <div
            *ngIf="newVaccination.other || newVaccination.number === 'booster'"
          >
            <div class="box-control input--filled">
              <a
                class="btn btn-dotted btn-code btn-full"
                [ngClass]="{
                  danger:
                    (((createVaccinationForm.submitted &&
                      (newVaccination.other ||
                        (!newVaccination.other &&
                          newVaccination.number !== 'booster')) &&
                      !newVaccination.immunization) ||
                      serverErrorValidate?.immunization) &&
                      invalidVaccinationForm) ||
                    dateRangeErrorMessage.due
                }"
                style="user-select: none"
              >
                <label class="label">
                  <span
                    *ngIf="
                      !createVaccinationForm.submitted ||
                      (createVaccinationForm.submitted &&
                        !!newVaccination.immunization) ||
                      (newVaccination.number === 'booster' &&
                        !newVaccination.other)
                    "
                    >{{ "HORSES.dateofNextAdmin" | translate }}</span
                  >
                </label>
                <div class="code relative">
                  <input
                    type="text"
                    [textMask]="datePickerMask"
                    placeholder="{{ 'CORE.selectDate' | translate }}"
                    class="form-control input__field"
                    [(ngModel)]="datePicker5.currentDate_input"
                    (ngModelChange)="invalidVaccinationForm = false"
                    autocomplete="off"
                    name="immunization"
                    (ngModelChange)="
                      setNextVaccinationDateInput();
                      checknewVaccinationValidity()
                    "
                  />
                  <i
                    class="fa fa-calendar calendar"
                    (click)="datePicker5.isActive = !datePicker5.isActive"
                    aria-hidden="true"
                  ></i>
                </div>
                <span class="text-danger" *ngIf="dateRangeErrorMessage.due"
                  >{{ dateRangeErrorMessage.due | translate
                  }}{{ dateRangeErrorMessage.date }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createVaccinationForm.submitted &&
                    (newVaccination.other ||
                      (!newVaccination.other &&
                        newVaccination.number !== 'booster')) &&
                    !newVaccination.immunization &&
                    invalidVaccinationForm
                  "
                  >{{ "HORSES.dateofNextAdmin" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    !dateRangeErrorMessage.due &&
                    serverErrorValidate?.immunization &&
                    invalidVaccinationForm
                  "
                  >{{ serverErrorValidate?.immunization | translate }}</span
                >
              </a>
            </div>
            <div class="box-control" *ngIf="datePicker5.isActive">
              <datepicker
                class="datepicker"
                name="vaccination-next-datepicker"
                [startingDay]="1"
                [onlyCurrentMonth]="true"
                [minDate]="datePicker5.minDate"
                [maxDate]="datePicker5.maxDate"
                [showWeeks]="false"
                [initDate]="datePicker5.currentDate"
                [dateDisabled]="dateDisabled"
                (selectionDone)="
                  setNextVaccinationDate($event);
                  datePicker5.isActive = !datePicker5.isActive
                "
              >
              </datepicker>
            </div>
          </div>

          <div class="box-nav">
            <div class="label">
              <span
                *ngIf="
                  (!createVaccinationForm.submitted ||
                    (createVaccinationForm.submitted &&
                      !!newVaccination.number)) &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.vaccinationNumber" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createVaccinationForm.submitted &&
                  !newVaccination.number &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.vaccinationNumber" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </div>
            <ul class="nav-type line">
              <li
                class="change-active"
                *ngFor="let vaccinationNumber of vaccinationNumbers"
                [ngClass]="{
                  active: vaccinationNumber == newVaccination.number
                }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.number = vaccinationNumber;
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted && !newVaccination.number
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "CORE." + vaccinationNumber | translate }}
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <ng-container *ngIf="!this.isUSCountry">
            <div class="title-vaccination">
              {{ "HORSES.vaccinationApplicable" | translate }}
            </div>
          </ng-container>

          <div>
            <label class="container">
              <span *ngFor="let country of vaccinationCountries" class="comma">
                {{ "ISO." + country | translate }}
              </span>
              <input type="checkbox" [checked]="true" disabled />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            createVaccinationForm.submitted &&
            (createVaccinationForm.errors ||
              newVaccinationName?.errors ||
              (!newVaccination.influenza &&
                !newVaccination.herpes &&
                !newVaccination.other) ||
              !newVaccination.date ||
              (!newVaccination.other && !newVaccination.number) ||
              (newVaccination.other &&
                (!newVaccination.immunization || !newVaccination.name) &&
                newVaccination.number !== 'booster') ||
              isRequestInProgress) &&
            invalidVaccinationForm
          "
        >
          {{ "HORSES.addVacs" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="add-treatment">
  <div
    class="modal-wrapper"
    style="padding-left: 20px; padding-right: 20px"
    [ngClass]="{ 'quick-treatment': quick_treatment }"
  >
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createTreatmentForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(createTreatmentForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.addTreatment" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #createTreatmentForm="ngForm"
      novalidate
      (ngSubmit)="
        tabs.isQuickTreatment
          ? createQuickTreatment(
              appMemory.roles[appMemory.user?.roles[0]?.id],
              createTreatmentForm
            )
          : createTreatment(
              appMemory.roles[appMemory.user?.roles[0]?.id],
              createTreatmentForm
            )
      "
    >
      <tabset class="nav-tabs nav-left" *ngIf="quick_treatment">
        <tab
          heading="{{ 'TREATS.normalTreatment' | translate }}"
          [active]="tabs.isNormalTreatment"
          (select)="tabs.isNormalTreatment = true"
          (deselect)="tabs.isNormalTreatment = false"
        ></tab>
        <tab
          heading="{{ 'TREATS.quickTreatment' | translate }}"
          [active]="tabs.isQuickTreatment"
          (select)="tabs.isQuickTreatment = true"
          (deselect)="tabs.isQuickTreatment = false"
        ></tab>
      </tabset>
      <div class="modal-body" [ngClass]="{ hidden: !tabs.isNormalTreatment }">
        <div class="padding-block">
          <div class="box-control">
            <div
              *ngIf="oneHorseId === false"
              class="btn btn-dotted btn-full open-modal"
            >
              {{ "HORSES.selectedHorses" | translate }}
              <br />
              <a
                target="_blank"
                [routerLink]="['/horses', sh.id]"
                *ngFor="let sh of getSelectedHorses()"
                >{{ sh?.name }} ,
              </a>
            </div>
            <div
              *ngIf="oneHorseId !== false"
              class="btn btn-dotted btn-full open-modal"
            >
              {{ "HORSES.selectedHorses" | translate }}
              <br />
              <a target="_blank" [routerLink]="['/horses', oneHorseId]">
                {{ oneHorseName }} ,
              </a>
            </div>
          </div>
          <div class="clearfix administer-section">
            <div
              class="box-control"
              [ngClass]="{
                'input--filled--model': newTreatment.administer
              }"
            >
              <ng-select
                [allowClear]="false"
                [ngClass]="{
                  danger:
                    createTreatmentForm.submitted && !newTreatment.administer
                }"
                [active]="administer"
                id="administer-selector"
                required
                (selected)="setTreatmentAdminister($event)"
                [items]="administers"
                name="administer"
                placeholder="{{ 'HORSES.administer_name' | translate }}"
              >
              </ng-select>
              <label class="input__label">
                <span>{{ "HORSES.administer" | translate }}</span>
              </label>
            </div>
          </div>
          <div
            *ngIf="administer?.length && newTreatment.administer?.user_id == -1"
          >
            <div
              class="box-control"
              [ngClass]="{
                'input--filled--model': newTreatment?.administer.contact_name
              }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    createTreatmentForm.submitted &&
                    !newTreatment?.administer.contact_name
                }"
                name="contact_name"
                [(ngModel)]="newTreatment.administer.contact_name"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !createTreatmentForm.submitted ||
                    (createTreatmentForm.submitted &&
                      newTreatment?.administer?.contact_name)
                  "
                  >{{ "HORSES.administer_name" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createTreatmentForm.submitted &&
                    !newTreatment?.administer?.contact_name
                  "
                  >{{ "HORSES.administer_name" | translate }}</span
                >
              </label>
            </div>

            <div
              class="box-control"
              [ngClass]="{
                'input--filled--model': newTreatment?.administer?.contact_info
              }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    createTreatmentForm.submitted &&
                    !newTreatment.administer.contact_info
                }"
                name="contact_info"
                [(ngModel)]="newTreatment.administer.contact_info"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !createTreatmentForm.submitted ||
                    (createTreatmentForm.submitted &&
                      !!newTreatment?.administer?.contact_info)
                  "
                  >{{ "HORSES.administer_contact_info" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createTreatmentForm.submitted &&
                    !newTreatment?.administer?.contact_info
                  "
                  >{{ "HORSES.administer_contact_info" | translate }}</span
                >
              </label>
            </div>
          </div>
          <div class="box-control" *ngIf="!newTreatment.drug">
            <a
              class="btn btn-dotted btn-full open-modal"
              [ngClass]="{
                danger: createTreatmentForm.submitted && !newTreatment.drug
              }"
              href="#modal-search"
              (click)="hideModal(true); filterDrugs()"
            >
              <div class="box-icon box-right">
                <i class="icon icon-plus icon-middle"></i>
              </div>
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && newTreatment.drug)
                "
                >{{ "HORSES.drugName" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="createTreatmentForm.submitted && !newTreatment.drug"
                >{{ "HORSES.drugNameRequired" | translate }}</span
              >
            </a>
          </div>
          <div class="box-control" *ngIf="newTreatment.drug?.name">
            <a
              class="btn btn-dotted btn-full open-modal"
              href="#modal-search"
              (click)="hideModal(true)"
            >
              <div class="label">{{ "HORSES.drugName" | translate }}</div>
              <div class="name">{{ newTreatment.drug.name }}</div>
            </a>
          </div>
          <div class="box-control" *ngIf="newTreatment?.drug?.id == 0">
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error: createTreatmentForm.submitted && !newTreatment.drug_name
              }"
              name="other-drug-name"
              #newOtherDrug="ngModel"
              [(ngModel)]="newTreatment.drug_name"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && !!newTreatment.drug_name)
                "
                >{{ "TREATS.otherDrugName" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="createTreatmentForm.submitted && !newTreatment.drug_name"
                >{{ "TREATS.otherDrugName" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control">
            <div class="label">
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && !!newTreatment.route)
                "
                >{{ "HORSES.route" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="createTreatmentForm.submitted && !newTreatment.route"
                >{{ "HORSES.routeIsRequired" | translate }}</span
              >
            </div>
            <ul class="nav-type line">
              <li
                class="change-active"
                *ngFor="let routeType of routeTypes"
                [ngClass]="{ active: routeType == newTreatment.route }"
              >
                <a
                  class="btn"
                  [ngClass]="{
                    danger: createTreatmentForm.submitted && !newTreatment.route
                  }"
                  (click)="newTreatment.route = routeType"
                >
                  <div class="name">
                    {{
                      "HORSES." +
                        (appMemory?.translates[routeType] || routeType)
                        | translate
                    }}
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="box-control"
            *ngIf="
              newTreatment.route && newTreatment.route.toLowerCase() == 'other'
            "
            [ngClass]="{ 'input--filled--model': newTreatment?.other_route }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createTreatmentForm.submitted && !newTreatment.other_route
              }"
              name="other-route"
              #newOtherRoute="ngModel"
              [(ngModel)]="newTreatment.other_route"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && newTreatment.other_route)
                "
                >{{ "TREATS.otherRoute" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createTreatmentForm.submitted && !newTreatment.other_route
                "
                >{{ "TREATS.otherRoute" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control" *ngIf="currentCountry == 'uk'">
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error: createTreatmentForm.submitted && !newTreatment.frequency
              }"
              name="frequency"
              #newFrequency="ngModel"
              [(ngModel)]="newTreatment.frequency"
              autocomplete="off"
              [maxlength]="50"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && newTreatment.frequency)
                "
                >{{ "HORSES.frequency" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="createTreatmentForm.submitted && !newTreatment.frequency"
                >{{ "HORSES.frequency_required" | translate }}</span
              >
            </label>
          </div>

          <div class="clearfix">
            <div
              class="box-control"
              id="quantity-box"
              [ngClass]="{ 'input--filled--model': newTreatment?.quantity }"
              style="
                width: calc(50% - 10px);
                float: left;
                margin: 0;
                margin-right: 20px;
              "
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error: createTreatmentForm.submitted && newQuantity.errors,
                  'only-number-float': true
                }"
                name="quantity"
                [maxlength]="calcQuantityMaxLength()"
                #newQuantity="ngModel"
                [(ngModel)]="newTreatment.quantity"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !createTreatmentForm.submitted ||
                    (createTreatmentForm.submitted && newQuantity.valid)
                  "
                  >{{ "HORSES.dailyQuantity" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createTreatmentForm.submitted &&
                    newQuantity.errors?.required
                  "
                  >{{ "HORSES.dailyQuanityRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createTreatmentForm.submitted && newQuantity.errors?.pattern
                  "
                  >{{ "HORSES.dailyQuantity" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createTreatmentForm.submitted &&
                    newQuantity.errors?.maxlength
                  "
                  >{{ "HORSES.dailyQuantity" | translate
                  }}{{ "CORE.isTooBig" | translate }}</span
                >
              </label>
            </div>
            <div
              class="box-control"
              [ngClass]="{
                'input--filled--model': newTreatment?.quantity_measure
              }"
              style="width: calc(50% - 10px); float: left; margin: 0"
            >
              <ng-select
                [allowClear]="false"
                id="measures-selector"
                class="measures-selector"
                [ngClass]="{
                  danger:
                    createTreatmentForm.submitted &&
                    !newTreatment.quantity_measure
                }"
                required
                #measuresSelector
                [active]="measuresSelectorModel"
                (selected)="setMeasure($event)"
                [items]="appMemory.measures"
                name="measures"
                placeholder="{{
                  'HORSES.' +
                    (createTreatmentForm.submitted
                      ? 'quantityMeasure'
                      : 'quantityMeasure') | translate
                }}"
              >
              </ng-select>
            </div>
          </div>
          <div
            class="box-control"
            id="other_quantity"
            [ngClass]="{ 'input--filled--model': newTreatment?.other_quantity }"
            *ngIf="newTreatment.quantity_measure == 'other'"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createTreatmentForm.submitted && !newTreatment.other_quantity
              }"
              name="other_quantity"
              #newReason="ngModel"
              [(ngModel)]="newTreatment.other_quantity"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && !!newTreatment.reason)
                "
                >{{ "TREATS.otherQuantityMeasure" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="createTreatmentForm.submitted && !newTreatment.reason"
                >{{ "TREATS.otherQuantityMeasure" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="duration-box"
            [ngClass]="{ 'input--filled--model': newTreatment?.duration }"
          >
            <input
              type="text"
              class="form-control input__field only-number"
              [ngClass]="{
                error: createTreatmentForm.submitted && !newTreatment.duration
              }"
              name="duration"
              #newDuration="ngModel"
              maxlength="8"
              [(ngModel)]="newTreatment.duration"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && !!newTreatment.duration)
                "
                >{{ "HORSES.withdrawal" | translate }} ({{
                  "HORSES.days" | translate
                }})</span
              >
              <span
                class="text-danger"
                *ngIf="createTreatmentForm.submitted && !newTreatment.duration"
                >{{ "HORSES.withdrawalUsRequired" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="reason-box"
            [ngClass]="{ 'input--filled--model': newTreatment?.reason }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error: createTreatmentForm.submitted && !newTreatment.reason
              }"
              name="reason"
              #newReason="ngModel"
              [(ngModel)]="newTreatment.reason"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createTreatmentForm.submitted ||
                  (createTreatmentForm.submitted && !!newTreatment.reason)
                "
                >{{ "HORSES.reason" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="createTreatmentForm.submitted && !newTreatment.reason"
                >{{ "HORSES.reasonIsRequired" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (createTreatmentForm.submitted && !newTreatment.start_date) ||
                  serverErrorValidate?.start_date ||
                  dateRangeTreatmentErrorMessage.from
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !createTreatmentForm.submitted ||
                    (createTreatmentForm.submitted && !!newTreatment.start_date)
                  "
                  >{{ "HORSES.startDate" | translate }}</span
                >
              </label>
              <div class="code relative">
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker4.currentDate_input"
                  autocomplete="off"
                  name="start_date"
                  (ngModelChange)="setStartDateInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker4.isActive = !datePicker4.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="text-danger"
                *ngIf="dateRangeTreatmentErrorMessage.from"
                >{{ dateRangeTreatmentErrorMessage.from | translate
                }}{{ dateRangeTreatmentErrorMessage.date }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createTreatmentForm.submitted && !newTreatment.start_date
                "
                >{{ "HORSES.startDateRequired" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  serverErrorValidate?.start_date &&
                  !dateRangeTreatmentErrorMessage.from
                "
                >{{ serverErrorValidate?.start_date | translate }}</span
              >
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker4.isActive">
            <datepicker
              class="datepicker"
              name="withdrawal-datepicker"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker4.minDate"
              [maxDate]="datePicker4.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker4.currentDate"
              [dateDisabled]="dateDisabled"
              (selectionDone)="
                setStartDate($event);
                datePicker4.isActive = !datePicker4.isActive
              "
            >
            </datepicker>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (createTreatmentForm.submitted && !newTreatment.withdrawal) ||
                  serverErrorValidate?.withdrawal ||
                  dateRangeTreatmentErrorMessage.due
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !createTreatmentForm.submitted ||
                    (createTreatmentForm.submitted && !!newTreatment.withdrawal)
                  "
                  >{{ "HORSES.endDate" | translate }}</span
                >
              </label>
              <div class="code relative" ng>
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker2.currentDate_input"
                  autocomplete="off"
                  name="withdrawal"
                  (ngModelChange)="
                    setWithdrawalInput(); checknewTreatmentValidity()
                  "
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker2.isActive = !datePicker2.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="text-danger"
                *ngIf="dateRangeTreatmentErrorMessage.due"
                >{{ dateRangeTreatmentErrorMessage.due | translate
                }}{{ dateRangeTreatmentErrorMessage.date }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createTreatmentForm.submitted && !newTreatment.withdrawal
                "
                >{{ "HORSES.endDateRequired" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  serverErrorValidate?.withdrawal &&
                  !dateRangeTreatmentErrorMessage.due
                "
                >{{ serverErrorValidate?.withdrawal | translate }}</span
              >
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker2.isActive">
            <datepicker
              class="datepicker"
              name="withdrawal-datepicker"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker2.minDate"
              [maxDate]="datePicker2.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker2.currentDate"
              [dateDisabled]="dateDisabled"
              (selectionDone)="
                setWithdrawal($event);
                datePicker2.isActive = !datePicker2.isActive
              "
            >
            </datepicker>
          </div>
          <div class="box-control">
            <div class="border-control with_description">
              <image-upload
                #imageLoader22
                [max]="maxImgCount"
                [maxFileSize]="maxImgSize"
                [buttonCaption]="'HORSES.dragSelectPhoto' | translate"
                [extensions]="['jpeg', 'png']"
                (onFileUploadFinish)="
                  setImages(imageLoader22.files); debug(imageLoader22)
                "
                (onRemove)="debug(imageLoader22)"
              >
              </image-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" [ngClass]="{ hidden: !tabs.isQuickTreatment }">
        <div class="padding-block">
          <div class="box-control">
            <div class="border-control with_description">
              <image-upload
                #imageLoader23
                [max]="maxImgCount"
                [maxFileSize]="maxImgSize"
                [buttonCaption]="'HORSES.dragSelectPhoto' | translate"
                [extensions]="[
                  'jpeg',
                  'png',
                  'pdf',
                  'doc',
                  'docx',
                  'csv',
                  'xls',
                  'xlsx',
                  'txt',
                  'mp4',
                  '3gp',
                  '3gp2'
                ]"
                (onFileUploadFinish)="
                  setImages(imageLoader23.files); debug(imageLoader23)
                "
                (onRemove)="debug(imageLoader23)"
              >
              </image-upload>
            </div>
            <span
              class="text-danger"
              *ngIf="createTreatmentForm.submitted && !newTreatment.src"
              >{{ "TREATS.prescriptionMissing" | translate }}</span
            >
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="isRequestInProgress4 || invalidTreatmentForm"
        >
          {{ "HORSES.saveTreatment" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal search-modal" id="modal-search">
  <div class="modal-wrapper no-footer">
    <div class="modal-header">
      <div class="left-part" (click)="search.value = ''; searchDrugs('')">
        <a
          class="btn close-modal open-modal show-mobile"
          [attr.href]="'#add-treatment'"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="right-part" (click)="search.value = ''; searchDrugs('')">
        <a
          class="btn btn-close close-modal open-modal"
          [attr.href]="'#add-treatment'"
        >
          <i class="icon icon-close dark icon-middle"></i>
        </a>
      </div>

      <form class="form">
        <div class="box-control-search">
          <input
            class="search-control"
            placeholder="{{ 'CORE.search' | translate }}"
            #search
            (input)="searchDrugs(search.value)"
          />
        </div>
      </form>
    </div>
    <div class="modal-body">
      <div class="search-result">
        <div class="padding-block">
          <ng-template
            ngFor
            let-drug
            [ngForOf]="drugs || appMemory.static?.drugs"
          >
            <div
              class="item-result close-modal open-modal"
              (click)="newTreatment.drug = drug; hideModal(true)"
              [attr.href]="'#add-treatment'"
            >
              <div class="name">{{ drug?.name }}</div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="edit-yard">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearFarmInput(updateFarmForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a class="btn close-modal show-mobile"
          ><i class="icon icon-back icon-middle"></i
        ></a>
      </div>
      <div class="title">{{ "HORSES.editYard" | translate }}</div>
    </div>

    <form
      class="form modal-form"
      #updateFarmForm="ngForm"
      novalidate
      (ngSubmit)="submitUpdateFarm(updateFarmForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="box-control"
            id="farm-name-box"
            [ngClass]="{ 'input--filled--model': updateFarm?.name }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateFarmForm.submitted &&
                  (updateFarmName.errors?.required ||
                    updateFarmName.errors?.maxlength)
              }"
              name="farm_name"
              maxlength="40"
              #updateFarmName="ngModel"
              [(ngModel)]="updateFarm.name"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateFarmForm.submitted ||
                  (updateFarmForm.submitted && updateFarmName.valid)
                "
                >{{ "CORE.yardName" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmName.errors?.required
                "
                >{{ "CORE.yardName" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmName.errors?.pattern
                "
                >{{ "CORE.yardName" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmName.errors?.maxlength
                "
                >{{ "CORE.yardName" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-address-first-box"
            [ngClass]="{ 'input--filled--model': updateFarm?.address_first }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateFarmForm.submitted &&
                  (updateFarmAddressFirst.errors?.required ||
                    updateFarmAddressFirst.errors?.maxlength)
              }"
              name="address_first"
              maxlength="40"
              #updateFarmAddressFirst="ngModel"
              [(ngModel)]="updateFarm.address_first"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateFarmForm.submitted ||
                  (updateFarmForm.submitted && updateFarmAddressFirst.valid)
                "
                >{{ "HORSES.firstAddress" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmAddressFirst.errors?.required
                "
                >{{ "HORSES.firstAddress" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmAddressFirst.errors?.pattern
                "
                >{{ "HORSES.firstAddress" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmAddressFirst.errors?.maxlength
                "
                >{{ "HORSES.firstAddress" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-address-second-box"
            [ngClass]="{ 'input--filled--model': updateFarm?.address_second }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateFarmForm.submitted &&
                  (updateFarmAddressSecond.errors?.required ||
                    updateFarmAddressSecond.errors?.maxlength)
              }"
              name="address_second"
              maxlength="40"
              #updateFarmAddressSecond="ngModel"
              [(ngModel)]="updateFarm.address_second"
              autocomplete="off"
              spellcheck="false"
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateFarmForm.submitted ||
                  (updateFarmForm.submitted && updateFarmAddressSecond.valid)
                "
                >{{ "HORSES.secondAddress" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmAddressSecond.errors?.required
                "
                >{{ "HORSES.secondAddress" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmAddressSecond.errors?.pattern
                "
                >{{ "HORSES.secondAddress" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmAddressSecond.errors?.maxlength
                "
                >{{ "HORSES.secondAddress" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-city-box"
            [ngClass]="{ 'input--filled--model': updateFarm?.city }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateFarmForm.submitted &&
                  (updateFarmCity.errors?.required ||
                    updateFarmCity.errors?.maxlength)
              }"
              name="city"
              maxlength="40"
              #updateFarmCity="ngModel"
              [(ngModel)]="updateFarm.city"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateFarmForm.submitted ||
                  (updateFarmForm.submitted && updateFarmCity.valid)
                "
                >{{ "CORE.city" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmCity.errors?.required
                "
                >{{ "CORE.city" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmCity.errors?.pattern
                "
                >{{ "CORE.city" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmCity.errors?.maxlength
                "
                >{{ "CORE.city" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-region-box"
            *ngIf="!showRegionsSelect"
            [ngClass]="{ 'input--filled--model': updateFarm?.region }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateFarmForm.submitted &&
                  (updateFarmRegion.errors?.required ||
                    updateFarmRegion.errors?.maxlength)
              }"
              name="region"
              maxlength="40"
              #updateFarmRegion="ngModel"
              [(ngModel)]="updateFarm.region"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateFarmForm.submitted ||
                  (updateFarmForm.submitted && updateFarmRegion.valid)
                "
                >{{ "YARDS.region" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmRegion.errors?.required
                "
                >{{ "YARDS.region" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmRegion.errors?.pattern
                "
                >{{ "YARDS.region" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmRegion.errors?.maxlength
                "
                >{{ "YARDS.region" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-region-box"
            *ngIf="showRegionsSelect"
          >
            <label class="label ng-select-label">
              <span>{{ "YARDS.region" | translate }}</span>
            </label>
            <ng-select
              [allowClear]="false"
              id="region-selector"
              class="farms-selector"
              [ngClass]="{
                danger: updateFarmForm.submitted && !updateFarm.region
              }"
              required
              [active]="selectedRegion"
              #updateFarmRegion
              (selected)="setRegion($event)"
              [items]="regions"
              name="region"
              placeholder=" {{ 'YARDS.region' | translate }} {{
                (updateFarmForm.submitted ? 'CORE.isRequired' : '') | translate
              }}"
            >
            </ng-select>
          </div>
          <div
            class="box-control"
            id="farm-post-code-box"
            [ngClass]="{ 'input--filled--model': updateFarm?.post_code }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateFarmForm.submitted &&
                  (updateFarmPostCode.errors?.required ||
                    updateFarmPostCode.errors?.maxlength)
              }"
              name="post_code"
              maxlength="40"
              #updateFarmPostCode="ngModel"
              [(ngModel)]="updateFarm.post_code"
              autocomplete="off"
              spellcheck="false"
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateFarmForm.submitted ||
                  (updateFarmForm.submitted && updateFarmPostCode.valid)
                "
                >{{ "YARDS.postCode" | translate }} ({{
                  "HORSES.optional" | translate
                }})</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmPostCode.errors?.required
                "
                >{{ "YARDS.postCode" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmPostCode.errors?.pattern
                "
                >{{ "YARDS.postCode" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmPostCode.errors?.maxlength
                "
                >{{ "YARDS.postCode" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-country-box"
            [ngClass]="{ 'input--filled--model': updateFarm?.country_id }"
          >
            <ng-select
              [allowClear]="false"
              id="country-selector"
              class="farms-selector"
              [ngClass]="{
                danger: updateFarmForm.submitted && !updateFarm.country_id
              }"
              required
              [active]="selectedCountry"
              #updateFarmCountry
              (selected)="setCountryId($event)"
              [items]="countries"
              name="country_id"
              placeholder="{{
                updateFarmForm.submitted
                  ? 'Country name is required'
                  : 'Country'
              }}"
            >
            </ng-select>
            <label class="input__label">
              <span
                *ngIf="
                  !updateFarmForm.submitted ||
                  (updateFarmForm.submitted && updateFarmCountry.valid)
                "
                >{{ "YARDS.country" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmCountry.errors?.required
                "
                >{{ "YARDS.country" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted && updateFarmCountry.errors?.pattern
                "
                >{{ "YARDS.country" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateFarmForm.submitted &&
                  updateFarmCountry.errors?.maxlength
                "
                >{{ "YARDS.country" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
        </div>
        <div *ngIf="serverError" style="margin-bottom: 30px">
          <div *ngFor="let err of serverError">
            <div class="box-btn text-center text-danger">{{ err }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            isRequestInProgress ||
            (updateFarmForm.submitted &&
              (!updateFarm.name ||
                !updateFarm.address_first ||
                !updateFarmName.valid ||
                !updateFarm.country_id ||
                !updateFarm.city))
          "
        >
          {{ "HORSES.editYard" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="add-horse">
  <div class="modal-wrapper" style="padding-left: 15px; padding-right: 15px">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createHorseForm)"
      ><i class="icon icon-close"></i
    ></a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a class="btn close-modal show-mobile"
          ><i class="icon icon-back icon-middle"></i
        ></a>
      </div>
      <div class="title">{{ "HORSES.addHorse" | translate }}</div>
    </div>

    <form
      class="form modal-form"
      #createHorseForm="ngForm"
      novalidate
      (ngSubmit)="createHorse(createHorseForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div class="box-nav">
            <ul class="nav-type line">
              <li
                class="change-active"
                style="text-transform: capitalize"
                [ngClass]="{ active: gender.id == newHorse.gender_id }"
                *ngFor="let gender of appMemory?.static?.genders"
              >
                <a
                  class="btn"
                  (click)="newHorse.gender_id = gender.id"
                  [ngClass]="{
                    danger: createHorseForm.submitted && !newHorse.gender_id
                  }"
                >
                  <div class="name">
                    {{
                      "HORSES." + (gender.name | lowercase)
                        | translate
                        | uppercase
                    }}
                  </div>
                </a>
              </li>
            </ul>
            <div
              class="box-btn text-center text-danger"
              style="margin-bottom: 35px"
              *ngIf="createHorseForm.submitted && !newHorse.gender_id"
            >
              {{ "HORSES.mustChooseHorseGender" | translate }}
            </div>
          </div>
          <div
            class="box-control"
            id="horse-name-box"
            [ngClass]="{ 'input--filled--model': newHorse?.name }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createHorseForm.submitted &&
                  (newHorseName.errors?.required ||
                    newHorseName.errors?.pattern ||
                    newHorseName.errors?.maxlength)
              }"
              name="horse_name"
              maxlength="40"
              #newHorseName="ngModel"
              [(ngModel)]="newHorse.name"
              autocomplete="off"
              spellcheck="false"
              autofocus
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createHorseForm.submitted ||
                  (createHorseForm.submitted && newHorseName.valid)
                "
                >{{ "HORSES.horseName" | translate }}</span
              >
              <span
                *ngIf="
                  createHorseForm.submitted && newHorseName.errors?.required
                "
                >{{ "HORSES.horseName" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  createHorseForm.submitted && newHorseName.errors?.pattern
                "
                >{{ "HORSES.horseName" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createHorseForm.submitted && newHorseName.errors?.maxlength
                "
                >{{ "HORSES.horseName" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (createHorseForm.submitted && !newHorse.birth) ||
                  serverErrorValidate?.birth
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !createHorseForm.submitted ||
                    (createHorseForm.submitted && !!newHorse.birth)
                  "
                  >{{ "CORE.dateOfBirth" | translate }}</span
                >
              </label>
              <div class="code relative">
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker.currentDate_input"
                  autocomplete="off"
                  name="birth"
                  (ngModelChange)="setBirthDateInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker.isActive = !datePicker.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="text-danger"
                *ngIf="createHorseForm.submitted && !newHorse.birth"
                >{{ "HORSES.dateBirthRequired" | translate }}</span
              >
              <span class="text-danger" *ngIf="serverErrorValidate?.birth">{{
                serverErrorValidate?.birth | translate
              }}</span>
            </a>
            <div class="box-control" *ngIf="datePicker.isActive">
              <datepicker
                class="datepicker"
                name="birth-datepicker"
                [startingDay]="1"
                [onlyCurrentMonth]="true"
                [minDate]="datePicker.minDate"
                [maxDate]="datePicker.maxDate"
                [showWeeks]="false"
                [initDate]="datePicker.currentDate"
                [dateDisabled]="dateDisabled"
                (selectionDone)="
                  setBirthDate($event);
                  datePicker.isActive = !datePicker.isActive
                "
              >
              </datepicker>
            </div>
          </div>

          <div class="box-control">
            <label class="label ng-select-label">
              <span>{{ "CORE.yard" | translate }}</span>
            </label>
            <ng-select
              [allowClear]="false"
              id="farms-selector"
              class="farms-selector"
              [ngClass]="{
                danger: createHorseForm.submitted && !newHorse.farm_id
              }"
              required
              #farmsSelector
              (selected)="setFarmId($event)"
              [items]="farms"
              name="farms"
              [active]="farms && farms.length === 1 ? farms : []"
              placeholder="{{ 'CORE.yardName' | translate }} {{
                (createHorseForm.submitted ? 'CORE.isRequired' : '') | translate
              }}"
            >
            </ng-select>
          </div>
          <div class="box-control clearfix" *ngIf="showInTheFoodChainToggle">
            <input
              type="checkbox"
              id="food-chain"
              name="is-food-chain"
              [checked]="newHorse.is_in_food_chain"
              (change)="newHorse.is_in_food_chain = !newHorse.is_in_food_chain"
            />
            <div class="toggle">
              <label for="food-chain"><i></i></label>
            </div>
            <div class="toggle__text">
              {{
                "HORSES." +
                  (newHorse.is_in_food_chain ? "inFoodChain" : "outFoodChain")
                  | translate
              }}
            </div>
          </div>
          <div class="box-control clearfix">
            <input
              type="checkbox"
              id="in-training"
              name="in-training"
              [checked]="newHorse.in_training"
              (change)="newHorse.in_training = !newHorse.in_training"
            />
            <div class="toggle">
              <label for="in-training">
                <i></i>
              </label>
            </div>
            <div class="toggle__text">
              {{
                "HORSES." +
                  (newHorse.in_training ? "inTraining" : "outOfTraining")
                  | translate
              }}
            </div>
          </div>
          <div
            class="box-control"
            id="microchip-number-box"
            [ngClass]="{ 'input--filled--model': newHorse?.microchip_number }"
          >
            <input
              type="text"
              class="form-control input__field only-number"
              [ngClass]="{
                error:
                  createHorseForm.submitted &&
                  (newMicrochipNumber.errors?.required ||
                    newMicrochipNumber.errors?.pattern ||
                    newMicrochipNumber.errors?.minlength)
              }"
              name="microchip-number"
              #newMicrochipNumber="ngModel"
              [(ngModel)]="newHorse.microchip_number"
              autocomplete="off"
              minlength="5"
              maxlength="5"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createHorseForm.submitted ||
                  (createHorseForm.submitted && newMicrochipNumber.valid)
                "
                >{{ "HORSES.microchipNumber" | translate }} ({{
                  "HORSES.last5Digits" | translate
                }})</span
              >
              <span
                *ngIf="
                  createHorseForm.submitted &&
                  newMicrochipNumber.errors?.required
                "
                >{{ "HORSES.microchipNumberRequired" | translate }}</span
              >
              <span
                *ngIf="
                  createHorseForm.submitted &&
                  newMicrochipNumber.errors?.pattern
                "
                >{{ "HORSES.microchipNumber" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createHorseForm.submitted &&
                  newMicrochipNumber.errors?.minlength
                "
                >{{ "HORSES.microchipNumber" | translate }}
                {{ "CORE.mustBeAtLeast5Chars" | translate }}</span
              >
            </label>
          </div>
          <!-- HISA ID -->
          <ng-container *ngIf="checkHorseIsBC2023Region()">
            <div
              class="box-control"
              id="hisa-id-box"
              [ngClass]="{ 'input--filled--model': newHorse?.hisa_id }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    createHorseForm.submitted &&
                    (newHorseHisaId.errors?.minlength ||
                      newHorseHisaId.errors?.maxlength)
                }"
                name="hisa_id"
                #newHorseHisaId="ngModel"
                [(ngModel)]="newHorse.hisa_id"
                autocomplete="off"
                spellcheck="false"
                autofocus
                minlength="5"
                maxlength="30"
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !createHorseForm.submitted ||
                    (createHorseForm.submitted && newHorseHisaId.valid)
                  "
                  >{{ "HORSES.hisa_id" | translate }}</span
                >

                <span
                  *ngIf="
                    createHorseForm.submitted &&
                    newHorseHisaId.errors?.minlength
                  "
                  >{{ "HORSES.hisa_id" | translate }}
                  {{ "CORE.mustBeAtLeast5Chars" | translate }}</span
                >
                <span
                  *ngIf="
                    createHorseForm.submitted &&
                    newHorseHisaId.errors?.maxlength
                  "
                  >{{ "HORSES.hisa_id" | translate
                  }}{{ "CORE.isTooBig" | translate }}</span
                >
              </label>
            </div>
          </ng-container>
          <!-- HISA ID END-->
          <div class="box-control">
            <div class="border-control">
              <image-upload
                #imageLoader
                [max]="1"
                [buttonCaption]="'HORSES.dragOrSelect' | translate"
                [extensions]="['jpeg', 'png']"
                (onFileUploadFinish)="setImage($event.file)"
                (onRemove)="newHorse.microchip = null"
              >
              </image-upload>
            </div>
          </div>

          <div
            class="box-control"
            id="horse-descr-box"
            [ngClass]="{ 'input--filled--model': newHorse?.description }"
          >
            <p class="custom_pop_desc">
              <span
                popover="{{ 'HORSES.otherInformation' | translate }}"
                triggers=""
                #pop="bs-popover"
              >
              </span>
            </p>

            <input
              type="text"
              class="form-control input__field"
              name="horse_descr"
              (blur)="pop.hide()"
              (focus)="pop.show()"
              [(ngModel)]="newHorse.description"
              autocomplete="off"
              spellcheck="false"
            />
            <label class="input__label"
              ><span
                >{{ "HORSES.description" | translate }}({{
                  "HORSES.optional" | translate
                }})</span
              ></label
            >
          </div>
        </div>
        <div *ngIf="serverError" style="margin-bottom: 30px">
          <div *ngFor="let err of serverError">
            <div class="box-btn text-center text-danger">{{ err }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            isRequestInProgress ||
            serverErrorValidate?.birth ||
            (createHorseForm.submitted &&
              (!newHorse.farm_id ||
                !newHorse.gender_id ||
                !newHorseName.valid ||
                !newHorse.birth ||
                !newHorse.farm_id))
          "
        >
          {{ "HORSES.addHorse" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div *ngIf="!billingService.getShow()" class="modal" id="unvaccinated">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.vacs" | translate }}</div>
    </div>

    <div class="modal-body">
      <div class="padding-block">
        <div class="text-center" style="font-size: 21px; margin: 45px 0 60px 0">
          <span *ngIf="unvaccinatedHorses?.length === 1">{{
            "HORSES.horse" | translate
          }}</span>
          <span *ngIf="unvaccinatedHorses?.length > 1">{{
            "HORSES.horses" | translate
          }}</span>
          {{ "HORSES.withoutVacs" | translate }}:
          <ng-container
            *ngFor="let horse of unvaccinatedHorses; let last = last"
          >
            <a class="close-modal" (click)="navigateToHorse(horse?.id)">{{
              horse?.name
            }}</a>
            <span *ngIf="!last">,</span>
            <span *ngIf="last">.</span>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        <button class="btn btn-green btn-full close-modal" type="button">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="premium">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "BILLING.billing" | translate }}</div>
    </div>

    <div class="modal-body">
      <div class="padding-block">
        <div class="text-center" style="font-size: 21px; margin: 45px 0 60px 0">
          {{ "BILLING.premiumWarning" | translate }}
          <div *ngIf="isTrial">
            {{ "BILLING.premiumWarningTrial" | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        <button class="btn btn-green btn-full close-modal" type="button">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>

<a
  class="btn btn-border btn-middle open-modal"
  id="unvaccinated-link"
  href="#unvaccinated"
  style="display: none"
></a>
<a
  class="btn btn-border btn-middle open-modal"
  id="premium-link"
  href="#premium"
  style="display: none"
></a>

<div class="container">
  <div class="hide-mobile">
    <div class="name-page">
      <span *ngIf="!isYardPage" class="name">{{
        "HORSES.horses" | translate
      }}</span>
      <span *ngIf="isYardPage" class="name"
        >{{ "HORSES.horses" | translate }} - {{ farm?.name }}</span
      >
      <ng-container *ngIf="currentCountry != 'aus'">
        <a
          (click)="selectAll()"
          [ngClass]="{ deselect: allSelected }"
          class="btn btn-border btn-middle select-all"
          >{{
            "CORE." + (allSelected ? "deselectAll" : "selectAll") | translate
          }}</a
        >
      </ng-container>
      <a
        href=""
        class="btn btn-border btn-middle open-modal"
        href="#edit-yard"
        (click)="getYard()"
        *ngIf="
          isYardPage &&
          (appMemory.user?.roles[0]?.id == 1 ||
            appMemory.user?.roles[0]?.id == 2)
        "
      >
        {{ "HORSES.editYard" | translate }}
      </a>
      <ng-container *ngIf="currentCountry != 'aus'">
        <a
          class="btn btn-border btn-middle open-modal"
          href="#add-horse"
          (click)="getFarms()"
          *ngIf="
            appMemory.user?.roles[0]?.id == 1 ||
            appMemory.user?.roles[0]?.id == 2
          "
        >
          <i class="icon icon-left icon-horse icon-middle"></i>
          {{ "HORSES.addHorse" | translate }}
        </a>
      </ng-container>

      <a
        class="btn btn-border btn-middle open-modal"
        href="#add-treatment"
        (click)="addTreatmentBulk()"
        *ngIf="selectedHorse.length"
      >
        <i class="icon icon-left icon-plus icon-middle"></i>
        {{ "HORSES.addTreatment" | translate }}
      </a>
      <a
        class="btn btn-border btn-middle open-modal"
        href="#add-vaccination"
        (click)="addVaccinationBulk()"
        *ngIf="selectedHorse.length"
      >
        <i class="icon icon-left icon-syringe icon-middle"></i>
        {{ "HORSES.addVacs" | translate }}
      </a>
      <a
        class="btn btn-border btn-middle open-modal"
        href="#add-note"
        (click)="addNodeSetDefaultDate(); addNoteBulk()"
        *ngIf="selectedHorse.length && !deactivateMultipleNotes"
      >
        <i class="icon icon-left icon-note icon-middle"></i>
        {{ "HORSES.addNote" | translate }}
      </a>

      <a
        class="btn btn-border btn-middle disabled"
        *ngIf="selectedHorse.length && deactivateMultipleNotes"
      >
        <i class="icon icon-left icon-note icon-middle"></i>
        {{ "HORSES.addNote" | translate }}
      </a>
    </div>
  </div>
  <div class="sorting-block hide-mobile">
    <a
      href="javascript:;"
      [ngClass]="{
        asc: sortObj.field == 'name' && sortObj.order == 'asc',
        desc: sortObj.field == 'name' && sortObj.order == 'desc'
      }"
      (click)="sortHorseBy('name')"
      class="sorting"
      >{{ "HORSES.name" | translate }}</a
    >
    <ng-container *ngIf="currentCountry != 'aus'">
      <a
        href="javascript:;"
        [ngClass]="{
          asc: sortObj.field == 'birth' && sortObj.order == 'asc',
          desc: sortObj.field == 'birth' && sortObj.order == 'desc'
        }"
        (click)="sortHorseBy('birth')"
        class="sorting"
        >{{ "HORSES.horseAge" | translate }}</a
      >
      <a
        href="javascript:;"
        [ngClass]="{
          asc: sortObj.field == 'lastTreatment' && sortObj.order == 'asc',
          desc: sortObj.field == 'lastTreatment' && sortObj.order == 'desc'
        }"
        (click)="sortHorseBy('lastTreatment')"
        class="sorting"
        >{{ "HORSES.dateOfLastTreat" | translate }}</a
      >
      <a
        href="javascript:;"
        [ngClass]="{
          asc: sortObj.field == 'lastVaccination' && sortObj.order == 'asc',
          desc: sortObj.field == 'lastVaccination' && sortObj.order == 'desc'
        }"
        (click)="sortHorseBy('lastVaccination')"
        class="sorting"
        >{{ "HORSES.dateOfLastVacc" | translate }}</a
      >
      <a
        href="javascript:;"
        [ngClass]="{
          asc: sortObj.field == 'created_at' && sortObj.order == 'asc',
          desc: sortObj.field == 'created_at' && sortObj.order == 'desc'
        }"
        (click)="sortHorseBy('created_at')"
        class="sorting"
        >{{ "HORSES.dateAddeToYard" | translate }}</a
      >
      <a
        href="javascript:;"
        [ngClass]="{
          asc: sortObj.field == 'farm_id' && sortObj.order == 'asc',
          desc: sortObj.field == 'farm_id' && sortObj.order == 'desc'
        }"
        (click)="sortHorseBy('farm_id')"
        class="sorting"
        >{{ "CORE.yard" | translate }}</a
      >
    </ng-container>
    <div class="box-right" style="padding-left: 20px">
      <a (click)="changeGrid()" id="gridView" title="Grid view">
        <i class="icon icon-grid"></i>
      </a>
      <a (click)="changeList()" id="listView" title="List view">
        <i class="icon icon-listview"></i>
      </a>
      <a
        (click)="changeCondensedView()"
        title="Condensed view"
        id="condensedView"
        style="margin-right: 10px"
      >
        <i class="icon icon-list"></i>
      </a>
    </div>
  </div>
  <div class="padding-block" id="grid">
    <div class="box-items-horse">
      <div class="no-data" *ngIf="!isRequestInProgress && horses?.length == 0">
        <span>{{ "HORSES.noHorses" | translate }}</span>
      </div>
      <div class="clearfix">
        <div
          class="item-horse"
          [ngClass]="{ selected: horse.isSelected && horse.is_active }"
          *ngFor="let horse of horses"
        >
          <div
            class="checkbox"
            *ngIf="horse.is_active && horse.farm.region != UKRegions.Aintree"
          >
            <span
              class="step"
              [ngClass]="{ active: horse.isSelected }"
              (click)="selectHorse(horse)"
            ></span>
          </div>
          <a class="item-horse-link" [routerLink]="['/horses', horse.id]">
            <div class="box-name">
              <div
                class="has-vet"
                *ngIf="
                  horse?.has_active_vaccinations ||
                  horse?.last_treatment?.length > 0
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 42 42"
                  style="enable-background: new 0 0 42 42"
                  xml:space="preserve"
                  width="16px"
                  height="16px"
                >
                  <path
                    d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"
                    fill="#D80027"
                  />
                </svg>
              </div>
              <div class="age">
                {{ horse?.birth | dateObj | date : "dd MMM y" }}
              </div>
              <div class="name">
                <span
                  [class.name-pl]="
                    horse?.has_active_vaccinations ||
                    horse?.last_treatment?.length > 0
                  "
                  >{{ horse?.name }}</span
                >
                <div
                  class="status blue"
                  *ngIf="appMemory?.static"
                  [ngStyle]="{
                    color: '#' + appMemory.gendersList[horse?.gender_id].color
                  }"
                >
                  {{
                    "HORSES." +
                      (appMemory.gendersList[horse?.gender_id].name | lowercase)
                      | translate
                      | uppercase
                  }}
                </div>
                <div class="status grey" *ngIf="!horse?.is_active">
                  {{ "HORSES.archive" | translate }}
                </div>
              </div>
            </div>
            <div class="hide-mobile box-about">
              <div
                class="treatment"
                *ngIf="
                  horse?.last_treatment && horse?.last_treatment.length > 0
                "
              >
                <div class="label">{{ "CORE.lastTreatment" | translate }}:</div>
                <div class="date">
                  {{
                    horse?.last_treatment[0]?.start_date ||
                      horse?.last_treatment[0]?.prescriptions[0]?.created_at
                      | dateObj
                      | date : "dd MMM y"
                  }}
                  &mdash;
                  {{
                    horse?.last_treatment[0]?.withdrawal
                      | dateObj
                      | date : "dd MMM y"
                  }}<span class="gray"> |</span>
                  <span class="green">
                    {{
                      "CORE." +
                        (appMemory?.translates[horse?.last_treatment[0]?.status]
                          | lowercase) | translate
                    }}
                  </span>
                </div>
                <div class="person label">
                  {{ appMemory.user?.name }} {{ appMemory.user?.last_name }}
                  <i
                    class="icon icon-right"
                    [ngClass]="{
                      'icon-trainer': appMemory.user?.roles[0]?.id == 1,
                      'icon-assistant': appMemory.user?.roles[0]?.id == 2,
                      'icon-vet': appMemory.user?.roles[0]?.id == 3
                    }"
                  >
                  </i>
                </div>
              </div>
              <div class="text">{{ horse?.description }}</div>
              <div class="count" *ngIf="horse?.notes_count">
                <i class="fa fa-file-text-o icon-middle"></i>
                {{ horse?.notes_count }}
              </div>
              <div
                class="trainer"
                *ngIf="horse?.instructor && appMemory.user?.roles[0]?.id != 1"
              >
                {{
                  horse?.instructor?.name + " " + horse?.instructor?.last_name
                }}
                <i class="icon icon-right icon-trainer"></i>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- <button class="btn select-all" (click)="selectAll()" [ngClass]="{'deselect': allSelected}">{{allSelected ? 'deselect' : 'select all'}}</button> -->

    <div class="text-center">
      <app-loader *ngIf="pagination.inProgress || !horses"></app-loader>
      <button
        class="btn lazy-btn"
        (click)="addNewData(horses)"
        *ngIf="!pagination.inProgress && !!pagination.nextPage"
      >
        {{ "CORE.showMore" | translate }}
      </button>
    </div>
  </div>

  <div class="padding-block" id="list">
    <div class="box-items-horse">
      <div class="no-data" *ngIf="!isRequestInProgress && horses?.length == 0">
        <span>{{ "HORSES.noHorses" | translate }}</span>
      </div>

      <div
        class="table-treatment"
        *ngIf="!isRequestInProgress && horses?.length > 0"
      >
        <div class="table-header">
          <div class="box-table">
            <div class="item-cell cell-checkbox" *ngIf="this.currentCountry != 'aus'"></div>
            <div class="item-cell cell-duration">
              {{ "HORSES.name" | translate }}
            </div>
            <div class="item-cell cell-minim">
              {{ "CORE.yard" | translate }}
            </div>

            <div
              class="item-cell cell-status"
              *ngIf="
                !horsesCondensedListView &&
                this.currentCountry != 'us' &&
                this.currentCountry != 'aus'
              "
            >
              {{ "HORSES.dateofNextAdmin" | translate }}
            </div>
            <div class="item-cell cell-minim">
              {{ "HORSES.gender" | translate }}
            </div>
            <div class="item-cell cell-minim">
              {{ "HORSES.horseAge" | translate }}
            </div>
            <div class="item-cell cell-status" *ngIf="!horsesCondensedListView && this.currentCountry != 'aus'">
              {{ "HORSES.actions" | translate }}
            </div>
          </div>
        </div>
        <div class="table-body">
          <div class="item-treatment">
            <div class="item-cell cell-event">
              <div
                class="item-horse-list"
                [ngClass]="{
                  selected: horse.isSelected && horse.is_active,
                  p_0: horsesCondensedListView
                }"
                *ngFor="let horse of horses"
              >
                <div class="box-table">
                  <div
                    class="item-cell cell-checkbox"
                    [class.checkbox]="
                      horse.is_active && horse.farm.region != UKRegions.Aintree
                    "
                    *ngIf="this.currentCountry != 'aus'"
                  >
                    <span
                      class="step absolute-delete"
                      [ngClass]="{ active: horse.isSelected }"
                      (click)="selectHorse(horse)"
                    ></span>
                  </div>
                  <a
                    class="item-horse-link"
                    [routerLink]="['/horses', horse.id]"
                  >
                    <div class="line-even">
                      <div class="item-cell cell-duration">
                        {{ horse?.name }}
                      </div>
                      <div *ngIf="horse.farm" class="item-cell cell-minim">
                        {{ horse?.farm.name }}
                      </div>
                      <div
                        class="item-cell cell-status text-center"
                        *ngIf="
                          !horsesCondensedListView &&
                          this.currentCountry != 'us' &&
                          this.currentCountry != 'aus'
                        "
                      >
                        {{
                          horse?.vaccinations[0]?.immunization ||
                            horse?.vaccinations[0]?.deadline
                            | dateObj
                            | date : "dd MMM y"
                        }}
                        <div
                          *ngFor="
                            let vc of horse?.vaccinations[0]
                              ?.vaccination_country
                          "
                        >
                          ({{ "ISO." + vc?.country?.iso | translate }})
                          {{
                            vc?.immunization || vc?.deadline
                              | dateObj
                              | date : "dd MMM y"
                          }}
                        </div>
                      </div>
                      <div
                        class="item-cell cell-minim text-center"
                        [ngStyle]="{
                          color:
                            '#' + appMemory.gendersList[horse?.gender_id].color
                        }"
                      >
                        {{
                          "HORSES." +
                            (appMemory.gendersList[horse?.gender_id].name
                              | lowercase)
                            | translate
                            | uppercase
                        }}
                      </div>
                      <div class="item-cell cell-minim text-center">
                        {{ horse?.birth | age }}
                      </div>
                    </div>
                  </a>
                  <div
                    class="item-cell cell-actions"
                    *ngIf="!horsesCondensedListView && this.currentCountry != 'aus'"
                  >
                    <ng-container *ngIf="horse.isSelected">
                      <a
                        class="btn btn-border btn-middle open-modal"
                        href="#add-treatment"
                        (click)="
                          oneHorseId = horse.id; oneHorseName = horse.name
                        "
                      >
                        <i class="icon icon-left icon-plus icon-middle"></i>
                      </a>
                      <a
                        class="btn btn-border btn-middle open-modal"
                        href="#add-vaccination"
                        (click)="
                          oneHorseId = horse.id; oneHorseName = horse.name
                        "
                      >
                        <i class="icon icon-left icon-syringe icon-middle"></i>
                      </a>

                      <ng-container
                        *ngIf="
                          this.currentCountry == 'us';
                          else usAddNoteButtonTemplate
                        "
                      >
                        <a
                          class="btn btn-border btn-middle open-modal"
                          *ngIf="userCanAddHorseNote(horse)"
                          href="#add-note"
                          (click)="
                            addNodeSetDefaultDate(); prepareHorseNote(horse)
                          "
                        >
                          <i class="icon icon-left icon-note icon-middle"></i>
                        </a>
                      </ng-container>
                      <ng-template #usAddNoteButtonTemplate>
                        <a
                          class="btn btn-border btn-middle open-modal"
                          href="#add-note"
                          (click)="
                            oneHorseId = horse.id; oneHorseName = horse.name
                          "
                        >
                          <i class="icon icon-left icon-note icon-middle"></i>
                        </a>
                      </ng-template>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-loader *ngIf="pagination.inProgress"></app-loader>
      </div>
    </div>

    <!-- <button class="btn select-all" (click)="selectAll()" [ngClass]="{'deselect': allSelected}">{{allSelected ? 'deselect' : 'select all'}}</button> -->

    <div class="text-center">
      <app-loader *ngIf="pagination.inProgress || !horses"></app-loader>
      <button
        class="btn lazy-btn"
        (click)="addNewData(horses)"
        *ngIf="!pagination.inProgress && !!pagination.nextPage"
      >
        {{ "CORE.showMore" | translate }}
      </button>
    </div>
  </div>

  <div
    class="footer-button show-mobile"
    *ngIf="
      (appMemory.user?.roles[0]?.id == 1 ||
        appMemory.user?.roles[0]?.id == 2) &&
      !selectedHorse.length &&
      !billingService.getShow()
    "
  >
    <a
      class="btn btn-green btn-full open-modal"
      href="#add-horse"
      (click)="getFarms()"
      >{{ "HORSES.addHorse" | translate }}</a
    >
  </div>
  <div
    class="footer-button selectedHorse show-mobile"
    *ngIf="selectedHorse.length"
  >
    <a
      class="btn btn-green open-modal"
      href="#add-treatment"
      (click)="addTreatmentBulk()"
    >
      {{ "TREATS.addTreat" | translate }}
    </a>
    <a
      class="btn btn-green open-modal"
      href="#add-vaccination"
      (click)="addVaccinationBulk()"
    >
      {{ "HORSES.addVacs" | translate }}
    </a>

    <ng-container
      *ngIf="this.currentCountry == 'us'; else usAddNoteButtonTemplate2"
    >
      <a
        class="btn btn-green open-modal"
        href="#add-note"
        (click)="addNoteBulk()"
        *ngIf="!deactivateMultipleNotes"
      >
        {{ "HORSES.addNote" | translate }}
      </a>

      <a class="btn btn-green disabled" *ngIf="deactivateMultipleNotes">
        {{ "HORSES.addNote" | translate }}
      </a>
    </ng-container>
    <ng-template #usAddNoteButtonTemplate2>
      <a
        class="btn btn-green open-modal"
        href="#add-note"
        (click)="addNoteBulk()"
      >
        {{ "HORSES.addNote" | translate }}
      </a>
    </ng-template>
  </div>
</div>
