<div class="container">

    <div class="hide-mobile" *ngIf="!isRequestInProgress">
        <div class="name-page">
            <span class="name">{{ 'INVOICES.invoices' | translate}} ({{invoices?.length}})</span>
        </div>
    </div>
    <tabset class="nav-tabs nav-left" *ngIf="!isRequestInProgress">
        <tab heading="{{'PROFILE.profile' | translate}}" [active]="false" (select)="this.goToProfile()" (deselect)="true"></tab>
        <tab heading="{{'INVOICES.invoices' | translate}}" [active]="true" (select)="true" (deselect)="false"></tab>
        <tab heading="{{'PROFILE.informations' | translate}}" [active]="false" (select)="this.goToInfo()" (deselect)="true"></tab>
    </tabset>

    <div class="padding-block">

        <app-loader *ngIf="isRequestInProgress"></app-loader>
        <div class="no-data" *ngIf="!isRequestInProgress && invoices?.length == 0 && invoices?.length == 0">{{ 'INVOICES.noInvoices' | translate}}</div>

        <div class="table-treatment" *ngIf="!isRequestInProgress && (invoices?.length > 0 || invoices?.length > 0)">
            <div class="table-header hide-mobile">
                <div class="box-table">
                    <div class="item-cell cell-duration">{{ 'INVOICES.amount' | translate}}</div>
                    <div class="item-cell cell-duration">{{ 'INVOICES.currency' | translate}}</div>
                    <div class="item-cell cell-status">{{ 'INVOICES.status' | translate }}</div>
                    <div class="item-cell cell-status">{{ 'INVOICES.invoiceNumber' | translate }}</div>
                    <div class="item-cell cell-status">{{ 'INVOICES.paymentDue' | translate}}</div>
                    <div class="item-cell cell-status">{{ 'INVOICES.created' | translate}}</div>
                    <div class="item-cell cell-status">{{ 'INVOICES.actions' | translate}}</div>
                </div>
            </div>
            <div class="table-body">
                <!--<ng-template ngFor let-key [ngForOf]="invoices">-->
                    <div class="item-treatment">
                        <!--<div class="item-cell cell-date show-mobile">-->
                            <!--<div class="date">-->
                                <!--<div class="day">{{validDate(key.status)}}</div>-->
                                <!--{{key.status}}-->
                            <!--</div>-->
                        <!--</div>-->
                        <div class="item-cell cell-event">
                            <ng-template ngFor let-invoice [ngForOf]="invoices">

                                <div class="item-treatment show-mobile">
                                    <div class="item-cell cell-date">
                                        <div class="date">
                                            <div class="day invoice-state">{{invoice.status}}</div>
                                        </div>
                                    </div>
                                    <div class="item-cell cell-event">
                                        <div class="line-even">
                                            <div class="item-cell cell-status invoice-total">
                                               {{'INVOICES.amount' | translate}}: {{invoice.total || 0}} {{invoice.currency.toUpperCase()}}
                                            </div>
                                            <div class="item-cell cell-duration">
                                                {{'INVOICES.invoiceNumber' | translate}}: {{invoice.number.toUpperCase()}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="line-even hide-mobile">
                                    <div class="item-cell cell-status">{{totals(invoice.total) || 0}}</div>
                                    <div class="item-cell cell-duration">{{invoice.currency.toUpperCase()}}</div>

                                    <div class="item-cell cell-status">
                                        <span class="invoice-status" [ngClass]="{
                            'green': invoice?.status == 'paid'}">
                                            {{invoice.status.toUpperCase()}}
                                        </span>
                                    </div>

                                    <div class="item-cell cell-status">
                                        {{invoice.number.toUpperCase()}}
                                    </div>

                                    <div class="item-cell cell-status">
                                        <span>
                                            &nbsp;{{validDate(invoice.due_date)}}
                                        </span>
                                    </div>

                                    <div class="item-cell cell-status">
                                        <span>
                                            {{validDate(invoice.created)}}
                                        </span>
                                    </div>

                                    <div class="item-cell cell-status">
                                        <a class="btn btn-border btn-invoice" [ngClass]="{
                            'draft': invoice?.status == 'draft'}" *ngIf="(invoice.status == 'draft')">
                                            {{'INVOICES.waiting' | translate}}
                                        </a>

                                        <a class="btn btn-border btn-invoice" href="{{invoice.hosted_invoice_url}}" target="_blank" *ngIf="!(invoice.status == 'draft')">
                                            {{ (invoice.status == 'paid') ? ('INVOICES.viewNow' | translate) : ('INVOICES.payNow' | translate)}}
                                        </a>
                                    </div>
                                </div>

                            </ng-template>

                        </div>
                    </div>
                <!--</ng-template>-->

            </div>

            <app-loader *ngIf="pagination.inProgress"></app-loader>

            <div class="no-data invoices-empty" *ngIf="pagination.nextPage === ''">{{('INVOICES.noMoreInvoices' | translate)}}</div>

            <div class="text-center btn-more" (click)="addNewData(invoices)" *ngIf="!pagination.inProgress && !!pagination.nextPage">
                <button class="btn lazy-btn">
                    {{ 'INVOICES.viewMore' | translate}}
                </button>
            </div>

        </div>
    </div>


</div>
