<header>
  <div class="left-part">
    <a class="btn btn-menu" *ngIf="!appMemory.pageInfo.isHorse"><i class="icon icon-menu icon-middle"></i></a>
    <a class="btn" [routerLink]="['/horses']" *ngIf="appMemory.pageInfo.isHorse"><i class="icon icon-back icon-middle"></i></a>
  </div>
  <div class="right-part">
    <a class="btn open-modal" *ngIf="appMemory.user?.roles[0]?.id == 1 && appMemory.pageInfo.isHorse && appMemory?.horse?.is_active" href="#edit-horse"><i class="icon icon-edit icon-middle"></i></a>
  </div>
  <div class="right-part" [hidden]="this.router.url.split('?')[0] !== '/horses'"><a class="btn btn-toggle btn-toggle-search" data-toggle="#search-form-toggle"><i class="icon icon-search icon-middle hide-open"></i><i class="icon icon-close white icon-middle show-open"></i></a></div>
  <div class="center-part">
    <div class="title">{{appMemory?.pageInfo?.title}}</div>
    <div class="search-form-toggle" id="search-form-toggle" [hidden]="this.router.url.split('?')[0] !== '/horses'">
      <form class="form">
        <div class="box-control-search toggle-show">
          <input #searchRef class="search-control" placeholder="{{ 'CORE.search' | translate}}">
        </div>
      </form>
    </div>
  </div>
</header>
