<div class="container">
  <div class="hide-mobile">
    <div class="name-page">
      <span class="name">Rule Book</span>
    </div>
  </div>
  <div class="padding-block">
    <div class="block-text rules-text">
      <div class="name-box">Medicines Register</div>
      <p>
        All medications used on any horse in your training establishment or under your care should be entered.
      </p>
      <p>
        This includes topical ointments, oral medicines and drugs given by injection.
      </p>
      <p>
        All entries should be made on the same day that the medication is given.
      </p>
      <div class="table-rules-wrapper">
        <table class="table table-rules">
          <tr>
            <td>Date of administration to horse</td>
            <td>Insert any date on which the animal remedy was administered</td>
          </tr>
          <tr>
            <td>Authorised name of animal remedy administered (including brand and generic name)</td>
            <td>Insert the name of the brand and the drug administered</td>
          </tr>
          <tr>
            <td>Route of administration</td>
            <td>Enter the route of administration i.e.
                  Oral (O),
                  Topical (T),
                  Intravenous (I/V),
                  Intramuscular (I/M),
                  Subcutaneous (S/C),
                  Intra articular(I/A)
            </td>
          </tr>
          <tr>
            <td>Quantity of animal remedy administered</td>
            <td>Enter the quantity given each time there is an administration</td>
          </tr>
          <tr>
            <td>Reason for administration</td>
            <td>Specify the reason for the administration e.g. lameness etc.</td>
          </tr>
          <tr>
            <td>Name of person who administered remedy</td>
            <td>This should be the person who actually gave the drug to the horse. Prescription only medicines (POM) must also be authorised by the Trainer’s veterinary surgeon</td>
          </tr>
          <tr>
            <td>Name of prescribing Veterinary Surgeon (when applicable)</td>
            <td>Enter the veterinary surgeon’s name who prescribed the medicines</td>
          </tr>
          <tr>
            <td>Recommended withdrawal time (if applicable)</td>
            <td>The withdrawal time recommended by the veterinary surgeon should be entered</td>
          </tr>
          <tr>
            <td>Trainer’s Signature</td>
            <td>The trainer should sign as having authorised all administrations</td>
          </tr>
        </table>
      </div>
      <p>
        The Stewards of the Governing Bodies recommend that the declaration that the horse is “not for human consumption” is signed on all passports. If this is not done there are restrictions on the medication which can be used and more stringent reporting requirements. You should consult with your veterinary surgeon for details.
      </p>

      <div class="name-box">Guidelines for Trainers Regarding the Responsible Use of Veterinary Medicines</div>
      <p>
        NOTE: The Stewards of the Governing Bodies wish to point out that familiarity with the guidelines as set out hereunder, is a condition on which the licence to Trainers is issued and renewed.
      </p>
      <ol class="rules-list">
        <li>The following general guidelines are provided in order to reduce the occurrence of inadvertent race day positives arising from the presence of Prohibited Substances in blood and urine.</li>
        <li>Proper liaison and regular communication with the stable’s Veterinary Surgeon is essential at all times to ensure correct use of therapeutic medicines. The Trainer must ensure that only the appropriate prescribed medicines are used, and that they are withdrawn at the correct time prior to racing, in accordance with the professional judgement and opinion of the veterinary surgeon.</li>
        <li>All Trainers/Authorised stable personnel should be acquainted with the Rules as they apply to the definition of “Prohibited Substances”.</li>
        <li>Trainers should be aware that most of the commonly used, licensed medicines are in fact Prohibited Substances if detected in a raceday sample.</li>
        <li>All medicines should be kept in safe custody, and stored in accordance with the requirements of current medicines legislation and unavailable, other than to authorised stable personnel.</li>
        <li>Medicines must not be left carelessly dispersed around stables and loose boxes or subject to free and unsupervised availability.</li>
        <li>Horses receiving legitimate therapeutic medication, as prescribed by a Veterinary Surgeon, should not be stabled in close proximity to horses about to run, and similarly, all efforts must be made to avoid cross contamination of medicines, via feed, bedding, urine etc. between medicated and competing horses. Proximity between these animals should be avoided at all times. Contamination from handlers is always a possibility, and anyone using skin medication should avoid contact with the horse.</li>
        <li>All medicines must be clearly labelled.</li>
        <li>All horses must be clearly and correctly identified before they receive a medicine.</li>
        <li>Use of all medicines must be clearly recorded in the Medicines Register, which must be made freely available for inspection if necessary, by officers of the Turf Club. Particular attention must be paid to the medicine administered; date of administration of the medicine, persons who administered the medicine, dose administered and reason for the administration. This must be in conformity with the current Animal Remedies Regulations.</li>
        <li>The Trainer must take responsibility at all times for his/her stable staff. The Trainer must ensure that they are well briefed and particularly knowledgeable on the specific issue of Prohibited Substances and that they are also competent, at all times, to ensure the correct administration of medicines to the correct horse, as prescribed by the Veterinary Surgeon. It is desirable that one person only in any training establishment is permitted to administer medicines and is responsible for recording the details of each administration in the Medicines Register.</li>
        <li>Only authorised bona fide medicines in compliance with the requirements of current medicines legislation may be administered to horses.</li>
        <li>The Trainer is at all times responsible for the correct use of medicines in his premises, and it is incumbent on him/her to ensure that his/her staff are adequately trained on all such relevant matters. Further, he/she must ensure that following consultation with the Veterinary Surgeon, that they will be requested to take responsibility for medicines administration, only on the direct instruction of the trainer.</li>
        <li>It is advisable, that at all times, the administration of a medicine to a stabled Horse is witnessed by the Trainer or his/her authorised representative.</li>
        <li>The Medicines Register must be kept up to date at all times.</li>
      </ol>
    </div>
  </div>
</div>
