<div class="modal" id="vaccination-warning">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-body modal-form">
      <div class="padding-full-block">
        <h3>
          The administration date you have selected is more than
          {{
            this.isUSCountry
              ? this.usVaccinationPeriod
              : this.europeVaccinationPeriod
          }}
          since the last booster administered. This horse must restart the
          vaccination schedule. Click OK in order to treat this vaccination as a
          Primary vaccination or click cancel to amend the date of
          administration
        </h3>
      </div>
      <div class="modal-footer padding-full-block">
        <div class="row-half">
          <button
            class="btn btn-green btn-full close-modal"
            type="submit"
            (click)="switchFromBoosterToPrimary()"
          >
            Ok
          </button>
        </div>
        <div class="row-half">
          <button class="btn btn-red btn-full close-modal" type="button">
            {{ "CORE.cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<a
  class="btn btn-border btn-middle open-modal"
  id="modal-vaccination-link"
  href="#vaccination-warning"
  style="display: none"
></a>

<div class="modal" id="edit-horse" *ngIf="horse?.is_active">
  <div class="modal-wrapper" style="padding-left: 15px; padding-right: 15px">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(); reasignHorse()"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(); reasignHorse()"
        >
          <i class="icon icon-back icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.editHorse" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #updateHorseForm="ngForm"
      novalidate
      (ngSubmit)="updateHorse(updateHorseForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div class="box-nav">
            <ul class="nav-type line">
              <li
                class="change-active"
                [ngClass]="{ active: gender.id == newHorse.gender_id }"
                *ngFor="let gender of appMemory?.static?.genders"
                style="text-transform: capitalize"
              >
                <a
                  class="btn"
                  (click)="newHorse.gender_id = gender.id"
                  [ngClass]="{
                    danger: updateHorseForm.submitted && !newHorse.gender_id
                  }"
                >
                  <div class="name">
                    {{
                      "HORSES." + (gender.name | lowercase)
                        | translate
                        | uppercase
                    }}
                  </div>
                </a>
              </li>
            </ul>
            <div
              class="box-btn text-center text-danger"
              style="margin-bottom: 35px"
              *ngIf="updateHorseForm.submitted && !newHorse.gender_id"
            >
              {{ "HORSES.mustChooseHorseGender" | translate }}
            </div>
          </div>
          <div
            class="box-control"
            id="horse-name-box"
            [ngClass]="{ 'input--filled--model': newHorse?.name }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateHorseForm.submitted &&
                  (newHorseName.errors?.required ||
                    newHorseName.errors?.pattern ||
                    newHorseName.errors?.maxlength)
              }"
              name="horse_name"
              maxlength="40"
              #newHorseName="ngModel"
              [(ngModel)]="newHorse.name"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateHorseForm.submitted ||
                  (updateHorseForm.submitted && newHorseName.valid)
                "
                >{{ "HORSES.horseName" | translate }}</span
              >
              <span
                *ngIf="
                  updateHorseForm.submitted && newHorseName.errors?.required
                "
                >{{ "HORSES.horseName" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateHorseForm.submitted && newHorseName.errors?.pattern
                "
                >{{ "HORSES.horseName" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateHorseForm.submitted && newHorseName.errors?.maxlength
                "
                >{{ "HORSES.horseName" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger: updateHorseForm.submitted && !newHorse.birth
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !updateHorseForm.submitted ||
                    (updateHorseForm.submitted && !!newHorse.birth)
                  "
                  >{{ "CORE.dateOfBirth" | translate }}</span
                >
              </label>
              <div class="code relative" ng>
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker.currentDate_input"
                  autocomplete="off"
                  name="birth"
                  (change)="setBirthDateInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker.isActive = !datePicker.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="text-danger"
                *ngIf="updateHorseForm.submitted && !newHorse.birth"
                >{{ "HORSES.dateBirthRequired" | translate }}</span
              >
              <span class="text-danger" *ngIf="serverErrorValidate?.birth">{{
                serverErrorValidate?.birth | translate
              }}</span>
            </a>
            <div class="box-control" *ngIf="datePicker.isActive">
              <datepicker
                class="datepicker"
                name="birth-datepicker"
                [startingDay]="1"
                [onlyCurrentMonth]="true"
                [minDate]="datePicker.minDate"
                [maxDate]="datePicker.maxDate"
                [showWeeks]="false"
                [initDate]="datePicker.currentDate"
                [dateDisabled]="dateDisabled"
                (selectionDone)="
                  setBirthDate($event);
                  datePicker.isActive = !datePicker.isActive
                "
              >
              </datepicker>
            </div>
          </div>

          <div class="box-control" *ngIf="farmSelectModelLoaded">
            <label class="label ng-select-label">
              <span>{{ "CORE.yard" | translate }}</span>
            </label>
            <ng-select
              [allowClear]="false"
              id="farms-selector"
              class="farms-selector"
              [ngClass]="{
                danger: updateHorseForm.submitted && !newHorse.farm_id
              }"
              required
              #farmsSelector
              [active]="farmSelectModel"
              (selected)="setFarmId($event)"
              [items]="farms"
              name="farms"
              placeholder="{{ 'CORE.yardName' | translate }} {{
                (updateHorseForm.submitted ? 'CORE.isRequired' : '') | translate
              }}"
            >
            </ng-select>
          </div>
          <div class="box-control clearfix" *ngIf="showInTheFoodChainToggle">
            <input
              type="checkbox"
              id="food-chain"
              name="is-food-chain"
              [checked]="newHorse.is_in_food_chain"
              (change)="newHorse.is_in_food_chain = !newHorse.is_in_food_chain"
            />
            <div class="toggle">
              <label for="food-chain">
                <i></i>
              </label>
            </div>
            <div class="toggle__text">
              {{
                "HORSES." +
                  (newHorse.is_in_food_chain ? "inFoodChain" : "outFoodChain")
                  | translate
              }}
            </div>
          </div>
          <div class="box-control clearfix">
            <input
              type="checkbox"
              id="in-training"
              name="in-training"
              [checked]="newHorse.in_training"
              (change)="newHorse.in_training = !newHorse.in_training"
            />
            <div class="toggle">
              <label for="in-training">
                <i></i>
              </label>
            </div>
            <div class="toggle__text">
              {{
                "HORSES." +
                  (newHorse.in_training ? "inTraining" : "outOfTraining")
                  | translate
              }}
            </div>
          </div>
          <div
            class="box-control"
            id="microchip-number-box"
            [ngClass]="{ 'input--filled--model': newHorse?.microchip_number }"
          >
            <input
              type="text"
              class="form-control input__field only-number"
              [ngClass]="{
                error:
                  updateHorseForm.submitted &&
                  (newMicrochipNumber.errors?.required ||
                    newMicrochipNumber.errors?.pattern ||
                    newMicrochipNumber.errors?.minlength)
              }"
              name="microchip-number"
              #newMicrochipNumber="ngModel"
              [(ngModel)]="newHorse.microchip_number"
              autocomplete="off"
              minlength="5"
              maxlength="5"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateHorseForm.submitted ||
                  (updateHorseForm.submitted && newMicrochipNumber.valid)
                "
                >{{ "HORSES.microchipNumber" | translate }} ({{
                  "HORSES.last5Digits" | translate
                }})</span
              >
              <span
                *ngIf="
                  updateHorseForm.submitted &&
                  newMicrochipNumber.errors?.required
                "
                >{{ "HORSES.microchipNumberRequired" | translate }}</span
              >
              <span
                *ngIf="
                  updateHorseForm.submitted &&
                  newMicrochipNumber.errors?.pattern
                "
                >{{ "HORSES.microchipNumber" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  updateHorseForm.submitted &&
                  newMicrochipNumber.errors?.minlength
                "
                >{{ "HORSES.microchipNumber" | translate }}
                {{ "CORE.mustBeAtLeast5Chars" | translate }}</span
              >
            </label>
          </div>

          <!-- HISA ID -->
          <ng-container *ngIf="checkHorseIsBC2023Region()">
            <div
              class="box-control"
              id="hisa-id-box"
              [ngClass]="{ 'input--filled--model': newHorse?.hisa_id }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    updateHorseForm.submitted &&
                    (newHorseHisaId.errors?.minlength ||
                      newHorseHisaId.errors?.maxlength)
                }"
                name="hisa_id"
                #newHorseHisaId="ngModel"
                [(ngModel)]="newHorse.hisa_id"
                autocomplete="off"
                spellcheck="false"
                autofocus
                minlength="5"
                maxlength="30"
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !updateHorseForm.submitted ||
                    (updateHorseForm.submitted && newHorseHisaId.valid)
                  "
                  >{{ "HORSES.hisa_id" | translate }}</span
                >
                <span
                  *ngIf="
                    updateHorseForm.submitted &&
                    newHorseHisaId.errors?.minlength
                  "
                  >{{ "HORSES.hisa_id" | translate }}
                  {{ "CORE.mustBeAtLeast5Chars" | translate }}</span
                >
                <span
                  *ngIf="
                    updateHorseForm.submitted &&
                    newHorseHisaId.errors?.maxlength
                  "
                  >{{ "HORSES.hisa_id" | translate
                  }}{{ "CORE.isTooBig" | translate }}</span
                >
              </label>
            </div>
          </ng-container>
          <!-- HISA ID END-->

          <div class="box-control">
            <div class="border-control">
              <image-upload
                #imageLoader
                [max]="1"
                [maxFileSize]="maxImgSize"
                [buttonCaption]="'HORSES.dragOrSelect' | translate"
                [extensions]="['jpeg', 'png']"
                (onFileUploadFinish)="setImage($event.file)"
                (onRemove)="
                  newHorse.microchip = null; isMicrochipChanged = true
                "
              >
              </image-upload>
            </div>
          </div>

          <div
            class="box-control"
            id="horse-descr-box"
            [ngClass]="{ 'input--filled--model': newHorse?.description }"
          >
            <p class="custom_pop_desc">
              <span
                popover="Enter any other information you would like to record about the horse here"
                triggers=""
                #pop="bs-popover"
              >
              </span>
            </p>

            <input
              type="text"
              class="form-control input__field"
              name="horse_descr"
              (blur)="pop.hide()"
              (focus)="pop.show()"
              [(ngModel)]="newHorse.description"
              autocomplete="off"
              spellcheck="false"
            />
            <label class="input__label">
              <span
                >{{ "HORSES.description" | translate }}({{
                  "HORSES.optional" | translate
                }})</span
              >
            </label>
          </div>
        </div>
        <div *ngIf="serverError" style="margin-bottom: 30px">
          <div *ngFor="let err of serverError">
            <div class="box-btn text-center text-danger">{{ err }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            isRequestInProgress ||
            (updateHorseForm.submitted && !newHorseName.valid) ||
            serverErrorValidate?.birth
          "
        >
          {{ "HORSES.saveEdits" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<!-- treatment view -->
<div class="modal" id="treatment">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
        <ng-container *ngIf="!horse?.is_eligible && !isAUSCountry">
          <a
            *ngIf="isTreatmentEditable(currentTreatment)"
            class="btn open-modal hide-mobile"
            href="#edit-treatment"
            (click)="hideModal(); cloneTreatment(currentTreatment)"
          >
            <i class="icon icon-edit dark icon-middle icon-left"></i>
            {{ "CORE.edit" | translate }}</a
          >
        </ng-container>
      </div>
      <ng-container *ngIf="!horse?.is_eligible">
        <div
          class="right-part"
          *ngIf="isTreatmentEditable(currentTreatment) || !horse?.is_eligible"
        >
          <a
            class="btn open-modal show-mobile"
            href="#edit-treatment"
            (click)="hideModal(); cloneTreatment(currentTreatment)"
          >
            <i class="icon icon-edit dark icon-middle"></i>
          </a>
        </div>
      </ng-container>

      <div class="title">{{ "TREATS.treatment" | translate }}</div>
    </div>
    <div class="modal-body">
      <div
        class="status-treatment"
        [ngClass]="{
          pending: currentTreatment?.request?.status == 'pending',
          active:
            currentTreatment?.status != 'closed' &&
            (currentTreatment?.request?.status == 'approved' ||
              !currentTreatment?.request)
        }"
      >
        {{
          "TREATS." +
            (currentTreatment?.status == "closed"
              ? "closed"
              : appMemory?.translates[currentTreatment?.request?.status] ||
                "approved") | translate
        }}
      </div>
      <div class="padding-block">
        <form class="form">
          <div class="box-control">
            <div class="name-box">{{ horse?.name }}</div>
          </div>
          <ng-container *ngIf="tabs.displayAintreeTabs">
            <div
              class="box-control input--filled"
              *ngFor="let t of treatmentTypes"
            >
              <div *ngIf="t.type === currentTreatment?.type">
                <div class="text-value name input__field">{{ t.title }}</div>
                <label class="input__label">
                  <span>{{ "TREATS.type" | translate }}</span>
                </label>
              </div>
            </div>
            <div
              class="box-info"
              [innerHTML]="
                'TREATS.forms.' + currentTreatment?.type + '.intro' | translate
              "
            ></div>
            <br />
          </ng-container>

          <ng-container *ngIf="isAUSCountry">
            <div
              class="box-control input--filled"
              *ngFor="let t of ausTreatmentTypes"
            >
              <div *ngIf="t.type === currentTreatment?.type">
                <div class="text-value name input__field">{{ t.title }}</div>
                <label class="input__label">
                  <span>{{ "TREATS.type" | translate }}</span>
                </label>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="currentTreatment?.type != 'trainer_routine_procedure_form'"
          >
            <div>
              <div class="box-control input--filled">
                <div class="text-value name input__field">
                  {{
                    currentTreatment?.prescriptions[0]?.drugs[0]?.name ||
                      currentTreatment?.drug_name
                  }}
                </div>
                <label class="input__label">
                  <span>{{ "HORSES.drugName" | translate }}</span>
                </label>
              </div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="currentTreatment?.type == 'trainer_routine_procedure_form'"
          >
            <div>
              <div class="box-control input--filled">
                <div class="text-value name input__field">
                  {{ currentTreatment?.procedure_type }}
                </div>
                <label class="input__label">
                  <span>{{ "HORSES.routineProcedureName" | translate }}</span>
                </label>
              </div>
            </div>
          </ng-container>
          <div *ngIf="!hasNoTreatment">
            <div
              class="box-control"
              *ngIf="currentTreatment?.type != 'trainer_routine_procedure_form'"
            >
              <div class="clearfix double-block">
                <div class="row-half">
                  <div class="box-control input--filled">
                    <div class="text-value input__field">
                      {{ currentTreatment?.prescriptions[0]?.quantity }}
                      {{
                        currentTreatment?.prescriptions[0]?.quantity_measure !=
                        "other"
                          ? currentTreatment?.prescriptions[0]?.quantity_measure
                          : currentTreatment?.prescriptions[0]?.other_quantity
                      }}
                    </div>
                    <label class="input__label">
                      <span>{{ "HORSES.dailyQuantity" | translate }}</span>
                    </label>
                  </div>
                </div>
                <div class="row-half">
                  <div class="box-control input--filled">
                    <div class="text-value input__field">
                      {{
                        "HORSES." +
                          (appMemory?.translates[currentTreatment?.route] ||
                            currentTreatment?.route) | translate
                      }}
                    </div>
                    <label class="input__label">
                      <span>{{ "HORSES.route" | translate }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-control">
              <ng-container
                *ngIf="
                  this.isUkCountry ||
                  (this.isAUSCountry &&
                    currentTreatment?.type == 'trainer_routine_procedure_form')
                "
              >
                <div class="box-control input--filled">
                  <div class="text-value input__field">
                    {{ currentTreatment?.frequency }}
                  </div>
                  <label class="input__label">
                    <span>{{
                      "HORSES." +
                        (currentTreatment?.type ==
                        "trainer_routine_procedure_form"
                          ? "routineProcedureFrequency"
                          : "frequency") | translate
                    }}</span>
                  </label>
                </div>
              </ng-container>
            </div>
            <div
              class="box-control input--filled"
              *ngIf="
                currentTreatment?.reason &&
                currentTreatment?.type != 'trainer_routine_procedure_form'
              "
            >
              <div class="text-value input__field">
                {{ currentTreatment?.reason }}
              </div>
              <label class="input__label">
                <span>{{ "HORSES.reason" | translate }}</span>
              </label>
            </div>

            <div
              class="box-control input--filled"
              *ngIf="
                currentTreatment?.start_date || currentTreatment?.created_at
              "
            >
              <div class="text-value input__field">
                {{
                  currentTreatment?.start_date || currentTreatment?.created_at
                    | dateObj
                    | date : "dd MMM y"
                }}
              </div>
              <label class="input__label">
                <span>{{ "HORSES.startDate" | translate }}</span>
              </label>
            </div>

            <div
              class="box-control input--filled"
              *ngIf="currentTreatment?.withdrawal"
            >
              <div class="text-value input__field">
                {{ currentTreatment?.withdrawal | dateObj | date : "dd MMM y" }}
              </div>
              <label class="input__label">
                <span>{{ "HORSES.endDate" | translate }}</span>
              </label>
            </div>
            <!-- div class="box-control input--filled" *ngIf="currentTreatment?.detection_time">
            <div class="text-value input__field">{{ currentTreatment?.detection_time + (currentTreatment?.detection_time > 1 ? ' hours' : ' hour') }}</div>
            <label class="input__label"><span>Detection Time</span></label>
          </div> -->
            <div
              class="box-control input--filled"
              *ngIf="currentTreatment?.prescriptions[0]?.duration"
            >
              <div class="text-value input__field">
                {{ currentTreatment?.prescriptions[0]?.duration }}
                {{
                  "HORSES." +
                    (currentTreatment?.prescriptions[0]?.duration > 1
                      ? "days"
                      : "day") | translate
                }}
              </div>
              <label class="input__label">
                <span>{{ "HORSES.withdrawal" | translate }}</span>
              </label>
            </div>
            <!-- Intra articular other info -->
            <ng-container *ngIf="tabs.displayAintreeTabs">
              <ng-container
                *ngIf="currentTreatment?.route == 'intra-articular'"
              >
                <div class="box-control input--filled">
                  <div class="text-value input__field">
                    {{ currentTreatment?.intra_articular_infoA }}
                  </div>
                  <label class="input__label">
                    <span>{{ "HORSES.intraArticularInfoA" | translate }}</span>
                  </label>
                </div>
              </ng-container>
              <ng-container
                *ngIf="currentTreatment?.route == 'intra-articular'"
              >
                <div class="box-control input--filled">
                  <div class="text-value input__field">
                    {{ currentTreatment?.intra_articular_infoB }}
                  </div>
                  <label class="input__label">
                    <span>{{ "HORSES.intraArticularInfoB" | translate }}</span>
                  </label>
                </div>
              </ng-container>
              <ng-container
                *ngIf="currentTreatment?.intra_articular_infoB == 'other'"
              >
                <div class="box-control input--filled">
                  <div class="text-value input__field">
                    {{ currentTreatment?.intra_articular_other_info }}
                  </div>
                  <label class="input__label">
                    <span>{{
                      "HORSES.intraArticularOtherInfo" | translate
                    }}</span>
                  </label>
                </div>
              </ng-container>
            </ng-container>
            <div class="box-control">
              <div class="img-load" *ngFor="let image of newTreatment?.srcs">
                <a [href]="image.src" target="_blank">
                  <img [src]="image.iconsrc" alt="" />
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      class="modal-footer"
      *ngIf="horse?.is_active && currentTreatment?.status != 'closed'"
    >
      <div class="clearfix" *ngIf="appMemory.user?.roles[0]?.id === 1">
        <div class="row-half full">
          <a
            class="btn btn-gold btn-full open-modal"
            *ngIf="
              currentTreatment?.request?.status != 'pending' && !isAUSCountry
            "
            (click)="
              changeTreatmentStatus(currentTreatment?.id, { status: 'closed' })
            "
          >
            {{ "HORSES.archive" | translate }}
          </a>
        </div>
        <div class="row-half">
          <a
            class="btn btn-red btn-full open-modal"
            href="#treatment-decline"
            (click)="
              treatmentStatus.request_id = currentTreatment?.request?.id;
              hideModal()
            "
            *ngIf="
              currentTreatment?.request &&
              currentTreatment?.request?.status == 'pending'
            "
          >
            {{ "TREATS.reject" | translate }}
          </a>
        </div>
        <div class="row-half">
          <a
            class="btn btn-green btn-full"
            (click)="
              treatmentStatus.request_id = currentTreatment?.request?.id;
              treatmentStatus.status = 'approved';
              proceessTreatment()
            "
            *ngIf="
              currentTreatment?.request &&
              currentTreatment?.request?.status == 'pending'
            "
          >
            {{ "TREATS.approve" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- add treatment -->

<div class="modal" id="add-treatment" *ngIf="horse?.is_active">
  <div
    class="modal-wrapper"
    [ngClass]="{ 'quick-treatment': quick_treatment }"
    style="padding-left: 20px; padding-right: 20px"
  >
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createTreatmentForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(createTreatmentForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.addTreatment" | translate }}</div>
    </div>

    <form
      class="form modal-form"
      #createTreatmentForm="ngForm"
      novalidate
      (ngSubmit)="
        tabs.isQuickTreatment
          ? createQuickTreatment(
              appMemory.roles[appMemory.user?.roles[0]?.id],
              createTreatmentForm
            )
          : createTreatment(
              appMemory.roles[appMemory.user?.roles[0]?.id],
              createTreatmentForm
            )
      "
    >
      <tabset class="nav-tabs nav-left" *ngIf="quick_treatment">
        <tab
          heading="{{ 'TREATS.normalTreatment' | translate }}"
          [active]="tabs.isNormalTreatment"
          (select)="tabs.isNormalTreatment = true"
          (deselect)="tabs.isNormalTreatment = false"
        ></tab>
        <tab
          heading="{{ 'TREATS.quickTreatment' | translate }}"
          [active]="tabs.isQuickTreatment"
          (select)="tabs.isQuickTreatment = true"
          (deselect)="tabs.isQuickTreatment = false"
        ></tab>
      </tabset>
      <div class="modal-body" [ngClass]="{ hidden: !tabs.isNormalTreatment }">
        <div class="padding-block">
          <ng-container *ngIf="tabs.displayAintreeTabs">
            <div class="box-control">
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let t of treatmentTypes"
                  [ngClass]="{
                    active: t.type == (newTreatment.type || selectedTab)
                  }"
                >
                  <a
                    class="btn"
                    [ngClass]="{
                      danger:
                        createTreatmentForm.submitted && !newTreatment.type
                    }"
                    (click)="newTreatment.type = t.type || selectedTab"
                  >
                    <div class="name">
                      {{
                        t.title ||
                          "HORSES." + (appMemory?.translates[t.type] || t.type)
                          | translate
                      }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="box-info"
              [innerHTML]="
                'TREATS.forms.' + (newTreatment?.type || selectedTab) + '.intro'
                  | translate
              "
            ></div>
            <br />

            <div [ngClass]="{ 'margin-bottom-25': hasNoTreatment }">
              <input
                type="checkbox"
                id="has-no-treatment"
                [checked]="!!hasNoTreatment"
                (change)="setHasNoTreatment($event)"
              />
              <div class="toggle">
                <label for="has-no-treatment">
                  <i></i>
                </label>
              </div>
              <span class="toggle__label">No medication to declare</span>
            </div>
          </ng-container>

          <ng-container *ngIf="isHKCountry">
            <div class="box-control">
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let t of hkTreatmentTypes"
                  [ngClass]="{
                    active: t.type == (newTreatment.type || selectedTab)
                  }"
                >
                  <a
                    class="btn"
                    [ngClass]="{
                      danger:
                        createTreatmentForm.submitted && !newTreatment.type
                    }"
                    (click)="newTreatment.type = t.type || selectedTab"
                  >
                    <div class="name">
                      {{ t.title }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <br />
            <div
              class="box-info"
              [innerHTML]="
                'TREATS.forms.' + (newTreatment?.type || selectedTab) + '.intro'
                  | translate
              "
            ></div>
            <br />

            <div [ngClass]="{ 'margin-bottom-25': hasNoTreatment }">
              <input
                type="checkbox"
                id="has-no-treatment"
                [checked]="!!hasNoTreatment"
                (change)="setHasNoTreatment($event)"
              />
              <div class="toggle">
                <label for="has-no-treatment">
                  <i></i>
                </label>
              </div>
              <span class="toggle__label">No medication to declare</span>
            </div>
          </ng-container>

          <ng-container *ngIf="isAUSCountry">
            <app-treatment-selector
              [treatmentTypes]="ausTreatmentTypes"
              [treatment]="newTreatment"
              [selectedTab]="selectedTab"
              [treatmentForm]="createTreatmentForm"
              [hasNoTreatment]="hasNoTreatment"
              (setHasNoTreatment)="setHasNoTreatment($event)"
              [toggleRequired]="true"
              [toggleLabel]="toggleLabel"
              (typeSelected)="onTypeSelected($event)"
            ></app-treatment-selector>
          </ng-container>

          <div *ngIf="!hasNoTreatment">
            <ng-container
              *ngIf="
                isAUSCountry &&
                newTreatment.type == 'trainer_routine_procedure_form'
              "
            >
              <div class="box-control">
                <label class="label ng-select-label">Procedure</label>
                <ng-select
                  class="farms-selector"
                  [allowClear]="false"
                  [items]="ausTreatmentProcedures"
                  #procedureSelector
                  placeholder="Select Procedure"
                  [active]="getActiveProcedure()"
                  (selected)="setProcedureValue($event)"
                >
                </ng-select>
              </div>
            </ng-container>

            <!-- administer field section -->
            <ng-container
              *ngIf="
                newTreatment.type != 'trainer_routine_procedure_form'
              "
            >
              <div class="clearfix administer-section">
                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model': newTreatment.administer
                  }"
                >
                  <ng-select
                    [allowClear]="false"
                    [ngClass]="{
                      danger:
                        createTreatmentForm.submitted &&
                        !newTreatment.administer
                    }"
                    [active]="administer"
                    id="administer-selector"
                    required
                    (selected)="setTreatmentAdminister($event)"
                    [items]="administers"
                    name="administer"
                    placeholder="{{ 'HORSES.administer_name' | translate }}"
                  >
                  </ng-select>
                  <label class="input__label">
                    <span>{{ "HORSES.administer" | translate }}</span>
                  </label>
                </div>
              </div>
              <div
                *ngIf="
                  administer?.length && newTreatment.administer?.user_id == -1
                "
              >
                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model':
                      newTreatment?.administer.contact_name
                  }"
                >
                  <input
                    type="text"
                    class="form-control input__field"
                    [ngClass]="{
                      error:
                        createTreatmentForm.submitted &&
                        !newTreatment?.administer.contact_name
                    }"
                    name="contact_name"
                    [(ngModel)]="newTreatment.administer.contact_name"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !createTreatmentForm.submitted ||
                        (createTreatmentForm.submitted &&
                          newTreatment?.administer?.contact_name)
                      "
                      >{{ "HORSES.administer_name" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        createTreatmentForm.submitted &&
                        !newTreatment?.administer?.contact_name
                      "
                      >{{ "HORSES.administer_name" | translate }}</span
                    >
                  </label>
                </div>

                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model':
                      newTreatment?.administer?.contact_info
                  }"
                >
                  <input
                    type="text"
                    class="form-control input__field"
                    [ngClass]="{
                      error:
                        createTreatmentForm.submitted &&
                        !newTreatment.administer.contact_info
                    }"
                    name="contact_info"
                    [(ngModel)]="newTreatment.administer.contact_info"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !createTreatmentForm.submitted ||
                        (createTreatmentForm.submitted &&
                          !!newTreatment?.administer?.contact_info)
                      "
                      >{{ "HORSES.administer_contact_info" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        createTreatmentForm.submitted &&
                        !newTreatment?.administer?.contact_info
                      "
                      >{{ "HORSES.administer_contact_info" | translate }}</span
                    >
                  </label>
                </div>
              </div>
            </ng-container>
            <!-- administer field section -->

            <ng-container
              *ngIf="
                newTreatment.type != 'trainer_routine_procedure_form'
              "
            >
              <div class="box-control" *ngIf="!newTreatment.drug">
                <a
                  class="btn btn-dotted btn-full open-modal"
                  [ngClass]="{
                    danger: createTreatmentForm.submitted && !newTreatment.drug
                  }"
                  href="#modal-search"
                  (click)="hideModal(true); filterDrugs()"
                >
                  <div class="box-icon box-right">
                    <i class="icon icon-plus icon-middle"></i>
                  </div>
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted && newTreatment.drug)
                    "
                    >{{ "HORSES.drugName" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="createTreatmentForm.submitted && !newTreatment.drug"
                    >{{ "HORSES.drugNameRequired" | translate }}</span
                  >
                </a>
              </div>

              <div class="box-control" *ngIf="newTreatment.drug?.name">
                <a
                  class="btn btn-dotted btn-full open-modal"
                  href="#modal-search"
                  (click)="hideModal(true); filterDrugs()"
                >
                  <div class="label">{{ "HORSES.drugName" | translate }}</div>
                  <div class="name">{{ newTreatment.drug.name }}</div>
                </a>
              </div>

              <div class="box-control" *ngIf="newTreatment?.drug?.id == 0">
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    danger:
                      createTreatmentForm.submitted && !newTreatment.drug_name
                  }"
                  name="other-drug-name"
                  #newOtherDrug="ngModel"
                  [(ngModel)]="newTreatment.drug_name"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted &&
                        !!newTreatment.drug_name)
                    "
                    >{{ "TREATS.otherDrugName" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      createTreatmentForm.submitted && !newTreatment.drug_name
                    "
                    >{{ "TREATS.otherDrugName" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                </label>
              </div>

              <div class="box-control">
                <div class="label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted && !!newTreatment.route)
                    "
                    >{{ "HORSES.route" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="createTreatmentForm.submitted && !newTreatment.route"
                    >{{ "HORSES.routeIsRequired" | translate }}</span
                  >
                </div>
                <ul class="nav-type line">
                  <li
                    class="change-active"
                    *ngFor="let routeType of routeTypes"
                    [ngClass]="{ active: routeType == newTreatment.route }"
                  >
                    <a
                      class="btn"
                      [ngClass]="{
                        danger:
                          createTreatmentForm.submitted && !newTreatment.route
                      }"
                      (click)="newTreatment.route = routeType"
                    >
                      <div class="name">
                        {{
                          "HORSES." +
                            (appMemory?.translates[routeType] || routeType)
                            | translate
                        }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <ng-container
                *ngIf="
                  tabs.displayAintreeTabs &&
                  newTreatment.route == 'intra-articular'
                "
              >
                <div class="box-control">
                  <ul class="nav-type line" style="padding: 0">
                    <li
                      class="change-active"
                      *ngFor="let infoType of intraArticularAdditionalInfosA"
                      [ngClass]="{
                        active: infoType == newTreatment.intra_articular_infoA
                      }"
                    >
                      <a
                        class="btn"
                        [ngClass]="{
                          danger:
                            createTreatmentForm.submitted &&
                            !newTreatment.infoType
                        }"
                        (click)="newTreatment.intra_articular_infoA = infoType"
                        style="padding: 18px"
                      >
                        <div class="name">
                          {{ infoType }}
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="box-control">
                  <ul class="nav-type line" style="padding: 0">
                    <li
                      class="change-active"
                      *ngFor="let infoType of intraArticularAdditionalInfosB"
                      [ngClass]="{
                        active: infoType == newTreatment.intra_articular_infoB
                      }"
                    >
                      <a
                        class="btn"
                        [ngClass]="{
                          danger:
                            createTreatmentForm.submitted &&
                            !newTreatment.infoType
                        }"
                        (click)="newTreatment.intra_articular_infoB = infoType"
                        style="padding: 12px"
                      >
                        <div class="name">
                          {{ infoType }}
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  class="box-control"
                  *ngIf="
                    newTreatment.intra_articular_infoB &&
                    newTreatment.intra_articular_infoB.toLowerCase() == 'other'
                  "
                  [ngClass]="{
                    'input--filled--model':
                      newTreatment?.intra_articular_other_info
                  }"
                >
                  <input
                    type="text"
                    class="form-control input__field"
                    [ngClass]="{
                      error:
                        createTreatmentForm.submitted &&
                        !newTreatment.intra_articular_other_info
                    }"
                    name="other-info"
                    #newIntraArticularOtherInfo="ngModel"
                    [(ngModel)]="newTreatment.intra_articular_other_info"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !createTreatmentForm.submitted ||
                        (createTreatmentForm.submitted &&
                          newTreatment.intra_articular_other_info)
                      "
                      >{{ "TREATS.other_articular_info" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        createTreatmentForm.submitted &&
                        !newTreatment.intra_articular_other_info
                      "
                      >{{ "TREATS.other_articular_info" | translate }}
                      {{ "CORE.isRequired" | translate }}</span
                    >
                  </label>
                </div>
              </ng-container>

              <!-- AUS intra-additional-Info Section -->
              <ng-container
                *ngIf="isAUSCountry && newTreatment.route == 'intra-articular'"
              >
                <app-intra-articular-additional-info
                  [intraArticularAdditionalInfo]="
                    ausIntraArticularAdditionalInfo
                  "
                  [treatment]="newTreatment"
                  [treatmentForm]="createTreatmentForm"
                ></app-intra-articular-additional-info>
              </ng-container>
              <!-- AUS intra-additional-Info Section -->

              <div
                class="box-control"
                *ngIf="
                  newTreatment.route &&
                  newTreatment.route.toLowerCase() == 'other'
                "
                [ngClass]="{
                  'input--filled--model': newTreatment?.other_route
                }"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      createTreatmentForm.submitted && !newTreatment.other_route
                  }"
                  name="other-route"
                  #newOtherRoute="ngModel"
                  [(ngModel)]="newTreatment.other_route"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted &&
                        newTreatment.other_route)
                    "
                    >{{ "TREATS.otherRoute" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      createTreatmentForm.submitted && !newTreatment.other_route
                    "
                    >{{ "TREATS.otherRoute" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                </label>
              </div>
            </ng-container>
            <ng-container *ngIf="this.isUkCountry || isAUSCountry">
              <div class="box-control">
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      createTreatmentForm.submitted && !newTreatment.frequency
                  }"
                  name="frequency"
                  #newFrequency="ngModel"
                  [(ngModel)]="newTreatment.frequency"
                  autocomplete="off"
                  [maxlength]="50"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted && newTreatment.frequency)
                    "
                    >{{
                      (newTreatment.type != "trainer_routine_procedure_form"
                        ? "HORSES.frequency"
                        : "HORSES.frequencyOfTreatment"
                      ) | translate
                    }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      createTreatmentForm.submitted && !newTreatment.frequency
                    "
                    >{{ "HORSES.frequency_required" | translate }}</span
                  >
                </label>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                newTreatment.type != 'trainer_routine_procedure_form'
              "
            >
              <div class="clearfix">
                <div
                  class="box-control"
                  id="quantity-box"
                  [ngClass]="{ 'input--filled--model': newTreatment?.quantity }"
                  style="
                    width: calc(50% - 10px);
                    float: left;
                    margin: 0;
                    margin-right: 20px;
                  "
                >
                  <input
                    type="text"
                    class="form-control input__field"
                    [ngClass]="{
                      error:
                        createTreatmentForm.submitted && newQuantity.errors,
                      'only-number-float': true
                    }"
                    name="quantity"
                    [maxlength]="calcQuantityMaxLength()"
                    #newQuantity="ngModel"
                    [(ngModel)]="newTreatment.quantity"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !createTreatmentForm.submitted ||
                        (createTreatmentForm.submitted && newQuantity.valid)
                      "
                      >{{ "HORSES.dailyQuantity" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        createTreatmentForm.submitted &&
                        newQuantity.errors?.required
                      "
                      >{{ "HORSES.dailyQuanityRequired" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        createTreatmentForm.submitted &&
                        newQuantity.errors?.pattern
                      "
                      >{{ "HORSES.dailyQuantity" | translate }}
                      {{ "CORE.isInvalid" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        createTreatmentForm.submitted &&
                        newQuantity.errors?.maxlength
                      "
                      >{{ "HORSES.dailyQuantity" | translate
                      }}{{ "CORE.isTooBig" | translate }}</span
                    >
                  </label>
                </div>
                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model': newTreatment?.quantity_measure
                  }"
                  style="width: calc(50% - 10px); float: left; margin: 0"
                >
                  <ng-select
                    [allowClear]="false"
                    id="measures-selector"
                    class="measures-selector"
                    [ngClass]="{
                      danger:
                        createTreatmentForm.submitted &&
                        !newTreatment.quantity_measure
                    }"
                    required
                    #measuresSelector
                    [active]="measuresSelectorModel"
                    (selected)="setMeasure($event)"
                    [items]="appMemory.measures"
                    name="measures"
                    placeholder="{{
                      'HORSES.' +
                        (createTreatmentForm.submitted
                          ? 'quantityMeasure'
                          : 'quantityMeasure') | translate
                    }}"
                  >
                  </ng-select>
                </div>
              </div>

              <div
                class="box-control"
                id="other_quantity"
                [ngClass]="{
                  'input--filled--model': newTreatment?.other_quantity
                }"
                *ngIf="newTreatment.quantity_measure == 'other'"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      createTreatmentForm.submitted &&
                      !newTreatment.other_quantity
                  }"
                  name="other_quantity"
                  #newReason="ngModel"
                  [(ngModel)]="newTreatment.other_quantity"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted && !!newTreatment.reason)
                    "
                    >{{ "TREATS.otherQuantityMeasure" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      createTreatmentForm.submitted && !newTreatment.reason
                    "
                    >{{ "TREATS.otherQuantityMeasure" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                </label>
              </div>

              <ng-container
                *ngIf="
                  !tabs.displayAintreeTabs && !isHKCountry && !isAUSCountry
                "
              >
                <div
                  class="box-control"
                  id="duration-box"
                  [ngClass]="{ 'input--filled--model': newTreatment?.duration }"
                >
                  <input
                    type="text"
                    class="form-control input__field only-number"
                    [ngClass]="{
                      error:
                        createTreatmentForm.submitted && !newTreatment.duration
                    }"
                    name="duration"
                    #newDuration="ngModel"
                    maxlength="8"
                    [(ngModel)]="newTreatment.duration"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !createTreatmentForm.submitted ||
                        (createTreatmentForm.submitted &&
                          !!newTreatment.duration)
                      "
                      >{{ "HORSES.withdrawal" | translate }} ({{
                        "HORSES.days" | translate
                      }})</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        createTreatmentForm.submitted && !newTreatment.duration
                      "
                      >{{ "HORSES.withdrawalUsRequired" | translate }}</span
                    >
                  </label>
                </div>
              </ng-container>

              <div
                class="box-control"
                id="reason-box"
                [ngClass]="{ 'input--filled--model': newTreatment?.reason }"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error: createTreatmentForm.submitted && !newTreatment.reason
                  }"
                  name="reason"
                  #newReason="ngModel"
                  [(ngModel)]="newTreatment.reason"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted && !!newTreatment.reason)
                    "
                    >{{ "HORSES.reason" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      createTreatmentForm.submitted && !newTreatment.reason
                    "
                    >{{ "HORSES.reasonIsRequired" | translate }}</span
                  >
                </label>
              </div>
            </ng-container>
            <div class="box-control input--filled">
              <a
                class="btn btn-dotted btn-code btn-full"
                [ngClass]="{
                  danger:
                    (createTreatmentForm.submitted &&
                      !newTreatment.start_date) ||
                    serverErrorValidate?.start_date ||
                    dateRangeTreatmentErrorMessage.from
                }"
                style="user-select: none"
              >
                <label class="label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted &&
                        !!newTreatment.start_date)
                    "
                    >{{ "HORSES.startDate" | translate }}</span
                  >
                </label>
                <div class="code relative" ng>
                  <input
                    type="text"
                    [textMask]="datePickerMask"
                    placeholder="{{ 'CORE.selectDate' | translate }}"
                    class="form-control input__field"
                    [(ngModel)]="datePicker4.currentDate_input"
                    autocomplete="off"
                    name="start_date"
                    (ngModelChange)="setStartDateInput()"
                  />
                  <i
                    class="fa fa-calendar calendar"
                    (click)="datePicker4.isActive = !datePicker4.isActive"
                    aria-hidden="true"
                  ></i>
                </div>
                <span
                  class="text-danger"
                  *ngIf="dateRangeTreatmentErrorMessage.from"
                  >{{ dateRangeTreatmentErrorMessage.from | translate }}
                  {{ dateRangeTreatmentErrorMessage.date }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createTreatmentForm.submitted && !newTreatment.start_date
                  "
                  >{{ "HORSES.startDateRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="serverErrorValidate?.start_date"
                  >{{ serverErrorValidate?.start_date | translate }}</span
                >
              </a>
              <div class="box-control" *ngIf="datePicker4.isActive">
                <datepicker
                  class="datepicker"
                  name="withdrawal-datepicker"
                  [startingDay]="1"
                  [onlyCurrentMonth]="true"
                  [minDate]="datePicker4.minDate"
                  [maxDate]="datePicker4.maxDate"
                  [showWeeks]="false"
                  [initDate]="datePicker4.currentDate"
                  [dateDisabled]="dateDisabled"
                  (selectionDone)="
                    setStartDate($event);
                    datePicker4.isActive = !datePicker4.isActive
                  "
                >
                </datepicker>
              </div>
            </div>

            <div class="box-control input--filled">
              <a
                class="btn btn-dotted btn-code btn-full"
                [ngClass]="{
                  danger:
                    (createTreatmentForm.submitted &&
                      !newTreatment.withdrawal) ||
                    serverErrorValidate?.withdrawal ||
                    dateRangeTreatmentErrorMessage.due
                }"
                style="user-select: none"
              >
                <label class="label">
                  <span
                    *ngIf="
                      !createTreatmentForm.submitted ||
                      (createTreatmentForm.submitted &&
                        !!newTreatment.withdrawal)
                    "
                    >{{ "HORSES.endDate" | translate }}</span
                  >
                </label>
                <div class="code relative" ng>
                  <input
                    type="text"
                    [textMask]="datePickerMask"
                    placeholder="{{ 'CORE.selectDate' | translate }}"
                    class="form-control input__field"
                    [(ngModel)]="datePicker2.currentDate_input"
                    autocomplete="off"
                    name="withdrawal"
                    (ngModelChange)="
                      setWithdrawalInput(); checknewTreatmentValidity()
                    "
                  />
                  <i
                    class="fa fa-calendar calendar"
                    (click)="datePicker2.isActive = !datePicker2.isActive"
                    aria-hidden="true"
                  ></i>
                </div>
                <span
                  class="text-danger"
                  *ngIf="dateRangeTreatmentErrorMessage.due"
                  >{{ dateRangeTreatmentErrorMessage.due | translate }}
                  {{ dateRangeTreatmentErrorMessage.date }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createTreatmentForm.submitted && !newTreatment.withdrawal
                  "
                  >{{ "HORSES.endDateRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="serverErrorValidate?.withdrawal"
                  >{{ serverErrorValidate?.withdrawal | translate }}</span
                >
              </a>
              <div class="box-control" *ngIf="datePicker2.isActive">
                <datepicker
                  class="datepicker"
                  name="withdrawal-datepicker"
                  [startingDay]="1"
                  [onlyCurrentMonth]="true"
                  [minDate]="datePicker2.minDate"
                  [maxDate]="datePicker2.maxDate"
                  [showWeeks]="false"
                  [initDate]="datePicker2.currentDate"
                  [dateDisabled]="dateDisabled"
                  (selectionDone)="
                    setWithdrawal($event);
                    datePicker2.isActive = !datePicker2.isActive
                  "
                >
                </datepicker>
              </div>
            </div>

            <ng-container *ngIf="!isAUSCountry">
              <div class="box-control">
                <div class="border-control with_description">
                  <image-upload
                    #imageLoader22
                    [max]="maxImgCount"
                    [maxFileSize]="maxImgSize"
                    [buttonCaption]="'HORSES.dragSelectPhoto' | translate"
                    [extensions]="[
                      'jpeg',
                      'png',
                      'pdf',
                      'doc',
                      'docx',
                      'csv',
                      'xls',
                      'xlsx',
                      'txt',
                      'mp4',
                      '3gp',
                      '3gp2'
                    ]"
                    (onFileUploadFinish)="
                      setImages(imageLoader22.files); debug(imageLoader22)
                    "
                    (onRemove)="debug(imageLoader22)"
                  >
                  </image-upload>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-body" [ngClass]="{ hidden: !tabs.isQuickTreatment }">
        <div class="padding-block">
          <div class="box-control">
            <div class="border-control with_description">
              <image-upload
                #imageLoader21
                [max]="maxImgCount"
                [maxFileSize]="maxImgSize"
                [buttonCaption]="'HORSES.dragSelectPhoto' | translate"
                [extensions]="[
                  'jpeg',
                  'png',
                  'pdf',
                  'doc',
                  'docx',
                  'csv',
                  'xls',
                  'xlsx',
                  'txt',
                  'mp4',
                  '3gp',
                  '3gp2'
                ]"
                (onFileUploadFinish)="
                  setImages(imageLoader21.files); debug(imageLoader21)
                "
                (onRemove)="debug(imageLoader21)"
              >
              </image-upload>
            </div>
            <span
              class="text-danger"
              *ngIf="createTreatmentForm.submitted && !newTreatment.src"
              >{{ "TREATS.prescriptionMissing" | translate }}</span
            >
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <ng-container
          *ngIf="!tabs.displayAintreeTabs && !isAUSCountry; else withoutCopy"
        >
          <div class="row-half" id="content">
            <button
              class="btn btn-green btn-full"
              type="submit"
              [disabled]="isRequestInProgress4 || invalidTreatmentForm"
              (click)="setTriggerButton(treatmentSaveType.Save)"
              style="margin-bottom: 10px"
            >
              {{ "HORSES.saveTreatment" | translate }}
            </button>
          </div>

          <div class="row-half" style="padding-left: 10px">
            <button
              class="btn btn-green btn-full"
              type="submit"
              [disabled]="isRequestInProgress4 || invalidTreatmentForm"
              (click)="setTriggerButton(treatmentSaveType.SaveAndCopy)"
            >
              {{ "HORSES.save_copy_treatment" | translate }}
            </button>
          </div>
        </ng-container>
        <ng-template #withoutCopy>
          <div class="modal-footer">
            <button
              class="btn btn-green btn-full"
              type="submit"
              [disabled]="isRequestInProgress4 || invalidTreatmentForm"
            >
              {{ "HORSES.saveTreatment" | translate }}
            </button>
          </div>
        </ng-template>
      </div>
    </form>
  </div>
</div>

<!-- edit treatment -->

<div class="modal" id="edit-treatment" *ngIf="horse?.is_active">
  <div class="modal-wrapper" style="padding-left: 20px; padding-right: 20px">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(updateTreatmentForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(updateTreatmentForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.editTreat" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #updateTreatmentForm="ngForm"
      novalidate
      (ngSubmit)="
        updateTreatment(
          appMemory.roles[appMemory.user?.roles[0]?.id],
          updateTreatmentForm
        )
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <ng-container
            *ngIf="tabs.displayAintreeTabs && newTreatment.type != 'other'"
          >
            <div class="box-control">
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let t of treatmentTypes"
                  [ngClass]="{
                    active: t.type == newTreatment.type,
                    disabled: t.type != newTreatment.type
                  }"
                >
                  <a class="btn">
                    <div class="name">
                      {{
                        t.title ||
                          "HORSES." + (appMemory?.translates[t.type] || t.type)
                          | translate
                      }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>

            <div
              class="box-info"
              [innerHTML]="
                'TREATS.forms.' + (newTreatment?.type || selectedTab) + '.intro'
                  | translate
              "
            ></div>
            <br />

            <div [ngClass]="{ 'margin-bottom-25': hasNoTreatment }">
              <input
                type="checkbox"
                id="has-no-treatment-edit"
                disabled
                [checked]="!!hasNoTreatment"
              />
              <div class="toggle">
                <label for="has-no-treatment-edit">
                  <i></i>
                </label>
              </div>
              <span class="toggle__label">No medication to declare</span>
            </div>
          </ng-container>

          <ng-container *ngIf="isHKCountry">
            <div class="box-control">
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let t of hkTreatmentTypes"
                  [ngClass]="{
                    active: t.type == (newTreatment.type || selectedTab),
                    disabled: t.type != newTreatment.type
                  }"
                >
                  <a
                    class="btn"
                    [ngClass]="{
                      danger:
                        updateTreatmentForm.submitted && !newTreatment.type
                    }"
                  >
                    <div class="name">
                      {{ t.title }}
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <br />
            <div
              class="box-info"
              [innerHTML]="
                'TREATS.forms.' + (newTreatment?.type || selectedTab) + '.intro'
                  | translate
              "
            ></div>
            <br />

            <div [ngClass]="{ 'margin-bottom-25': hasNoTreatment }">
              <input
                type="checkbox"
                id="has-no-treatment-edit"
                disabled
                [checked]="!!hasNoTreatment"
              />
              <div class="toggle">
                <label for="has-no-treatment-edit">
                  <i></i>
                </label>
              </div>
              <span class="toggle__label">No medication to declare</span>
            </div>
          </ng-container>

          <ng-container *ngIf="isAUSCountry">
            <app-treatment-selector
              [treatmentTypes]="ausTreatmentTypes"
              [treatment]="newTreatment"
              [treatmentForm]="updateTreatmentForm"
              [hasNoTreatment]="hasNoTreatment"
              (setHasNoTreatment)="setHasNoTreatment($event)"
              [toggleRequired]="true"
              [toggleLabel]="toggleLabel"
              [isEdit]="true"
            ></app-treatment-selector>
          </ng-container>

          <ng-container *ngIf="!hasNoTreatment">
            <ng-container
              *ngIf="
                isAUSCountry &&
                newTreatment.type == 'trainer_routine_procedure_form'
              "
            >
              <div class="box-control">
                <label class="label ng-select-label">Procedure</label>
                <ng-select
                  class="farms-selector"
                  [allowClear]="false"
                  [items]="ausTreatmentProcedures"
                  #procedureSelector
                  placeholder="Select Procedure"
                  [active]="getActiveProcedure()"
                  (selected)="setProcedureValue($event)"
                >
                </ng-select>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                newTreatment.type != 'trainer_routine_procedure_form'
              "
            >
              <div class="clearfix administer-section">
                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model': newTreatment.administer
                  }"
                >
                  <ng-select
                    [allowClear]="false"
                    [ngClass]="{
                      danger:
                        updateTreatmentForm.submitted &&
                        !newTreatment.administer
                    }"
                    [active]="administer"
                    id="administer-selector"
                    required
                    (selected)="setTreatmentAdminister($event)"
                    [items]="administers"
                    name="administer"
                    placeholder="{{ 'HORSES.administer_name' | translate }}"
                  >
                  </ng-select>
                  <label class="input__label">
                    <span>{{ "HORSES.administer" | translate }}</span>
                  </label>
                </div>
              </div>

              <div
                *ngIf="
                  administer?.length && newTreatment.administer?.user_id == -1
                "
              >
                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model':
                      newTreatment?.administer.contact_name
                  }"
                >
                  <input
                    type="text"
                    class="form-control input__field"
                    [ngClass]="{
                      error:
                        updateTreatmentForm.submitted &&
                        !newTreatment?.administer.contact_name
                    }"
                    name="contact_name"
                    [(ngModel)]="newTreatment.administer.contact_name"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !updateTreatmentForm.submitted ||
                        (updateTreatmentForm.submitted &&
                          !!newTreatment?.administer?.contact_name)
                      "
                      >{{ "HORSES.administer_name" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        updateTreatmentForm.submitted &&
                        !newTreatment?.administer?.contact_name
                      "
                      >{{ "HORSES.administer_name" | translate }}</span
                    >
                  </label>
                </div>

                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model':
                      newTreatment?.administer?.contact_info
                  }"
                >
                  <input
                    type="text"
                    class="form-control input__field"
                    [ngClass]="{
                      error:
                        updateTreatmentForm.submitted &&
                        !newTreatment.administer.contact_info
                    }"
                    name="contact_info"
                    [(ngModel)]="newTreatment.administer.contact_info"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !updateTreatmentForm.submitted ||
                        (updateTreatmentForm.submitted &&
                          !!newTreatment?.administer?.contact_info)
                      "
                      >{{ "HORSES.administer_contact_info" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        updateTreatmentForm.submitted &&
                        !newTreatment?.administer?.contact_info
                      "
                      >{{ "HORSES.administer_contact_info" | translate }}</span
                    >
                  </label>
                </div>
              </div>
              <div class="box-control" *ngIf="!newTreatment.drug">
                <a
                  class="btn btn-dotted btn-full open-modal"
                  [ngClass]="{
                    danger: updateTreatmentForm.submitted && !newTreatment.drug
                  }"
                  href="#modal-search"
                  (click)="hideModal(true); filterDrugs()"
                >
                  <div class="box-icon box-right">
                    <i class="icon icon-plus icon-middle"></i>
                  </div>
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted && newTreatment.drug)
                    "
                    >{{ "HORSES.drugName" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="updateTreatmentForm.submitted && !newTreatment.drug"
                    >{{ "HORSES.drugNameRequired" | translate }}</span
                  >
                </a>
              </div>
              <div class="box-control" *ngIf="newTreatment.drug?.name">
                <a
                  class="btn btn-dotted btn-full open-modal"
                  href="#modal-search"
                  (click)="hideModal(true); filterDrugs()"
                >
                  <div class="label">{{ "HORSES.drugName" | translate }}</div>
                  <div class="name">{{ newTreatment?.drug?.name }}</div>
                </a>
              </div>
              <div
                class="box-control"
                *ngIf="newTreatment?.drug?.id == 0"
                [ngClass]="{ 'input--filled--model': newTreatment?.drug_name }"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      updateTreatmentForm.submitted && !newTreatment.drug_name
                  }"
                  name="other-drug-name"
                  #newOtherDrug="ngModel"
                  [(ngModel)]="newTreatment.drug_name"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted &&
                        !!newTreatment.drug_name)
                    "
                    >{{ "TREATS.otherDrugName" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      updateTreatmentForm.submitted && !newTreatment.drug_name
                    "
                    >{{ "TREATS.otherDrugName" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                </label>
              </div>
              <div class="box-control">
                <div class="label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted && !!newTreatment.route)
                    "
                    >{{ "HORSES.route" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="updateTreatmentForm.submitted && !newTreatment.route"
                    >{{ "HORSES.routeIsRequired" | translate }}</span
                  >
                </div>
                <ul class="nav-type line">
                  <li
                    class="change-active"
                    *ngFor="let routeType of routeTypes"
                    [ngClass]="{ active: routeType == newTreatment.route }"
                  >
                    <a
                      class="btn"
                      [ngClass]="{
                        danger:
                          updateTreatmentForm.submitted && !newTreatment.route
                      }"
                      (click)="newTreatment.route = routeType"
                    >
                      <div class="name">
                        {{
                          "HORSES." +
                            (appMemory?.translates[routeType] || routeType)
                            | translate
                        }}
                      </div>
                    </a>
                  </li>
                </ul>
              </div>

              <ng-container
                *ngIf="isAUSCountry && newTreatment.route == 'intra-articular'"
              >
                <app-intra-articular-additional-info
                  [intraArticularAdditionalInfo]="
                    ausIntraArticularAdditionalInfo
                  "
                  [treatment]="newTreatment"
                  [treatmentForm]="createTreatmentForm"
                ></app-intra-articular-additional-info>
              </ng-container>

              <ng-container
                *ngIf="
                  tabs.displayAintreeTabs &&
                  newTreatment.route == 'intra-articular'
                "
              >
                <div class="box-control">
                  <ul class="nav-type line" style="padding: 0">
                    <li
                      class="change-active"
                      *ngFor="let infoType of intraArticularAdditionalInfosA"
                      [ngClass]="{
                        active: infoType == newTreatment.intra_articular_infoA
                      }"
                    >
                      <a
                        class="btn"
                        [ngClass]="{
                          danger:
                            updateTreatmentForm.submitted &&
                            !newTreatment.infoType
                        }"
                        (click)="newTreatment.intra_articular_infoA = infoType"
                        style="padding: 18px"
                      >
                        <div class="name">
                          {{ infoType }}
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div class="box-control">
                  <ul class="nav-type line" style="padding: 0">
                    <li
                      class="change-active"
                      *ngFor="let infoType of intraArticularAdditionalInfosB"
                      [ngClass]="{
                        active: infoType == newTreatment.intra_articular_infoB
                      }"
                    >
                      <a
                        class="btn"
                        [ngClass]="{
                          danger:
                            updateTreatmentForm.submitted &&
                            !newTreatment.infoType
                        }"
                        (click)="newTreatment.intra_articular_infoB = infoType"
                        style="padding: 12px"
                      >
                        <div class="name">
                          {{ infoType }}
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div
                  class="box-control"
                  *ngIf="
                    newTreatment.intra_articular_infoB &&
                    newTreatment.intra_articular_infoB.toLowerCase() == 'other'
                  "
                  [ngClass]="{
                    'input--filled--model':
                      newTreatment?.intra_articular_other_info
                  }"
                >
                  <input
                    type="text"
                    class="form-control input__field"
                    [ngClass]="{
                      error:
                        updateTreatmentForm.submitted &&
                        !newTreatment.intra_articular_other_info
                    }"
                    name="other-info"
                    #newIntraArticularOtherInfo="ngModel"
                    [(ngModel)]="newTreatment.intra_articular_other_info"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !updateTreatmentForm.submitted ||
                        (updateTreatmentForm.submitted &&
                          newTreatment.intra_articular_other_info)
                      "
                      >{{ "TREATS.other_articular_info" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        updateTreatmentForm.submitted &&
                        !newTreatment.intra_articular_other_info
                      "
                      >{{ "TREATS.other_articular_info" | translate }}
                      {{ "CORE.isRequired" | translate }}</span
                    >
                  </label>
                </div>
              </ng-container>

              <div
                class="box-control"
                *ngIf="
                  newTreatment.route &&
                  newTreatment.route.toLowerCase() == 'other'
                "
                [ngClass]="{
                  'input--filled--model': newTreatment?.other_route
                }"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      updateTreatmentForm.submitted && !newTreatment.other_route
                  }"
                  name="other-route"
                  #newOtherRoute="ngModel"
                  [(ngModel)]="newTreatment.other_route"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted &&
                        newTreatment.other_route)
                    "
                    >{{ "TREATS.otherRoute" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      updateTreatmentForm.submitted && !newTreatment.other_route
                    "
                    >{{ "TREATS.otherRoute" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                </label>
              </div>
            </ng-container>

            <ng-container *ngIf="this.isUkCountry || isAUSCountry">
              <div
                class="box-control"
                [ngClass]="{ 'input--filled--model': newTreatment?.frequency }"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      updateTreatmentForm.submitted && !newTreatment.frequency
                  }"
                  name="frequency"
                  #newFrequency="ngModel"
                  [(ngModel)]="newTreatment.frequency"
                  autocomplete="off"
                  [maxlength]="50"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted && newTreatment.frequency)
                    "
                    >{{ "HORSES.frequency" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      updateTreatmentForm.submitted && !newTreatment.frequency
                    "
                    >{{ "HORSES.frequency_required" | translate }}</span
                  >
                </label>
              </div>
            </ng-container>

            <ng-container
              *ngIf="
                newTreatment.type != 'trainer_routine_procedure_form'
              "
            >
              <div class="clearfix">
                <div
                  class="box-control"
                  id="quantity-box"
                  [ngClass]="{ 'input--filled--model': newTreatment?.quantity }"
                  style="
                    width: calc(50% - 10px);
                    float: left;
                    margin: 0;
                    margin-right: 20px;
                  "
                >
                  <input
                    type="text"
                    class="form-control input__field only-number-float"
                    [ngClass]="{
                      error:
                        updateTreatmentForm.submitted && newQuantity.errors,
                      'only-number-float':
                        newTreatment?.quantity_measure != 'other'
                    }"
                    name="quantity"
                    [attr.maxlength]="calcQuantityMaxLength()"
                    #newQuantity="ngModel"
                    [(ngModel)]="newTreatment.quantity"
                    autocomplete="off"
                    required
                    (input)="calcMaxLength($event.target.value)"
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !updateTreatmentForm.submitted ||
                        (updateTreatmentForm.submitted && newQuantity.valid)
                      "
                      >{{ "HORSES.dailyQuantity" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        updateTreatmentForm.submitted &&
                        newQuantity.errors?.required
                      "
                      >{{ "HORSES.dailyQuanityRequired" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        updateTreatmentForm.submitted &&
                        newQuantity.errors?.pattern
                      "
                      >{{ "HORSES.dailyQuantity" | translate }}
                      {{ "CORE.isInvalid" | translate }}</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        updateTreatmentForm.submitted &&
                        newQuantity.errors?.maxlength
                      "
                      >{{ "HORSES.dailyQuantity" | translate
                      }}{{ "CORE.isTooBig" | translate }}</span
                    >
                  </label>
                </div>
                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model': newTreatment?.quantity_measure
                  }"
                  style="width: calc(50% - 10px); float: left; margin: 0"
                >
                  <ng-select
                    [allowClear]="false"
                    id="measures-selector"
                    class="measures-selector"
                    [ngClass]="{
                      danger:
                        updateTreatmentForm.submitted &&
                        !newTreatment.quantity_measure
                    }"
                    required
                    #measuresSelector
                    [active]="measuresSelectorModel"
                    (selected)="setMeasure($event)"
                    [items]="appMemory.measures"
                    name="measures"
                    placeholder="{{
                      'HORSES.' +
                        (updateTreatmentForm.submitted
                          ? 'quantityMeasure'
                          : 'quantityMeasure') | translate
                    }}"
                  >
                  </ng-select>
                </div>
              </div>

              <div
                class="box-control"
                id="other_quantity"
                [ngClass]="{
                  'input--filled--model': newTreatment?.other_quantity
                }"
                *ngIf="newTreatment.quantity_measure == 'other'"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      updateTreatmentForm.submitted &&
                      !newTreatment.other_quantity
                  }"
                  name="other_quantity"
                  #newReason="ngModel"
                  [(ngModel)]="newTreatment.other_quantity"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted && !!newTreatment.reason)
                    "
                    >{{ "TREATS.otherQuantityMeasure" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      updateTreatmentForm.submitted && !newTreatment.reason
                    "
                    >{{ "TREATS.otherQuantityMeasure" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                </label>
              </div>

              <ng-container
                *ngIf="
                  !tabs.displayAintreeTabs && !isHKCountry && !isAUSCountry
                "
              >
                <div
                  class="box-control"
                  id="duration-box"
                  [ngClass]="{ 'input--filled--model': newTreatment?.duration }"
                >
                  <input
                    type="text"
                    class="form-control input__field only-number"
                    [ngClass]="{
                      error:
                        updateTreatmentForm.submitted && !newTreatment.duration
                    }"
                    name="duration"
                    maxlength="8"
                    #newDuration="ngModel"
                    [(ngModel)]="newTreatment.duration"
                    autocomplete="off"
                    required
                  />
                  <label class="input__label">
                    <span
                      *ngIf="
                        !updateTreatmentForm.submitted ||
                        (updateTreatmentForm.submitted &&
                          !!newTreatment.duration)
                      "
                      >{{ "HORSES.withdrawal" | translate }} ({{
                        "HORSES.days" | translate
                      }})</span
                    >
                    <span
                      class="text-danger"
                      *ngIf="
                        updateTreatmentForm.submitted && !newTreatment.duration
                      "
                      >{{ "HORSES.withdrawalUsRequired" | translate }}</span
                    >
                  </label>
                </div>
              </ng-container>

              <div
                class="box-control"
                id="reason-box"
                [ngClass]="{ 'input--filled--model': newTreatment?.reason }"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error: updateTreatmentForm.submitted && !newTreatment.reason
                  }"
                  name="reason"
                  #newReason="ngModel"
                  [(ngModel)]="newTreatment.reason"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted && !!newTreatment.reason)
                    "
                    >{{ "HORSES.reason" | translate }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      updateTreatmentForm.submitted && !newTreatment.reason
                    "
                    >{{ "HORSES.reasonIsRequired" | translate }}</span
                  >
                </label>
              </div>
            </ng-container>

            <div class="box-control input--filled">
              <a
                class="btn btn-dotted btn-code btn-full"
                [ngClass]="{
                  danger:
                    (updateTreatmentForm.submitted &&
                      !newTreatment.start_date) ||
                    serverErrorValidate?.start_date ||
                    dateRangeEditTreatmentErrorMessage.from
                }"
                style="user-select: none"
              >
                <label class="label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted &&
                        !!newTreatment.start_date)
                    "
                    >{{ "HORSES.startDate" | translate }}</span
                  >
                </label>
                <div class="code relative" ng>
                  <input
                    type="text"
                    [textMask]="datePickerMask"
                    placeholder="{{ 'CORE.selectDate' | translate }}"
                    class="form-control input__field"
                    [(ngModel)]="datePicker4.currentDate_input"
                    autocomplete="off"
                    name="start_date"
                    (ngModelChange)="
                      setStartDateInput(); checkEditTreatmentValidity()
                    "
                  />
                  <i
                    class="fa fa-calendar calendar"
                    (click)="datePicker4.isActive = !datePicker4.isActive"
                    aria-hidden="true"
                  ></i>
                </div>
                <span
                  class="text-danger"
                  *ngIf="dateRangeEditTreatmentErrorMessage.from"
                  >{{ dateRangeEditTreatmentErrorMessage.from | translate }}
                  {{ dateRangeEditTreatmentErrorMessage.date }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted && !newTreatment.start_date
                  "
                  >{{ "HORSES.startDateRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    serverErrorValidate?.start_date &&
                    !dateRangeEditTreatmentErrorMessage.from
                  "
                  >{{ serverErrorValidate?.start_date | translate }}</span
                >
              </a>
              <div class="box-control" *ngIf="datePicker4.isActive">
                <datepicker
                  class="datepicker"
                  name="withdrawal-datepicker"
                  [startingDay]="1"
                  [onlyCurrentMonth]="true"
                  [minDate]="datePicker4.minDate"
                  [maxDate]="datePicker4.maxDate"
                  [showWeeks]="false"
                  [initDate]="datePicker4.currentDate"
                  [dateDisabled]="dateDisabled"
                  (selectionDone)="
                    setStartDate($event);
                    datePicker4.isActive = !datePicker4.isActive
                  "
                >
                </datepicker>
              </div>
            </div>

            <div class="box-control input--filled">
              <a
                class="btn btn-dotted btn-code btn-full"
                [ngClass]="{
                  danger:
                    (updateTreatmentForm.submitted &&
                      !newTreatment.withdrawal) ||
                    serverErrorValidate?.withdrawal
                }"
                style="user-select: none"
              >
                <label class="label">
                  <span
                    *ngIf="
                      !updateTreatmentForm.submitted ||
                      (updateTreatmentForm.submitted &&
                        !!newTreatment.withdrawal)
                    "
                    >{{ "HORSES.endDate" | translate }}</span
                  >
                </label>
                <div class="code relative" ng>
                  <input
                    type="text"
                    [textMask]="datePickerMask"
                    placeholder="{{ 'CORE.selectDate' | translate }}"
                    class="form-control input__field"
                    [(ngModel)]="datePicker2.currentDate_input"
                    autocomplete="off"
                    name="withdrawal"
                    (ngModelChange)="
                      setWithdrawalInput(); checkEditTreatmentValidity()
                    "
                  />
                  <i
                    class="fa fa-calendar calendar"
                    (click)="datePicker2.isActive = !datePicker2.isActive"
                    aria-hidden="true"
                  ></i>
                </div>
                <span
                  class="text-danger"
                  *ngIf="dateRangeEditTreatmentErrorMessage.due"
                  >{{ dateRangeEditTreatmentErrorMessage.due | translate
                  }}{{ dateRangeEditTreatmentErrorMessage.date }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted && !newTreatment.withdrawal
                  "
                  >{{ "HORSES.endDateRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    serverErrorValidate?.withdrawal &&
                    !dateRangeEditTreatmentErrorMessage.due
                  "
                  >{{ serverErrorValidate?.withdrawal | translate }}</span
                >
              </a>
              <div class="box-control" *ngIf="datePicker2.isActive">
                <datepicker
                  class="datepicker"
                  name="withdrawal-datepicker"
                  [startingDay]="1"
                  [onlyCurrentMonth]="true"
                  [minDate]="datePicker2.minDate"
                  [maxDate]="datePicker2.maxDate"
                  [showWeeks]="false"
                  [initDate]="datePicker2.currentDate"
                  [dateDisabled]="dateDisabled"
                  (selectionDone)="
                    setWithdrawal($event);
                    datePicker2.isActive = !datePicker2.isActive
                  "
                >
                </datepicker>
              </div>
            </div>

            <ng-container *ngIf="!isAUSCountry">
              <div class="box-control">
                <div class="border-control with_description">
                  <image-upload
                    #imageLoader2
                    [max]="maxImgCount - (newTreatment?.srcs?.length || 0)"
                    [maxFileSize]="maxImgSize"
                    [buttonCaption]="'HORSES.dragSelectPhoto' | translate"
                    [extensions]="[
                      'jpeg',
                      'png',
                      'pdf',
                      'doc',
                      'docx',
                      'csv',
                      'xls',
                      'xlsx',
                      'txt',
                      'mp4',
                      '3gp',
                      '3gp2'
                    ]"
                    (onFileUploadFinish)="
                      setImages(imageLoader2.files); debug(imageLoader2)
                    "
                    (onRemove)="debug(imageLoader2)"
                  >
                  </image-upload>

                  <div *ngIf="newTreatment?.srcs">
                    <ng-template
                      ngFor
                      let-omgImg
                      [ngForOf]="newTreatment?.srcs"
                      let-i="index"
                    >
                      <div class="image-container hr-inline-group">
                        <div
                          class="image image-block"
                          [ngStyle]="{
                            'background-image': 'url(' + omgImg?.src + ')'
                          }"
                        >
                          <div
                            class="x-mark"
                            (click)="newTreatment.srcs.splice(i, 1)"
                          >
                            <span class="close"></span>
                          </div>
                          <div
                            class="box-control remove_on_remove_img remove_on_remove_img--text"
                          >
                            {{ omgImg.description }}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <div class="clearfix">
          <div
            class="row-half"
            *ngIf="
              appMemory.user?.id ===
              ((currentTreatment
                ? currentTreatment?.request
                : newTreatment?.request) !== null
                ? currentTreatment
                  ? currentTreatment?.request?.user_id
                  : newTreatment?.request?.user_id
                : currentTreatment
                ? currentTreatment?.horse?.instructor_id
                : newTreatment.horse?.instructor_id)
            "
          >
            <a
              class="btn btn-transparent btn-full close-modal open-modal"
              href="#remove-treatment"
            >
              {{ "CORE.delete" | translate }}
            </a>
          </div>
          <div
            [class.row-half]="
              appMemory.user?.id ===
              ((currentTreatment
                ? currentTreatment?.request
                : newTreatment?.request) !== null
                ? currentTreatment
                  ? currentTreatment?.request?.user_id
                  : newTreatment?.request?.user_id
                : currentTreatment
                ? currentTreatment?.horse?.instructor_id
                : newTreatment.horse?.instructor_id)
            "
          >
            <button
              class="btn btn-green btn-full"
              type="submit"
              [disabled]="isRequestInProgress4"
              (click)="setTriggerButton(treatmentSaveType.Save)"
            >
              {{ "CORE.save" | translate }}
            </button>
          </div>
          <ng-container *ngIf="!tabs.displayAintreeTabs && !isAUSCountry">
            <div class="clearfix">
              <button
                style="margin-top: 15px"
                class="btn btn-green btn-full"
                type="submit"
                [disabled]="isRequestInProgress4 || invalidTreatmentForm"
                (click)="setTriggerButton(treatmentSaveType.SaveAndCopy)"
              >
                {{ "HORSES.save_copy_treatment" | translate }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="treatment-decline" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(declineForm, true)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(declineForm, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.declineTreat" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #declineForm="ngForm"
      novalidate
      (ngSubmit)="
        treatmentStatus.status = 'cancelled';
        proceessTreatment(undefined, declineForm)
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="box-control"
            id="comment-box"
            [ngClass]="{ 'input--filled--model': treatmentStatus?.comment }"
          >
            <textarea
              class="form-control input__field"
              name="comment"
              [ngClass]="{
                error:
                  declineForm.submitted &&
                  (declineComment.errors?.required ||
                    declineComment.errors?.pattern)
              }"
              #declineComment="ngModel"
              [(ngModel)]="treatmentStatus.comment"
              required
            >
            </textarea>
            <label class="input__label">
              <span
                *ngIf="
                  !declineForm.submitted ||
                  (declineForm.submitted && declineComment.valid)
                "
                >{{ "CORE.comment" | translate }}</span
              >
              <span
                *ngIf="declineForm.submitted && declineComment.errors?.required"
                >{{ "CORE.comment" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            (declineForm.submitted && declineComment.errors) ||
            isRequestInProgress
          "
        >
          {{ "CORE.decline" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<!-- note view-->
<div class="modal" id="note" *ngIf="currentNote">
  <div class="modal-wrapper no-footer">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>

        <ng-container *ngIf="!horse?.is_eligible">
          <a
            class="btn open-modal hide-mobile"
            href="#edit-note"
            *ngIf="
              horse?.is_active && currentNote?.user_id == appMemory.user?.id
            "
            (click)="setNote(currentNote); setIsFormInViewMode()"
          >
            <i class="icon icon-edit dark icon-middle icon-left"></i
            >{{ (isAUSCountry ? "CORE.aus_view" : "CORE.edit") | translate }}</a
          >
        </ng-container>
      </div>
      <div class="right-part">
        <ng-container *ngIf="!horse?.is_eligible">
          <a
            class="btn open-modal show-mobile"
            href="#edit-note"
            *ngIf="
              horse?.is_active && currentNote?.user_id == appMemory.user?.id
            "
            (click)="setNote(currentNote); setIsFormInViewMode()"
          >
            <i class="icon icon-edit dark icon-middle"></i>
          </a>
        </ng-container>
      </div>
      <div class="title">
        {{
          (!tabs.displayAintreeTabs && horse?.farm?.region != "BC2023"
            ? "HORSES.note"
            : "HORSES.form"
          ) | translate
        }}
      </div>
    </div>
    <div class="modal-body">
      <div class="padding-block">
        <div class="item-note full form">
          <div class="name-horse name-box">
            <ng-container
              *ngIf="tabs.displayAintreeTabs; else view_normalNoteType"
            >
              <div *ngFor="let nt of aintreeNoteTypes">
                <div *ngIf="nt.type === currentNote?.type">
                  {{ horse?.name }} / {{ nt.name }}
                </div>
              </div>
            </ng-container>
            <ng-template #view_normalNoteType>
              {{ horse?.name }}
              <div class="status box-right" style="text-transform: capitalize">
                {{ "HORSES." + currentNote?.type | translate }}
              </div>
            </ng-template>
          </div>
          <div class="person">
            {{ currentNote?.user?.name + " " + currentNote?.user?.last_name }}
          </div>
          <div class="date">
            {{ "HORSES.createdAt" | translate }}:
            {{
              currentNote?.created_at | dateObj | date : "dd MMM y hh:mm:ss a"
            }}
          </div>
          <div class="date">
            {{ "HORSES.noteDate" | translate }}:
            {{
              currentNote?.date
                ? (currentNote?.date | dateObj | date : "dd MMM y")
                : ""
            }}
          </div>
          <ng-container
            *ngIf="tabs.displayAintreeTabs; else view_normalNoteDetails"
          >
            <p>&nbsp;</p>
            <div id="note_text">
              <ng-container *ngIf="newNote.type != 'trainer_declaration_form'">
                <div
                  [innerHTML]="
                    ('NOTES.forms.' + currentNote.type + '.intro'
                      | translate) || ''
                  "
                ></div>
                <p>&nbsp;</p>
                <div
                  [innerHTML]="
                    ('NOTES.forms.' + currentNote.type + '.parentList1'
                      | translate) || ''
                  "
                  style="padding-bottom: 10px"
                ></div>
                <ng-container
                  *ngFor="let field of generateNoteFields(currentNote?.type)"
                >
                  <ng-container
                    *ngIf="
                      [
                        'review_consent_vetForm1',
                        'review_consent_vetForm2',
                        'previous_treatment_vetForm1',
                        'previous_treatment_vetForm2'
                      ].includes(field.id)
                    "
                  >
                    <div class="box-control input--filled--model clearfix">
                      <div
                        class="input__label"
                        [ngClass]="{
                          'input--filled--model': currentNote[field?.name]
                        }"
                      >
                        <span>{{ field?.label }}</span>
                      </div>
                      <div class="form-control input__field">
                        {{ currentNote[field?.name] || "" }}
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <div
                  [innerHTML]="
                    ('NOTES.forms.' + currentNote.type + '.parentList1_list2'
                      | translate) || ''
                  "
                ></div>
              </ng-container>

              <ng-container
                *ngFor="let field of generateNoteFields(currentNote?.type)"
              >
                <ng-container
                  *ngIf="
                    ![
                      'review_consent_vetForm1',
                      'review_consent_vetForm2',
                      'previous_treatment_vetForm1',
                      'previous_treatment_vetForm2'
                    ].includes(field.id)
                  "
                >
                  <div class="box-control input--filled--model clearfix">
                    <div
                      class="input__label"
                      [ngClass]="{
                        'input--filled--model': currentNote[field?.name]
                      }"
                    >
                      <span>{{ field?.label }}</span>
                    </div>
                    <div class="form-control input__field">
                      {{ currentNote[field?.name] || "" }}
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container
                *ngIf="currentNote.type != 'trainer_declaration_form'"
              >
                <div
                  [innerHTML]="
                    ('NOTES.forms.' + currentNote.type + '.parentList2'
                      | translate) || ''
                  "
                ></div>
              </ng-container>

              <ng-container
                *ngIf="currentNote.type == 'trainer_declaration_form'"
              >
                <div
                  [innerHTML]="
                    ('NOTES.forms.' + currentNote.type + '.description'
                      | translate) || ''
                  "
                ></div>

                <ng-container
                  [ngTemplateOutlet]="noteDatePicker"
                ></ng-container>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="currentNote?.type == 'preTravel_vet_form'">
            <div style="margin: 10px 0px">
              <b>I the Veterinary Surgeon certify that I have</b>
            </div>
          </ng-container>

          <ng-container
            *ngIf="isHKCountry && currentNote?.type != 'preTravel_vet_form'"
          >
            <div style="margin: 10px 0px">
              <b>{{ "HORSES." + currentNote?.type | translate }}</b>
            </div>
          </ng-container>

          <!-- generateNoteFields -->
          <ng-container
            *ngIf="
              isHKCountry &&
              !!currentNote.type &&
              newNote.type != 'preTravel_vet_form'
            "
          >
            <div
              *ngFor="let field of generateNoteFields(currentNote?.type)"
              class="box-control clearfix"
              [ngClass]="{ 'input--filled--model': currentNote[field.name] }"
            >
              <div *ngIf="field.type === 'checkbox'">
                <div>
                  <ng-container
                    *ngIf="currentNote?.type == 'preTravel_vet_form'"
                  >
                    <div class="box-control input--filled--model clearfix">
                      <div
                        class="input__label"
                        [ngClass]="{
                          'input--filled--model': currentNote[field?.name]
                        }"
                      >
                        <p [innerHTML]="field?.label"></p>
                      </div>
                    </div>
                  </ng-container>
                  <input
                    type="checkbox"
                    disabled
                    id="{{ field?.uuid }}"
                    [checked]="!!currentNote[field?.name]"
                  />
                  <div class="toggle">
                    <label
                      for="{{ field.uuid }}"
                      [ngClass]="{
                        danger: !!(this.serverErrorValidate || {})[field.id]
                      }"
                    >
                      <i>&nbsp;</i>
                    </label>
                  </div>
                  <span class="toggle__label">
                    <span *ngIf="!!currentNote[field.name]">Yes</span>
                    <span *ngIf="!currentNote[field.name]">No</span>
                  </span>
                </div>
              </div>

              <div *ngIf="field.type !== 'checkbox'">
                <ng-container *ngIf="field?.id == 'signature_for_statement'">
                  <div style="margin-bottom: 20px">
                    <h6>
                      <b>Signature for the statement</b>
                    </h6>
                  </div>
                </ng-container>

                <div class="box-control input--filled--model clearfix">
                  <div
                    class="input__label"
                    [ngClass]="{
                      'input--filled--model': currentNote[field?.name]
                    }"
                  >
                    <p>{{ field?.label }}</p>
                  </div>
                  <div class="form-control input__field">
                    {{ currentNote[field?.name] || "" }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="this.isHKCountry && currentNote.type == 'preTravel_vet_form'"
          >
            <app-prevet-travel-fields
              [noteFields]="generateNoteFields(currentNote?.type)"
              [newNote]="currentNote"
            >
            </app-prevet-travel-fields>
          </ng-container>

          <ng-template #view_normalNoteDetails>
            <ng-container *ngIf="!this.isUSCountry; else USNoteView">
              <div class="name">{{ currentNote?.title }}</div>
              <div class="text">
                {{ currentNote?.description }} {{ currentNote?.weight }}
              </div>
            </ng-container>
            <ng-template #USNoteView>
              <div
                *ngIf="!['sign', 'attendingVetForm'].includes(currentNote.type)"
              >
                <div class="name">{{ currentNote?.title }}</div>
                <div class="text">
                  {{ currentNote?.description }} {{ currentNote?.weight }}
                </div>
                <div
                  class="box-control"
                  [ngClass]="{
                    'input--filled--model': currentNote?.description
                  }"
                  *ngIf="!['weight', 'exam'].includes(currentNote.type)"
                >
                  <div class="form-control input__field">
                    {{ currentNote.description }}
                  </div>
                  <label class="input__label">
                    <span>{{ "NOTES.labels.history" | translate }}</span>
                  </label>
                </div>
              </div>
              <ng-container *ngIf="currentNote.type == 'attendingVetForm'">
                <div style="margin-top: 10px">
                  <h6>
                    <b
                      >Certificate for Regulatory Veterinarians examination
                      only</b
                    >
                  </h6>
                </div>
              </ng-container>

              <div
                *ngFor="let field of generateNoteFields(currentNote?.type)"
                class="box-control input--filled--model clearfix"
              >
                <div *ngIf="field?.type === 'checkbox'">
                  <div class="toggle__text__inline">{{ field?.label }}</div>
                  <input
                    type="checkbox"
                    disabled
                    id="{{ field?.uuid }}"
                    [checked]="!!currentNote[field?.name]"
                  />
                  <div class="toggle">
                    <label for="{{ field?.uuid }}">
                      <i>&nbsp;</i>
                    </label>
                  </div>
                  <span class="toggle__label">
                    <span *ngIf="!!currentNote[field?.name]">Yes</span>
                    <span *ngIf="!currentNote[field?.name]">No</span>
                  </span>
                </div>

                <div *ngIf="field?.type !== 'checkbox'">
                  <div
                    class="input__label"
                    [ngClass]="{
                      'input--filled--model': currentNote[field?.name]
                    }"
                  >
                    <span>{{ field?.label }}</span>
                  </div>
                  <div class="form-control input__field">
                    {{ currentNote[field?.name] || "" }}
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
          <div class="box-control">
            <div class="img-load" *ngFor="let image of newNote?.srcs">
              <a [href]="image.src" target="_blank">
                <img [src]="image.iconsrc" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- add note -->
<div class="modal" id="add-note" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createNoteForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(createNoteForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">
        {{ "HORSES.addForm" | translate }}
      </div>
    </div>
    <form
      class="form modal-form"
      #createNoteForm="ngForm"
      novalidate
      (ngSubmit)="
        createNote(
          appMemory.roles[appMemory.user?.roles[0]?.id],
          createNoteForm
        )
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <div class="box-nav" style="padding-bottom: 10px">
            <div class="label">{{ "HORSES.noteType" | translate }}</div>
            <ng-container *ngIf="tabs.displayAintreeTabs; else normalNoteType">
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let noteType of aintreeNoteTypes"
                  [ngClass]="{
                    active: noteType.type === selectedTab
                  }"
                >
                  <a
                    class="btn"
                    (click)="
                      clearUserInput(createNoteForm);
                      newNote.type = noteType.type || selectedTab;
                      noteType.type == newNote.type
                        ? void
                        : (newNote.weight = undefined)
                    "
                  >
                    <div class="name" style="text-transform: capitalize">
                      <!-- {{ "HORSES." + noteType.type | translate }} -->
                      {{ noteType.name }}
                    </div>
                  </a>
                </li>
              </ul>
              <p>&nbsp;</p>
              <div id="note_text">
                <ng-container
                  *ngIf="newNote.type != 'trainer_declaration_form'"
                >
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' + (newNote.type || selectedTab) + '.intro'
                        | translate) || ''
                    "
                  ></div>

                  <ng-container
                    [ngTemplateOutlet]="noteDatePicker"
                  ></ng-container>

                  <div
                    [innerHTML]="
                      ('NOTES.forms.' +
                        (newNote.type || selectedTab) +
                        '.parentList1' | translate) || ''
                    "
                    style="padding-bottom: 10px"
                  ></div>

                  <ng-container
                    *ngFor="
                      let field of generateNoteFields(
                        newNote?.type || selectedTab
                      )
                    "
                  >
                    <div class="nestedList_1">
                      <ng-container
                        *ngIf="
                          [
                            'review_consent_vetForm1',
                            'review_consent_vetForm2',
                            'previous_treatment_vetForm1',
                            'previous_treatment_vetForm2'
                          ].includes(field.id)
                        "
                      >
                        <div
                          class="box-control clearfix"
                          [ngClass]="{
                            'input--filled--model': newNote[field.name]
                          }"
                        >
                          <input
                            type="{{ field.type }}"
                            class="form-control input__field"
                            style="display: block"
                            name="{{ field?.name }}"
                            autocomplete="off"
                            spellcheck="false"
                            [(ngModel)]="newNote[field.name]"
                            value="{{ newNote[field.name] }}"
                          />
                          <label class="input__label">
                            <span>{{ field.label }}</span>
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>

                  <div
                    [innerHTML]="
                      ('NOTES.forms.' +
                        (newNote.type || selectedTab) +
                        '.parentList1_list2' | translate) || ''
                    "
                  ></div>
                </ng-container>

                <ng-container
                  *ngFor="
                    let field of generateNoteFields(
                      newNote?.type || selectedTab
                    )
                  "
                >
                  <ng-container
                    *ngIf="
                      ![
                        'review_consent_vetForm1',
                        'review_consent_vetForm2',
                        'previous_treatment_vetForm1',
                        'previous_treatment_vetForm2'
                      ].includes(field.id)
                    "
                  >
                    <div
                      class="box-control clearfix"
                      [ngClass]="{
                        'input--filled--model': newNote[field.name]
                      }"
                    >
                      <input
                        type="{{ field.type }}"
                        class="form-control input__field"
                        style="display: block"
                        name="{{ field?.name }}"
                        autocomplete="off"
                        spellcheck="false"
                        [(ngModel)]="newNote[field.name]"
                        value="{{ newNote[field.name] }}"
                      />
                      <label class="input__label">
                        <span>{{ field.label }}</span>
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="newNote.type != 'trainer_declaration_form'"
                >
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' +
                        (newNote.type || selectedTab) +
                        '.parentList2' | translate) || ''
                    "
                  ></div>
                </ng-container>

                <ng-container
                  *ngIf="this.newNote.type == 'trainer_declaration_form'"
                >
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' + this.newNote.type + '.description'
                        | translate) || ''
                    "
                  ></div>
                </ng-container>
                <ng-container
                  *ngIf="this.newNote.type == 'trainer_declaration_form'"
                >
                  <ng-container
                    [ngTemplateOutlet]="noteDatePicker"
                  ></ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #normalNoteType>
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let noteType of noteTypes"
                  [ngClass]="{
                    active: noteType.type == newNote.type || hasSignNoteType
                  }"
                >
                  <a
                    class="btn"
                    (click)="
                      newNote.type =
                        noteType.type || hasSignNoteType || selectedTab
                    "
                  >
                    <div class="name" style="text-transform: capitalize">
                      {{ "HORSES." + noteType.type | translate }}
                    </div>
                  </a>
                </li>
              </ul>
            </ng-template>
          </div>
          <ng-container
            *ngIf="tabs.displayAintreeTabs; else normalNoteTypeForm"
          >
            <div
              class="box-control"
              *ngIf="
                ['other', 'vet_form_stage1', 'vet_form_stage2'].includes(
                  newNote.type || selectedTab
                )
              "
            >
              <div
                class="box-info"
                [innerHTML]="
                  ('NOTES.forms.' +
                    (newNote.type || selectedTab) +
                    '.fields.files' | translate) || ''
                "
              ></div>
              <div class="border-control with_description">
                <image-upload
                  #imageLoader23
                  [max]="maxImgCount"
                  [maxFileSize]="maxImgSize"
                  [buttonCaption]="'NOTES.labels.drag' | translate"
                  [extensions]="[
                    'jpeg',
                    'png',
                    'pdf',
                    'doc',
                    'docx',
                    'csv',
                    'xls',
                    'xlsx',
                    'txt',
                    'mp4',
                    '3gp',
                    '3gp2'
                  ]"
                  (onFileUploadFinish)="
                    setNoteImages(imageLoader23.files); debug(imageLoader23)
                  "
                  (onRemove)="debug(imageLoader23)"
                >
                </image-upload>
              </div>
            </div>
          </ng-container>
          <ng-template #normalNoteTypeForm>
            <div *ngIf="!hasSignNoteType && !isHKCountry && !isAUSCountry">
              <!-- title -->
              <div
                class="box-control"
                style="padding-bottom: 10px"
                id="note-title-box"
                [ngClass]="{ 'input--filled--model': newNote?.title }"
                *ngIf="newNote.type !== 'attendingVetForm'"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      createNoteForm.submitted &&
                      (newNoteTitle.errors?.required ||
                        newNoteTitle.errors?.pattern)
                  }"
                  name="note_title"
                  #newNoteTitle="ngModel"
                  [(ngModel)]="newNote.title"
                  autocomplete="off"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !createNoteForm.submitted ||
                      (createNoteForm.submitted && newNoteTitle.valid)
                    "
                    >{{ "CORE.title" | translate }}</span
                  >
                  <span
                    *ngIf="
                      createNoteForm.submitted && newNoteTitle.errors?.required
                    "
                    >{{ "CORE.title" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                  <span
                    *ngIf="
                      createNoteForm.submitted && newNoteTitle.errors?.pattern
                    "
                    >{{ "CORE.title" | translate }}
                    {{ "CORE.isInvalid" | translate }}</span
                  >
                </label>
              </div>
              <!-- description -->
              <div
                class="box-control"
                id="note-descr-box"
                [ngClass]="{ 'input--filled--model': newNote?.description }"
                *ngIf="
                  newNote.type !== 'weight' &&
                  newNote.type !== 'attendingVetForm'
                "
              >
                <textarea
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      createNoteForm.submitted &&
                      (noteDescr.errors?.required || noteDescr.errors?.pattern)
                  }"
                  name="note_descr"
                  #noteDescr="ngModel"
                  [(ngModel)]="newNote.description"
                  autocomplete="off"
                  spellcheck="false"
                  required
                >
                </textarea>
                <label class="input__label">
                  <span
                    *ngIf="
                      !createNoteForm.submitted ||
                      (createNoteForm.submitted && noteDescr.valid)
                    "
                    >{{ "HORSES.description" | translate }}</span
                  >
                  <span
                    *ngIf="
                      createNoteForm.submitted && noteDescr.errors?.required
                    "
                    >{{ "HORSES.description" | translate
                    }}{{ "CORE.isRequired" | translate }}</span
                  >
                  <span
                    *ngIf="
                      createNoteForm.submitted && noteDescr.errors?.pattern
                    "
                    >{{ "HORSES.description" | translate
                    }}{{ "CORE.isInvalid" | translate }}</span
                  >
                </label>
              </div>
            </div>

            <!-- date picker -->
            <ng-container
              *ngIf="!isAUSCountry"
              [ngTemplateOutlet]="noteDatePicker"
            ></ng-container>
            <!-- weight -->
            <div
              class="clearfix"
              *ngIf="newNote.type === 'weight'"
              style="margin-bottom: 45px"
            >
              <div
                class="box-control"
                id="note-title-box"
                [ngClass]="{ 'input--filled--model': newNote?.description }"
                style="
                  width: calc(50% - 10px);
                  float: left;
                  margin: 0;
                  margin-right: 20px;
                "
              >
                <input
                  type="text"
                  class="form-control input__field only-number"
                  maxlength="7"
                  [ngClass]="{
                    error:
                      createNoteForm.submitted &&
                      (noteWeightDescr.errors?.required ||
                        noteWeightDescr.errors?.pattern)
                  }"
                  name="note_weight"
                  #noteWeightDescr="ngModel"
                  [(ngModel)]="newNote.description"
                  autocomplete="off"
                  spellcheck="false"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !createNoteForm.submitted ||
                      (createNoteForm.submitted && noteWeightDescr.valid)
                    "
                    >{{ "HORSES.weight" | translate }}</span
                  >
                  <span
                    *ngIf="
                      createNoteForm.submitted &&
                      noteWeightDescr.errors?.required
                    "
                    >{{ "HORSES.weight" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                  <span
                    *ngIf="
                      createNoteForm.submitted &&
                      noteWeightDescr.errors?.pattern
                    "
                    >{{ "HORSES.weight" | translate }}
                    {{ "CORE.isInvalid" | translate }}</span
                  >
                </label>
              </div>
              <div
                class="box-control"
                [ngClass]="{ 'input--filled--model': newNote.weight }"
                style="width: calc(50% - 10px); float: left; margin: 0"
              >
                <ng-select
                  [allowClear]="false"
                  id="weights-selector"
                  class="weights-selector"
                  [ngClass]="{
                    danger:
                      createNoteForm.submitted &&
                      (!newNote.weight || newNote.weight == undefined)
                  }"
                  required
                  #weightsSelector
                  [active]="weightsSelectorModel"
                  (selected)="setWeight($event)"
                  [items]="appMemory.weights"
                  name="weights-s"
                  placeholder="{{
                    'HORSES.' +
                      (createNoteForm.submitted
                        ? 'weightMeasure'
                        : 'weightMeasure') | translate
                  }}"
                >
                </ng-select>
              </div>
            </div>
            <ng-container *ngIf="newNote.type == 'attendingVetForm'">
              <h6>
                <b>Certificate for Regulatory Veterinarians examination only</b>
              </h6>
            </ng-container>
            <ng-container *ngIf="newNote.type == 'preTravel_vet_form'">
              <div
                class="custom-list"
                [innerHTML]="
                  ('NOTES.forms.' + (newNote.type || selectedTab) + '.intro'
                    | translate) || ''
                "
              ></div>
            </ng-container>

            <ng-container
              *ngIf="
                this.isUSCountry ||
                (this.isHKCountry && newNote.type != 'preTravel_vet_form')
              "
            >
              <div
                *ngFor="let field of generateNoteFields(newNote?.type)"
                class="box-control clearfix"
                [ngClass]="{ 'input--filled--model': newNote[field.name] }"
              >
                <div *ngIf="field.type === 'checkbox'">
                  <div
                    class="toggle__text__inline"
                    [innerHTML]="field.label"
                  ></div>
                  <div>
                    <input
                      type="checkbox"
                      id="{{ field.uuid }}"
                      name="{{ field.name }}"
                      [checked]="!!newNote[field.name]"
                      (change)="newNote[field.name] = !newNote[field.name]"
                    />
                    <div class="toggle">
                      <label for="{{ field.uuid }}">
                        <i></i>
                      </label>
                    </div>
                    <span class="toggle__label">
                      <span *ngIf="!!newNote[field.name]">Yes</span>
                      <span *ngIf="!newNote[field.name]">No</span>
                    </span>
                  </div>
                </div>

                <div *ngIf="field.type !== 'checkbox'">
                  <ng-container *ngIf="field?.id == 'signature_for_statement'">
                    <div style="margin-bottom: 20px">
                      <h6>
                        <b>Signature for above statement</b>
                      </h6>
                    </div>
                  </ng-container>

                  <input
                    type="{{ field.type }}"
                    class="form-control input__field"
                    style="display: block"
                    name="{{ field?.name }}"
                    autocomplete="off"
                    spellcheck="false"
                    [(ngModel)]="newNote[field.name]"
                    value="{{ newNote[field.name] }}"
                    [checked]="
                      field.type === 'checkbox' && !!newNote[field.name]
                    "
                  />

                  <label
                    class="input__label"
                    [ngClass]="{
                      input_label_filled: field?.id == 'signature_for_statement'
                    }"
                  >
                    <span *ngIf="field.label">{{
                      field.label || "" | translate
                    }}</span>
                  </label>
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf="this.isHKCountry && newNote.type == 'preTravel_vet_form'"
            >
              <app-prevet-travel-fields
                [noteFields]="generateNoteFields(newNote?.type)"
                [newNote]="newNote"
                [noteForm]="createNoteForm"
              >
              </app-prevet-travel-fields>
            </ng-container>

            <ng-container *ngIf="isAUSCountry">
              <ng-container
                *ngIf="newNote?.type == 'trainer_vertinary_history_form'"
              >
              <app-aus-verterinary-history-form-info
                [noteFields]="generateNoteFields(newNote?.type)"
                [newNote]="newNote"
                [noteForm]="createNoteForm"
              >
              </app-aus-verterinary-history-form-info>
              </ng-container>
              <ng-container
                *ngIf="
                  newNote?.type ==
                    'trainer_declaration_execution_submission_form' ||
                  newNote?.type ==
                    'veterinary_declaration_execution_submission_form'
                "
              >
                <app-aus-declaration-execution-submission
                  [noteFields]="generateNoteFields(newNote?.type)"
                  [newNote]="newNote"
                  [yesLabel]="'Agree'"
                  [noLabel]="'No'"
                ></app-aus-declaration-execution-submission>
              </ng-container>

              <ng-container
                *ngIf="newNote?.type == 'veterinary_inspection_form'"
              >
                <app-aus-verterinary-inspection-form-info
                  [noteFields]="generateNoteFields(newNote?.type)"
                  [newNote]="newNote"
                  [yesLabel]="'Abnormalities Detected'"
                  [noLabel]="'No Abnormalities Detected'"
                  [noteForm]="createNoteForm"
                >
                </app-aus-verterinary-inspection-form-info>
              </ng-container>
            </ng-container>
            <!-- file upload -->
            <ng-container *ngIf="!isAUSCountry">
              <div
                class="box-control"
                *ngIf="
                  (newNote.type == 'other' ||
                    newNote.type == 'attendingVetForm') &&
                  !hasSignNoteType &&
                  !isHKCountry
                "
              >
                <div class="border-control with_description">
                  <image-upload
                    #imageLoader23
                    [max]="maxImgCount"
                    [maxFileSize]="maxImgSize"
                    [buttonCaption]="'HORSES.dragOrSelectPhoto' | translate"
                    [extensions]="[
                      'jpeg',
                      'png',
                      'pdf',
                      'doc',
                      'docx',
                      'csv',
                      'xls',
                      'xlsx',
                      'txt',
                      'mp4',
                      '3gp',
                      '3gp2'
                    ]"
                    (onFileUploadFinish)="
                      setNoteImages(imageLoader23.files); debug(imageLoader23)
                    "
                    (onRemove)="debug(imageLoader23)"
                  >
                  </image-upload>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
      <div class="modal-footer">
        <ng-container
          *ngIf="
            this.newNote.type == 'trainer_declaration_form';
            else buttonTemplate
          "
        >
          <button
            class="btn btn-green btn-full"
            type="submit"
            [disabled]="
              createNoteForm.submitted ||
              isRequestInProgress ||
              serverErrorValidate?.note_date
            "
          >
            Submit declaration
          </button>
        </ng-container>

        <ng-template #buttonTemplate>
          <button
            class="btn btn-green btn-full"
            type="submit"
            [disabled]="
              createNoteForm.form.invalid ||
              isRequestInProgress ||
              serverErrorValidate?.note_date
            "
          >
            {{
              (!tabs.displayAintreeTabs && !isHKCountry && !isAUSCountry
                ? "HORSES.addNote"
                : isHKCountry
                ? "HORSES.addDeclaration"
                : isAUSCountry &&
                  (this.newNote.type ==
                    "veterinary_declaration_execution_submission_form" ||
                    this.newNote.type ==
                      "trainer_declaration_execution_submission_form")
                ? "HORSES.submitForm"
                : "HORSES.addForm"
              ) | translate
            }}
          </button>
        </ng-template>
      </div>
    </form>
  </div>
</div>

<!-- add video -->
<div class="modal" id="add-video">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">Optional video submission</div>
    </div>
    <div class="modal-body">
      <div class="padding-block form">
        <div class="box-control">
          <div id="video-submission-text">
            {{ "HORSES.videoSubmissionInfo" | translate }}
          </div>
        </div>
        <div class="box-control">
          <div class="border-control with_description">
            <image-upload
              #imageLoader28
              [max]="1"
              [maxFileSize]="maxImgSize"
              [buttonCaption]="'NOTES.labels.drag' | translate"
              [extensions]="['mp4']"
              (onFileUploadFinish)="
                setNoteImages(imageLoader28.files); debug(imageLoader28)
              "
              (onRemove)="debug(imageLoader28)"
            >
            </image-upload>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-green btn-full"
        type="submit"
        (click)="addVideo(appMemory.roles[appMemory.user?.roles[0]?.id])"
      >
        Add Video
      </button>
    </div>
  </div>
</div>

<div class="modal" id="edit-note" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(updateNoteForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(updateNoteForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>

      <div class="title" *ngIf="!isFormInViewMode">
        {{
          (!tabs.displayAintreeTabs &&
          horse?.farm?.region != "BC2023" &&
          !isAUSCountry
            ? "HORSES.editNote"
            : "HORSES.editForm"
          ) | translate
        }}
      </div>

      <div class="title" *ngIf="isFormInViewMode">
        {{ "HORSES.view_form" | translate }}
      </div>
    </div>
    <form
      class="form modal-form"
      #updateNoteForm="ngForm"
      novalidate
      (ngSubmit)="
        updateNote(
          appMemory.roles[appMemory.user?.roles[0]?.id],
          updateNoteForm
        )
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <div class="box-nav" style="padding-bottom: 10px">
            <div class="label">{{ "HORSES.noteType" | translate }}</div>
            <ng-container
              *ngIf="tabs.displayAintreeTabs; else edit_normalNoteType"
            >
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let noteType of aintreeNoteTypes"
                  [ngClass]="{
                    active: noteType.type === (newNote.type || selectedTab),
                    disabled: noteType.type != newNote.type
                  }"
                >
                  <a class="btn">
                    <div class="name" style="text-transform: capitalize">
                      <!-- {{ "HORSES." + noteType.type | translate }} -->
                      {{ noteType.name }}
                    </div>
                  </a>
                </li>
              </ul>
              <p>&nbsp;</p>
              <div id="note_text">
                <ng-container
                  *ngIf="newNote.type != 'trainer_declaration_form'"
                >
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' + (newNote.type || selectedTab) + '.intro'
                        | translate) || ''
                    "
                  ></div>
                  <ng-container
                    [ngTemplateOutlet]="noteDatePicker"
                  ></ng-container>
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' + newNote.type + '.parentList1'
                        | translate) || ''
                    "
                    style="padding-bottom: 10px"
                  ></div>
                  <ng-container
                    *ngFor="let field of generateNoteFields(newNote?.type)"
                  >
                    <div class="nestedList_1">
                      <ng-container
                        *ngIf="
                          [
                            'review_consent_vetForm1',
                            'review_consent_vetForm2',
                            'previous_treatment_vetForm1',
                            'previous_treatment_vetForm2'
                          ].includes(field.id)
                        "
                      >
                        <div
                          class="box-control clearfix"
                          [ngClass]="{
                            'input--filled--model': newNote[field.name]
                          }"
                        >
                          <input
                            type="{{ field.type }}"
                            class="form-control input__field"
                            style="display: block"
                            name="{{ field?.name }}"
                            autocomplete="off"
                            spellcheck="false"
                            [(ngModel)]="newNote[field.name]"
                            value="{{ newNote[field.name] }}"
                          />
                          <label class="input__label">
                            <span>{{ field.label }}</span>
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' + newNote.type + '.parentList1_list2'
                        | translate) || ''
                    "
                  ></div>
                </ng-container>
                <ng-container
                  *ngFor="let field of generateNoteFields(newNote?.type)"
                >
                  <ng-container
                    *ngIf="
                      ![
                        'review_consent_vetForm1',
                        'review_consent_vetForm2',
                        'previous_treatment_vetForm1',
                        'previous_treatment_vetForm2'
                      ].includes(field.id)
                    "
                  >
                    <div
                      class="box-control clearfix"
                      [ngClass]="{
                        'input--filled--model': newNote[field.name]
                      }"
                    >
                      <input
                        type="{{ field.type }}"
                        class="form-control input__field"
                        style="display: block"
                        name="{{ field?.name }}"
                        autocomplete="off"
                        spellcheck="false"
                        [(ngModel)]="newNote[field.name]"
                        value="{{ newNote[field.name] }}"
                      />
                      <label class="input__label">
                        <span>{{ field.label }}</span>
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container
                  *ngIf="newNote.type != 'trainer_declaration_form'"
                >
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' +
                        (newNote.type || selectedTab) +
                        '.parentList2' | translate) || ''
                    "
                  ></div>
                </ng-container>

                <ng-container
                  *ngIf="this.newNote.type == 'trainer_declaration_form'"
                >
                  <div
                    [innerHTML]="
                      ('NOTES.forms.' + this.newNote.type + '.description'
                        | translate) || ''
                    "
                  ></div>

                  <ng-container
                    [ngTemplateOutlet]="noteDatePicker"
                  ></ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #edit_normalNoteType>
              <ul class="nav-type line">
                <li
                  class="change-active"
                  *ngFor="let noteType of noteTypes"
                  [ngClass]="{
                    active:
                      noteType.type == newNote.type ||
                      noteType.type == selectedTab,
                    disabled: true
                  }"
                >
                  <a class="btn">
                    <div class="name" style="text-transform: capitalize">
                      {{ "HORSES." + noteType.type | translate }}
                    </div>
                  </a>
                </li>
              </ul>
            </ng-template>
          </div>
          <ng-container
            *ngIf="tabs.displayAintreeTabs; else edit_normalNoteTypeForm"
          >
            <div
              class="box-control"
              *ngIf="
                ['other', 'vet_form_stage1', 'vet_form_stage2'].includes(
                  newNote.type || selectedTab
                )
              "
            >
              <div
                class="box-info"
                [innerHTML]="
                  ('NOTES.forms.' +
                    (newNote?.type || selectedTab) +
                    '.fields.files' | translate) || ''
                "
              ></div>
              <div class="border-control with_description">
                <image-upload
                  #imageLoader24
                  [max]="maxImgCount - (newNote?.srcs?.length || 0)"
                  [maxFileSize]="maxImgSize"
                  [buttonCaption]="'NOTES.labels.drag' | translate"
                  [extensions]="[
                    'jpeg',
                    'png',
                    'pdf',
                    'doc',
                    'docx',
                    'csv',
                    'xls',
                    'xlsx',
                    'txt',
                    'mp4',
                    '3gp',
                    '3gp2'
                  ]"
                  (onFileUploadFinish)="
                    setNoteImages(imageLoader24.files); debug(imageLoader24)
                  "
                  (onRemove)="debug(imageLoader24)"
                >
                </image-upload>

                <div *ngIf="newNote?.srcs">
                  <ng-template
                    ngFor
                    let-omgImg
                    [ngForOf]="newNote?.srcs"
                    let-i="index"
                  >
                    <div class="image-container hr-inline-group">
                      <div
                        class="image image-block"
                        [ngStyle]="{
                          'background-image': 'url(' + omgImg?.iconsrc + ')'
                        }"
                      >
                        <div class="x-mark" (click)="newNote.srcs.splice(i, 1)">
                          <span class="close"></span>
                        </div>
                        <div
                          class="box-control remove_on_remove_img remove_on_remove_img--text"
                        >
                          {{ omgImg.description }}
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #edit_normalNoteTypeForm>
            <ng-container
              *ngIf="!hasSignNoteType && !isHKCountry && !isAUSCountry"
            >
              <!-- title -->
              <div
                class="box-control"
                style="padding-bottom: 10px"
                id="note-title-box"
                [ngClass]="{ 'input--filled--model': newNote?.title }"
                *ngIf="newNote.type !== 'attendingVetForm'"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      updateNoteForm.submitted &&
                      (newNoteTitle.errors?.required ||
                        newNoteTitle.errors?.pattern)
                  }"
                  name="note_title"
                  #newNoteTitle="ngModel"
                  [(ngModel)]="newNote.title"
                  autocomplete="off"
                  spellcheck="false"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateNoteForm.submitted ||
                      (updateNoteForm.submitted && newNoteTitle.valid)
                    "
                    >{{ "CORE.title" | translate }}</span
                  >
                  <span
                    *ngIf="
                      updateNoteForm.submitted && newNoteTitle.errors?.required
                    "
                    >{{ "CORE.title" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                  <span
                    *ngIf="
                      updateNoteForm.submitted && newNoteTitle.errors?.pattern
                    "
                    >{{ "CORE.title" | translate }}
                    {{ "CORE.isInvalid" | translate }}</span
                  >
                </label>
              </div>
              <!-- description -->
              <div
                class="box-control"
                id="note-descr-box"
                [ngClass]="{ 'input--filled--model': newNote?.description }"
                *ngIf="
                  newNote.type !== 'weight' &&
                  newNote.type !== 'attendingVetForm'
                "
              >
                <textarea
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      updateNoteForm.submitted &&
                      (noteDescr.errors?.required || noteDescr.errors?.pattern)
                  }"
                  name="note_descr"
                  #noteDescr="ngModel"
                  [(ngModel)]="newNote.description"
                  spellcheck="false"
                  required
                >
                </textarea>
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateNoteForm.submitted ||
                      (updateNoteForm.submitted && noteDescr.valid)
                    "
                    >{{ "HORSES.description" | translate }}</span
                  >
                  <span
                    *ngIf="
                      updateNoteForm.submitted && noteDescr.errors?.required
                    "
                    >{{ "HORSES.description" | translate
                    }}{{ "CORE.isRequired" | translate }}</span
                  >
                  <span
                    *ngIf="
                      updateNoteForm.submitted && noteDescr.errors?.pattern
                    "
                    >{{ "HORSES.description" | translate
                    }}{{ "CORE.isInvalid" | translate }}</span
                  >
                </label>
              </div>
            </ng-container>

            <!-- weight -->
            <div
              class="clearfix"
              *ngIf="newNote.type === 'weight'"
              style="margin-bottom: 45px"
            >
              <div
                class="box-control"
                id="note-title-box"
                [ngClass]="{ 'input--filled--model': newNote?.description }"
                style="
                  width: calc(50% - 10px);
                  float: left;
                  margin: 0;
                  margin-right: 20px;
                "
              >
                <input
                  type="text"
                  class="form-control input__field only-number"
                  maxlength="7"
                  [ngClass]="{
                    error:
                      updateNoteForm.submitted &&
                      (noteWeightDescr.errors?.required ||
                        noteWeightDescr.errors?.pattern)
                  }"
                  name="note_weight"
                  #noteWeightDescr="ngModel"
                  [(ngModel)]="newNote.description"
                  autocomplete="off"
                  spellcheck="false"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !updateNoteForm.submitted ||
                      (updateNoteForm.submitted && noteWeightDescr.valid)
                    "
                    >{{ "HORSES.weight" | translate }}</span
                  >
                  <span
                    *ngIf="
                      updateNoteForm.submitted &&
                      noteWeightDescr.errors?.required
                    "
                    >{{ "HORSES.weight" | translate }}
                    {{ "CORE.isRequired" | translate }}</span
                  >
                  <span
                    *ngIf="
                      updateNoteForm.submitted &&
                      noteWeightDescr.errors?.pattern
                    "
                    >{{ "HORSES.weight" | translate }}
                    {{ "CORE.isInvalid" | translate }}</span
                  >
                </label>
              </div>
              <div
                class="box-control"
                [ngClass]="{ 'input--filled--model': newNote.weight }"
                style="width: calc(50% - 10px); float: left; margin: 0"
              >
                <ng-select
                  [allowClear]="false"
                  id="weights-selector"
                  class="weights-selector"
                  [ngClass]="{
                    danger:
                      updateNoteForm.submitted &&
                      (!newNote.weight || newNote.weight == undefined)
                  }"
                  required
                  #weightsSelector
                  [active]="weightsSelectorModel"
                  (selected)="setWeight($event)"
                  [items]="appMemory.weights"
                  name="weights-s"
                  placeholder="{{
                    'HORSES.' +
                      (updateNoteForm.submitted
                        ? 'weightMeasure'
                        : 'weightMeasure') | translate
                  }}"
                >
                </ng-select>
              </div>
            </div>
            <!-- date picker -->
            <ng-container
              *ngIf="!isAUSCountry"
              [ngTemplateOutlet]="noteDatePicker"
            ></ng-container>

            <ng-container *ngIf="newNote.type == 'attendingVetForm'">
              <h6>
                <b>Certificate for Regulatory Veterinarians examination only</b>
              </h6>
            </ng-container>

            <ng-container *ngIf="newNote.type == 'preTravel_vet_form'">
              <div
                class="custom-list"
                [innerHTML]="
                  ('NOTES.forms.' + (newNote.type || selectedTab) + '.intro'
                    | translate) || ''
                "
              ></div>
            </ng-container>

            <!-- generateNoteFields -->
            <ng-container
              *ngIf="
                this.isUSCountry ||
                (this.isHKCountry && newNote.type != 'preTravel_vet_form')
              "
            >
              <div
                *ngFor="let field of generateNoteFields(newNote?.type)"
                class="box-control clearfix"
                [ngClass]="{ 'input--filled--model': newNote[field.name] }"
              >
                <div *ngIf="field.type === 'checkbox'">
                  <div
                    class="toggle__text__inline"
                    [innerHTML]="
                      field.label ||
                        'HORSES.' +
                          (newHorse.is_in_food_chain
                            ? 'inFoodChain'
                            : 'outFoodChain') | translate
                    "
                  ></div>
                  <div>
                    <input
                      type="checkbox"
                      id="{{ field.uuid }}"
                      name="{{ field.name }}"
                      [checked]="!!newNote[field.name]"
                      (change)="newNote[field.name] = !newNote[field.name]"
                    />
                    <div class="toggle">
                      <label
                        for="{{ field.uuid }}"
                        [ngClass]="{
                          danger: !!(this.serverErrorValidate || {})[field.id]
                        }"
                      >
                        <i>&nbsp;</i>
                      </label>
                    </div>
                    <span class="toggle__label">
                      <span *ngIf="!!newNote[field.name]">Yes</span>
                      <span *ngIf="!newNote[field.name]">No</span>
                    </span>
                  </div>
                </div>

                <div *ngIf="field.type !== 'checkbox'">
                  <ng-container *ngIf="field?.id == 'signature_for_statement'">
                    <div style="margin-bottom: 20px">
                      <h6>
                        <b>Signature for above statement</b>
                      </h6>
                    </div>
                  </ng-container>

                  <input
                    type="{{ field.type }}"
                    class="form-control input__field"
                    style="display: block"
                    name="{{ field?.name }}"
                    autocomplete="off"
                    spellcheck="false"
                    [(ngModel)]="newNote[field.name]"
                    value="{{ newNote[field.name] }}"
                  />

                  <label
                    class="input__label"
                    [ngClass]="{
                      input_label_filled: field?.id == 'signature_for_statement'
                    }"
                  >
                    <span *ngIf="field.label">{{
                      field.label || "" | translate
                    }}</span>
                  </label>
                  <ng-container *ngIf="field?.id != 'signature_for_statement'">
                    <label
                      class="input__label"
                      [ngClass]="{
                        'text-danger': !!(this.serverErrorValidate || {})[
                          field.id
                        ]
                      }"
                    >
                      <span>{{
                        field.label || "HORSES.weight" | translate
                      }}</span>
                    </label>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf="this.isHKCountry && newNote.type == 'preTravel_vet_form'"
            >
              <app-prevet-travel-fields
                [noteFields]="generateNoteFields(newNote?.type)"
                [newNote]="newNote"
                [noteForm]="updateNoteForm"
              >
              </app-prevet-travel-fields>
            </ng-container>

            <ng-container *ngIf="isAUSCountry">
              <ng-container
                *ngIf="newNote?.type == 'trainer_vertinary_history_form'"
              >
                <app-aus-verterinary-history-form-info
                  [noteFields]="generateNoteFields(newNote?.type)"
                  [newNote]="newNote"
                  [noteForm]="updateNoteForm"
                >
                </app-aus-verterinary-history-form-info>
              </ng-container>
              <ng-container
                *ngIf="
                  newNote?.type ==
                    'trainer_declaration_execution_submission_form' ||
                  newNote?.type ==
                    'veterinary_declaration_execution_submission_form'
                "
              >
                <app-aus-declaration-execution-submission
                  [noteFields]="generateNoteFields(newNote?.type)"
                  [newNote]="newNote"
                ></app-aus-declaration-execution-submission>
              </ng-container>
              <ng-container
                *ngIf="newNote?.type == 'veterinary_inspection_form'"
              >
                <app-aus-verterinary-inspection-form-info
                  [noteFields]="generateNoteFields(newNote?.type)"
                  [newNote]="newNote"
                  [yesLabel]="'Abnormalities Detected'"
                  [noLabel]="'No Abnormalities Detected'"
                  [noteForm]="updateNoteForm"
                >
                </app-aus-verterinary-inspection-form-info>
              </ng-container>
            </ng-container>

            <!-- file upload -->
            <ng-container *ngIf="!isAUSCountry">
              <div
                class="box-control"
                *ngIf="
                  newNote.type == 'other' || newNote.type == 'attendingVetForm'
                "
              >
                <div class="border-control with_description">
                  <image-upload
                    #imageLoader24
                    [max]="maxImgCount - (newNote?.srcs?.length || 0)"
                    [maxFileSize]="maxImgSize"
                    [buttonCaption]="'HORSES.dragOrSelectPhoto' | translate"
                    [extensions]="[
                      'jpeg',
                      'png',
                      'pdf',
                      'doc',
                      'docx',
                      'csv',
                      'xls',
                      'xlsx',
                      'txt',
                      'mp4',
                      '3gp',
                      '3gp2'
                    ]"
                    (onFileUploadFinish)="
                      setNoteImages(imageLoader24.files); debug(imageLoader24)
                    "
                    (onRemove)="debug(imageLoader24)"
                  >
                  </image-upload>

                  <div *ngIf="newNote?.srcs">
                    <ng-template
                      ngFor
                      let-omgImg
                      [ngForOf]="newNote?.srcs"
                      let-i="index"
                    >
                      <div class="image-container hr-inline-group">
                        <div
                          class="image image-block"
                          [ngStyle]="{
                            'background-image': 'url(' + omgImg?.iconsrc + ')'
                          }"
                        >
                          <div
                            class="x-mark"
                            (click)="newNote.srcs.splice(i, 1)"
                          >
                            <span class="close"></span>
                          </div>
                          <div
                            class="box-control remove_on_remove_img remove_on_remove_img--text"
                          >
                            {{ omgImg.description }}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
      <div class="modal-footer">
        <ng-container *ngIf="!isFormInViewMode">
          <ng-container
            *ngIf="
              this.newNote.type == 'trainer_declaration_form';
              else buttonTemplate
            "
          >
            <button
              class="btn btn-green btn-full"
              type="submit"
              [disabled]="
                updateNoteForm.submitted ||
                isRequestInProgress ||
                serverErrorValidate?.note_date
              "
            >
              Submit declaration
            </button>
          </ng-container>

          <ng-template #buttonTemplate>
            <button
              class="btn btn-green btn-full"
              type="submit"
              [disabled]="
                (updateNoteForm.submitted &&
                  (newNoteTitle?.errors ||
                    noteDescr?.errors ||
                    (this.newNote.type == 'weight' && !this.newNote.weight))) ||
                isRequestInProgress ||
                serverErrorValidate?.note_date
              "
            >
              {{
                (!tabs.displayAintreeTabs && !isHKCountry && !isAUSCountry
                  ? "HORSES.editNote"
                  : isHKCountry
                  ? "HORSES.editDeclaration"
                  : "HORSES.editForm"
                ) | translate
              }}
            </button>
          </ng-template>
        </ng-container>
      </div>
    </form>
  </div>
</div>

<div class="modal search-modal" id="modal-search" *ngIf="horse?.is_active">
  <div class="modal-wrapper no-footer">
    <div class="modal-header">
      <div class="left-part" (click)="search.value = ''; searchDrugs('')">
        <a
          class="btn close-modal open-modal show-mobile"
          [attr.href]="isAddTreatment ? '#add-treatment' : '#edit-treatment'"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="right-part" (click)="search.value = ''; searchDrugs('')">
        <a
          class="btn btn-close close-modal open-modal"
          [attr.href]="isAddTreatment ? '#add-treatment' : '#edit-treatment'"
        >
          <i class="icon icon-close dark icon-middle"></i>
        </a>
      </div>

      <form class="form">
        <div class="box-control-search">
          <input
            class="search-control"
            placeholder="{{ 'CORE.search' | translate }}"
            #search
            (input)="searchDrugs(search.value)"
          />
        </div>
      </form>
    </div>
    <div class="modal-body">
      <div class="search-result">
        <div class="padding-block">
          <ng-template
            ngFor
            let-drug
            [ngForOf]="drugs || appMemory.static?.drugs"
          >
            <div
              class="item-result close-modal open-modal"
              (click)="newTreatment.drug = drug; hideModal(true)"
              [attr.href]="
                isAddTreatment ? '#add-treatment' : '#edit-treatment'
              "
            >
              <div class="name">{{ drug?.name }}</div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="add-vaccination" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createVaccinationForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(createVaccinationForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.addVacs" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #createVaccinationForm="ngForm"
      novalidate
      (ngSubmit)="createVaccination(createVaccinationForm)"
    >
      <div class="modal-body" style="margin-bottom: 50px">
        <div class="padding-block">
          <div class="box-nav">
            <div class="label">
              <span
                *ngIf="
                  (!createVaccinationForm.submitted ||
                    (createVaccinationForm.submitted &&
                      (!!newVaccination.influenza ||
                        !!newVaccination.herpes ||
                        !!newVaccination.other))) &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.vaccinationName" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createVaccinationForm.submitted &&
                  !newVaccination.influenza &&
                  !newVaccination.herpes &&
                  !newVaccination.other &&
                  invalidVaccinationForm
                "
                >Vaccination Name {{ "CORE.isRequired" | translate }}</span
              >
            </div>

            <ul class="nav-type line">
              <li
                class="change-active"
                [ngClass]="{ active: newVaccination.influenza }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.influenza = true;
                    newVaccination.herpes = false;
                    newVaccination.other = false;
                    newVaccination.name = 'Equine Influenza';
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted &&
                      !newVaccination.influenza &&
                      !newVaccination.herpes &&
                      !newVaccination.other &&
                      invalidVaccinationForm
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "HORSES.equineInfluenza" | translate }}
                  </div>
                </a>
              </li>
              <li
                class="change-active"
                [ngClass]="{ active: newVaccination.herpes }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.herpes = true;
                    newVaccination.influenza = false;
                    newVaccination.other = false;
                    newVaccination.name = 'Equine Herpes';
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted &&
                      !newVaccination.influenza &&
                      !newVaccination.herpes &&
                      !newVaccination.other &&
                      invalidVaccinationForm
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "HORSES.equineHerpes" | translate }}
                  </div>
                </a>
              </li>
              <li
                class="change-active"
                [ngClass]="{ active: newVaccination.other }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.other = true;
                    newVaccination.herpes = false;
                    newVaccination.influenza = false;
                    newVaccination.name = '';
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted &&
                      !newVaccination.influenza &&
                      !newVaccination.herpes &&
                      !newVaccination.other &&
                      invalidVaccinationForm
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "HORSES.other" | translate }}
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div
            class="box-control"
            id="vaccination-title-box"
            [ngClass]="{ 'input--filled--model': newVaccination?.name }"
            *ngIf="newVaccination.other"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createVaccinationForm.submitted &&
                  (newVaccinationName.errors?.required ||
                    newVaccinationName.errors?.pattern) &&
                  invalidVaccinationForm
              }"
              name="other-vacc"
              #newVaccinationName="ngModel"
              [(ngModel)]="newVaccination.name"
              (ngModelChange)="invalidVaccinationForm = false"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createVaccinationForm.submitted ||
                  (createVaccinationForm.submitted &&
                    newVaccinationName.valid &&
                    invalidVaccinationForm)
                "
                >{{ "HORSES.otherVacs" | translate }}</span
              >
              <span
                *ngIf="
                  createVaccinationForm.submitted &&
                  newVaccinationName.errors?.required &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.otherVacs" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  createVaccinationForm.submitted &&
                  newVaccinationName.errors?.pattern &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.otherVacs" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
            </label>
          </div>

          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (((createVaccinationForm.submitted && !newVaccination.date) ||
                    serverErrorValidate?.vaccination_date) &&
                    invalidVaccinationForm) ||
                  dateRangeErrorMessage.from
              }"
              style="user-select: none"
            >
              <label class="label">
                <span>{{ "HORSES.dateOfAdmin" | translate }}</span>
              </label>
              <div class="code relative">
                <input
                  type="text"
                  placeholder="__/__/____"
                  class="form-control input__field"
                  [(ngModel)]="datePicker3.currentDate_input"
                  (ngModelChange)="
                    invalidVaccinationForm = false;
                    setVaccinationDateInput();
                    checknewVaccinationValidity()
                  "
                  autocomplete="off"
                  name="vaccination_date"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker3.isActive = !datePicker3.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span class="text-danger" *ngIf="dateRangeErrorMessage.from"
                >{{ dateRangeErrorMessage.from | translate
                }}{{ dateRangeErrorMessage.date }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createVaccinationForm.submitted &&
                  !newVaccination.date &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.dateOfAdmin" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  !dateRangeErrorMessage.from &&
                  serverErrorValidate?.vaccination_date &&
                  !invalidVaccinationForm
                "
                >{{ serverErrorValidate?.vaccination_date | translate }}</span
              >
            </a>
            <div class="box-control" *ngIf="datePicker3.isActive">
              <datepicker
                class="datepicker"
                name="vaccination-datepicker"
                [startingDay]="1"
                [onlyCurrentMonth]="true"
                [minDate]="datePicker3.minDate"
                [maxDate]="datePicker3.maxDate"
                [showWeeks]="false"
                [initDate]="datePicker3.currentDate"
                (selectionDone)="
                  setVaccinationDate($event);
                  datePicker3.isActive = !datePicker3.isActive
                "
              >
              </datepicker>
            </div>
          </div>

          <div
            *ngIf="newVaccination.other || newVaccination.number === 'booster'"
          >
            <div class="box-control input--filled">
              <a
                class="btn btn-dotted btn-code btn-full"
                [ngClass]="{
                  danger:
                    (((createVaccinationForm.submitted &&
                      (newVaccination.other ||
                        (!newVaccination.other &&
                          newVaccination.number !== 'booster')) &&
                      !newVaccination.immunization) ||
                      serverErrorValidate?.immunization) &&
                      invalidVaccinationForm) ||
                    dateRangeErrorMessage.due
                }"
                style="user-select: none"
              >
                <label class="label">
                  <span
                    *ngIf="
                      !createVaccinationForm.submitted ||
                      (createVaccinationForm.submitted &&
                        !!newVaccination.immunization) ||
                      (newVaccination.number === 'booster' &&
                        !newVaccination.other)
                    "
                    >{{ "HORSES.dateofNextAdmin" | translate }}</span
                  >
                </label>
                <div class="code relative">
                  <input
                    type="text"
                    placeholder="__/__/____"
                    class="form-control input__field"
                    [(ngModel)]="datePicker5.currentDate_input"
                    autocomplete="off"
                    name="immunization"
                    (ngModelChange)="
                      invalidVaccinationForm = false;
                      setNextVaccinationDateInput();
                      checknewVaccinationValidity()
                    "
                  />
                  <i
                    class="fa fa-calendar calendar"
                    (click)="datePicker5.isActive = !datePicker5.isActive"
                    aria-hidden="true"
                  ></i>
                </div>
                <span class="text-danger" *ngIf="dateRangeErrorMessage.due"
                  >{{ dateRangeErrorMessage.due | translate
                  }}{{ dateRangeErrorMessage.date }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    createVaccinationForm.submitted &&
                    (newVaccination.other ||
                      (!newVaccination.other &&
                        newVaccination.number !== 'booster')) &&
                    !newVaccination.immunization
                  "
                  >{{ "HORSES.dateofNextAdmin" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    !dateRangeErrorMessage.due &&
                    serverErrorValidate?.immunization &&
                    invalidVaccinationForm
                  "
                  >{{ serverErrorValidate?.immunization | translate }}</span
                >
              </a>
              <div class="box-control" *ngIf="datePicker5.isActive">
                <datepicker
                  class="datepicker"
                  name="vaccination-next-datepicker"
                  [startingDay]="1"
                  [onlyCurrentMonth]="true"
                  [minDate]="datePicker5.minDate"
                  [maxDate]="datePicker5.maxDate"
                  [showWeeks]="false"
                  [initDate]="datePicker5.currentDate"
                  [dateDisabled]="dateDisabled"
                  (selectionDone)="
                    setNextVaccinationDate($event);
                    datePicker5.isActive = !datePicker5.isActive
                  "
                >
                </datepicker>
              </div>
            </div>
          </div>

          <div class="box-nav">
            <div class="label">
              <span
                *ngIf="
                  (!createVaccinationForm.submitted ||
                    (createVaccinationForm.submitted &&
                      !!newVaccination.number)) &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.vaccinationNumber" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  createVaccinationForm.submitted &&
                  !newVaccination.number &&
                  invalidVaccinationForm
                "
                >{{ "HORSES.vaccinationNumber" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </div>
            <ul class="nav-type line">
              <li
                class="change-active"
                *ngFor="let vaccinationNumber of vaccinationNumbers"
                [ngClass]="{
                  active: vaccinationNumber == newVaccination.number
                }"
              >
                <a
                  class="btn"
                  (click)="
                    newVaccination.number = vaccinationNumber;
                    invalidVaccinationForm = false
                  "
                  [ngClass]="{
                    danger:
                      createVaccinationForm.submitted && !newVaccination.number
                  }"
                >
                  <div class="name" style="text-transform: capitalize">
                    {{ "CORE." + vaccinationNumber | translate }}
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <ng-container *ngIf="!this.isUSCountry">
            <div class="title-vaccination">
              {{ "HORSES.vaccinationApplicable" | translate }}
            </div>
            <div>
              <label class="container">
                <span
                  *ngFor="let country of vaccinationCountries"
                  class="comma"
                >
                  {{ "ISO." + country | translate }}
                </span>
                <input type="checkbox" [checked]="true" disabled />
                <span class="checkmark"></span>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            createVaccinationForm.submitted &&
            (createVaccinationForm.errors ||
              newVaccinationName?.errors ||
              (!newVaccination.influenza &&
                !newVaccination.herpes &&
                !newVaccination.other) ||
              !newVaccination.date ||
              (!this.newVaccination.other && !this.newVaccination.number) ||
              (newVaccination.other &&
                (!newVaccination.immunization || !newVaccination.name) &&
                newVaccination.number !== 'booster') ||
              isRequestInProgress) &&
            invalidVaccinationForm
          "
        >
          {{ "HORSES.addVacs" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="remove-vaccination" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(declineForm, true)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(declineForm, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "CORE.delete" | translate }} vaccination</div>
    </div>
    <form
      class="form modal-form"
      #removeVaccinationForm="ngForm"
      novalidate
      (ngSubmit)="removeVaccination(declineForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="text-center"
            style="font-size: 21px; margin: 45px 0 60px 0"
          >
            {{ "CORE.areYouSureToDelete" | translate }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row-half">
          <button class="btn btn-red btn-full" type="submit">
            {{ "CORE.delete" | translate }}
          </button>
        </div>
        <div class="row-half">
          <button class="btn btn-green btn-full close-modal" type="button">
            {{ "CORE.cancel" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="archive-horse" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(archiveHorseForm, true)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(declineForm, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "HORSES.archiveHorse" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #archiveHorseForm="ngForm"
      novalidate
      (ngSubmit)="archiveHorse(archiveHorseForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="text-center"
            style="font-size: 21px; margin: 45px 0 60px 0"
          >
            Horse will be moved to archive and will be unavailable for editing.
            Are you sure?
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row-half">
          <button class="btn btn-red btn-full" type="submit">
            {{ "HORSES.archive" | translate }}
          </button>
        </div>
        <div class="row-half">
          <button class="btn btn-green btn-full close-modal" type="button">
            {{ "CORE.cancel" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="remove-treatment" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(deleteTreatmentForm, true)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(deleteTreatmentForm, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.deleteTreat" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #deleteTreatmentForm="ngForm"
      novalidate
      (ngSubmit)="deleteTreatment(deleteTreatmentForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="text-center"
            style="font-size: 21px; margin: 45px 0 60px 0"
          >
            {{ "CORE.areYouSureToDeleteTreat" | translate }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row-half">
          <button class="btn btn-red btn-full" type="submit">
            {{ "CORE.delete" | translate }}
          </button>
        </div>
        <div class="row-half">
          <button class="btn btn-green btn-full close-modal" type="button">
            {{ "CORE.cancel" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="remove-note" *ngIf="horse?.is_active">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(newNote, true)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(newNote, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "CORE.delete" | translate }} Note</div>
    </div>
    <form
      class="form modal-form"
      #deleteNoteForm="ngForm"
      novalidate
      (ngSubmit)="deleteNote()"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="text-center"
            style="font-size: 21px; margin: 45px 0 60px 0"
          >
            {{ "CORE.areYouSureToDeleteNote" | translate }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row-half">
          <button class="btn btn-red btn-full" type="submit">
            {{ "CORE.delete" | translate }}
          </button>
        </div>
        <div class="row-half">
          <button class="btn btn-green btn-full close-modal" type="button">
            {{ "CORE.cancel" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container" *ngIf="horse" style="width: 100%">
  <div class="hide-mobile">
    <div class="about-horse">
      <div class="name-page">
        <div class="nav-breadcrumbs">
          <a class="link link-default" [routerLink]="['/horses']">{{
            "HORSES.horses" | translate
          }}</a>
          /
        </div>
        <span class="name">{{ horse?.name }}</span>
        <div
          class="status blue"
          [ngStyle]="{
            color: '#' + appMemory.gendersList[horse?.gender_id].color
          }"
          *ngIf="appMemory?.static"
        >
          {{
            "HORSES." +
              (appMemory.gendersList[horse?.gender_id].name | lowercase)
              | translate
              | uppercase
          }}
          <!-- {{}} -->
        </div>
        <div
          class="status grey"
          style="margin-left: 0"
          *ngIf="!horse?.is_active"
        >
          {{ "HORSES.archive" | translate }}
        </div>
        <ng-container *ngIf="!isAUSCountry">
          <a
            class="btn btn-border btn-middle open-modal"
            *ngIf="
              horse?.is_active &&
              (appMemory.user?.roles[0]?.id == 1 ||
                appMemory.user?.roles[0]?.id == 2)
            "
            href="#edit-horse"
            >{{ "CORE.edit" | translate }}</a
          >
        </ng-container>
        <a
          class="btn btn-border btn-middle open-modal"
          *ngIf="horse?.is_active"
          (click)="
            isAddTreatment = true;
            serverErrorValidate = null;
            setDeaultAdministerValue()
          "
          href="#add-treatment"
          [ngClass]="{ hidden: !tabs.isTreatments }"
        >
          <i class="icon icon-left icon-plus icon-middle"></i
          >{{ "HORSES.addTreatment" | translate }}</a
        >

        <ng-container
          *ngIf="newNote.type == 'video-submission'; else noteButton"
        >
          <a
            class="btn btn-border btn-middle open-modal"
            *ngIf="horse?.is_active"
            href="#add-video"
            [ngClass]="{ hidden: !tabs.isNotes }"
          >
            <i class="fa fa-video-camera"></i>
            Add Video
          </a>
        </ng-container>
        <ng-template #noteButton>
          <a
            class="btn btn-border btn-middle open-modal"
            *ngIf="horse?.is_active"
            (click)="addNodeSetDefaultDate()"
            href="#add-note"
            [ngClass]="{ hidden: !tabs.isNotes }"
          >
            <i class="icon icon-left icon-note icon-middle"></i>
            {{ "HORSES.addForm" | translate }}
          </a>
        </ng-template>

        <span
          class="vaccination-container"
          [ngClass]="{ hidden: !tabs.isVaccinations }"
        >
          <app-tooltip
            class="addVaccinationTutorial"
            #addVaccinationTutorial
            [position]="{
              right: screenWidth < 1084 ? '200px' : '-30px',
              top: screenWidth < 1084 ? '55px' : '5px'
            }"
            *ngIf="getTooltip()"
            (click)="showTooltip('add-vaccination', '.addVaccinationTutorial')"
          >
          </app-tooltip>
          <a
            class="btn btn-border btn-middle open-modal"
            *ngIf="horse?.is_active"
            href="#add-vaccination"
            [ngClass]="{ hidden: !tabs.isVaccinations }"
          >
            <i class="icon icon-left icon-syringe icon-middle"></i>
            {{ "HORSES.addVacs" | translate }}</a
          >
        </span>
      </div>
      <div class="box-desc">
        <div class="date">
          <span class="label">{{ "CORE.dateOfBirth" | translate }}:</span
          >{{ horse?.birth | dateObj | date : "dd MMM y" }}
          <span
            *ngIf="horse?.microchip_number"
            style="margin-left: 20px; margin-bottom: 20px"
          >
            <span class="label"
              >{{ "HORSES.microchipNumber" | translate }}:</span
            >{{ pad(horse?.microchip_number, 5) }}</span
          >

          <ng-container *ngIf="!isAUSCountry">
            <span
              class="label"
              style="margin-left: 20px; margin-bottom: 20px"
              *ngIf="showInTheFoodChainToggle"
              >{{
                "HORSES." +
                  (horse?.is_in_food_chain ? "inFoodChain" : "outFoodChain")
                  | translate
              }}</span
            >
          </ng-container>
          <span
            class="label"
            style="margin-left: 20px; margin-bottom: 20px"
            *ngIf="horse?.microchip"
          >
            <a
              href="javascript: void(0);"
              (click)="getPresignedUrl(horse?.microchip)"
              >{{ "HORSES.showPassport" | translate }}</a
            >
          </span>
          <ng-container *ngIf="!isAUSCountry">
            <span
              class="label"
              style="margin-left: 20px; margin-bottom: 20px"
              *ngIf="appMemory.user?.roles[0]?.id == 1"
            >
              <a
                class="open-modal"
                href="#archive-horse"
                *ngIf="horse?.is_active"
                >{{ "HORSES.moveToArchive" | translate }}</a
              >
            </span>
          </ng-container>
        </div>
        <div
          class="trainer"
          *ngIf="appMemory.user?.roles[0]?.id != 1"
          style="margin-bottom: 15px"
        >
          <span class="label">{{ "CORE.trainer" | translate }}:</span
          >{{ horse?.instructor?.name + " " + horse?.instructor?.last_name }}
        </div>
        <div class="text">{{ horse?.description }}</div>
        <!-- <ng-template ngFor let-key [ngForOf]="objectKeys(treatmentsByDate)">
          {{key}}
            <ng-template ngFor let-treatment [ngForOf]="treatmentsByDate[key]">
                <table>
                  <tr>
                      <td>{{treatment | json}}</td>
                  </tr>
                </table>
            </ng-template>
        </ng-template> -->
      </div>
    </div>
  </div>

  <tabset class="nav-tabs nav-left">
    <ng-container
      *ngIf="!tabs.displayAintreeTabs && !isHKCountry && !isAUSCountry"
    >
      <tab
        heading="{{ 'TREATS.treatments' | translate }}"
        [active]="tabs.isTreatments"
        (select)="tabs.isTreatments = true"
        (deselect)="tabs.isTreatments = false"
      ></tab>
      <ng-container *ngIf="horse?.farm?.region != 'BC2023'">
        <tab
          heading="{{ 'HORSES.vacs' | translate }}"
          [active]="tabs.isVaccinations"
          (select)="tabs.isVaccinations = true"
          (deselect)="tabs.isVaccinations = false"
        ></tab>
      </ng-container>
      <tab
        heading="{{ 'HORSES.notes' | translate }}"
        [active]="tabs.isNotes"
        (select)="tabs.isNotes = true"
        (deselect)="tabs.isNotes = false"
        *ngIf="
          !!hasSignNoteType ||
          (appMemory.user?.roles[0]?.id == 3 &&
            isUSCountry &&
            horse?.farm?.region == 'BC2023') ||
          appMemory.user?.roles[0]?.id != 3
        "
      ></tab>
    </ng-container>

    <ng-container *ngIf="tabs.displayAintreeTabs">
      <tab
        *ngFor="let tab of aintreeTabs"
        heading="{{ tab.title || tab.name }}"
        [active]="selectedTab === tab.type"
        (select)="
          setTab(
            tab,
            [
              'vet_form_stage1',
              'vet_form_stage2',
              'trainer_declaration_form'
            ].includes(tab.type)
              ? 'note'
              : 'treatment'
          )
        "
      ></tab>
      <tab
        *ngFor="let tab of extraAintreeTabs"
        heading="{{ tab.title || tab.name }}"
        [active]="selectedTab === tab.type"
        (select)="setTab(tab, tab.type)"
      ></tab>
    </ng-container>

    <ng-container *ngIf="isHKCountry">
      <tab
        *ngFor="let tab of hongkongTabs"
        heading="{{ tab.title || tab.name }}"
        [active]="selectedTab === tab.type"
        (select)="
          setTab(
            tab,
            ['md_f1a', 'md_f1b', 'md_f2', 'md_f3'].includes(tab.type)
              ? 'treatment'
              : 'note'
          );
          setHKNoteTypes(tab)
        "
      ></tab>
    </ng-container>

    <ng-container *ngIf="isAUSCountry">
      <tab
        *ngFor="let tab of ausTabs"
        heading="{{ tab.title || tab.name }}"
        [active]="selectedTab === tab.type"
        (select)="
          setTab(
            tab,
            [
              'trainer_potential_long_acting',
              'trainer_routine',
              'trainer_routine_procedure_form'
            ].includes(tab.type)
              ? 'treatment'
              : 'note'
          )
        "
      ></tab>
    </ng-container>

    <tab
      heading="{{ 'HORSES.horses' | translate }}"
      customClass="show-mobile"
      [active]="tabs.isHorse"
      (select)="tabs.isHorse = true"
      (deselect)="tabs.isHorse = false"
    ></tab>
  </tabset>

  <div class="padding-block" [ngClass]="{ hidden: !tabs.isTreatments }">
    <app-loader *ngIf="isRequestInProgress2"></app-loader>
    <div
      class="no-data"
      *ngIf="
        !isRequestInProgress2 &&
        treatmentsByDate?.length == 0 &&
        treatmentsByDateArchive?.length == 0
      "
    >
      {{ "TREATS.noTreats" | translate }}
    </div>
    <div
      class="table-treatment"
      *ngIf="
        !isRequestInProgress2 &&
        (treatmentsByDate?.length > 0 || treatmentsByDateArchive?.length > 0)
      "
      style="margin-top: 0"
    >
      <div class="table-header hide-mobile sorting-block">
        <div class="box-table">
          <div class="item-cell cell-time" style="width: 98px">
            <a
              href="javascript:;"
              [ngClass]="{
                asc:
                  sortTreatmentObj.field == 'treatmentDate' &&
                  sortTreatmentObj.order == 'asc',
                desc:
                  sortTreatmentObj.field == 'treatmentDate' &&
                  sortTreatmentObj.order == 'desc',
                sorting: sortTreatmentObj.field == 'treatmentDate'
              }"
              (click)="sortTreatmentBy('treatmentDate', 'treatmentDate')"
              >{{ "HORSES.startDate" | translate }}</a
            >
          </div>
          <div class="item-cell cell-medium">
            {{ "HORSES.endDate" | translate }}
          </div>
          <ng-container *ngIf="!isAUSCountry">
            <div class="item-cell cell-medium">
              {{ "CORE.status" | translate }}
            </div>
          </ng-container>

          <div class="item-cell cell-treatment">
            <a
              href="javascript:;"
              [ngClass]="{
                asc:
                  sortTreatmentObj.field == 'treatmentDrug' &&
                  sortTreatmentObj.order == 'asc',
                desc:
                  sortTreatmentObj.field == 'treatmentDrug' &&
                  sortTreatmentObj.order == 'desc',
                sorting: sortTreatmentObj.field == 'treatmentDrug'
              }"
              (click)="sortTreatmentBy('treatmentDrug', 'treatmentDrug')"
              >{{ "TREATS.treatment" | translate }}</a
            >
          </div>
          <div class="item-cell cell-person text-center">
            {{ "HORSES.person" | translate }}
          </div>
          <div class="item-cell cell-administer text-center">
            {{ "HORSES.administer_cell" | translate }}
          </div>
          <ng-container *ngIf="!tabs.displayAintreeTabs && !isAUSCountry">
            <div class="item-cell cell-duration text-center">
              {{ "HORSES.withdrawal" | translate }}
            </div>
          </ng-container>
          <ng-container *ngIf="!isAUSCountry">
            <div class="item-cell cell-action" *ngIf="horse?.is_active">
              {{ "HORSES.action" | translate }}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="table-body">
        <ng-template ngFor let-key [ngForOf]="treatmentsByDate">
          <div class="item-treatment">
            <div class="item-cell cell-date show-mobile">
              <div class="date">
                <div class="day">{{ key?.date | date : "dd" }}</div>
                {{ key?.date | date : "MMM y" }}
              </div>
            </div>
            <div class="item-cell cell-event">
              <ng-template ngFor let-treatment [ngForOf]="key.data">
                <div class="line-even">
                  <div
                    class="item-cell cell-time hide-mobile"
                    style="width: 98px"
                  >
                    {{
                      treatment?.start_date || treatment?.withdrawal
                        | dateObj
                        | date : "dd MMM y"
                    }}
                  </div>
                  <div class="item-cell cell-medium hide-mobile">
                    {{ treatment?.withdrawal | date : "dd MMM y" }}
                  </div>
                  <ng-container *ngIf="!isAUSCountry">
                    <div class="item-cell cell-medium hide-mobile">
                      <div
                        class="status"
                        [ngClass]="{
                          green:
                            treatment?.request?.status == 'approved' ||
                            !treatment?.request,
                          yellow: treatment?.request?.status == 'pending',
                          red:
                            treatment?.request?.status == 'cancelled' ||
                            treatment?.request?.status == 'canceled'
                        }"
                      >
                        {{
                          "HORSES." +
                            (appMemory?.translates[
                              treatment?.request?.status
                            ] || "approved") | translate
                        }}
                      </div>
                    </div>
                  </ng-container>
                  <div class="item-cell cell-treatment">
                    <a
                      class="open-modal"
                      href="#treatment"
                      (click)="setCurrentTreatment(treatment)"
                    >
                      <div class="name">
                        <ng-container
                          *ngIf="
                            treatment.type == 'trainer_routine_procedure_form';
                            else otherTreatmentType
                          "
                        >
                          {{ treatment?.procedure_type }}
                        </ng-container>
                        <ng-template #otherTreatmentType>
                          {{
                            treatment?.prescriptions[0]?.drugs[0]?.name ||
                              treatment?.drug_name
                          }}
                        </ng-template>
                        <div
                          class="status show-mobile"
                          [ngClass]="{
                            green:
                              treatment?.request?.status == 'approved' ||
                              !treatment?.request,
                            yellow: treatment?.request?.status == 'pending',
                            red:
                              treatment?.request?.status == 'cancelled' ||
                              treatment?.request?.status == 'canceled'
                          }"
                        >
                          {{
                            "HORSES." +
                              (appMemory?.translates[
                                treatment?.request?.status
                              ] || "approved") | translate
                          }}
                        </div>
                      </div>
                      <div class="text">
                        {{ "HORSES.reason" | translate }}:
                        {{ treatment?.reason }}
                      </div>
                    </a>

                    <div
                      class="file hide-mobile"
                      *ngIf="treatment?.prescriptions[0]?.images?.length > 0"
                    >
                      <i class="icon icon-file icon-left icon-middle"></i
                      >{{ treatment?.prescriptions[0]?.images.length }}
                    </div>
                  </div>
                  <div class="item-cell cell-person text-center hide-mobile">
                    {{
                      treatment?.request
                        ? treatment?.request?.user?.name +
                          " " +
                          treatment?.request?.user?.last_name
                        : appMemory.user?.roles[0]?.id == 1
                        ? appMemory.user?.name + " " + appMemory.user?.last_name
                        : treatment?.horse?.instructor?.name +
                          " " +
                          treatment?.horse?.instructor?.last_name
                    }}
                    <i
                      class="icon icon-right"
                      [ngClass]="{
                        'icon-trainer': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 1
                          : true,
                        'icon-assistant': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 2
                          : false,
                        'icon-vet': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 3
                          : false
                      }"
                    >
                    </i>
                  </div>
                  <div
                    class="item-cell cell-administer text-center hide-mobile"
                  >
                    <span>{{
                      treatment?.administer?.contact_name || " "
                    }}</span>
                  </div>
                  <ng-container
                    *ngIf="!tabs.displayAintreeTabs && !isAUSCountry"
                  >
                    <div
                      class="item-cell cell-duration text-center hide-mobile"
                    >
                      <span *ngIf="treatment?.prescriptions[0]?.duration">
                        {{ treatment?.prescriptions[0]?.duration }}
                        {{
                          "HORSES." +
                            (treatment?.prescriptions[0]?.duration > 1
                              ? "days"
                              : "day") | translate
                        }}
                      </span>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!isAUSCountry">
                    <div
                      class="item-cell cell-action hide-mobile"
                      *ngIf="horse?.is_active && !horse?.is_eligible"
                    >
                      &nbsp;
                      <a
                        class="btn btn-circle approve"
                        *ngIf="
                          appMemory.user?.roles[0]?.id === 1 &&
                          treatment?.request &&
                          treatment?.request?.status == 'pending'
                        "
                        (click)="
                          treatmentStatus.request_id = treatment?.request?.id;
                          treatmentStatus.status = 'approved';
                          proceessTreatment()
                        "
                      >
                        <i class="icon icon-check icon-middle"></i>
                      </a>
                      <a
                        class="btn btn-circle remove open-modal"
                        (click)="
                          treatmentStatus.request_id = treatment?.request?.id
                        "
                        href="#treatment-decline"
                        *ngIf="
                          appMemory.user?.roles[0]?.id === 1 &&
                          treatment?.request &&
                          treatment?.request?.status == 'pending'
                        "
                      >
                        <i class="icon icon-close icon-middle"></i>
                      </a>
                      <a
                        class="btn btn-circle edit open-modal"
                        href="#edit-treatment"
                        (click)="cloneTreatment(treatment)"
                        *ngIf="isTreatmentEditable(treatment)"
                      >
                        <i class="icon icon-edit dark icon-middle"></i>
                      </a>
                      <a
                        class="btn btn-circle archive"
                        (click)="
                          changeTreatmentStatus(treatment?.id, {
                            status: 'closed'
                          })
                        "
                        *ngIf="
                          treatment?.horse?.is_active &&
                          appMemory.user?.roles[0]?.id === 1 &&
                          treatment?.request?.status !== 'pending'
                        "
                      >
                        <i class="icon icon-ban icon-middle"></i>
                      </a>
                      <ng-container *ngIf="!tabs.displayAintreeTabs">
                        <a
                          class="btn btn-circle copy open-modal"
                          href="#add-treatment"
                          (click)="
                            cloneTreatment(treatment, true);
                            isAddTreatment = true
                          "
                          *ngIf="treatment?.horse?.is_active"
                        >
                          <i class="icon icon-copy icon-middle"></i>
                        </a>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>

        <ng-template ngFor let-key [ngForOf]="treatmentsByDateArchive">
          <div class="item-treatment">
            <div class="item-cell cell-date show-mobile">
              <div class="date">
                <div class="day">{{ key?.date | date : "dd" }}</div>
                {{ key?.date | date : "MMM y" }}
              </div>
            </div>
            <div class="item-cell cell-event">
              <ng-template ngFor let-treatment [ngForOf]="key.data">
                <div class="line-even">
                  <div
                    class="item-cell cell-time hide-mobile"
                    style="width: 98px"
                  >
                    {{
                      treatment?.start_date || treatment?.withdrawal
                        | dateObj
                        | date : "dd MMM y"
                    }}
                  </div>
                  <div class="item-cell cell-medium hide-mobile">
                    {{ treatment?.withdrawal | date : "dd MMM y" }}
                  </div>
                  <div class="item-cell cell-medium hide-mobile">
                    <div class="status grey">
                      {{ "TREATS.closed" | translate }}
                    </div>
                  </div>
                  <div class="item-cell cell-treatment">
                    <a
                      class="open-modal"
                      href="#treatment"
                      (click)="setCurrentTreatment(treatment)"
                    >
                      <div class="name">
                        {{
                          treatment?.prescriptions[0]?.drugs[0]?.name ||
                            treatment?.drug_name
                        }}
                        <div class="status show-mobile grey">
                          {{ "TREATS.closed" | translate }}
                        </div>
                      </div>
                      <div class="text">
                        {{ "HORSES.reason" | translate }}:
                        {{ treatment?.reason }}
                      </div>
                    </a>

                    <div
                      class="file hide-mobile"
                      *ngIf="treatment?.prescriptions[0]?.images?.length > 0"
                    >
                      <i class="icon icon-file icon-left icon-middle"></i
                      >{{ treatment?.prescriptions[0]?.images.length }}
                    </div>
                  </div>
                  <div class="item-cell cell-person text-center hide-mobile">
                    {{
                      treatment?.request
                        ? treatment?.request?.user?.name +
                          " " +
                          treatment?.request?.user?.last_name
                        : appMemory.user?.roles[0]?.id == 1
                        ? appMemory.user?.name + " " + appMemory.user?.last_name
                        : treatment?.horse?.instructor?.name +
                          " " +
                          treatment?.horse?.instructor?.last_name
                    }}
                    <i
                      class="icon icon-right"
                      [ngClass]="{
                        'icon-trainer': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 1
                          : true,
                        'icon-assistant': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 2
                          : false,
                        'icon-vet': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 3
                          : false
                      }"
                    >
                    </i>
                  </div>
                  <div
                    class="item-cell cell-administer text-center hide-mobile"
                  >
                    <span>{{
                      treatment?.administer
                        ? treatment?.administer?.contact_name
                        : "-"
                    }}</span>
                  </div>
                  <ng-container *ngIf="!tabs.displayAintreeTabs">
                    <div
                      class="item-cell cell-duration text-center hide-mobile"
                    >
                      <span *ngIf="treatment?.prescriptions[0]?.duration">
                        {{ treatment?.prescriptions[0]?.duration }}
                        {{
                          "HORSES." +
                            (treatment?.prescriptions[0]?.duration > 1
                              ? "days"
                              : "day") | translate
                        }}
                      </span>
                    </div>
                  </ng-container>

                  <div
                    class="item-cell cell-action hide-mobile"
                    *ngIf="horse?.is_active"
                  >
                    &nbsp;
                    <ng-container
                      *ngIf="!tabs.displayAintreeTabs && !isAUSCountry"
                    >
                      <a
                        class="btn btn-circle copy open-modal"
                        href="#add-treatment"
                        (click)="
                          cloneTreatment(treatment, true); isAddTreatment = true
                        "
                        *ngIf="treatment?.horse?.is_active"
                      >
                        <i class="icon icon-copy icon-middle"></i>
                      </a>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>

      <div class="text-center">
        <app-loader *ngIf="paginationTr.inProgress"></app-loader>
        <button
          class="btn lazy-btn"
          (click)="addNewData('')"
          *ngIf="!paginationTr.inProgress && !!paginationTr.nextPage"
        >
          {{ "CORE.showMore" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="box-notes-horse" [ngClass]="{ hidden: !tabs.isNotes }">
    <div class="table-treatment" *ngIf="notes?.length > 0">
      <div class="table-header hide-mobile">
        <div class="box-table">
          <div class="sorting-block hide-mobile">
            <div class="item-cell cell-treatment">
              <a
                href="javascript:;"
                [ngClass]="{
                  asc:
                    sortNoteObj.field == 'noteDate' &&
                    sortNoteObj.order == 'asc',
                  desc:
                    sortNoteObj.field == 'noteDate' &&
                    sortNoteObj.order == 'desc',
                  sorting: sortNoteObj.field == 'noteDate'
                }"
                (click)="sortNoteBy('noteDate', 'noteDate')"
                class="sorting"
                >{{ "HORSES.creationDate" | translate }}</a
              >
            </div>
            <ng-container>
              <div class="item-cell cell-status">
                {{ "HORSES.noteType" | translate }}
              </div>
            </ng-container>

            <div class="item-cell cell-treatment">
              <ng-container *ngIf="!tabs.displayAintreeTabs && !isAUSCountry">
                <a
                  href="javascript:;"
                  [ngClass]="{
                    asc:
                      sortNoteObj.field == 'noteTitle' &&
                      sortNoteObj.order == 'asc',
                    desc:
                      sortNoteObj.field == 'noteTitle' &&
                      sortNoteObj.order == 'desc',
                    sorting: sortNoteObj.field == 'noteTitle'
                  }"
                  (click)="sortNoteBy('noteTitle', 'noteTitle')"
                  >{{ "CORE.title" | translate }}</a
                >
              </ng-container>
            </div>

            <div class="item-cell cell-treatment text-center">
              <ng-container *ngIf="!tabs.displayAintreeTabs && !isAUSCountry">
                {{ "HORSES.description" | translate }}
              </ng-container>
            </div>
            <ng-container *ngIf="!isAUSCountry">
              <div class="item-cell cell-treatment text-right">
                {{ "HORSES.action" | translate }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="table-body">
        <div
          class="item-note item-treatment cell-event"
          *ngFor="let note of notes"
          [ngStyle]="{
            'max-width': tabs.displayAintreeTabs ? '680px' : 'auto'
          }"
        >
          <div class="box-table">
            <div
              class="item-cell cell-treatment hide-mobile open-modal"
              href="#note"
              style="cursor: pointer"
              (click)="currentNote = normalizeNote(note); setNote(note)"
            >
              <div class="person">
                {{ note?.user?.name + " " + note?.user?.last_name }}
                <i
                  class="icon icon-right icon-middle"
                  [ngClass]="{
                    'icon-trainer': note?.user?.roles[0]?.id == 1,
                    'icon-assistant': note?.user?.roles[0]?.id == 2,
                    'icon-vet': note?.user?.roles[0]?.id == 3
                  }"
                >
                </i>
              </div>
              <div>
                {{ "HORSES.noteDate" | translate }}:
                <span class="bold">{{
                  note?.date
                    ? (note?.date | dateObj | date : "dd MMM y")
                    : note?.created_at
                    ? (note?.created_at | dateObj | date : "dd MMM y")
                    : ""
                }}</span>
              </div>
            </div>
            <ng-container
              *ngIf="tabs.displayAintreeTabs; else noramlNoteTypeListing"
            >
              <ng-container *ngFor="let nt of aintreeNoteTypes">
                <div
                  *ngIf="nt.type === note.type"
                  class="item-cell cell-status hide-mobile open-modal"
                  href="#note"
                  style="cursor: pointer"
                  (click)="currentNote = normalizeNote(note)"
                >
                  <div class="item-note-status hide-mobile">{{ nt.name }}</div>
                  <a class="text-preview show-mobile" href="#note">
                    <div class="item-note-status">{{ nt.name }}</div>
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noramlNoteTypeListing>
              <div
                class="item-cell cell-status hide-mobile open-modal"
                href="#note"
                style="cursor: pointer"
                (click)="currentNote = normalizeNote(note)"
              >
                <div
                  class="item-note-status"
                  style="text-transform: capitalize"
                >
                  {{ "HORSES." + note?.type | translate }}
                </div>
              </div>
              <div
                class="item-cell cell-treatment hide-mobile"
                *ngIf="!isAUSCountry"
              >
                <div class="name">
                  {{ note?.type !== "sign" ? note?.title : "-" }}
                </div>
              </div>
              <ng-container *ngIf="!isAUSCountry">
                <div
                  *ngIf="!tabs.displayAintreeTabs || note?.type !== 'sign'"
                  class="item-cell cell-treatment hide-mobile"
                  href="#note"
                >
                  <div class="text">
                    {{ note?.type !== "sign" ? note?.description : "" }}
                    {{ note?.type !== "sign" ? note?.weight : "" }}
                  </div>
                </div>
              </ng-container>
            </ng-template>

            <div class="box-table show-mobile box-info">
              <div
                class="item-cell open-modal"
                href="#note"
                (click)="currentNote = normalizeNote(note)"
              >
                <ng-container *ngIf="!isAUSCountry">
                  <div class="name">{{ note?.title }}</div>
                </ng-container>
                <a class="text-preview show-mobile">
                  <div
                    class="text"
                    *ngIf="note?.type !== 'sign' && !isAUSCountry"
                  >
                    {{ note?.description }} {{ note?.weight }}
                  </div>
                </a>
                <div class="box-table show-mobile box-info">
                  <div class="item-cell">
                    <div class="person">
                      {{ note?.user?.name + " " + note?.user?.last_name }}
                    </div>
                    <div class="date">
                      {{ note?.created_at | dateObj | date : "dd MMM y" }}
                    </div>
                  </div>
                  <div class="item-cell text-right">
                    <div
                      class="status"
                      *ngIf="note?.type !== 'sign'"
                      style="text-transform: capitalize"
                    >
                      {{ note?.type }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-cell cell-action hide-mobile text-right">
              <ng-container
                *ngIf="note?.type == 'video-submission'; else normalActions"
              >
                <a
                  class="btn btn-circle edit"
                  href="javascript: void(0);"
                  (click)="getPresignedUrl(note?.images[0]?.src)"
                >
                  <i class="icon icon-file dark icon-middle"></i>
                </a>
              </ng-container>
              <ng-template #normalActions>
                <ng-container *ngIf="!isAUSCountry">
                  <div
                    *ngIf="
                      !horse?.is_eligible &&
                      horse?.is_active &&
                      note?.user_id == appMemory.user?.id
                    "
                  >
                    <a
                      class="btn btn-circle edit open-modal"
                      href="#edit-note"
                      (click)="setNote(note)"
                    >
                      <i class="icon icon-edit dark icon-middle"></i>
                    </a>
                    <a
                      class="btn btn-circle remove open-modal"
                      href="#remove-note"
                      (click)="setNote(note)"
                    >
                      <i class="icon icon-close icon-middle"></i>
                    </a>
                  </div>
                </ng-container>
              </ng-template>
            </div>
          </div>
          <div
            class="no-data"
            *ngIf="!isRequestInProgress3 && notes?.length == 0"
          >
            {{ "CORE.noNotes" | translate }}
          </div>

          <div class="text-center">
            <app-loader
              *ngIf="
                (isRequestInProgress3 && notes?.length == 0) ||
                this.pagination.inProgress
              "
            ></app-loader>
            <button
              class="btn lazy-btn"
              (click)="addNewData(notes)"
              *ngIf="!pagination.inProgress && !!pagination.nextPage"
            >
              {{ "CORE.showMore" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="padding-block show-mobile" [ngClass]="{ hidden: !tabs.isHorse }">
    <div class="form">
      <div class="double-block clearfix">
        <div class="row-half">
          <div class="box-control input--filled" *ngIf="appMemory?.static">
            <div class="label-value input__field">
              {{ appMemory.gendersList[horse?.gender_id].name }}
            </div>
            <label class="input__label">
              <span>{{ "HORSES.horseSex" | translate }}</span>
            </label>
          </div>
          <div class="box-control input--filled">
            <div class="label-value input__field">
              {{
                horse?.instructor?.name + " " + horse?.instructor?.last_name ||
                  appMemory.user?.name + " " + appMemory.user?.last_name
              }}
            </div>
            <label class="input__label">
              <span>{{ "CORE.trainer" | translate }}</span>
            </label>
          </div>
          <div class="box-control input--filled">
            <div class="label-value input__field">
              {{ horse?.description }}
            </div>
            <label class="input__label">
              <span>{{ "HORSES.description" | translate }}</span>
            </label>
          </div>
          <div class="box-control input--filled" *ngIf="horse?.microchip">
            <div class="label-value input__field">
              <a [href]="horse?.microchip" target="_blank">{{
                "CORE.show" | translate
              }}</a>
            </div>
            <label class="input__label">
              <span>{{ "HORSES.photoPass" | translate }}</span>
            </label>
          </div>
        </div>

        <div class="row-half">
          <div class="box-control input--filled">
            <div class="label-value input__field">
              {{ horse?.birth | dateObj | date : "dd MMM y" }}
            </div>
            <label class="input__label">
              <span>{{ "CORE.dateOfBirth" | translate }}</span>
            </label>
          </div>

          <div
            class="box-control input--filled"
            *ngIf="horse?.microchip_number"
          >
            <div class="label-value input__field">
              {{ pad(horse?.microchip_number, 5) }}
            </div>
            <label class="input__label">
              <span>{{ "HORSES.microchipNumber" | translate }}</span>
            </label>
          </div>

          <ng-container *ngIf="!isAUSCountry">
            <div
              class="box-control input--filled"
              *ngIf="showInTheFoodChainToggle"
            >
              <div class="label-value input__field">
                {{ horse?.is_in_food_chain ? "In" : "Out" }}
              </div>
              <label class="input__label">
                <span>{{ "HORSES.isInFoodChain" | translate }}</span>
              </label>
            </div>
          </ng-container>

          <ng-container *ngIf="!isAUSCountry">
            <div
              class="box-control input--filled"
              *ngIf="appMemory.user?.roles[0]?.id == 1 && horse?.is_active"
            >
              <div class="label-value input__field">
                <a class="open-modal" href="#archive-horse">{{
                  "HORSES.moveToArchive" | translate
                }}</a>
              </div>
              <label class="input__label">
                <span>{{ "HORSES.archive" | translate }}</span>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    class="padding-block padding-block--no-xs"
    [ngClass]="{ hidden: !tabs.isVaccinations }"
  >
    <app-loader *ngIf="isRequestInProgress5"></app-loader>
    <div
      class="no-data"
      *ngIf="!isRequestInProgress5 && vaccinations?.length == 0"
    >
      {{ "CORE.noVacs" | translate }}
    </div>
    <div
      class="table-treatment table-vaccination"
      *ngIf="!isRequestInProgress5 && vaccinations?.length > 0"
    >
      <div class="table-header hide-mobile">
        <div class="box-table">
          <div class="item-cell cell-time">
            {{ "HORSES.creationDate" | translate }}
          </div>
          <div class="item-cell cell-treatment">
            {{ "HORSES.vaccinationName" | translate }}
          </div>
          <div class="item-cell cell-status">
            {{ "HORSES.vaccinationNumber" | translate }}
          </div>
          <div class="item-cell cell-time">
            {{ "HORSES.administrationDate" | translate }}
          </div>
          <div class="item-cell cell-action" *ngIf="horse?.is_active">
            {{ "HORSES.action" | translate }}
          </div>
        </div>
      </div>
      <div class="table-body">
        <div
          class="item-treatment cell-event"
          *ngFor="let vaccination of vaccinations"
        >
          <div class="item-note show-mobile">
            <div class="box-table">
              <div class="item-cell cell-info hide-mobile">
                <div class="status" style="text-transform: capitalize">
                  {{ "HORSES.vacc" | translate }}
                </div>
                <div class="date">
                  <div class="time">
                    {{
                      vaccination?.created_at | dateObj | date : "hh:mm:ss a"
                    }}
                  </div>
                  20 Jun 2017
                  {{ vaccination?.created_at | dateObj | date : "dd MMM y" }}
                </div>
              </div>
              <div
                class="item-cell"
                style="cursor: default"
                (click)="currentVaccination = vaccination"
              >
                <div class="name">
                  <span *ngIf="vaccination?.name === 'Equine Influenza'">{{
                    "HORSES.equineInfluenza" | translate
                  }}</span>
                  <span *ngIf="vaccination?.name === 'Equine Herpes'">{{
                    "HORSES.equineHerpes" | translate
                  }}</span>
                  <span
                    *ngIf="
                      vaccination?.name !== 'Equine Herpes' &&
                      vaccination?.name !== 'Equine Influenza'
                    "
                    >{{ vaccination?.name }}</span
                  >
                </div>
                <div class="text hide-mobile">
                  {{ vaccination?.date | dateObj | date : "dd MMM y" }}
                </div>
                <a class="text-preview show-mobile" style="cursor: default">
                  <div class="text" style="text-transform: capitalize"></div>
                </a>
                <div class="box-table show-mobile box-info">
                  <div class="item-cell">
                    <ng-container *ngIf="vaccination?.immunization">
                      <div style="color: rgb(169, 167, 177); margin-top: 5px">
                        {{ "HORSES.dateofNextAdmin" | translate }}
                      </div>
                      <div style="margin-top: 5px">
                        {{
                          vaccination?.immunization
                            | dateObj
                            | date : "dd MMM y"
                        }}
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        vaccination?.deadline && !vaccination?.immunization
                      "
                    >
                      <div style="color: rgb(169, 167, 177); margin-top: 5px">
                        <span style="text-transform: capitalize">{{
                          "CORE." +
                            (vaccination?.other
                              ? "other"
                              : vaccination?.number === "primary"
                              ? "secondary"
                              : "booster") | translate
                        }}</span>
                        {{ "HORSES.toBeAdministrated" | translate }}
                        <span>{{
                          "HORSES." +
                            (vaccination?.number === "booster"
                              ? "by"
                              : "between") | translate
                        }}</span>
                      </div>
                      <div style="margin-top: 5px">
                        <span *ngIf="vaccination?.eligible"
                          >{{
                            vaccination?.eligible | dateObj | date : "dd MMM y"
                          }}
                          <span style="color: rgb(169, 167, 177)">{{
                            "HORSES.and" | translate
                          }}</span></span
                        >
                        {{
                          vaccination?.deadline | dateObj | date : "dd MMM y"
                        }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="item-cell text-right">
                    <div class="status" style="text-transform: capitalize">
                      {{
                        "CORE." +
                          (vaccination?.other ? "other" : vaccination?.number)
                          | translate
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="item-cell cell-action hide-mobile text-right"
                *ngIf="appMemory.user?.roles[0]?.id === 1 && horse?.is_active"
              >
                <a
                  class="btn btn-circle remove open-modal"
                  (click)="currentVaccination = vaccination"
                  href="#remove-vaccination"
                  *ngIf="appMemory.user?.roles[0]?.id === 1"
                >
                  <i class="icon icon-close icon-middle"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="line-even hide-mobile">
            <div class="item-cell cell-time">
              <div class="date">
                <div class="time">
                  {{ vaccination?.created_at | dateObj | date : "hh:mm:ss a" }}
                </div>
                {{ vaccination?.created_at | dateObj | date : "dd MMM y" }}
              </div>
            </div>
            <div class="item-cell cell-treatment">
              <span *ngIf="vaccination?.name === 'Equine Influenza'">{{
                "HORSES.equineInfluenza" | translate
              }}</span>
              <span *ngIf="vaccination?.name === 'Equine Herpes'">{{
                "HORSES.equineHerpes" | translate
              }}</span>
              <span
                *ngIf="
                  vaccination?.name !== 'Equine Herpes' &&
                  vaccination?.name !== 'Equine Influenza'
                "
                >{{ vaccination?.name }}</span
              >
              <ng-container *ngIf="vaccination?.immunization">
                <div style="color: rgb(169, 167, 177); margin-top: 5px">
                  {{ "HORSES.dateofNextAdmin" | translate }}
                </div>
                <div style="margin-top: 5px">
                  {{ vaccination?.immunization | dateObj | date : "dd MMM y" }}
                </div>
              </ng-container>
              <ng-container
                *ngIf="vaccination?.deadline && !vaccination?.immunization"
              >
                <div style="color: rgb(169, 167, 177); margin-top: 5px">
                  <span style="text-transform: capitalize">{{
                    "CORE." +
                      (vaccination?.other
                        ? "other"
                        : vaccination?.number === "primary"
                        ? "secondary"
                        : "booster") | translate
                  }}</span>
                  {{ "HORSES.toBeAdministrated" | translate }}
                  <span>{{
                    "HORSES." +
                      (vaccination?.number === "booster" ? "by" : "between")
                      | translate
                  }}</span>
                </div>
                <div style="margin-top: 5px">
                  <span *ngIf="vaccination?.eligible"
                    >{{ vaccination?.eligible | dateObj | date : "dd MMM y" }}
                    <span style="color: rgb(169, 167, 177)">{{
                      "HORSES.and" | translate
                    }}</span></span
                  >
                  {{ vaccination?.deadline | dateObj | date : "dd MMM y" }}
                </div>
              </ng-container>

              <div
                style="margin-top: 5px"
                *ngFor="let vc of vaccination?.vaccination_country"
              >
                ({{ "ISO." + vc?.country?.iso | translate }})
                <span *ngIf="vc?.eligible"
                  >{{ vc?.eligible | dateObj | date : "dd MMM y" }}
                  <span style="color: rgb(169, 167, 177)">{{
                    "HORSES.and" | translate
                  }}</span></span
                >
                {{
                  vc?.immunization || vc?.deadline | dateObj | date : "dd MMM y"
                }}
              </div>
            </div>
            <div
              class="item-cell cell-status"
              style="text-transform: capitalize"
            >
              {{
                "CORE." + (vaccination?.other ? "other" : vaccination?.number)
                  | translate
              }}
            </div>
            <div class="item-cell cell-time">
              {{ vaccination?.date | dateObj | date : "dd MMM y" }}
            </div>
            <div
              class="item-cell cell-action hide-mobile text-right"
              *ngIf="horse?.is_active && !horse?.is_eligible"
            >
              <ng-container *ngIf="this.isUSCountry">
                <ng-template [ngIf]="vaccination?.evet_id">
                  <a class="btn btn-circle" (click)="goToLink()">
                    <i class="icon icon-file icon-middle"></i>
                  </a>
                </ng-template>
              </ng-container>
              <a
                class="btn btn-circle remove open-modal"
                (click)="currentVaccination = vaccination"
                href="#remove-vaccination"
              >
                <i class="icon icon-close icon-middle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <app-loader
          *ngIf="
            (isRequestInProgress5 && vaccinations?.length == 0) ||
            this.paginationVc.inProgress
          "
        ></app-loader>
        <button
          class="btn lazy-btn"
          (click)="addNewData(vaccinations)"
          *ngIf="!paginationVc.inProgress && !!paginationVc.nextPage"
        >
          {{ "CORE.showMore" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="footer-button show-mobile"
  *ngIf="tabs.isTreatments && horse?.is_active"
>
  <a
    class="btn btn-green btn-full open-modal"
    href="#add-treatment"
    (click)="
      clearUserInput(); isAddTreatment = true; setDeaultAdministerValue()
    "
    >{{ "HORSES.addTreatment" | translate }}</a
  >
</div>

<div class="footer-button show-mobile" *ngIf="tabs.isNotes && horse?.is_active">
  <a
    class="btn btn-green btn-full open-modal"
    (click)="addNodeSetDefaultDate()"
    href="#add-note"
    >{{ "HORSES.addNote" | translate }}</a
  >
</div>

<div
  class="footer-button show-mobile"
  *ngIf="tabs.isVaccinations && horse?.is_active"
>
  <a class="btn btn-green btn-full open-modal" href="#add-vaccination">{{
    "HORSES.addVacs" | translate
  }}</a>
</div>

<ng-template #noteDatePicker>
  <div class="box-control input--filled">
    <a
      class="btn btn-dotted btn-code btn-full"
      style="user-select: none"
      [ngClass]="{ danger: serverErrorValidate?.note_date }"
    >
      <label class="label">
        <span>{{ "HORSES.dateOfNote" | translate }} </span>
      </label>
      <div class="code relative">
        <input
          type="text"
          placeholder="__/__/____"
          class="form-control input__field"
          [(ngModel)]="datePickerAddNote.currentDate_input"
          autocomplete="off"
          name="note_date"
          (change)="setNoteDateInput()"
        />
        <i
          class="fa fa-calendar calendar"
          (click)="datePickerAddNote.isActive = !datePickerAddNote.isActive"
          aria-hidden="true"
        ></i>
      </div>
      <span class="text-danger" *ngIf="serverErrorValidate?.note_date">{{
        serverErrorValidate?.note_date | translate
      }}</span>
    </a>
    <div class="box-control" *ngIf="datePickerAddNote.isActive">
      <datepicker
        class="datepicker"
        name="note-date-datepicker"
        [startingDay]="1"
        [onlyCurrentMonth]="true"
        [minDate]="datePickerAddNote.minDate"
        [maxDate]="datePickerAddNote.maxDate"
        [showWeeks]="false"
        [initDate]="datePickerAddNote.currentDate"
        [dateDisabled]="dateDisabled"
        (selectionDone)="
          setNoteDate($event);
          datePickerAddNote.isActive = !datePickerAddNote.isActive
        "
      >
      </datepicker>
    </div>
  </div>
</ng-template>
