<ng-container>
  <div class="text-center">
    <div
      class="link-ireland"
      *ngFor="let availableCountry of availableCountries"
    >
      <a href="{{ availableCountry.url }}"
        >{{ "AUTH.navigateTo" | translate }}
        {{ "CORE." + availableCountry.country | translate }}</a
      >
    </div>
  </div>
</ng-container>
