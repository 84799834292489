<div>
  <b> 1. Veterinary Inspection Form </b>
  <br /><br />
  <div>
    <p>The veterinary inspection must include:</p>
    <br />
    <ul class="field_info">
      <li>A comprehensive review of the horse’s veterinary history.</li>
      <li>Cardiac auscultation on both sides of the horse’s thorax.</li>
      <li>
        Palpation and assessment of musculoskeletal structures for potentially
        significant abnormalities.
      </li>
      <li>Assessment of gait symmetry.</li>
      <li>
        A trot-up on a flat firm surface to assess the horse’s action and to
        detect lameness; in a straight line
      </li>
      <li>
        Submission of two satisfactory videos of the horse’s trot up evaluation.
        The method for this submission is via the Sliep application (two trot up
        laps are recorded in a single video). This requires contact with RV
        vetererinay services manager at veterinaryadmin@racingvictoria.net.au or
        Dr Grace Forbes (RV – General Manager, Veterinary Services), Mobile:
        0406 750 390, to receive a horse invitation, and downloading of the
        Sliep application on an apple devide (available here :
        <span
          ><a
            href="https://apps.apple.com/au/app/sleip/id1583194375"
            target="_blank"
            >Link</a
          ></span
        >).
      </li>
    </ul>
  </div>
  <br />
  <div>
    <p>The veterinary report must:</p>
    <br />
    <ul class="field_info">
      <li>
        Identify and report on any abnormalities detected during the veterinary
        inspection and comment on the potential significance of those
        abnormalities; including an assessment of gait normality and scoring any
        gait/ lameness observations on scale of 1 to 5 (as described by Ross and
        Dyson)
      </li>
    </ul>
  </div>
  <br />
  <div><b>Report On Veterinary Examination</b><br /></div>

  <ng-container *ngFor="let field of noteFields; let _index = index">
    <ng-container
      *ngIf="
        field.id != 'special_farriery_requirements' &&
        field.id != 'report_by_veterinarian' &&
        field.id != 'LF_Lameness_Grade_select' &&
        field.id != 'RF_Lameness_Grade_select' &&
        field.id != 'LH_Lameness_Grade_select' &&
        field.id != 'RH_Lameness_Grade_select'
      "
    >
      <div
        class="box-control clearfix"
        [ngClass]="{ 'input--filled--model': newNote[field.name] }"
      >
        <ng-container
          *ngTemplateOutlet="
            formInfoFieldTemplate;
            context: {
              field: field,
              yesLabel: yesLabel,
              noLabel: noLabel,
              textFieldLabel: 'Description of abnormality found',
              required: true
            }
          "
        >
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <div>
    <p>
      Gait Assessment: Lameness grades from 0 to 5 as described by Ross and
      Dyson
    </p>
    <br />
    <ng-container *ngFor="let item of reviewMenus">
      <div class="box-control">
        <label class="label ng-select-label">{{ item.label }}</label>
        <ng-select
          class="farms-selector"
          [allowClear]="false"
          [items]="ratings"
          id="{{ item.type }}"
          #ratingSelector
          placeholder="Select"
          [active]="getActiveRating(item)"
          (selected)="setRatingValue($event, item)"
        >
        </ng-select>
      </div>
      <ng-container *ngIf="getSelectedInputValue(item.field) >= 2">
        <div
          class="box-control clearfix"
          [ngClass]="{
            'input--filled--model': newNote[getSelectInputName(item.field)]
          }"
        >
          <input
            type="text"
            class="form-control input__field"
            style="display: block"
            name="{{ getToggleInputName(item.field) }}"
            autocomplete="off"
            spellcheck="false"
            [(ngModel)]="newNote[getToggleInputName(item.field)]"
            value="{{ newNote[getToggleInputName(item.field)] }}"
            #itemName="ngModel"
          />

          <label class="input__label">
            <span>Provide details</span>
          </label>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngFor="let field of noteFields; let _index = index">
    <ng-container
      *ngIf="
        field.id == 'special_farriery_requirements' ||
        field.id == 'report_by_veterinarian'
      "
    >
      <div
        class="box-control clearfix"
        [ngClass]="{ 'input--filled--model': newNote[field.name] }"
      >
        <ng-container
          *ngTemplateOutlet="
            formInfoFieldTemplate;
            context: {
              field: field,
              yesLabel: 'Yes',
              noLabel: 'No',
              textFieldLabel:
                'If yes, provide details of special farriery requirements',
              required: !optionalFields.includes(field.id)
            }
          "
        >
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template
  #formInfoFieldTemplate
  let-field="field"
  let-yesLabel="yesLabel"
  let-noLabel="noLabel"
  let-textFieldLabel="textFieldLabel"
  let-required="required"
>
  <div *ngIf="field.type === 'checkbox'">
    <div class="toggle__text__inline" [innerHTML]="field.label"></div>
    <div>
      <input
        type="checkbox"
        id="{{ field.uuid }}"
        name="{{ field.name }}"
        [checked]="!!newNote[field.name]"
        (change)="updateTextFieldValue(newNote, field)"
      />
      <div class="toggle">
        <label for="{{ field.uuid }}">
          <i></i>
        </label>
      </div>
      <span class="toggle__label">
        <span *ngIf="!!newNote[field.name]">{{ yesLabel }}</span>
        <span *ngIf="!newNote[field.name]">{{ noLabel }}</span>
      </span>
    </div>
  </div>

  <ng-container
    *ngIf="
      !!newNote[field.name] &&
      field.id != 'veterinarians_review_medical_history' &&
      field.id != 'report_by_veterinarian'
    "
  >
    <div
      class="box-control clearfix"
      [ngClass]="{
        'input--filled--model': newNote[field.name]
      }"
    >
      <input
        type="text"
        class="form-control input__field"
        style="display: block"
        name="{{ getToggleInputName(field) }}"
        autocomplete="off"
        spellcheck="false"
        [(ngModel)]="newNote[getToggleInputName(field)]"
        value="{{ newNote[getToggleInputName(field)] }}"
        #itemName="ngModel"
        required="{{ required }}"
        [ngClass]="{
          error: noteForm.submitted && itemName.errors?.required
        }"
      />
      <label class="input__label">
        <span>{{ textFieldLabel }}</span>
      </label>
    </div>
  </ng-container>

  <div *ngIf="field.type === 'text'">
    <textarea
      min="5"
      cols="50"
      class="form-control input__field"
      style="display: block"
      name="{{ field.name }}"
      autocomplete="off"
      spellcheck="false"
      [(ngModel)]="newNote[field.name]"
      value="{{ newNote[field.name] }}"
      required="{{ required }}"
      #itemName="ngModel"
      [ngClass]="{
        error: noteForm.submitted && itemName.errors?.required
      }"
    ></textarea>
    <label class="input__label">
      <span>{{ field.label }}</span>
    </label>
  </div>
</ng-template>
