<div class="weight-reporting-component">
  <div class="modal date-range" id="date-range">
    <div class="modal-wrapper">
      <a class="btn btn-close close-modal hide-mobile">
        <i class="icon icon-close"></i>
      </a>
      <div class="modal-header">
        <div class="left-part">
          <a class="btn close-modal show-mobile">
            <i class="icon icon-back dark icon-middle"></i>
          </a>
        </div>
        <div class="title">{{ 'REPORT.pickDateRange' | translate}}</div>
      </div>
      <div class="modal-body">
        <ul class="nav-type line">
          <li class="change-active">
            <a class="btn" (click)="period.type = 'day'; showDateRange = false"><span class="name">{{ 'REPORT.today' |
                translate}}</span></a>
          </li>
          <li class="change-active">
            <a class="btn" (click)="period.type = 'week'; showDateRange = false"><span class="name">{{
                'REPORT.thisWeek' | translate}}</span></a>
          </li>
          <li class="change-active">
            <a class="btn" (click)="period.type = 'month'; showDateRange = false"><span class="name">{{
                'REPORT.thisMonth' | translate}}</span></a>
          </li>
          <li class="change-active">
            <a class="btn" (click)="period.type = 'year'; showDateRange = false"><span class="name">{{
                'REPORT.thisYear' | translate}}</span></a>
          </li>
          <li class="change-active">
            <a class="btn" (click)="period.type = ''; showDateRange = !showDateRange"><span class="name">{{
                'REPORT.custom' | translate }}</span></a>
          </li>
        </ul>
        <div [hidden]="!showDateRange">
          <div class="box-control input--filled date-from">
            <div class="btn btn-dotted btn-code btn-full" [ngClass]="{'danger': serverError?.start || dateRangeErrorMessage.from}"
              style="user-select: none;">
              <label class="label">
                <span>{{ 'REPORT.periodFrom' | translate }}</span>
              </label>
              <div class="code relative">
                <input type="text" [textMask]="datePickerMask" placeholder="{{ 'CORE.selectDate' | translate }}" class="form-control input__field"
                  [(ngModel)]="datePickerPeriodFrom.currentDate_input" autocomplete="off" name="start" (ngModelChange)="setStartDateInput()">
                <i class="fa fa-calendar calendar" (click)="datePickerPeriodFrom.isActive = !datePickerPeriodFrom.isActive; datePickerPeriodTo.isActive = false"
                  aria-hidden="true"></i>
              </div>
              <span class="box-btn text-center text-danger" *ngIf="dateRangeErrorMessage.from">{{dateRangeErrorMessage.from}}</span>
              <span class="box-btn text-center text-danger" *ngIf="!dateRangeErrorMessage.from && serverError?.start">{{serverError.start
                | translate}}</span>
            </div>
            <div class="box-control datepicker" *ngIf="datePickerPeriodFrom.isActive">
              <datepicker class="datepicker" container="body" name="birth-datepicker"
                [maxDate]="datePickerPeriodTo.currentDate" [startingDay]="1" [onlyCurrentMonth]="true" [showWeeks]="false"
                [initDate]="datePickerPeriodFrom.currentDate" (selectionDone)="selectDate(datePickerPeriodFrom, 'start', $event); datePickerPeriodFrom.isActive = !datePickerPeriodFrom.isActive">
              </datepicker>
            </div>
          </div>
          <div class="box-control input--filled date-to">
            <div class="btn btn-dotted btn-code btn-full" [ngClass]="{'danger': serverError?.end || dateRangeErrorMessage.due}"
              style="user-select: none;">
              <label class="label">
                <span>{{ 'REPORT.periodDue' | translate }}</span>
              </label>
              <div class="code relative">
                <input type="text" [textMask]="datePickerMask" placeholder="{{ 'CORE.selectDate' | translate }}" class="form-control input__field"
                  [(ngModel)]="datePickerPeriodTo.currentDate_input" autocomplete="off" name="end" (ngModelChange)="setEndDateInput()">
                <i class="fa fa-calendar calendar" (click)="datePickerPeriodTo.isActive = !datePickerPeriodTo.isActive; datePickerPeriodFrom.isActive = false"
                  aria-hidden="true"></i>
              </div>
              <span class="box-btn text-center text-danger" *ngIf="dateRangeErrorMessage.due">{{dateRangeErrorMessage.due}}</span>
              <span class="box-btn text-center text-danger" *ngIf="!dateRangeErrorMessage.due && serverError?.end">{{serverError.end
                | translate}}</span>
            </div>
            <div class="box-control datepicker" *ngIf="datePickerPeriodTo.isActive">
              <datepicker class="datepicker" container="body" name="birth-datepicker" [minDate]="datePickerPeriodFrom.currentDate"
                [startingDay]="1" [onlyCurrentMonth]="true" [showWeeks]="false" [initDate]="datePickerPeriodTo.currentDate"
                (selectionDone)="selectDate(datePickerPeriodTo, 'end', $event); datePickerPeriodTo.isActive = !datePickerPeriodTo.isActive">
              </datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-green btn-full close-modal" type="submit" (click)="confirmPeriod()" [disabled]="checkPeriodValidity()">
          {{ 'REPORT.pickDate' | translate }}</button>
      </div>
      <div class="box-btn text-center text-danger" *ngIf="datePickerPeriodTo.currentDate_input && datePickerPeriodFrom.currentDate_input && checkPeriodValidity()">
        {{ 'REPORT.periodEarlier' | translate }}
      </div>
    </div>
  </div>
  <div class="container">
    <div class="hide-mobile">
      <div class="name-page">
        <span class="name">{{ 'REPORT.weightReporting' | translate}}</span>
        <div class="main-options">
          <div class="export" *ngIf="checkActiveFilters()" [ngClass]="{'disabled': exporting}">
            <a class="btn btn-border btn-middle export-btn" (click)="downloadWeightReport('pdf')" [attr.disabled]="exporting ? '' : null">{{
              'REPORT.exportReport' | translate}}</a>
          </div>
          <a class="btn btn-border btn-middle" (click)="sendOnEmail()" *ngIf="checkActiveFilters()" [attr.disabled]="emailSending ? '' : null">
            <span class="check" *ngIf="emailSent">&#x2713;</span>
            {{ 'REPORT.' + (!emailSent ? 'sendEmailReport' : 'sentEmailReport') | translate }}
          </a>
          <a class="btn btn-border btn-middle date-range-btn open-modal" href="#date-range" [ngClass]="{'danger': dateRangeRequired}">
            <span class="check" *ngIf="periodSelected">&#x2713;</span>
            {{ 'REPORT.dateRange' | translate }}
          </a>
          <a class="btn btn-border btn-middle" (click)="getReports()">{{ 'REPORT.getReports' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="filters">
      <app-filter-weight [title]="'HORSES.horses' | translate" [options]="horses" [value]="'id'" [label]="'name'"
        (activated)="onChecked($event, 'horses')" (selected)="onSelect($event, 'horses', '_id')"></app-filter-weight>
    </div>
    <div class="reports" *ngIf="currentWidth > 767">
      <div *ngFor="let report of reportsKeys">
        <app-report-weight *ngIf="reports[report] && reports[report].length" [title]="report" [data]="reports[report]"
          [total]="reports[report + '_count']" (load)="viewMore($event)" (sort)="sortData(report, $event)"></app-report-weight>
      </div>
      <div class="no-data" *ngIf="reportsKeys && !reportsKeys.length || !checkDataLength(reports, reportsKeys)">
        <span>{{ 'REPORT.noData' | translate }}</span>
      </div>
    </div>
    <div *ngIf="lineChartData && lineChartData.length && lineChartData[0].data.length" class="chart">
      <canvas baseChart width="400" height="400" [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
        [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)"></canvas>
    </div>
  </div>
</div>
