<div
  *ngFor="let field of noteFields; let _index = index"
  class="box-control clearfix"
  [ngClass]="{ 'input--filled--model': newNote[field.name] }"
>
  <div *ngIf="field.type === 'checkbox'">
    <div class="toggle__text__inline" [innerHTML]="field.label"></div>
    <div>
      <input
        type="checkbox"
        id="{{ field.uuid }}"
        name="{{ field.name }}"
        [checked]="!!newNote[field.name]"
        (change)="newNote[field.name] = !newNote[field.name]"
      />
      <div class="toggle">
        <label for="{{ field.uuid }}">
          <i></i>
        </label>
      </div>
      <span class="toggle__label">
        <span *ngIf="!!newNote[field.name]">{{ yesLabel }}</span>
        <span *ngIf="!newNote[field.name]">{{ noLabel }}</span>
      </span>
    </div>
  </div>

  <div *ngIf="field.type === 'text'">
    <input
      type="text"
      class="form-control input__field"
      style="display: block"
      name="{{ field.name }}"
      autocomplete="off"
      spellcheck="false"
      [(ngModel)]="newNote[field.name]"
      value="{{ newNote[field.name] }}"
    />

    <label class="input__label">
      <span>{{ field.label }}</span>
    </label>
  </div>
</div>
<div style="margin-bottom: 20px">
  <b>IMPORTANT NOTE:</b>
  <p>
    Trainers and veterinarians must ensure that the information provided in this
    form is accurate and not misleading in any way.  Failure to do so may result
    in an investigation and action taken by RV Stewards pursuant to the
    Australian Rules of Racing [see AR 229 (1)(h)
  </p>
  <br />
  <p>Queries and clarifications</p>
  <br />
  <p>Dr Grace Forbes (RV – General Manager, Veterinary Services)</p>
  <br />
  <p>Mobile:                +61 406 750 390</p>
  <br />
  <p>Email:                    g.forbes@racingvictoria.net.au</p>
  <br />
  <b>Annexure A - Privacy Collection Statement</b>
  <br />
  <p>
    Racing Victoria Limited ACN 096 917 930 (Racing Victoria) is the Principal
    Racing Authority responsible for governing thoroughbred racing in Victoria.
    Racing Victoria is collecting your personal information via this form, which
    may include your name, profession, and any other information capable of
    reasonably identifying an individual, for example, as a result of any
    documents you upload in this form.
  </p>
  <br />
  <p>
    You may also provide personal information about other individuals to Racing
    Victoria (such as the Stable Contact Person or Nominated Veterinarian). If
    so, you consent to Racing Victoria contacting those individuals for the
    purpose of your application and to those individuals providing information
    about you to Racing Victoria. You also should have: obtained consent from
    those individuals for us to collect their information; informed those
    individuals that you are providing their information to Racing Victoria and
    the purposes for which you are providing that information; and advised them
    of the matters in this privacy collection statement and Racing Victoria’s
    Privacy Policy.
  </p>
  <br />
  <p>
    The purpose of collecting your personal information is for our legitimate
    interests, including to: consider and make determinations regarding the
    suitability of the racehorse to travel and participate in the Victorian
    thoroughbred racing industry; protect and ensure equine welfare; promote or
    protect the integrity of the thoroughbred racing code and ensure compliance
    with the Rules of Racing; and any other function or activity described in
    our Privacy Policy.
  </p>
  <br />
  <p>
    We are permitted to process your personal information for the above
    purposes, by relying on one or more lawful grounds: (a) you have explicitly
    agreed to us processing such information for a specific reason; (b) the
    processing is necessary to perform the agreement we have with you or to take
    steps to enter into an agreement with you; (c) the processing is necessary
    for us to comply with our legal obligations; or (d) the processing is
    necessary for our legitimate interests, including the administration and
    promotion of racing operations and ensuring the compliance with the Rules of
    Racing (Lawful Grounds).
  </p>
  <br />
  <p>
    If your personal information is not collected, either on this form or
    otherwise when requested by Racing Victoria as part of its integrity and
    regulatory activities, we may not be able to process this form and therefore
    refuse or revoke your request to participate in the Victorian thoroughbred
    racing industry.
  </p>
  <br />
  <p>
    Racing Victoria may also obtain personal (including sensitive) information
    about you from, or disclose your personal information to, third parties
    (including, where the EU General Data Protection Regulation (EU GDPR) and/or
    UK General Data Protection Regulation (UK GDPR) applies, third countries
    outside of the UK and EU or European Economic Area (EEA)). Where the EU GDPR
    and/or UK GDPR applies, Racing Victoria implements adequate safeguards
    recognised by the EU and the UK for transferring such personal information,
    which may include standard contractual clauses.  Racing Victoria may
    disclose personal information to third parties including enforcement bodies
    (such as Victoria Police, the RSPCA or the Veterinary Practitioners
    Registration Board of Victoria), government authorities, other Principal
    Racing Authorities within Australia, and international horse racing
    authorities, and to other persons for any of the purposes described above or
    as otherwise provided by law.”
  </p>
  <br />
  <p>
    We will retain your personal information only for the duration necessary for
    the purposes identified in this Privacy Collection Statement and in
    accordance with this Privacy Collection Statement.
  </p>
</div>
