<nav>
  <div class="box-close show-mobile">
    <a class="btn tutorial-button" (click)="setTutorial()">Tutorial</a>
    <a class="btn help-button" (click)="setTooltip()">Help</a>
    <a class="btn" (click)="logout()"
      ><i class="icon icon-middle icon-logout"></i
    ></a>
    <a class="btn btn-close"><i class="icon icon-close"></i></a>
  </div>
  <div class="content-nav">
    <div class="padding-block">
      <div
        class="user-info"
        [ngClass]="isAUSCountry ? 'aus-bg-intro' : 'bg-intro'"
      >
        <a class="btn tutorial-button-desk" (click)="setTutorial()">Tutorial</a>
        <a class="btn help-button-desk" (click)="setTooltip()">Help</a>
        <div class="name">
          {{ appMemory.user?.name }} {{ appMemory.user?.last_name }}
        </div>
        <a [routerLink]="['/profile']" class="type">
          {{
            "CORE." +
              appMemory?.translates[appMemory.user?.roles[0]?.display_name]
              | translate
          }}
        </a>
      </div>
      <ul class="nav">
        <li
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a [routerLink]="['/profile']">{{ "PROFILE.profile" | translate }}</a>
        </li>

        <li
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: false }"
        >
          <app-tooltip
            class="addHorseTutorial"
            #addHorseTutorial
            *ngIf="isShowTooltip"
            (click)="showTooltip('add-horse', '.addHorseTutorial')"
          ></app-tooltip>
          <a [routerLink]="['/horses']">{{ "HORSES.horses" | translate }}</a>
        </li>
        <ng-container *ngIf="!isAUSCountry">
          <!-- <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><a [routerLink]="['/cat']">Vets & Assiatants</a></li> -->
          <li
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <app-tooltip
              class="addTreatmentTutorial"
              #addTreatmentTutorial
              *ngIf="isShowTooltip"
              (click)="showTooltip('add-treatment', '.addTreatmentTutorial')"
            ></app-tooltip>
            <a [routerLink]="['/all-treatments']">{{
              "TREATS.treatments" | translate
            }}</a>
          </li>
          <li
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: false }"
          >
            <a [routerLink]="['/yards']">{{ "YARDS.yards" | translate }}</a>
          </li>
          <!-- <li><a class="open-modal" href="#send-report" *ngIf="appMemory.user?.roles[0]?.id == 1">Report</a></li> -->
          <li
            class="nested"
            *ngIf="appMemory.user?.roles[0]?.id == 1"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: false }"
            (mouseenter)="showChild($event)"
            (mouseleave)="showChild($event)"
            (click)="showNestedOnClick()"
          >
            <a>{{ "TREATS.report" | translate }}</a>
            <div class="list" *ngIf="currentWidth > 767">
              <ul>
                <li>
                  <a
                    class="open-modal"
                    href="#send-report"
                    *ngIf="appMemory.user?.roles[0]?.id == 1"
                    >{{ "REPORT.regulatoryReport" | translate }}</a
                  >
                </li>
                <li
                  [routerLinkActive]="['active']"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <a [routerLink]="['/admin-report']">{{
                    "REPORT.adminReport" | translate
                  }}</a>
                </li>
                <li
                  [routerLinkActive]="['active']"
                  [routerLinkActiveOptions]="{ exact: false }"
                >
                  <a [routerLink]="['/weight-reporting']">{{
                    "REPORT.weightReporting" | translate
                  }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <ng-container *ngIf="this.isIECountry || this.isUSCountry">
              <a [routerLink]="['/rules-book']">{{
                "OTHER.ruleBook" | translate
              }}</a>
            </ng-container>
            <ng-container *ngIf="this.isUkCountry">
              <a href="http://rules.britishhorseracing.com" target="_blank">{{
                "OTHER.ruleBook" | translate
              }}</a>
            </ng-container>
          </li>
          <!-- <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"><a [routerLink]="['/about']">About</a></li> -->
          <li>
            <a href="https://www.equinemedirecord.com/faq/" target="_blank"
              >FAQ</a
            >
          </li>
          <li
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a [routerLink]="['/contact']">{{
              "CONTACTS.contactUs" | translate
            }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="copy hide-mobile">
      <div class="top">© {{ this.appMemory.year }} Equine MediRecord.</div>
      {{ "CONTACTS.allRightReserved" | translate }}
    </div>
  </div>
</nav>
<div
  class="subMenuMobile show-mobile"
  *ngIf="currentWidth < 768"
  [ngClass]="{ show: showSubMenu }"
>
  <p class="title">
    {{ "REPORT.chooseReportType" | translate }}
  </p>
  <div class="links">
    <ul>
      <li>
        <a
          class="open-modal"
          href="#send-report"
          *ngIf="appMemory.user?.roles[0]?.id == 1"
          >{{ "REPORT.regulatoryReport" | translate }}</a
        >
      </li>
      <li
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <a
          [routerLink]="['/admin-report']"
          (click)="showSubMenu = !showSubMenu"
          >{{ "REPORT.adminReport" | translate }}</a
        >
      </li>
      <li
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <a
          [routerLink]="['/weight-reporting']"
          (click)="showSubMenu = !showSubMenu"
          >{{ "REPORT.weightReporting" | translate }}</a
        >
      </li>
    </ul>
  </div>
</div>
