<div class="report">
  <div class="title">
    <span *ngIf="title === 'notes'">{{ 'HORSES.notes' | translate | titlecase }}&nbsp;</span>
    <span *ngIf="title === 'vaccinations'">{{ 'HORSES.vacs' | translate | titlecase }}&nbsp;</span>
    <span *ngIf="title === 'treatments'">{{ 'TREATS.treatments' | translate | titlecase }}&nbsp;</span>
    <span>{{ total ? ('(' + total + ')') : '' }}</span>
  </div>
  <div class="table">
    <div class="header">
      <div class="col" *ngFor="let item of tableCols" [style.flex]="'0 0 ' + (100 / tableCols.length) + '%'"  (click)="sortColumn(item.sort)">
        <span>{{ item.title | translate }}</span>
        <i *ngIf="item && item.sort && item.sort.active" class="fa" [ngClass]="{'fa-caret-up': item.sort.order, 'fa-caret-down': !item.sort.order}" aria-hidden="true"></i>
      </div>
    </div>
    <div class="content" *ngFor="let item of data">
      <div class="col" *ngFor="let col of tableCols" [style.flex]="'0 0 ' + (100 / tableCols.length) + '%'">
        <div *ngIf="col.values.length">
          <div *ngFor="let val of nestedProperties(item, col)" [ngClass]="col.className">
            <span *ngIf="col.type != 'download'">
              {{ (val.preffix || '') | translate }}
              <span *ngIf="col.title === 'HORSES.vaccinationName'">
                <span *ngIf="val.value === 'Equine Influenza'">
                  {{ 'HORSES.equineInfluenza' | translate }}
                </span>
                <span *ngIf="val.value === 'Equine Herpes'">
                  {{ 'HORSES.equineHerpes' | translate}}
                </span>
                <span *ngIf="val.value !== 'Equine Influenza' && val.value !== 'Equine Herpes'" [ngStyle]="{'text-transform': 'capitalize'}">
                  {{ val.value || '&#8213;' }}
                </span>
              </span>
              <span *ngIf="col.title !== 'HORSES.vaccinationName' && col.translate" [ngStyle]="{'text-transform': 'capitalize'}">{{ ('CORE.' + val.value) | translate }}</span>
              <span *ngIf="!col.translate" [ngStyle]="{'text-transform': 'capitalize'}">{{ val.value || '&#8213;' }}</span>
              {{ (val.suffix || '') | translate }}
            </span>
            <div *ngIf="col.type === 'download' && val.value.length">
              <button type="button" *ngFor="let item of val.value" class="attachment" (click)="downloadAttach(item.src, item.description)">
                <i class="fa fa-paperclip" aria-hidden="true"></i>
                {{ item.description }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" (click)="loadMore(title)" [attr.disabled]="total == data.length ? true : null">
      <div class="col">
        <span>{{ 'REPORT.viewMore' | translate}}</span>
      </div>
    </div>
  </div>
</div>
