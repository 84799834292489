<div class="modal" id="treatment">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
        <a
          *ngIf="isTreatmentEditable(currentTreatment)"
          class="btn open-modal hide-mobile"
          href="#edit-treatment"
          (click)="hideModal(); cloneTreatment(currentTreatment)"
        >
          <i class="icon icon-edit dark icon-middle icon-left"></i
          >{{ "CORE.edit" | translate }}</a
        >
      </div>
      <div class="right-part">
        <a
          class="btn open-modal show-mobile"
          href="#edit-treatment"
          (click)="hideModal(); cloneTreatment(currentTreatment)"
          *ngIf="isTreatmentEditable(currentTreatment)"
        >
          <i class="icon icon-edit dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.treatment" | translate }}</div>
    </div>
    <div class="modal-body">
      <div
        class="status-treatment"
        [ngClass]="{
          pending: currentTreatment?.request?.status == 'pending',
          active:
            currentTreatment?.status != 'closed' &&
            (currentTreatment?.request?.status == 'approved' ||
              !currentTreatment?.request)
        }"
      >
        {{
          "TREATS." +
            (currentTreatment?.status == "closed"
              ? "closed"
              : appMemory?.translates[currentTreatment?.request?.status] ||
                "approved") | translate
        }}
      </div>
      <div class="padding-block">
        <form class="form">
          <div class="box-control">
            <div class="name-box">{{ horse?.name }}</div>
          </div>
          <div>
            <div class="box-control input--filled">
              <div class="text-value name input__field">
                {{
                  currentTreatment?.prescriptions[0]?.drugs[0]?.name ||
                    currentTreatment?.drug_name
                }}
              </div>
              <label class="input__label">
                <span>{{ "HORSES.drugName" | translate }}</span>
              </label>
            </div>
            <!-- <div class="box-control input--filled">
              <div class="text-value name input__field">{{currentTreatment?.prescriptions[0]?.drugs[0]?.brand || currentTreatment?.drug_brand }}</div>
              <label class="input__label"><span>Drug brand</span></label>
            </div> -->
          </div>
          <div class="box-control">
            <div class="clearfix double-block">
              <div class="row-half">
                <div class="box-control input--filled">
                  <div class="text-value input__field">
                    {{ currentTreatment?.prescriptions[0]?.quantity }}
                    {{
                      currentTreatment?.prescriptions[0]?.quantity_measure !=
                      "other"
                        ? currentTreatment?.prescriptions[0]?.quantity_measure
                        : currentTreatment?.prescriptions[0]?.other_quantity
                    }}
                  </div>
                  <label class="input__label">
                    <span>{{ "HORSES.dailyQuantity" | translate }}</span>
                  </label>
                </div>
              </div>
              <div class="row-half">
                <div class="box-control input--filled">
                  <div class="text-value input__field">
                    {{
                      "HORSES." +
                        (appMemory?.translates[currentTreatment?.route] ||
                          currentTreatment?.route) | translate
                    }}
                  </div>
                  <label class="input__label">
                    <span>{{ "HORSES.route" | translate }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="this.isUkCountry">
            <div class="box-control input--filled">
              <div class="text-value input__field">
                {{ currentTreatment?.frequency }}
              </div>
              <label class="input__label">
                <span>{{ "HORSES.frequency" | translate }}</span>
              </label>
            </div>
          </ng-container>

          <!-- <div class="box-control input--filled">
            <div class="text-value input__field">Louise Fletcher</div>
            <label class="input__label"><span>Name of person who administered remedy</span></label>
          </div> -->
          <div
            class="box-control input--filled"
            *ngIf="currentTreatment?.reason"
          >
            <div class="text-value input__field">
              {{ currentTreatment?.reason }}
            </div>
            <label class="input__label">
              <span>{{ "HORSES.reason" | translate }}</span>
            </label>
          </div>

          <div
            class="box-control input--filled"
            *ngIf="currentTreatment?.created_at"
          >
            <div class="text-value input__field">
              {{ currentTreatment?.created_at | dateObj | date : "dd MMM y" }}
            </div>
            <label class="input__label">
              <span>{{ "TREATS.creationDate" | translate }}</span>
            </label>
          </div>

          <div
            class="box-control input--filled"
            *ngIf="currentTreatment?.start_date"
          >
            <div class="text-value input__field">
              {{ currentTreatment?.start_date | dateObj | date : "dd MMM y" }}
            </div>
            <label class="input__label">
              <span>{{ "HORSES.startDate" | translate }}</span>
            </label>
          </div>

          <div
            class="box-control input--filled"
            *ngIf="currentTreatment?.withdrawal"
          >
            <div class="text-value input__field">
              {{ currentTreatment?.withdrawal | dateObj | date : "dd MMM y" }}
            </div>
            <label class="input__label">
              <span>{{ "HORSES.endDate" | translate }}</span>
            </label>
          </div>
          <!--           <div class="box-control input--filled" *ngIf="currentTreatment?.detection_time">
  <div class="text-value input__field">{{ currentTreatment?.detection_time + (currentTreatment?.detection_time > 1 ? ' hours' : ' hour') }}</div>
  <label class="input__label"><span>Detection Time</span></label>
</div> -->
          <div
            class="box-control input--filled"
            *ngIf="currentTreatment?.prescriptions[0]?.duration"
          >
            <div class="text-value input__field">
              {{ currentTreatment?.prescriptions[0]?.duration }}
              {{
                "HORSES." +
                  (currentTreatment?.prescriptions[0]?.duration > 1
                    ? "days"
                    : "day") | translate
              }}
            </div>
            <label class="input__label">
              <span>{{ "HORSES.withdrawal" | translate }}</span>
            </label>
          </div>
          <div class="box-control">
            <div
              class="img-load"
              *ngFor="let image of currentTreatment?.prescriptions[0]?.images"
            >
              <a [href]="image.src" target="_blank">
                <img [src]="image.src" alt="" />
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      class="modal-footer"
      *ngIf="
        currentTreatment?.horse?.is_active &&
        currentTreatment?.status != 'closed'
      "
    >
      <div class="clearfix" *ngIf="appMemory.user?.roles[0]?.id === 1">
        <div class="row-half full">
          <a
            class="btn btn-gold btn-full open-modal"
            *ngIf="currentTreatment?.request?.status != 'pending'"
            (click)="
              changeTreatmentStatus(currentTreatment?.id, { status: 'closed' })
            "
          >
            {{ "HORSES.archive" | translate }}
          </a>
        </div>
        <div class="row-half">
          <a
            class="btn btn-red btn-full open-modal"
            href="#treatment-decline"
            (click)="
              treatmentStatus.request_id = currentTreatment?.request?.id;
              hideModal()
            "
            *ngIf="
              currentTreatment?.request &&
              currentTreatment?.request?.status == 'pending'
            "
          >
            {{ "TREATS.reject" | translate }}
          </a>
        </div>
        <div class="row-half">
          <a
            class="btn btn-green btn-full"
            (click)="
              treatmentStatus.request_id = currentTreatment?.request?.id;
              treatmentStatus.status = 'approved';
              proceessTreatment()
            "
            *ngIf="
              currentTreatment?.request &&
              currentTreatment?.request?.status == 'pending'
            "
          >
            {{ "TREATS.approve" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal search-modal" id="modal-search">
  <div class="modal-wrapper no-footer">
    <div class="modal-header">
      <div class="left-part" (click)="search.value = ''; searchDrugs('')">
        <a
          class="btn close-modal open-modal show-mobile"
          [attr.href]="'#edit-treatment'"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="right-part" (click)="search.value = ''; searchDrugs('')">
        <a
          class="btn btn-close close-modal open-modal"
          [attr.href]="'#edit-treatment'"
        >
          <i class="icon icon-close dark icon-middle"></i>
        </a>
      </div>

      <form class="form">
        <div class="box-control-search">
          <input
            class="search-control"
            placeholder="{{ 'CORE.search' | translate }}"
            #search
            (input)="searchDrugs(search.value)"
          />
        </div>
      </form>
    </div>
    <div class="modal-body">
      <div class="search-result">
        <div class="padding-block">
          <ng-template
            ngFor
            let-drug
            [ngForOf]="drugs || appMemory.static?.drugs"
          >
            <div
              class="item-result close-modal open-modal"
              (click)="newTreatment.drug = drug; hideModal(true)"
              [attr.href]="'#edit-treatment'"
            >
              <div class="name">{{ drug?.name }}</div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="edit-treatment">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(updateTreatmentForm)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(updateTreatmentForm)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.editTreat" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #updateTreatmentForm="ngForm"
      novalidate
      (ngSubmit)="
        updateTreatment(
          appMemory.roles[appMemory.user?.roles[0]?.id],
          updateTreatmentForm
        )
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <div *ngIf="newTreatment.administer">
            <div
              class="box-control"
              [ngClass]="{
                'input--filled--model': newTreatment?.administer.contact_name
              }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    updateTreatmentForm.submitted &&
                    !newTreatment?.administer.contact_name
                }"
                name="contact_name"
                [(ngModel)]="newTreatment.administer.contact_name"
                autocomplete="off"
                required
                [readonly]="newTreatment.administer.user_id > 0"
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !updateTreatmentForm.submitted ||
                    (updateTreatmentForm.submitted &&
                      !!newTreatment?.administer?.contact_name)
                  "
                  >{{ "HORSES.administer_name" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted &&
                    !newTreatment?.administer?.contact_name
                  "
                  >{{ "HORSES.administer_name" | translate }}</span
                >
              </label>
            </div>

            <div
              class="box-control"
              [ngClass]="{
                'input--filled--model': newTreatment?.administer?.contact_info
              }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    updateTreatmentForm.submitted &&
                    !newTreatment.administer.contact_info
                }"
                name="contact_info"
                [(ngModel)]="newTreatment.administer.contact_info"
                autocomplete="off"
                required
                [readonly]="newTreatment.administer.user_id > 0"
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !updateTreatmentForm.submitted ||
                    (updateTreatmentForm.submitted &&
                      !!newTreatment?.administer?.contact_info)
                  "
                  >{{ "HORSES.administer_contact_info" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted &&
                    !newTreatment?.administer?.contact_info
                  "
                  >{{ "HORSES.administer_contact_info" | translate }}</span
                >
              </label>
            </div>
          </div>
          <div class="box-control" *ngIf="!newTreatment.drug">
            <a
              class="btn btn-dotted btn-full open-modal"
              [ngClass]="{
                danger: updateTreatmentForm.submitted && !newTreatment.drug
              }"
              href="#modal-search"
              (click)="hideModal(true); filterDrugs()"
            >
              <div class="box-icon box-right">
                <i class="icon icon-plus icon-middle"></i>
              </div>
              <span
                *ngIf="
                  !updateTreatmentForm.submitted ||
                  (updateTreatmentForm.submitted && newTreatment.drug)
                "
                >{{ "HORSES.drugName" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="updateTreatmentForm.submitted && !newTreatment.drug"
                >{{ "HORSES.drugNameRequired" | translate }}</span
              >
            </a>
          </div>
          <div class="box-control" *ngIf="newTreatment.drug?.name">
            <a
              class="btn btn-dotted btn-full open-modal"
              href="#modal-search"
              (click)="hideModal(true)"
            >
              <div class="label">{{ "HORSES.drugName" | translate }}</div>
              <div class="name">{{ newTreatment?.drug?.name }}</div>
            </a>
          </div>
          <div class="box-control" *ngIf="newTreatment.drug?.brand">
            <a class="btn btn-dotted btn-full" style="cursor: default">
              <div class="label">{{ "TREATS.drugBrand" | translate }}</div>
              <div class="name" style="color: #1b1539">
                {{ newTreatment?.drug?.brand }}
              </div>
            </a>
          </div>
          <div
            class="box-control"
            *ngIf="newTreatment?.drug?.id == 0"
            [ngClass]="{ 'input--filled--model': newTreatment?.drug_name }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error: updateTreatmentForm.submitted && !newTreatment.drug_name
              }"
              name="other-drug-name"
              #newOtherDrug="ngModel"
              [(ngModel)]="newTreatment.drug_name"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateTreatmentForm.submitted ||
                  (updateTreatmentForm.submitted && !!newTreatment.drug_name)
                "
                >{{ "TREATS.otherDrugName" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="updateTreatmentForm.submitted && !newTreatment.drug_name"
                >{{ "TREATS.otherDrugName" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
          <!-- <div class="box-control" *ngIf="newTreatment?.drug?.id == 0" [ngClass]="{'input--filled--model' : newTreatment?.drug_brand}">
              <input type="text"
                     class="form-control input__field"
                     [ngClass]="{'error': updateTreatmentForm.submitted && !newTreatment.drug_brand}"
                     name="other-drug-brand"
                     #newOtherBrand="ngModel"
                     [(ngModel)]="newTreatment.drug_brand"
                     autocomplete="off"
                     required>
              <label class="input__label">
                <span *ngIf="!updateTreatmentForm.submitted || (updateTreatmentForm.submitted && newTreatment.drug_brand)">Other drug brand</span>
                <span class="text-danger" *ngIf="updateTreatmentForm.submitted && !newTreatment.drug_brand">Other drug brand {{ 'CORE.isRequired' | translate }}</span>
              </label>
            </div> -->
          <div class="box-control">
            <div class="label">
              <span
                *ngIf="
                  !updateTreatmentForm.submitted ||
                  (updateTreatmentForm.submitted && !!newTreatment.route)
                "
                >{{ "HORSES.route" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="updateTreatmentForm.submitted && !newTreatment.route"
                >{{ "HORSES.routeIsRequired" | translate }}</span
              >
            </div>
            <ul class="nav-type line">
              <li
                class="change-active"
                *ngFor="let routeType of routeTypes"
                [ngClass]="{ active: routeType == newTreatment.route }"
              >
                <a
                  class="btn"
                  [ngClass]="{
                    danger: updateTreatmentForm.submitted && !newTreatment.route
                  }"
                  (click)="newTreatment.route = routeType"
                >
                  <div class="name">
                    {{
                      "HORSES." +
                        (appMemory?.translates[routeType] || routeType)
                        | translate
                    }}
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div
            class="box-control"
            *ngIf="
              newTreatment.route && newTreatment.route.toLowerCase() == 'other'
            "
            [ngClass]="{ 'input--filled--model': newTreatment?.other_route }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateTreatmentForm.submitted && !newTreatment.other_route
              }"
              name="other-route"
              #newOtherRoute="ngModel"
              [(ngModel)]="newTreatment.other_route"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateTreatmentForm.submitted ||
                  (updateTreatmentForm.submitted && newTreatment.other_route)
                "
                >{{ "TREATS.otherRoute" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="
                  updateTreatmentForm.submitted && !newTreatment.other_route
                "
                >{{ "TREATS.otherRoute" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
          <ng-container *ngIf="this.isUkCountry">
            <div
              class="box-control"
              [ngClass]="{ 'input--filled--model': newTreatment?.frequency }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    updateTreatmentForm.submitted && !newTreatment.frequency
                }"
                name="frequency"
                #newFrequency="ngModel"
                [(ngModel)]="newTreatment.frequency"
                autocomplete="off"
                [maxlength]="50"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !updateTreatmentForm.submitted ||
                    (updateTreatmentForm.submitted && newTreatment?.frequency)
                  "
                  >{{ "HORSES.frequency" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted && !newTreatment?.frequency
                  "
                  >{{ "HORSES.frequency_required" | translate }}</span
                >
              </label>
            </div>
          </ng-container>

          <div class="clearfix">
            <div
              class="box-control"
              id="quantity-box"
              [ngClass]="{ 'input--filled--model': newTreatment?.quantity }"
              style="
                width: calc(50% - 10px);
                float: left;
                margin: 0;
                margin-right: 20px;
              "
            >
              <input
                type="text"
                class="form-control input__field only-number-float"
                [ngClass]="{
                  error: updateTreatmentForm.submitted && newQuantity.errors,
                  'only-number-float': newTreatment?.quantity_measure != 'other'
                }"
                name="quantity"
                [attr.maxlength]="calcQuantityMaxLength()"
                #newQuantity="ngModel"
                [(ngModel)]="newTreatment.quantity"
                autocomplete="off"
                required
                (input)="calcMaxLength($event.target.value)"
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !updateTreatmentForm.submitted ||
                    (updateTreatmentForm.submitted && newQuantity.valid)
                  "
                  >{{ "HORSES.dailyQuantity" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted &&
                    newQuantity.errors?.required
                  "
                  >{{ "HORSES.dailyQuanityRequired" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted && newQuantity.errors?.pattern
                  "
                  >{{ "HORSES.dailyQuantity" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted &&
                    newQuantity.errors?.maxlength
                  "
                  >{{ "HORSES.dailyQuantity" | translate
                  }}{{ "CORE.isTooBig" | translate }}</span
                >
              </label>
            </div>
            <div
              class="box-control"
              [ngClass]="{
                'input--filled--model': newTreatment?.quantity_measure
              }"
              style="width: calc(50% - 10px); float: left; margin: 0"
            >
              <ng-select
                [allowClear]="false"
                id="measures-selector"
                class="measures-selector"
                [ngClass]="{
                  danger:
                    updateTreatmentForm.submitted &&
                    !newTreatment.quantity_measure
                }"
                required
                #measuresSelector
                [active]="measuresSelectorModel"
                (selected)="setMeasure($event)"
                [items]="appMemory.measures"
                name="measures"
                placeholder="{{ 'HORSES.quantityMeasure' | translate }}"
              >
              </ng-select>
            </div>
          </div>
          <div
            class="box-control"
            id="other_quantity"
            [ngClass]="{ 'input--filled--model': newTreatment?.other_quantity }"
            *ngIf="newTreatment.quantity_measure == 'other'"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  updateTreatmentForm.submitted && !newTreatment.other_quantity
              }"
              name="other_quantity"
              #newReason="ngModel"
              [(ngModel)]="newTreatment.other_quantity"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateTreatmentForm.submitted ||
                  (updateTreatmentForm.submitted && !!newTreatment.reason)
                "
                >{{ "TREATS.otherQuantityMeasure" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="updateTreatmentForm.submitted && !newTreatment.reason"
                >{{ "TREATS.otherQuantityMeasure" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>

          <!-- <div class="box-control">
              <input type="text" class="form-control input__field">
              <label class="input__label"><span>Name of person who administered remedy</span></label>
            </div> -->
          <div
            class="box-control"
            id="reason-box"
            [ngClass]="{ 'input--filled--model': newTreatment?.reason }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error: updateTreatmentForm.submitted && !newTreatment.reason
              }"
              name="reason"
              #newReason="ngModel"
              [(ngModel)]="newTreatment.reason"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateTreatmentForm.submitted ||
                  (updateTreatmentForm.submitted && !!newTreatment.reason)
                "
                >{{ "HORSES.reason" | translate }}</span
              >
              <span
                class="text-danger"
                *ngIf="updateTreatmentForm.submitted && !newTreatment.reason"
                >{{ "HORSES.reasonIsRequired" | translate }}</span
              >
            </label>
          </div>

          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (updateTreatmentForm.submitted && !newTreatment.start_date) ||
                  serverError?.start_date
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !updateTreatmentForm.submitted ||
                    (updateTreatmentForm.submitted && !!newTreatment.start_date)
                  "
                  >{{ "HORSES.startDate" | translate }}</span
                >
              </label>
              <div class="code relative" ng>
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker5.currentDate_input"
                  autocomplete="off"
                  name="currentDate"
                  (change)="setStartDateInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker5.isActive = !datePicker5.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="text-danger"
                *ngIf="
                  updateTreatmentForm.submitted && !newTreatment.start_date
                "
                >{{ "HORSES.startDateRequired" | translate }}</span
              >
              <span class="text-danger" *ngIf="serverError?.start_date">{{
                serverError.start_date | translate
              }}</span>
            </a>
            <div class="box-control" *ngIf="datePicker5.isActive">
              <datepicker
                class="datepicker"
                name="withdrawal-datepicker"
                [startingDay]="1"
                [onlyCurrentMonth]="true"
                [minDate]="datePicker5.minDate"
                [maxDate]="datePicker5.maxDate"
                [showWeeks]="false"
                [initDate]="datePicker5.currentDate"
                [dateDisabled]="dateDisabled"
                (selectionDone)="
                  setStartDate($event);
                  datePicker5.isActive = !datePicker5.isActive
                "
              >
              </datepicker>
            </div>
          </div>

          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (updateTreatmentForm.submitted && !newTreatment.withdrawal) ||
                  serverError?.withdrawal
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !updateTreatmentForm.submitted ||
                    (updateTreatmentForm.submitted && !!newTreatment.withdrawal)
                  "
                  >{{ "HORSES.endDate" | translate }}</span
                >
              </label>
              <div class="code relative" ng>
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker2.currentDate_input"
                  autocomplete="off"
                  name="withdrawal"
                  (change)="setWithdrawalInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker2.isActive = !datePicker2.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="text-danger"
                *ngIf="
                  updateTreatmentForm.submitted && !newTreatment.withdrawal
                "
                >{{ "HORSES.endDateRequired" | translate }}</span
              >
              <span class="text-danger" *ngIf="serverError?.withdrawal">{{
                serverError.withdrawal | translate
              }}</span>
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker2.isActive">
            <datepicker
              class="datepicker"
              name="withdrawal-datepicker"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker2.minDate"
              [maxDate]="datePicker2.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker2.currentDate"
              [dateDisabled]="dateDisabled"
              (selectionDone)="
                setWithdrawal($event);
                datePicker2.isActive = !datePicker2.isActive
              "
            >
            </datepicker>
          </div>
          <!--
            <div class="box-control" id="detection_time-box" [ngClass]="{'input--filled--model' : newTreatment?.detection_time}">
              <input type="text"
                     class="form-control input__field only-number"
                     [ngClass]="{'error': updateTreatmentForm.submitted && !newTreatment.detection_time}"
                     name="detection-time"
                     maxlength="10"
                     #newDuration="ngModel"
                     [(ngModel)]="newTreatment.detection_time"
                     autocomplete="off"
                     required>
              <label class="input__label">
                <span *ngIf="!updateTreatmentForm.submitted || (updateTreatmentForm.submitted && !!newTreatment.detection_time)">Detection Time (hours)</span>
                <span class="text-danger" *ngIf="updateTreatmentForm.submitted && !newTreatment.detection_time">Detection Time {{ 'CORE.isRequired' | translate }}</span>
              </label>
            </div> -->

          <div
            class="box-control"
            id="duration-box"
            [ngClass]="{ 'input--filled--model': newTreatment?.duration }"
          >
            <input
              type="text"
              class="form-control input__field only-number"
              [ngClass]="{
                error: updateTreatmentForm.submitted && !newTreatment.duration
              }"
              name="duration"
              maxlength="8"
              #newDuration="ngModel"
              [(ngModel)]="newTreatment.duration"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !updateTreatmentForm.submitted ||
                  (updateTreatmentForm.submitted && !!newTreatment.duration)
                "
                >{{ "HORSES.withdrawal" | translate }} ({{
                  "HORSES.days" | translate
                }})</span
              >
              <span
                class="text-danger"
                *ngIf="updateTreatmentForm.submitted && !newTreatment.duration"
                >{{ "HORSES.withdrawalUsRequired" | translate }}</span
              >
            </label>
          </div>
          <!-- <div class="box-control">
              <div class="border-control">
                <div class="box-inputfile">
                  <input type="file" name="file-7[]" id="file-7" class="inputfile inputfile-6" data-multiple-caption="{count} files selected" multiple="">
                  <label for="file-7"><i class="icon icon-file icon-left"></i><span><p class="no-select">Drag or <span class="select">select files</span> for attach</p></span></label>
                </div>
              </div>
            </div> -->

          <div class="box-control">
            <div class="border-control with_description">
              <image-upload
                #imageLoader2
                [max]="maxImgCount - (newTreatment?.srcs?.length || 0)"
                [maxFileSize]="maxImgSize"
                [buttonCaption]="'HORSES.dragSelectPhoto' | translate"
                [extensions]="['jpeg', 'png']"
                (onFileUploadFinish)="
                  setImages(imageLoader2.files); debug(imageLoader2)
                "
                (onRemove)="debug(imageLoader2)"
              >
              </image-upload>

              <div *ngIf="newTreatment?.srcs">
                <ng-template
                  ngFor
                  let-omgImg
                  [ngForOf]="newTreatment?.srcs"
                  let-i="index"
                >
                  <div class="image-container hr-inline-group">
                    <div
                      class="image image-block"
                      [ngStyle]="{
                        'background-image': 'url(' + omgImg?.src + ')'
                      }"
                    >
                      <div
                        class="x-mark"
                        (click)="newTreatment.srcs.splice(i, 1)"
                      >
                        <span class="close"></span>
                      </div>
                      <div
                        class="box-control remove_on_remove_img remove_on_remove_img--text"
                      >
                        {{ omgImg.description }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="clearfix">
          <div
            class="row-half"
            *ngIf="
              appMemory.user?.id ===
              ((currentTreatment
                ? currentTreatment?.request
                : newTreatment?.request) !== null
                ? currentTreatment
                  ? currentTreatment?.request?.user_id
                  : newTreatment?.request?.user_id
                : currentTreatment
                ? currentTreatment?.horse?.instructor_id
                : newTreatment.horse?.instructor_id)
            "
          >
            <a
              class="btn btn-transparent btn-full close-modal open-modal"
              href="#remove-treatment"
            >
              {{ "CORE.delete" | translate }}
            </a>
          </div>
          <div
            [class.row-half]="
              appMemory.user?.id ===
              ((currentTreatment
                ? currentTreatment?.request
                : newTreatment?.request) !== null
                ? currentTreatment
                  ? currentTreatment?.request?.user_id
                  : newTreatment?.request?.user_id
                : currentTreatment
                ? currentTreatment?.horse?.instructor_id
                : newTreatment.horse?.instructor_id)
            "
          >
            <button
              class="btn btn-green btn-full"
              type="submit"
              [disabled]="isRequestInProgress4"
            >
              {{ "CORE.save" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="treatment-decline">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(declineForm, true)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(declineForm, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.declineTreat" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #declineForm="ngForm"
      novalidate
      (ngSubmit)="
        treatmentStatus.status = 'cancelled';
        proceessTreatment(undefined, declineForm)
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="box-control"
            id="comment-box"
            [ngClass]="{ 'input--filled--model': treatmentStatus?.comment }"
          >
            <textarea
              class="form-control input__field"
              name="comment"
              [ngClass]="{
                error:
                  declineForm.submitted &&
                  (declineComment.errors?.required ||
                    declineComment.errors?.pattern)
              }"
              #declineComment="ngModel"
              [(ngModel)]="treatmentStatus.comment"
              required
            >
            </textarea>
            <label class="input__label">
              <span
                *ngIf="
                  !declineForm.submitted ||
                  (declineForm.submitted && declineComment.valid)
                "
                >{{ "CORE.comment" | translate }}</span
              >
              <span
                *ngIf="declineForm.submitted && declineComment.errors?.required"
                >{{ "CORE.comment" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            (declineForm.submitted && declineComment.errors) ||
            isRequestInProgress
          "
        >
          {{ "CORE.decline" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="send-report">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile" (click)="clearUserInput()">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(declineForm, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.sendReport" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #reportForm="ngForm"
      novalidate
      (ngSubmit)="sendReport()"
    >
      <div class="modal-body">
        <div class="padding-block">
          <!-- from -->
          <div class="box-control input--filled reports_switcher">
            <input
              type="radio"
              id="reports_switcher_1"
              name="reports_switcher"
              [checked]="true"
              (change)="changeSwitcher(false)"
            />
            <label for="reports_switcher_1">
              <span [ngClass]="{ active: !reportDate.switcher }">{{
                "TREATS.byPeriod" | translate
              }}</span>
            </label>
            <input
              type="radio"
              id="reports_switcher_2"
              name="reports_switcher"
              [checked]="false"
              (change)="changeSwitcher(true)"
            />
            <label for="reports_switcher_2">
              <span [ngClass]="{ active: reportDate.switcher }">{{
                "TREATS.byDate" | translate
              }}</span>
            </label>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (updateTreatmentForm.submitted && !newTreatment.withdrawal) ||
                  serverError?.from
              }"
              style="user-select: none"
            >
              <label class="label">
                <span>{{
                  (!reportDate.switcher ? "TREATS.fromDate" : "CORE.selectDate")
                    | translate
                }}</span>
                <span
                  class="text-danger"
                  *ngIf="
                    updateTreatmentForm.submitted && !newTreatment.withdrawal
                  "
                  >{{ "TREATS.fromDate" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
              </label>
              <div class="code relative" ng>
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker3.currentDate_input"
                  autocomplete="off"
                  name="from"
                  (change)="setFromDateInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker3.isActive = !datePicker3.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span *ngIf="serverError?.from">{{
                serverError.from | translate
              }}</span>
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker3.isActive">
            <datepicker
              class="datepicker"
              name="withdrawal-datepicker-3"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker3.minDate"
              [maxDate]="datePicker3.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker3.currentDate"
              [dateDisabled]="dateDisabled"
              (selectionDone)="
                setDate($event, 1); datePicker3.isActive = !datePicker3.isActive
              "
            >
            </datepicker>
          </div>
          <!-- to -->
          <div class="box-control input--filled" *ngIf="!reportDate.switcher">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger:
                  (updateTreatmentForm.submitted && !newTreatment.withdrawal) ||
                  serverError?.to
              }"
              style="user-select: none"
            >
              <label class="label">
                <span
                  *ngIf="
                    !updateTreatmentForm.submitted ||
                    (updateTreatmentForm.submitted && !!newTreatment.withdrawal)
                  "
                  >{{ "TREATS.toDate" | translate }}</span
                >
              </label>
              <div class="code relative" ng>
                <input
                  type="text"
                  [textMask]="datePickerMask"
                  placeholder="{{ 'CORE.selectDate' | translate }}"
                  class="form-control input__field"
                  [(ngModel)]="datePicker4.currentDate_input"
                  autocomplete="off"
                  name="to"
                  (change)="setToDateInput()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker4.isActive = !datePicker4.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span *ngIf="serverError?.to">{{
                serverError.to | translate
              }}</span>
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker4.isActive">
            <datepicker
              class="datepicker"
              name="withdrawal-datepicker-4"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker4.minDate"
              [maxDate]="datePicker4.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker4.currentDate"
              [dateDisabled]="dateDisabled"
              (selectionDone)="
                setDate($event, 2); datePicker4.isActive = !datePicker4.isActive
              "
            >
            </datepicker>
          </div>
          <!--<div *ngIf="serverError" style="margin-bottom: 30px">
            <div *ngFor="let err of serverError">
              <div class="box-btn text-center text-danger">{{err}}</div>
            </div>
          </div>-->
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            !reportDate.from ||
            isRequestInProgress5 ||
            (!reportDate.switcher && !reportDate.to)
          "
        >
          {{ "TREATS.sendReport" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="remove-treatment">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(deleteTreatmentForm, true)"
    >
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(deleteTreatmentForm, true)"
        >
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.deleteTreat" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #deleteTreatmentForm="ngForm"
      novalidate
      (ngSubmit)="deleteTreatment(deleteTreatmentForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="text-center"
            style="font-size: 21px; margin: 45px 0 60px 0"
          >
            {{ "CORE.areYouSureToDeleteTreat" | translate }}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row-half">
          <button class="btn btn-red btn-full" type="submit">
            {{ "CORE.delete" | translate }}
          </button>
        </div>
        <div class="row-half">
          <button class="btn btn-green btn-full close-modal" type="button">
            {{ "CORE.cancel" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container">
  <div class="hide-mobile">
    <div class="name-page">
      <span class="name">{{ "TREATS.treatments" | translate }}</span>
      <a
        class="btn btn-border btn-middle open-modal"
        *ngIf="appMemory.user?.roles[0]?.id == 1"
        href="#send-report"
        >{{ "TREATS.sendReport" | translate }}</a
      >
    </div>
  </div>
  <div class="padding-block">
    <app-loader *ngIf="isRequestInProgress"></app-loader>
    <div
      class="no-data"
      *ngIf="
        !isRequestInProgress &&
        treatmentsByDate?.length == 0 &&
        treatmentsByDateArchive?.length == 0
      "
    >
      {{ "TREATS.noTreats" | translate }}
    </div>
    <div
      class="table-treatment"
      *ngIf="
        !isRequestInProgress &&
        (treatmentsByDate?.length > 0 || treatmentsByDateArchive?.length > 0)
      "
    >
      <div class="table-header hide-mobile">
        <div class="box-table">
          <div class="item-cell cell-time">
            {{ "HORSES.startDate" | translate }}
          </div>
          <div class="item-cell cell-time">
            {{ "HORSES.endDate" | translate }}
          </div>
          <div class="item-cell cell-status text-center">
            {{ "CORE.status" | translate }}
          </div>
          <div class="item-cell cell-treatment">
            {{ "TREATS.treatment" | translate }}
          </div>
          <div class="item-cell cell-person text-center">
            {{ "HORSES.person" | translate }}
          </div>
          <div class="item-cell cell-person text-center">
            {{ "HORSES.administer_cell" | translate }}
          </div>
          <div class="item-cell cell-duration text-center">
            {{ "HORSES.withdrawal" | translate }}
          </div>
          <div class="item-cell cell-action">
            {{ "HORSES.action" | translate }}
          </div>
        </div>
      </div>
      <div class="table-body">
        <ng-template ngFor let-key [ngForOf]="treatmentsByDate" let-i="index">
          <div class="item-treatment">
            <div class="item-cell cell-date show-mobile">
              <div class="date">
                <div class="day">{{ key?.date | date : "dd" }}</div>
                {{ key?.date | dateObj | date : "MMM y" }}
              </div>
            </div>
            <div class="item-cell cell-event">
              <ng-template ngFor let-treatment [ngForOf]="key.data">
                <div class="line-even">
                  <div class="item-cell cell-time hide-mobile">
                    {{
                      treatment?.start_date || treatment?.created_at
                        | dateObj
                        | date : "dd MMM y"
                    }}
                  </div>
                  <div class="item-cell cell-time hide-mobile">
                    {{ treatment?.withdrawal | dateObj | date : "dd MMM y" }}
                  </div>
                  <div class="item-cell cell-status text-center hide-mobile">
                    <div
                      class="status"
                      [ngClass]="{
                        green:
                          treatment?.request?.status == 'approved' ||
                          !treatment?.request,
                        yellow: treatment?.request?.status == 'pending',
                        red:
                          treatment?.request?.status == 'cancelled' ||
                          treatment?.request?.status == 'canceled'
                      }"
                    >
                      {{
                        "TREATS." +
                          (appMemory?.translates[treatment?.request?.status] ||
                            "approved") | translate
                      }}
                    </div>
                  </div>
                  <div class="item-cell cell-treatment">
                    <a
                      class="open-modal"
                      href="#treatment"
                      (click)="
                        currentTreatment = treatment; debug(currentTreatment)
                      "
                    >
                      <div class="name-horse">{{ treatment?.horse?.name }}</div>
                      <div class="name">
                        {{
                          treatment?.prescriptions[0]?.drugs[0]?.name ||
                            treatment?.drug_name
                        }}
                        <div
                          class="status show-mobile"
                          [ngClass]="{
                            green:
                              treatment?.request?.status == 'approved' ||
                              !treatment?.request,
                            yellow: treatment?.request?.status == 'pending',
                            red:
                              treatment?.request?.status == 'cancelled' ||
                              treatment?.request?.status == 'canceled'
                          }"
                        >
                          {{
                            "TREATS." +
                              (appMemory?.translates[
                                treatment?.request?.status
                              ] || "approved") | translate
                          }}
                        </div>
                      </div>
                      <div class="text">
                        {{ "HORSES.reason" | translate }}:
                        {{ treatment?.reason }}
                      </div>
                    </a>

                    <div
                      class="file hide-mobile"
                      *ngIf="treatment?.prescriptions[0]?.images?.length > 0"
                    >
                      <i class="icon icon-file icon-left icon-middle"></i
                      >{{ treatment?.prescriptions[0]?.images.length }}
                    </div>
                  </div>
                  <div class="item-cell cell-person text-center hide-mobile">
                    {{
                      treatment?.request
                        ? treatment?.request?.user?.name +
                          " " +
                          treatment?.request?.user?.last_name
                        : appMemory.user?.roles[0]?.id == 1
                        ? appMemory.user?.name + " " + appMemory.user?.last_name
                        : treatment?.horse?.instructor?.name +
                          " " +
                          treatment?.horse?.instructor?.last_name
                    }}
                    <i
                      class="icon icon-right"
                      [ngClass]="{
                        'icon-trainer': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 1
                          : true,
                        'icon-assistant': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 2
                          : false,
                        'icon-vet': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 3
                          : false
                      }"
                    >
                    </i>
                  </div>
                  <div class="item-cell cell-duration text-center hide-mobile">
                    <span>{{ treatment?.administer?.contact_name }}</span>
                  </div>
                  <div class="item-cell cell-duration text-center hide-mobile">
                    <span *ngIf="treatment?.prescriptions[0]?.duration"
                      >{{ treatment?.prescriptions[0]?.duration }}
                      {{
                        "HORSES." +
                          (treatment?.prescriptions[0]?.duration > 1
                            ? "days"
                            : "day") | translate
                      }}</span
                    >
                  </div>
                  <div class="item-cell cell-action hide-mobile">
                    &nbsp;
                    <a
                      class="btn btn-circle approve"
                      *ngIf="
                        treatment?.horse?.is_active &&
                        appMemory.user?.roles[0]?.id === 1 &&
                        treatment?.request &&
                        treatment?.request?.status == 'pending'
                      "
                      (click)="
                        treatmentStatus.request_id = treatment?.request?.id;
                        treatmentStatus.status = 'approved';
                        proceessTreatment()
                      "
                    >
                      <i class="icon icon-check icon-middle"></i>
                    </a>
                    <a
                      class="btn btn-circle remove open-modal"
                      (click)="
                        treatmentStatus.request_id = treatment?.request?.id
                      "
                      href="#treatment-decline"
                      *ngIf="
                        treatment?.horse?.is_active &&
                        appMemory.user?.roles[0]?.id === 1 &&
                        treatment?.request &&
                        treatment?.request?.status == 'pending'
                      "
                    >
                      <i class="icon icon-close icon-middle"></i>
                    </a>
                    <a
                      class="btn btn-circle edit open-modal"
                      href="#edit-treatment"
                      (click)="cloneTreatment(treatment)"
                      *ngIf="isTreatmentEditable(treatment)"
                    >
                      <i class="icon icon-edit dark icon-middle"></i>
                    </a>
                    <a
                      class="btn btn-circle archive"
                      (click)="
                        changeTreatmentStatus(treatment?.id, {
                          status: 'closed'
                        })
                      "
                      *ngIf="
                        treatment?.horse?.is_active &&
                        appMemory.user?.roles[0]?.id === 1 &&
                        treatment?.request?.status !== 'pending'
                      "
                    >
                      <i class="icon icon-ban icon-middle"></i>
                    </a>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>

        <ng-template ngFor let-key [ngForOf]="treatmentsByDateArchive">
          <div class="item-treatment">
            <div class="item-cell cell-date show-mobile">
              <div class="date">
                <div class="day">{{ key?.date | date : "dd" }}</div>
                {{ key?.date | date : "MMM y" }}
              </div>
            </div>
            <div class="item-cell cell-event">
              <ng-template ngFor let-treatment [ngForOf]="key.data">
                <div class="line-even">
                  <div class="item-cell cell-time hide-mobile">
                    {{
                      treatment?.start_date || treatment?.created_at
                        | dateObj
                        | date : "dd MMM y"
                    }}
                  </div>
                  <div class="item-cell cell-time hide-mobile">
                    {{ treatment?.withdrawal | date : "dd MMM y" }}
                  </div>
                  <div class="item-cell cell-status text-center hide-mobile">
                    <div class="status grey">
                      {{ "TREATS.closed" | translate }}
                    </div>
                  </div>
                  <div class="item-cell cell-treatment">
                    <a
                      class="open-modal"
                      href="#treatment"
                      (click)="
                        currentTreatment = treatment; debug(currentTreatment)
                      "
                    >
                      <div class="name-horse">{{ treatment?.horse?.name }}</div>
                      <div class="name">
                        {{
                          treatment?.prescriptions[0]?.drugs[0]?.name ||
                            treatment?.drug_name
                        }}
                        <div class="status show-mobile grey">
                          {{ "TREATS.closed" | translate }}
                        </div>
                      </div>
                      <div class="text">
                        {{ "HORSES.reason" | translate }}:
                        {{ treatment?.reason }}
                      </div>
                    </a>

                    <div
                      class="file hide-mobile"
                      *ngIf="treatment?.prescriptions[0]?.images?.length > 0"
                    >
                      <i class="icon icon-file icon-left icon-middle"></i
                      >{{ treatment?.prescriptions[0]?.images.length }}
                    </div>
                  </div>
                  <div class="item-cell cell-person text-center hide-mobile">
                    {{
                      treatment?.request
                        ? treatment?.request?.user?.name +
                          " " +
                          treatment?.request?.user?.last_name
                        : appMemory.user?.roles[0]?.id == 1
                        ? appMemory.user?.name + " " + appMemory.user?.last_name
                        : treatment?.horse?.instructor?.name +
                          " " +
                          treatment?.horse?.instructor?.last_name
                    }}
                    <i
                      class="icon icon-right"
                      [ngClass]="{
                        'icon-trainer': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 1
                          : true,
                        'icon-assistant': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 2
                          : false,
                        'icon-vet': treatment?.request?.user
                          ? treatment?.request?.user?.roles[0]?.id == 3
                          : false
                      }"
                    >
                    </i>
                  </div>
                  <div class="item-cell cell-duration text-center hide-mobile">
                    <span>{{ treatment?.administer?.contact_name }}</span>
                  </div>
                  <div class="item-cell cell-duration text-center hide-mobile">
                    <span *ngIf="treatment?.prescriptions[0]?.duration"
                      >{{ treatment?.prescriptions[0]?.duration }}
                      {{
                        "HORSES." +
                          (treatment?.prescriptions[0]?.duration > 1
                            ? "days"
                            : "day") | translate
                      }}</span
                    >
                  </div>

                  <div class="item-cell cell-action hide-mobile">
                    &nbsp;
                    <!-- <a class="btn btn-circle approve" *ngIf="treatment?.request && treatment?.request?.status == 'cancelled' || treatment?.request?.status == 'canceled'"><i class="icon icon-check icon-middle"></i></a>
                    <a class="btn btn-circle remove open-modal" (click)="treatmentStatus.request_id = treatment?.request?.id" href="#treatment-decline" *ngIf="treatment?.request && treatment?.request?.status == 'approved'"><i class="icon icon-close icon-middle"></i></a>
                    <a class="btn btn-circle edit open-modal" href="#edit-treatment"><i class="icon icon-edit dark icon-middle"></i></a> -->
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="text-center">
      <app-loader *ngIf="pagination.inProgress"></app-loader>
      <button
        class="btn lazy-btn"
        (click)="addNewData('')"
        *ngIf="!pagination.inProgress && !!pagination.nextPage"
      >
        {{ "CORE.showMore" | translate }}
      </button>
    </div>
  </div>
  <div
    class="footer-button show-mobile"
    *ngIf="appMemory.user?.roles[0]?.id == 1"
  >
    <a
      class="btn btn-green btn-full open-modal"
      href="#send-report"
      (click)="initFarms()"
      >{{ "TREATS.sendReport" | translate }}</a
    >
  </div>
</div>
