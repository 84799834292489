<div
  *ngFor="let field of noteFields; let _index = index"
  class="box-control clearfix"
  [ngClass]="{ 'input--filled--model': newNote[field.name] }"
>
  <ng-container *ngIf="_index == 0">
    <div>
      <b
        >1. Direction to Disclose Pre-existing Conditions or Poor Quality of
        Movement</b
      >
    </div>
    <br />
  </ng-container>

  <ng-container *ngIf="_index == 5">
    <div>
      <b>2. Veterinary History Declaration</b>
    </div>
    <br /><br />
    <div>
      <p>The Veterinary history declaration must:</p>
      <br />
      <ul class="field_info">
        <li>
          Disclose all relevant veterinary history of the horse; including but
          not limited to an unusual gait, asymmetrical or poor quality of
          movement, tendon injuries, chronic low-grade injury, any other
          physical condition that may assist a RV veterinarian examining the
          horse.
        </li>
        <li>
          Provide details of previous surgeries performed including but not
          limited to orthopaedic fracture repair and upper respiratory tract
          surgeries.
        </li>
      </ul>
    </div>
    <br />
  </ng-container>

  <div *ngIf="field.type === 'checkbox'">
    <div class="toggle__text__inline" [innerHTML]="field.label"></div>
    <div>
      <input
        type="checkbox"
        id="{{ field.uuid }}"
        name="{{ field.name }}"
        [checked]="!!newNote[field.name]"
        (change)="updateTextFieldValue(newNote, field)"
      />
      <div class="toggle">
        <label for="{{ field.uuid }}">
          <i></i>
        </label>
      </div>
      <span class="toggle__label">
        <span *ngIf="!!newNote[field.name]">{{ yesLabel }}</span>
        <span *ngIf="!newNote[field.name]">{{ noLabel }}</span>
      </span>
    </div>
  </div>

  <ng-container *ngIf="!!newNote[field.name] && _index != 4 && _index != 9">
    <div
      class="box-control clearfix"
      [ngClass]="{
        'input--filled--model': newNote[field.name]
      }"
    >
      <input
        type="text"
        class="form-control input__field"
        style="display: block"
        name="{{ getToggleInputName(field) }}"
        autocomplete="off"
        spellcheck="false"
        [(ngModel)]="newNote[getToggleInputName(field)]"
        value="{{ newNote[getToggleInputName(field)] }}"
        required
        #itemName="ngModel"
        [ngClass]="{
          error: noteForm.submitted && itemName.errors?.required
        }"
      />

      <label class="input__label">
        <span>Please provide details</span>
      </label>
    </div>
  </ng-container>

  <ng-container *ngIf="_index == 4 || _index == 9">
    <div class="box-control clearfix">
      <label>
        <span>{{ field.label }}</span>
      </label>

      <textarea
        min="3"
        cols="50"
        class="form-control input__field"
        style="display: block"
        id="{{ field.uuid }}"
        name="{{ field.name }}"
        autocomplete="off"
        spellcheck="false"
        [(ngModel)]="newNote[field.name]"
        value="{{ newNote[field.name] }}"
      ></textarea>
    </div>
  </ng-container>
</div>
