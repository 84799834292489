<div class="search-line hide-mobile">
  <div class="clearfix">
    <div class="box-nav">
      <a class="btn" (click)="logout()"><i class="icon icon-middle icon-logout"></i></a>
      <a class="btn btn-toggle btn-toggle-activity" data-toggle="#activity" (click)="resetActivity()">
          <i class="icon icon-middle icon-bell hide-open"><span [ngClass]="{active: appMemory?.pushInfo?.pushNew.length > 0}"><span *ngIf="appMemory?.pushInfo?.pushNew.length > 0">{{appMemory?.pushInfo?.pushNew.length}}</span></span></i>
          <i class="icon icon-middle icon-close white show-open"></i>
      </a>
    </div>
    <app-search-bar *ngIf="this.router.url.split('?')[0] === '/horses'"></app-search-bar>
  </div>
</div>
