<div
  *ngFor="let field of noteFields; let _index = index"
  class="box-control clearfix"
  [ngClass]="{ 'input--filled--model': newNote[field.name] }"
>
  <ng-container *ngIf="field.id == 'hk_examination_left_fore'">
    <b>Examination at the trot in hand:</b>
    <br />
    <br />
  </ng-container>
  <div *ngIf="field.type === 'checkbox'">
    <div class="toggle__text__inline" [innerHTML]="field.label"></div>
    <div>
      <input
        type="checkbox"
        id="{{ field.uuid }}"
        name="{{ field.name }}"
        [checked]="!!newNote[field.name]"
        (change)="updateCheckboxFieldValue(newNote, field)"
      />
      <div class="toggle">
        <label for="{{ field.uuid }}">
          <i></i>
        </label>
      </div>

      <span class="toggle__label">
        <span *ngIf="!!newNote[field.name]">{{
          isAbnormalOrNormalTypes(field.id) ? "Abnormal" : "Yes"
        }}</span>
        <span *ngIf="!newNote[field.name]">{{
          isAbnormalOrNormalTypes(field.id) ? "Normal" : "No"
        }}</span>
      </span>
      <ng-template #normalToggleLabel>
        <span class="toggle__label">
          <span *ngIf="!!newNote[field.name]">Yes</span>
          <span *ngIf="!newNote[field.name]">No</span>
        </span>
      </ng-template>
    </div>
  </div>

  <ng-container
    *ngIf="
      (newNote[field.name] == true || newNote[field.name] === 'true') &&
      isAbnormalOrNormalTypes(field.id)
    "
  >
    <div
      class="box-control clearfix"
      [ngClass]="{
        'input--filled--model': newNote[field.name]
      }"
    >
      <textarea
        name="{{ getToggleInputName(field) }}"
        class="form-control input__field"
        style="display: block"
        autocomplete="off"
        spellcheck="false"
        rows="2"
        [(ngModel)]="newNote[getToggleInputName(field)]"
        value="{{ newNote[getToggleInputName(field)] }}"
        required
        #itemName="ngModel"
        [ngClass]="{
          error: noteForm && noteForm.submitted && itemName.errors?.required
        }"
      ></textarea>

      <label class="input__label">
        <span>Please provide details</span>
      </label>
    </div>
  </ng-container>

  <div *ngIf="field.type !== 'checkbox'">
    <ng-container *ngIf="field?.id == 'signature_for_statement'">
      <div style="margin-bottom: 20px">
        <h6>
          <b>Signature for above statement</b>
        </h6>
      </div>
    </ng-container>

    <input
      type="{{ field.type }}"
      class="form-control input__field"
      style="display: block"
      name="{{ field?.name }}"
      autocomplete="off"
      spellcheck="false"
      [(ngModel)]="newNote[field.name]"
      value="{{ newNote[field.name] }}"
      [checked]="field.type === 'checkbox' && !!newNote[field.name]"
    />

    <label
      class="input__label"
      [ngClass]="{
        input_label_filled: field?.id == 'signature_for_statement'
      }"
    >
      <span *ngIf="field.label">{{ field.label || "" | translate }}</span>
    </label>
  </div>
</div>
