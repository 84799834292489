<div class="container">
  <div class="hide-mobile" *ngIf="!isRequestInProgress">
    <div class="name-page">
      <span class="name">{{ "PROFILE.informations" | translate }}</span>
    </div>
  </div>
  <tabset class="nav-tabs nav-left" *ngIf="!isRequestInProgress">
    <tab
      heading="{{ 'PROFILE.profile' | translate }}"
      [active]="false"
      (select)="this.goToProfile()"
      (deselect)="(true)"
    ></tab>
    <tab
      heading="{{ 'INVOICES.invoices' | translate }}"
      [active]="false"
      (select)="this.goToInvoices()"
      (deselect)="(true)"
    ></tab>
    <tab
      heading="{{ 'PROFILE.informations' | translate }}"
      [active]="true"
      (select)="(true)"
      (deselect)="(false)"
    ></tab>
  </tabset>

  <div class="padding-block">
    <div class="form form-left">
      <form
        class="form"
        #changeInfoform="ngForm"
        novalidate
        (ngSubmit)="changeInfo(changeInfoform)"
        autocomplete="off"
      >
        <div class="modal-body">
          <div class="padding-block">
            <div
              class="box-control"
              id="last-name-code-box"
              [ngClass]="{ 'input--filled--model': vat }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    changeInfoform.submitted &&
                    (VATInput.errors?.required || VATInput.errors?.pattern)
                }"
                name="VAT"
                #VATInput="ngModel"
                [(ngModel)]="vat"
                maxlength="17"
                spellcheck="false"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !changeInfoform.submitted ||
                    (changeInfoform.submitted && VATInput.valid)
                  "
                  >{{ "CORE.vat" | translate }}</span
                >
                <span
                  *ngIf="changeInfoform.submitted && VATInput.errors?.required"
                  >{{ "CORE.vat" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="changeInfoform.submitted && VATInput.errors?.pattern"
                  >{{ "CORE.vat" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
              </label>
            </div>

            <div
              class="box-control"
              id="last-name-code-box"
              [ngClass]="{ 'input--filled--model': vat }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    changeInfoform.submitted &&
                    (address1nput.errors?.required ||
                      address1nput.errors?.pattern)
                }"
                name="address1"
                #address1nput="ngModel"
                [(ngModel)]="address1"
                maxlength="200"
                spellcheck="false"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !changeInfoform.submitted ||
                    (changeInfoform.submitted && address1nput.valid)
                  "
                  >{{ "CORE.address1" | translate }}</span
                >
                <span
                  *ngIf="
                    changeInfoform.submitted && address1nput.errors?.required
                  "
                  >{{ "CORE.address1" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="
                    changeInfoform.submitted && address1nput.errors?.pattern
                  "
                  >{{ "CORE.address1" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
              </label>
            </div>
            <div
              class="box-control"
              id="last-name-code-box"
              [ngClass]="{ 'input--filled--model': vat }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    changeInfoform.submitted &&
                    (cityInput.errors?.required || cityInput.errors?.pattern)
                }"
                name="city"
                #cityInput="ngModel"
                [(ngModel)]="city"
                maxlength="200"
                spellcheck="false"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !changeInfoform.submitted ||
                    (changeInfoform.submitted && cityInput.valid)
                  "
                  >{{ "CORE.city" | translate }}</span
                >
                <span
                  *ngIf="changeInfoform.submitted && cityInput.errors?.required"
                  >{{ "CORE.city" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="changeInfoform.submitted && cityInput.errors?.pattern"
                  >{{ "CORE.city" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
              </label>
            </div>
            <div
              class="box-control"
              id="last-name-code-box"
              [ngClass]="{ 'input--filled--model': vat }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    changeInfoform.submitted &&
                    (stateInput.errors?.required || stateInput.errors?.pattern)
                }"
                name="state"
                #stateInput="ngModel"
                [(ngModel)]="state"
                maxlength="200"
                spellcheck="false"
                autocomplete="off"
                required
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !changeInfoform.submitted ||
                    (changeInfoform.submitted && stateInput.valid)
                  "
                  >{{ "AUTH.state" | translate }}</span
                >
                <span
                  *ngIf="
                    changeInfoform.submitted && stateInput.errors?.required
                  "
                  >{{ "AUTH.state" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="changeInfoform.submitted && stateInput.errors?.pattern"
                  >{{ "AUTH.state" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
              </label>
            </div>
            <div class="box-control" *ngIf="countries">
              <ng-select
                [allowClear]="false"
                id="country-selector"
                class="country-selector"
                [(ngModel)]="country"
                required
                #countrySelector
                [items]="countries"
                name="country"
                placeholder="Country"
              >
              </ng-select>
            </div>
            <div *ngIf="serverError" style="margin-bottom: 30px">
              <div *ngFor="let err of serverError">
                <div class="box-btn text-center text-danger">{{ err }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-green btn-full"
            type="submit"
            [disabled]="
              (changeInfoform.submitted && changeInfoform.invalid) ||
              isRequestInProgress
            "
          >
            {{ "CORE.save" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
