<div class="wrapper">
  <div class="main">
    <nav class="nav-tabs">
      <ul>
        <li class="cancel"><a [routerLink]="['/login']"><i class="icon icon-close icon-middle icon-left"></i>{{'CORE.cancel' | translate}}</a></li>
        <li class="language-switcher-login" *ngIf="languages.length">
          <ng-container *ngFor="let language of languages">
            <a *ngIf="translate.currentLang !== language"
               (click)="setLanguage(language)">{{ 'CORE.'+language | translate}}</a>
          </ng-container>
        </li>
      </ul>
    </nav>

    <div class="padding-block padding-bottom-100-px">
      <div class="form-center bg-intro">
        <div class="name-page text-center">{{'AUTH.resetPassword' | translate}}</div>
        <form class="form"
              #forgotForm="ngForm"
              novalidate
              (ngSubmit)="sendForgot(forgotForm)">
          <div class="form-group">
            <div class="box-text text-center">{{'AUTH.pleaseEnterCorrectEmail' | translate}}</div>
          </div>
          <div class="form-group">
            <div class="box-control" [ngClass]="{'input--filled--model' : forgottenEmail}">
               <input type="email"
                      class="form-control input__field"
                      [ngClass]="{'error': forgotForm.submitted && (forgotEmail.errors?.required || forgotEmail.errors?.pattern)}"
                      name="email"
                      #forgotEmail="ngModel"
                      [(ngModel)]="forgottenEmail"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                      required>
               <label class="input__label">
                 <span *ngIf="!forgotForm.submitted || (forgotForm.submitted && forgotEmail.valid)">{{ 'CORE.emailAddress' | translate }}</span>
                 <span *ngIf="forgotForm.submitted && forgotEmail.errors?.required">{{ 'CORE.emailRequired' | translate }}</span>
                 <span *ngIf="forgotForm.submitted && forgotEmail.errors?.pattern">{{ 'CORE.emailInvalid' | translate }}</span>
               </label>
            </div>
            <div class="box-btn text-center text-success"
                 style="margin-bottom: 30px"
                 *ngIf="isRequestSuccess">
                 {{ 'AUTH.resetLinkSuccessfullySent' | translate}}
            </div>
          </div>
          <ng-container *ngIf="serverError">
            <ng-container *ngFor="let err of serverError">
              <div class="box-btn text-center text-danger">{{err}}</div>
            </ng-container>
          </ng-container>
          <div class="box-btn text-center">
            <button type="submit"
                    class="btn btn-green btn-full"
                    [disabled]="forgotForm.submitted && (forgotEmail.errors || forgotEmail.errors) || isRequestInProgress">
                    {{ 'AUTH.requestResetLink' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
      <app-auth-footer></app-auth-footer>
  </div>
</div>
