<div class="wrapper">
    <div class="main">


        <div class="padding-block locate-page">
            <div class="form-center bg-intro">
                <div class="name-page text-center">{{ 'AUTH.welcome' | translate}}</div>
                <div class="text-center subname-page">{{ 'AUTH.pleaseChooseRegion' | translate }}</div>
                <form class="form"
                      #loginForm="ngForm"
                      novalidate>
                    <div class="box-btn text-center croj">
                        <button *ngFor="let availableCountry of availableCountries"
                                type="submit"
                                class="btn btn-green btn-full"
                                (click)="chooseCountry(availableCountry.country);"
                        >
                            {{ 'CORE.' + availableCountry.country  | translate }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <footer>
            <div class="copy">© {{this.appMemory.year}} Equine
                MediRecord. {{ 'CONTACTS.allRightReserved' | translate}}</div>
        </footer>
    </div>
</div>
