<div class="image-upload"
     fileDrop
     [accept]="supportedExtensions"
     (isFileOver)="fileOver($event)"
     (fileDrop)="fileChange($event)"
     [ngClass]="{'file-is-over': isFileOver}">

  <div class="file-upload hr-inline-group" *ngIf="fileCounter < max">
    <!-- <label class="upload button">
      <span [innerText]="buttonCaption"></span>
      <input
        type="file"
        [accept]="supportedExtensions"
        multiple (change)="fileChange(input.files)"
        #input>
    </label> -->
    <label *ngIf="fileCounter > 0" class="clear button" (click)="deleteAll()" style="display: none">
      <span [innerText]="'Clear'"></span>
    </label>

    <div class="box-inputfile">
      <label style="cursor: pointer">
        <input type="file"
               class="inputfile inputfile-6"
               [accept]="supportedExtensions"
               multiple (change)="fileChange(input.files)"
               #input>
        <i class="icon icon-file icon-left"></i>
        <span>
          <p class="no-select select" [innerText]="buttonCaption"></p>
        </span>
      </label>
    </div>
  </div>

  <p class="file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage">
  </p>

  <div *ngIf="preview" class="image-container hr-inline-group">
    <div
      class="image"
      *ngFor="let file of files"
      [ngStyle]="{'background-image': 'url('+ file.src +')'}"
    >
      <div *ngIf="file.pending" class="loading-overlay">
        <div class="spinningCircle"></div>
      </div>
      <div *ngIf="!file.pending" class="x-mark" (click)="deleteFile(file)">
        <span class="close"></span>
      </div>
    </div>
  </div>
</div>
