<simple-notifications [options]="options"></simple-notifications>
<app-tutorial
  *ngIf="showTutorialService()"
  [show]="showTutorial"
></app-tutorial>
<app-billing></app-billing>
<router-outlet></router-outlet>
<overage-notification
  *ngIf="showOverage()"
  [options]="user"
></overage-notification>
<div *ngIf="showUserNotification()">
  <user-notification
    *ngFor="let user_notification of user_notifications"
    [options]="user_notification"
  ></user-notification>
</div>

<div class="modal" id="send-report">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.sendReport" | translate }}</div>
    </div>
    <form
      class="form modal-form"
      #reportForm="ngForm"
      novalidate
      (ngSubmit)="sendReport()"
    >
      <div class="modal-body">
        <div class="padding-block">
          <!-- from -->
          <div class="box-control input--filled reports_switcher">
            <input
              type="radio"
              id="reports_switcher_1"
              name="reports_switcher"
              [checked]="true"
              (change)="changeSwitcher(false)"
            />
            <label for="reports_switcher_1">
              <span [ngClass]="{ active: !reportDate.switcher }">{{
                "TREATS.byPeriod" | translate
              }}</span>
            </label>
            <input
              type="radio"
              id="reports_switcher_2"
              name="reports_switcher"
              [checked]="false"
              (change)="changeSwitcher(true)"
            />
            <label for="reports_switcher_2">
              <span [ngClass]="{ active: reportDate.switcher }">{{
                "TREATS.byDate" | translate
              }}</span>
            </label>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              [ngClass]="{
                danger: serverError?.from || dateRangeErrorMessage.from
              }"
              style="user-select: none"
            >
              <label class="label">
                <span>{{
                  (!reportDate.switcher ? "TREATS.fromDate" : "CORE.selectDate")
                    | translate
                }}</span>
              </label>
              <div class="code relative">
                <input
                  type="text"
                  placeholder="__/__/____"
                  class="form-control input__field"
                  [(ngModel)]="datePicker3.currentDate_input"
                  autocomplete="off"
                  name="from"
                  (ngModelChange)="
                    setFromDateInput(); checkreportDateValidity()
                  "
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker3.isActive = !datePicker3.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="box-btn text-center text-danger"
                *ngIf="dateRangeErrorMessage.from"
                >{{ dateRangeErrorMessage.from | translate
                }}{{ dateRangeErrorMessage.date }}</span
              >
              <span
                class="box-btn text-center text-danger"
                *ngIf="!dateRangeErrorMessage.from && serverError?.from"
                >{{ serverError.from | translate }}</span
              >
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker3.isActive">
            <datepicker
              class="datepicker"
              name="withdrawal-datepicker-3"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker3.minDate"
              [maxDate]="datePicker3.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker3.currentDate"
              (selectionDone)="setDate($event, 1); datePicker3.isActive = false"
            >
            </datepicker>
          </div>
          <!-- to -->
          <div class="box-control input--filled" *ngIf="!reportDate.switcher">
            <a
              class="btn btn-dotted btn-code btn-full"
              style="user-select: none"
              [ngClass]="{
                danger: serverError?.to || dateRangeErrorMessage.due
              }"
            >
              <label class="label">
                <span>{{ "TREATS.fromDate" | translate }}</span>
              </label>
              <div class="code relative">
                <input
                  type="text"
                  placeholder="__/__/____"
                  class="form-control input__field"
                  [(ngModel)]="datePicker4.currentDate_input"
                  autocomplete="off"
                  name="to"
                  (ngModelChange)="setToDateInput(); checkreportDateValidity()"
                />
                <i
                  class="fa fa-calendar calendar"
                  (click)="datePicker4.isActive = !datePicker4.isActive"
                  aria-hidden="true"
                ></i>
              </div>
              <span
                class="box-btn text-center text-danger"
                *ngIf="dateRangeErrorMessage.due"
                >{{ dateRangeErrorMessage.due | translate
                }}{{ dateRangeErrorMessage.date }}</span
              >
              <span
                class="box-btn text-center text-danger"
                *ngIf="!dateRangeErrorMessage.due && serverError?.to"
                >{{ serverError.to | translate }}</span
              >
            </a>
          </div>
          <div class="box-control" *ngIf="datePicker4.isActive">
            <datepicker
              class="datepicker"
              name="withdrawal-datepicker-4"
              [startingDay]="1"
              [onlyCurrentMonth]="true"
              [minDate]="datePicker4.minDate"
              [maxDate]="datePicker4.maxDate"
              [showWeeks]="false"
              [initDate]="datePicker4.currentDate"
              (selectionDone)="setDate($event, 2); datePicker4.isActive = false"
            >
            </datepicker>
          </div>
          <!--<div *ngIf="serverError" style="margin-bottom: 30px">
            <div *ngFor="let err of serverError">
              <div class="box-btn text-center text-danger">{{err}}</div>
            </div>
          </div>-->
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            !reportDate.from ||
            isRequestInProgress ||
            (serverError && serverError.form) ||
            (!reportDate.switcher && serverError && serverError.to) ||
            (!reportDate.switcher && !reportDate.to)
          "
        >
          {{ "TREATS.sendReport" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="popup-info">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ "TREATS.popupInfo" | translate }}</div>
    </div>
    <div class="modal-body container">
      <pdf-viewer
        [src]="pdfSrc"
        *ngIf="pdfSrc"
        [render-text]="true"
        [original-size]="false"
        [autoresize]="true"
        style="display: block"
      ></pdf-viewer>
    </div>
    <div class="modal-footer">
      <button
        class="btn btn-green btn-full close-modal"
        (click)="isUSCountry ? ifLoged() : null"
      >
        {{ "TOOLTIPS.got_it" | translate }}
      </button>
    </div>
  </div>
</div>

<div *ngIf = "isAUSCountry" class="modal" id="termsAndCondition-info">
  <div class="modal-wrapper">
    <!-- <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a> -->
    <div class="modal-header">
      <!-- <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div> -->
      <div class="title">
        {{ "TOOLTIPS.terms_condition_heading" | translate }}
      </div>
    </div>
    <div class="modal-body container">
      <div style="font-size: x-large">
        Please read these terms and conditions carefully <br />

        <a
          href="https://equinemedirecord.com/emr-terms-conditions/"
          target="_blank"
        >
          {{ "AUTH.agreeWithTermsandConditions" | translate }}
        </a>
      </div>
    </div>
    <div class="modal-footer" (click)="acceptTermsAndConditions()">
      <button class="btn btn-green btn-full close-modal">
        {{ "TOOLTIPS.terms_condition" | translate }}
      </button>
    </div>
  </div>
</div>
