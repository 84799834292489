<div class="block-activity hide-mobile" id="activity">
  <div class="activity-wrapper">
    <ng-template ngFor let-new [ngForOf]="appMemory.pushInfo.pushNew">
      <div class="item-activity active">
        <div class="name"><span class="status" [ngClass]="colors[new?.data?.status] || 'blue'">{{new?.data?.status}} </span>{{new?.subject}}</div>
        <div class="person" *ngIf="new?.data?.name">{{ 'OTHER.by' | translate }} {{new?.data?.name}}</div>
        <div class="text">{{new?.message}}</div>
        <div class="date">{{new?.data?.created_at | date:'yMMMdjms'}}</div>
      </div>
    </ng-template>

    <div class="line-new" *ngIf="appMemory.pushInfo.pushNew.length > 0">
      <div class="name"><span class="caret up"></span>{{ 'OTHER.newActivity' | translate }}<span class="caret up"></span></div>
    </div>

    <div class="line-new" *ngIf="appMemory.pushInfo.pushNew.length == 0 && appMemory.pushInfo.pushOld.length == 0">
      <div class="name" style="padding: 0 10px">{{ 'OTHER.noNewActivity' | translate }}</div>
    </div>

    <ng-template ngFor let-old [ngForOf]="appMemory.pushInfo.pushOld">
      <div class="item-activity">
        <div class="name"><span class="status " [ngClass]="colors[old?.data?.status] || 'blue'">{{old?.data?.status}} </span>{{old?.subject}}</div>
        <div class="person" *ngIf="old?.data?.name">{{ 'OTHER.by' | translate }} {{old?.data?.name}}</div>
        <div class="text">{{old?.message}}</div>
        <div class="date">{{old?.data?.created_at | date:'yMMMdjms'}}</div>
      </div>
    </ng-template>

    <!--
    <div class="item-activity active">
      <div class="name"><span class="status blue">{{ 'HORSES.approved' | translate}}</span> treatment for Han Solo</div>
      <div class="person">by Michael Edmund</div>
      <div class="text">A black hole is a region of spacetime exhibiting such strong gravitational effects that nothing</div>
      <div class="date">12 Sept. 2017 at 11:44:07 AM</div>
    </div>
    <div class="item-activity active">
      <div class="name"><span class="status green">Opened</span> treatment for Han Solo</div>
      <div class="person">by <a class="link link-default">Michael Edmund</a></div>
      <div class="text">A black hole is a region of spacetime exhibiting such strong gravitational effects that nothing</div>
      <div class="date">12 Sept. 2017 at 11:44:07 AM</div>
    </div>
    <div class="item-activity">
      <div class="name"><span class="status red">Canceled</span> treatment for Han Solo</div>
      <div class="person">by Michael Edmund</div>
      <div class="text">A black hole is a region of spacetime exhibiting such strong gravitational effects that nothing</div>
      <div class="date">12 Sept. 2017 at 11:44:07 AM</div>
    </div>
    <div class="item-activity active">
      <div class="name"><span class="status blue">{{ 'HORSES.approved' | translate}}</span> treatment for Han Solo</div>
      <div class="person">by Michael Edmund</div>
      <div class="text">A black hole is a region of spacetime exhibiting such strong gravitational effects that nothing</div>
      <div class="date">12 Sept. 2017 at 11:44:07 AM</div>
    </div>
    <div class="item-activity active">
      <div class="name"><span class="status green">Opened</span> treatment for Han Solo</div>
      <div class="person">by <a class="link link-default">Michael Edmund</a></div>
      <div class="text">A black hole is a region of spacetime exhibiting such strong gravitational effects that nothing</div>
      <div class="date">12 Sept. 2017 at 11:44:07 AM</div>
    </div>
      </div> -->
</div>
