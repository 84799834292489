<div class="wrapper">
  <div class="main">
    <nav class="nav-tabs">
      <ul>
        <li class="active">
          <a [routerLink]="['/login']">{{ "AUTH.login" | translate }}</a>
        </li>
        <li>
          <a [routerLink]="['/sign']">{{ "AUTH.singUp" | translate }}</a>
        </li>
        <li class="language-switcher-login" *ngIf="languages.length">
          <ng-container *ngFor="let language of languages">
            <a
              *ngIf="translate.currentLang !== language"
              (click)="setLanguage(language)"
              >{{ "CORE." + language | translate }}</a
            >
          </ng-container>
        </li>
      </ul>
    </nav>
    <app-auth-header></app-auth-header>
    <div class="padding-block padding-bottom-100-px">
      <div
        class="form-center"
        [ngClass]="isAUSCountry ? 'aus-bg-intro' : 'bg-intro'"
      >
        <div class="name-page text-center" style="font-size: 42px">
          {{ "AUTH.welcome" | translate }}
        </div>
        <ng-container>
          <div class="country-name text-center">
            {{ "AUTH." + currentCountry | translate }}
          </div>
        </ng-container>
        <form
          class="form"
          #loginForm="ngForm"
          novalidate
          (ngSubmit)="sendLogin(loginForm)"
        >
          <div
            class="box-control"
            [ngClass]="{ 'input--filled--model': login?.email }"
          >
            <input
              type="email"
              class="form-control input__field"
              [ngClass]="{
                error:
                  loginForm.submitted &&
                  (loginEmail.errors?.required || loginEmail.errors?.pattern)
              }"
              name="email"
              #loginEmail="ngModel"
              [(ngModel)]="login.email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !loginForm.submitted ||
                  (loginForm.submitted && loginEmail.valid)
                "
                >{{ "CORE.emailAddress" | translate }}</span
              >
              <span
                *ngIf="loginForm.submitted && loginEmail.errors?.required"
                >{{ "CORE.emailRequired" | translate }}</span
              >
              <span *ngIf="loginForm.submitted && loginEmail.errors?.pattern">{{
                "CORE.emailInvalid" | translate
              }}</span>
            </label>
          </div>
          <div
            class="box-control"
            [ngClass]="{ 'input--filled--model': login?.password }"
          >
            <input
              type="password"
              class="form-control input__field"
              [ngClass]="{
                error:
                  loginForm.submitted &&
                  (loginPassword.errors?.required ||
                    loginPassword.errors?.minlength)
              }"
              name="password"
              #loginPassword="ngModel"
              [(ngModel)]="login.password"
              required
              minlength="8"
              maxlength="255"
            />
            <label class="input__label">
              <span
                *ngIf="
                  !loginForm.submitted ||
                  (loginForm.submitted && loginPassword.valid)
                "
                >{{ "AUTH.password" | translate }}</span
              >
              <span
                *ngIf="loginForm.submitted && loginPassword.errors?.required"
                >{{ "AUTH.passwordRequired" | translate }}</span
              >
              <span
                *ngIf="loginForm.submitted && loginPassword.errors?.minlength"
                >{{
                  "AUTH.minPassLength"
                    | translate
                      : {
                          value: loginPassword.errors?.minlength?.requiredLength
                        }
                }}</span
              >
            </label>
          </div>
          <div *ngIf="serverError">
            <div *ngFor="let err of serverError">
              <div class="box-btn text-center text-danger">{{ err }}</div>
            </div>
          </div>
          <div class="box-btn text-center">
            <a class="link" [routerLink]="['/forgot']">{{
              "AUTH.forgotPassword" | translate
            }}</a>
          </div>
          <div *ngIf="isRequestInProgress" class="loader"></div>
          <div class="box-btn text-center">
            <button
              type="submit"
              class="btn btn-green btn-full"
              [disabled]="
                (loginForm.submitted &&
                  (loginEmail.errors || loginPassword.errors)) ||
                isRequestInProgress
              "
            >
              {{ "AUTH.login" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <app-auth-footer></app-auth-footer>
  </div>
</div>

<ng-container *ngIf="this.isUkCountry">
  <div class="modal" id="checkCountry">
    <div class="modal-wrapper" style="overflow: hidden">
      <a class="btn btn-close hide-mobile" (click)="cancelLogin()">
        <i class="icon icon-close"></i>
      </a>
      <div class="modal-header">
        <div class="left-part">
          <a class="btn close-modal show-mobile">
            <i class="icon icon-back dark icon-middle"></i>
          </a>
        </div>
      </div>
      <div class="modal-body">
        <div class="padding-block">
          <h2 class="confirm-message-h2">
            Northern Irish users you should ensure you sign into the Irish
            system as you comply with IHRB regulations. Please note you are
            about to proceed to the UK system.
          </h2>
          <div class="button-set">
            <button
              class="btn btn-green pull-left btn-confirm-login"
              (click)="ifLoged()"
            >
              Ok
            </button>
            <button
              class="btn btn-green pull-right hide-mobile btn-confirm-login"
              (click)="cancelLogin()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="modal" id="blocked-warning">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{ blockErrorMessage.title }}</div>
    </div>

    <div class="modal-body">
      <div class="padding-block">
        <div class="text-center" style="font-size: 21px; margin: 45px 0 60px 0">
          {{ blockErrorMessage.message }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div *ngIf="invoiceLink.length" [ngStyle]="{ 'margin-bottom': '10px' }">
        <a [href]="invoiceLink" target="_blank">
          <div class="btn btn-green btn-full pay-button">
            {{ "BILLING.pay_your_invoice" | translate }}
          </div>
        </a>
      </div>
      <div>
        <button class="btn btn-green btn-full close-modal" type="button">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
