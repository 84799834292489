<div class="modal" id="add-yards">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(createFarmForm)"
      ><i class="icon icon-close"></i
    ></a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a class="btn close-modal show-mobile"
          ><i class="icon icon-back icon-middle"></i
        ></a>
      </div>
      <div class="title">{{ "YARDS.addYards" | translate }}</div>
    </div>

    <form
      class="form modal-form"
      #createFarmForm="ngForm"
      novalidate
      (ngSubmit)="createFarm(createFarmForm)"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="box-control"
            id="farm-name-box"
            [ngClass]="{ 'input--filled--model': newFarm?.name }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createFarmForm.submitted &&
                  (newFarmName.errors?.required ||
                    newFarmName.errors?.maxlength)
              }"
              name="farm_name"
              maxlength="40"
              #newFarmName="ngModel"
              [(ngModel)]="newFarm.name"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createFarmForm.submitted ||
                  (createFarmForm.submitted && newFarmName.valid)
                "
                >{{ "CORE.yardName" | translate }}</span
              ><!--todo-->
              <span
                *ngIf="createFarmForm.submitted && newFarmName.errors?.required"
                >{{ "YARDS.yardNameRequired" | translate }}</span
              >
              <span
                *ngIf="createFarmForm.submitted && newFarmName.errors?.pattern"
                >{{ "CORE.yardName" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmName.errors?.maxlength
                "
                >{{ "CORE.yardName" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-address-first-box"
            [ngClass]="{ 'input--filled--model': newFarm?.address_first }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createFarmForm.submitted &&
                  (newFarmAddressFirst.errors?.required ||
                    newFarmAddressFirst.errors?.maxlength)
              }"
              name="address_first"
              maxlength="40"
              #newFarmAddressFirst="ngModel"
              [(ngModel)]="newFarm.address_first"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createFarmForm.submitted ||
                  (createFarmForm.submitted && newFarmAddressFirst.valid)
                "
                >{{ "CORE.address1" | translate }}</span
              ><!--todo-->
              <span
                *ngIf="
                  createFarmForm.submitted &&
                  newFarmAddressFirst.errors?.required
                "
                >{{ "YARDS.address1Required" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted &&
                  newFarmAddressFirst.errors?.pattern
                "
                >{{ "CORE.address1" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted &&
                  newFarmAddressFirst.errors?.maxlength
                "
                >{{ "CORE.address1" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-address-second-box"
            [ngClass]="{ 'input--filled--model': newFarm?.address_second }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createFarmForm.submitted &&
                  (newFarmAddressSecond.errors?.required ||
                    newFarmAddressSecond.errors?.maxlength)
              }"
              name="address_second"
              maxlength="40"
              #newFarmAddressSecond="ngModel"
              [(ngModel)]="newFarm.address_second"
              autocomplete="off"
              spellcheck="false"
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createFarmForm.submitted ||
                  (createFarmForm.submitted && newFarmAddressSecond.valid)
                "
                >{{ "CORE.address2" | translate }}</span
              ><!--todo-->
              <span
                *ngIf="
                  createFarmForm.submitted &&
                  newFarmAddressSecond.errors?.required
                "
                >{{ "YARDS.address2Required" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted &&
                  newFarmAddressSecond.errors?.pattern
                "
                >{{ "CORE.address2" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted &&
                  newFarmAddressSecond.errors?.maxlength
                "
                >{{ "CORE.address2" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-city-box"
            [ngClass]="{ 'input--filled--model': newFarm?.city }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createFarmForm.submitted &&
                  (newFarmCity.errors?.required ||
                    newFarmCity.errors?.maxlength)
              }"
              name="city"
              maxlength="40"
              #newFarmCity="ngModel"
              [(ngModel)]="newFarm.city"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createFarmForm.submitted ||
                  (createFarmForm.submitted && newFarmCity.valid)
                "
                >{{ "CORE.city" | translate }}</span
              ><!--todo-->
              <span
                *ngIf="createFarmForm.submitted && newFarmCity.errors?.required"
                >{{ "YARDS.cityRequired" | translate }}</span
              >
              <span
                *ngIf="createFarmForm.submitted && newFarmCity.errors?.pattern"
                >{{ "CORE.city" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmCity.errors?.maxlength
                "
                >{{ "CORE.city" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="farm-region-box"
            [ngClass]="{ 'input--filled--model': newFarm?.region }"
            *ngIf="!showRegionsSelect"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createFarmForm.submitted &&
                  (newFarmRegion.errors?.required ||
                    newFarmRegion.errors?.maxlength)
              }"
              name="region"
              maxlength="40"
              #newFarmRegion="ngModel"
              [(ngModel)]="newFarm.region"
              autocomplete="off"
              spellcheck="false"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createFarmForm.submitted ||
                  (createFarmForm.submitted && newFarmRegion.valid)
                "
                >{{ "YARDS.region" | translate }}</span
              ><!--todo-->
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmRegion.errors?.required
                "
                >{{ "YARDS.regionRequired" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmRegion.errors?.pattern
                "
                >{{ "YARDS.region" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmRegion.errors?.maxlength
                "
                >{{ "YARDS.region" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control" *ngIf="showRegionsSelect">
            <label class="label ng-select-label">
              <span>{{ "YARDS.region" | translate }}</span>
            </label>
            <ng-select
              [allowClear]="false"
              id="region-selector"
              class="farms-selector"
              [ngClass]="{
                danger: createFarmForm.submitted && !newFarm.region
              }"
              required
              #newFarmRegion
              (selected)="setRegion($event)"
              [items]="regions"
              name="region"
              placeholder=" {{ 'YARDS.region' | translate }} {{
                (createFarmForm.submitted ? 'CORE.isRequired' : '') | translate
              }}"
            >
            </ng-select>
          </div>
          <div
            class="box-control"
            id="farm-post-code-box"
            [ngClass]="{ 'input--filled--model': newFarm?.post_code }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  createFarmForm.submitted &&
                  (newFarmPostCode.errors?.required ||
                    newFarmPostCode.errors?.maxlength)
              }"
              name="post_code"
              maxlength="40"
              #newFarmPostCode="ngModel"
              [(ngModel)]="newFarm.post_code"
              autocomplete="off"
              spellcheck="false"
            />
            <label class="input__label">
              <span
                *ngIf="
                  !createFarmForm.submitted ||
                  (createFarmForm.submitted && newFarmPostCode.valid)
                "
                >{{ "YARDS.postCode" | translate }} ({{
                  "HORSES.optional" | translate
                }})</span
              ><!--todo-->
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmPostCode.errors?.required
                "
                >{{ "YARDS.regionRequired" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmPostCode.errors?.pattern
                "
                >{{ "YARDS.postCode" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
              <span
                *ngIf="
                  createFarmForm.submitted && newFarmPostCode.errors?.maxlength
                "
                >{{ "YARDS.postCode" | translate
                }}{{ "CORE.isTooBig" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control">
            <label class="label ng-select-label">
              <span>{{ "YARDS.country" | translate }}</span>
            </label>
            <ng-select
              [allowClear]="false"
              id="country-selector"
              class="farms-selector"
              [ngClass]="{
                danger: createFarmForm.submitted && !newFarm.country_id
              }"
              required
              #newFarmCountry
              (selected)="setCountryId($event)"
              [items]="countries"
              name="country_id"
              placeholder=" {{ 'YARDS.country' | translate }} {{
                (createFarmForm.submitted ? 'CORE.isRequired' : '') | translate
              }}"
            >
            </ng-select>
          </div>
        </div>
        <div *ngIf="serverError" style="margin-bottom: 30px">
          <div *ngFor="let err of serverError">
            <div class="box-btn text-center text-danger">{{ err }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            isRequestInProgress ||
            (createFarmForm.submitted &&
              (!newFarm.name ||
                !newFarm.address_first ||
                !newFarmName.valid ||
                !newFarm.country_id ||
                !newFarm.city))
          "
        >
          {{ "YARDS.addYards" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<div class="container">
  <div class="hide-mobile">
    <div class="name-page">
      <span class="name">{{ "YARDS.yards" | translate }}</span>
      <a
        class="btn btn-border btn-middle open-modal"
        href="#add-yards"
        *ngIf="
          appMemory.user?.roles[0]?.id == 1 || appMemory.user?.roles[0]?.id == 2
        "
      >
        {{ "YARDS.addYards" | translate }}
      </a>
    </div>
  </div>
  <div class="padding-block">
    <div class="box-items-yard">
      <div class="no-data" *ngIf="!isRequestInProgress && yards?.length == 0">
        <span>No yards</span>
      </div>
      <div class="clearfix">
        <a
          class="item-yard"
          [routerLink]="[
            '/yards/horses/',
            yard.id,
            yard.region != UKRegions.Aintree
          ]"
          *ngFor="let yard of yards"
        >
          <div class="box-name">
            <div class="age">
              {{ yard?.created_at | dateObj | date : "dd MMM y" }}
            </div>
            <div class="name">
              <span>{{ yard?.name }}</span>
            </div>
          </div>
          <i class="icon icon-left icon-horse icon-middle"></i>
          <span class="yarn-horse-count" *ngIf="yard?.horseCount < 2"
            >{{ yard?.horseCount }} {{ "HORSES.horse" | translate }}</span
          >
          <span class="yarn-horse-count" *ngIf="yard?.horseCount > 1"
            >{{ yard?.horseCount }} {{ "HORSES.horses" | translate }}</span
          >
        </a>
      </div>
    </div>
    <div class="text-center">
      <app-loader *ngIf="pagination.inProgress || !yards"></app-loader>
      <button
        class="btn lazy-btn"
        (click)="addNewData(yards)"
        *ngIf="!pagination.inProgress && !!pagination.nextPage"
      >
        {{ "CORE.showMore" | translate }}
      </button>
    </div>
  </div>
  <div
    class="footer-button show-mobile"
    *ngIf="
      appMemory.user?.roles[0]?.id == 1 || appMemory.user?.roles[0]?.id == 2
    "
  >
    <a class="btn btn-green btn-full open-modal" href="#add-yards">{{
      "YARDS.addYards" | translate
    }}</a>
  </div>
</div>
