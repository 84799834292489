<div class="filter" [ngClass]="{'active': active}">
  <div class="header">
    <div class="checkbox">
      <span class="step" [ngClass]="{'active': active}" (click)="selectFilter()"></span>
    </div>
    <div class="filter-name">
      <span>{{ 'REPORT.filterBy' | translate}}</span>
      <span>{{ title | titlecase }}</span>
    </div>
  </div>
  <div class="selection">
    <button [ngClass]="{'active': selection.length === options.length}" (click)="selectAll()">{{ 'CORE.selectAll' | translate }}</button>
    <button class="dropdown-toggle" type="button" (click)="showMenu = !showMenu" [ngClass]="{'opened': showMenu, 'active': selection.length && selection.length !== options.length}">
      {{ 'REPORT.specialSelection' | translate }}
    </button>
  </div>
  <div class="custom-dropdown bounceInDown" [ngClass]="{'show': showMenu && active}">
    <div class="input-form">
      <input type="text" [(ngModel)]="searchText">
      <i class="fa fa-search" aria-hidden="true"></i>
    </div>
    <!-- <div class="selectAll">
      <button (click)="selectAll()">{{ 'CORE.selectAll' | translate }}</button>
      <button (click)="clearAll()">{{ 'REPORT.clearSelection' | translate}}</button>
    </div> -->
    <ul>
      <li role="menuitem" *ngFor="let item of options | filter : searchText : label ; let i = index">
        <input
          type="checkbox"
          class="styled-checkbox"
          id="{{title + '_' + (item.id + 1 || i + 1)}}"
          [checked]="selection.indexOf(item[value]) > -1"
          (click)="toggleSelection(item[value])"
          value="item[value]">
        <label for="{{title + '_' + (item.id + 1 || i + 1)}}">
          <span>{{item[label]}}</span>
        </label>
      </li>
    </ul>
    <div class="actions">
      <button class="ok" (click)="confirmSelection(); showMenu = !showMenu">Ok</button>
      <button class="cancel" (click)="cancelSelection(); showMenu = !showMenu">{{ 'CORE.cancel'| translate }}</button>
    </div>
  </div>
</div>
