<div class="modal" id="register_success">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile" [routerLink]="['/login']"
      ><i class="icon icon-close"></i
    ></a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a class="btn close-modal show-mobile" [routerLink]="['/login']"
          ><i class="icon icon-back icon-middle"></i
        ></a>
      </div>
      <div class="title">{{ "AUTH.successRegistration" | translate }}</div>
    </div>
    <div class="modal-body">
      <p class="verify-text">{{ "AUTH.waitUntilApproved" | translate }}</p>
    </div>
  </div>
</div>

<div class="wrapper">
  <div class="main">
    <nav class="nav-tabs">
      <ul>
        <li>
          <a [routerLink]="['/login']">{{ "AUTH.login" | translate }}</a>
        </li>
        <li class="active">
          <a [routerLink]="['/sign']">{{ "AUTH.singUp" | translate }}</a>
        </li>
        <li class="language-switcher-login" *ngIf="languages.length">
          <ng-container *ngFor="let language of languages">
            <a
              *ngIf="translate.currentLang !== language"
              (click)="setLanguage(language)"
              >{{ "CORE." + language | translate }}</a
            >
          </ng-container>
        </li>
      </ul>
    </nav>

    <div class="padding-block padding-bottom-100-px">
      <app-auth-header></app-auth-header>
      <div
        class="form-center"
        [ngClass]="isAUSCountry ? 'aus-bg-intro' : 'bg-intro'"
      >
        <ng-container>
          <div class="country-name text-center">
            {{ "AUTH." + currentCountry | translate }}
          </div>
        </ng-container>

        <div class="name-page text-center">
          {{ "AUTH.signUpAs" | translate }}
        </div>
        <form
          class="form"
          #registrationForm="ngForm"
          novalidate
          (ngSubmit)="sendRegistrationModal(registrationForm)"
        >
          <div class="box-control">
            <ul class="nav-type clearfix">
              <li
                class="change-active active"
                (click)="registration.role_id = 1; registration.step = 1"
              >
                <a class="btn btn-sign-in">
                  <div class="box-icon">
                    <i class="icon icon-trainer icon-middle"></i>
                  </div>
                  {{ "CORE.trainer" | translate }}</a
                >
              </li>
              <li
                class="change-active"
                (click)="registration.role_id = 2; registration.step = 1"
              >
                <a class="btn btn-sign-in">
                  <div class="box-icon">
                    <i class="icon icon-assistant icon-middle"></i>
                  </div>
                  {{ "CORE.assistant" | translate }}</a
                >
              </li>
              <li
                class="change-active"
                (click)="registration.role_id = 3; registration.step = 1"
              >
                <a class="btn btn-sign-in">
                  <div class="box-icon">
                    <i class="icon icon-vet icon-middle"></i>
                  </div>
                  {{ "CORE.vet" | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div
            *ngIf="registration.role_id == 1"
            class="box-control text-center"
          >
            <span
              class="step"
              [ngClass]="{
                'step-active':
                  registration.role_id == 1 && registration.step == 1
              }"
              >{{ "AUTH.personalDetails" | translate }}</span
            >
            <span
              class="step"
              [ngClass]="{
                'step-active':
                  registration.role_id == 1 && registration.step == 2
              }"
              >{{ "AUTH.companuDetails" | translate }}</span
            >
          </div>
          <div
            *ngIf="registration.step == 1"
            class="box-control"
            [ngClass]="{ 'input--filled--model': registration?.name }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{ error: serverError?.name }"
              name="registration-name"
              #registrationName="ngModel"
              [(ngModel)]="registration.name"
              autocomplete="off"
              [readonly]="isRequestSuccess"
              spellcheck="false"
              (keydown)="removeValidationError('name')"
              required
            />
            <label class="input__label">
              <span *ngIf="!serverError?.name">
                {{ "CORE.firstName" | translate }}
              </span>
              <span *ngIf="serverError?.name">{{ serverError.name }}</span>
            </label>
          </div>
          <div
            *ngIf="registration.step == 1"
            class="box-control"
            [ngClass]="{ 'input--filled--model': registration?.last_name }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{ error: serverError?.last_name }"
              name="registration-surname"
              #registrationLastName="ngModel"
              [(ngModel)]="registration.last_name"
              autocomplete="off"
              spellcheck="false"
              [readonly]="isRequestSuccess"
              (keydown)="removeValidationError('last_name')"
              required
            />
            <label class="input__label">
              <span *ngIf="!serverError?.last_name">{{
                "CORE.lastName" | translate
              }}</span>
              <span *ngIf="serverError?.last_name">{{
                serverError.last_name
              }}</span>
            </label>
          </div>
          <div
            *ngIf="registration.step == 1"
            class="box-control"
            [ngClass]="{ 'input--filled--model': registration?.email }"
          >
            <input
              type="email"
              class="form-control input__field"
              [ngClass]="{ error: serverError?.email || this.invalidEmail }"
              name="registration-email"
              #registrationEmail="ngModel"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              [(ngModel)]="registration.email"
              autocomplete="off"
              spellcheck="false"
              [readonly]="isRequestSuccess"
              (keyup)="removeValidationError('email')"
              required
            />
            <label class="input__label">
              <span *ngIf="!serverError?.email">E-mail</span>
              <span *ngIf="serverError?.email">{{ serverError.email }}</span>
            </label>
          </div>

          <div
            class="box-control input--filled"
            *ngIf="registration.role_id == 1 && registration.step == 1"
          >
            <!-- <a class="btn btn-dotted btn-code btn-full"
						   [ngClass]="{'danger': serverError?.birth}"
						   style="user-select: none;">
							<label class="label">
								<span *ngIf="!serverError?.birth">{{ 'CORE.dateOfBirth' | translate}}</span>
							</label>
							<div class="code relative">
								<input type="text"
									   [textMask]="datePickerMask"
									   placeholder="{{ 'CORE.selectDate' | translate }}"
									   class="form-control input__field"
									   [(ngModel)]="datePicker.currentDate_input"
									   autocomplete="off"
									   name="currentDate"
									   (change)="setBirthDateInput()"
								>
								<i class="fa fa-calendar calendar" (click)="datePicker.isActive = !datePicker.isActive" aria-hidden="true"></i>
							</div>
							<span class="error" *ngIf="serverError?.birth">{{serverError.birth | translate}}</span>
						</a> -->
            <div
              class="box-control"
              *ngIf="
                datePicker.isActive &&
                registration.role_id == 1 &&
                registration.step == 1
              "
            >
              <datepicker
                class="datepicker"
                name="birth-datepicker"
                [startingDay]="1"
                [onlyCurrentMonth]="true"
                [minDate]="datePicker.minDate"
                [maxDate]="datePicker.maxDate"
                [showWeeks]="false"
                [initDate]="datePicker.currentDate"
                [dateDisabled]="dateDisabled"
                (selectionDone)="
                  setBirthDate($event);
                  datePicker.isActive = !datePicker.isActive;
                  removeValidationError('birth')
                "
              >
              </datepicker>
            </div>
          </div>

          <div
            class="box-control"
            [ngClass]="{ 'input--filled--model': registration?.code }"
            *ngIf="registration.role_id != 1"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{ error: serverError?.code }"
              name="registration-code"
              #registrationCode="ngModel"
              [(ngModel)]="registration.code"
              autocomplete="off"
              [readonly]="isRequestSuccess"
              spellcheck="false"
              (keydown)="removeValidationError('code')"
              required
            />
            <label class="input__label">
              <span *ngIf="!serverError?.code">{{
                "AUTH.trainerIndetityCode" | translate
              }}</span>
              <span *ngIf="serverError?.code">{{ serverError.code }}</span>
            </label>
          </div>

          <div
            class="box-control"
            [ngClass]="{
              'input--filled--model': registration?.organization_name
            }"
            *ngIf="registration.role_id == 1 && registration.step == 2"
          >
            <input
              type="text"
              class="form-control input__field"
              name="organization_name"
              [ngClass]="{
                error: serverError?.organization_name || invalidOrganisationName
              }"
              #registrationOrganizationName="ngModel"
              [(ngModel)]="registration.organization_name"
              autocomplete="off"
              spellcheck="false"
              required
              (keydown)="removeValidationError('organization_name')"
            />
            <label class="input__label">
              <span *ngIf="!serverError?.organization_name">{{
                "AUTH.organizationName" | translate
              }}</span>
              <span *ngIf="serverError?.organization_name">{{
                serverError.organization_name
              }}</span>
            </label>
          </div>

          <div
            class=""
            *ngFor="
              let farm of registration.farms | slice : 0 : 1;
              let ind = index;
              trackBy: indexTracker
            "
          >
            <div
              *ngIf="registration.role_id == 1 && registration.step == 2"
              [ngClass]="{ 'input--filled--model': farm?.name }"
              class="box-control"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error: serverError && serverError['farms.' + ind + '.name']
                }"
                [(ngModel)]="farm.name"
                name="farms_name[{{ ind }}]"
              />
              <label
                class="input__label"
                [ngClass]="{ 'add-farm': farm.name && !farm.add_farm }"
              >
                <span
                  *ngIf="
                    !(serverError && serverError['farms.' + ind + '.name'])
                  "
                  >{{ "CORE.yardName" | translate }}</span
                >
                <span
                  *ngIf="serverError && serverError['farms.' + ind + '.name']"
                  >{{ serverError["farms." + ind + ".name"] }}</span
                >
              </label>
            </div>

            <div
              class="btn btn-code btn-dotted btn-full"
              style="cursor: default"
              [ngClass]="{
                danger:
                  ((registrationForm.submitted && registrationForm.invalid) ||
                    isRequestInProgress) &&
                  farm.add_farm
              }"
              *ngIf="registration.role_id == 1 && registration.step == 2"
            >
              <div class="code text-center" style="margin-top: 20px">
                {{ "AUTH.enterYardAddres" | translate }}
              </div>

              <div
                class="box-control"
                [ngClass]="{ 'input--filled--model': farm.address_first }"
                *ngIf="registration.role_id == 1"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      serverError &&
                      serverError['farms.' + ind + '.address_first']
                  }"
                  name="registration-address-1[{{ ind }}]"
                  #registrationAddressFirst="ngModel"
                  [(ngModel)]="farm.address_first"
                  autocomplete="off"
                  spellcheck="false"
                  [readonly]="isRequestSuccess"
                  (keydown)="
                    removeValidationError('farms.' + ind + '.address_first')
                  "
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !(
                        serverError &&
                        serverError['farms.' + ind + '.address_first']
                      )
                    "
                    >{{ "CORE.address1" | translate }}</span
                  >
                  <span
                    *ngIf="
                      serverError &&
                      serverError['farms.' + ind + '.address_first']
                    "
                    >{{ serverError["farms." + ind + ".address_first"] }}</span
                  >
                </label>
              </div>

              <div
                class="box-control"
                [ngClass]="{ 'input--filled--model': farm.address_second }"
                *ngIf="registration.role_id == 1"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      serverError &&
                      serverError['farms.' + ind + '.address_second']
                  }"
                  name="registration-address-2[{{ ind }}]"
                  #registrationAddressSecond="ngModel"
                  [(ngModel)]="farm.address_second"
                  autocomplete="off"
                  spellcheck="false"
                  (keydown)="
                    removeValidationError('farms.' + ind + '.address_second')
                  "
                  [readonly]="isRequestSuccess"
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !(
                        serverError &&
                        serverError['farms.' + ind + '.address_second']
                      )
                    "
                    >{{ "CORE.address2" | translate }}</span
                  >
                  <span
                    *ngIf="
                      serverError &&
                      serverError['farms.' + ind + '.address_second']
                    "
                    >{{ serverError["farms." + ind + ".address_second"] }}</span
                  >
                </label>
              </div>

              <div
                class="box-control"
                [ngClass]="{ 'input--filled--model': farm?.city }"
                *ngIf="registration.role_id == 1"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error: serverError && serverError['farms.' + ind + '.city']
                  }"
                  name="registration-city[{{ ind }}]"
                  #registrationCity="ngModel"
                  [(ngModel)]="farm.city"
                  autocomplete="off"
                  spellcheck="false"
                  [readonly]="isRequestSuccess"
                  (keydown)="removeValidationError('farms.' + ind + '.city')"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !(serverError && serverError['farms.' + ind + '.city'])
                    "
                    >{{ "CORE.city" | translate }}</span
                  >
                  <span
                    *ngIf="serverError && serverError['farms.' + ind + '.city']"
                    >{{ serverError["farms." + ind + ".city"] }}</span
                  >
                </label>
              </div>

              <div
                class="box-control"
                *ngIf="registration.role_id == 1 && !showRegionsSelect"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      serverError && serverError['farms.' + ind + '.region']
                  }"
                  name="registration-region[{{ ind }}]"
                  #registrationRegion="ngModel"
                  [(ngModel)]="farm.region"
                  autocomplete="off"
                  [readonly]="isRequestSuccess"
                  spellcheck="false"
                  (keydown)="removeValidationError('farms.' + ind + '.region')"
                  required
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !(serverError && serverError['farms.' + ind + '.region'])
                    "
                    >{{ "AUTH.state" | translate }}</span
                  >
                  <span
                    *ngIf="
                      serverError && serverError['farms.' + ind + '.region']
                    "
                    >{{ serverError["farms." + ind + ".region"] }}</span
                  >
                </label>
              </div>
              <div
                class="box-control"
                *ngIf="
                  registration.role_id == 1 && showRegionsSelect && regions
                "
              >
                <ng-select
                  [allowClear]="false"
                  id="region-selector"
                  class="region-selector"
                  [ngClass]="{
                    danger:
                      serverError && serverError['farms.' + ind + '.region']
                  }"
                  required
                  #regionSelector
                  (selected)="
                    setRegionId($event, farm);
                    removeValidationError('farms.' + ind + '.region')
                  "
                  [items]="regions"
                  placeholder="{{ 'YARDS.region' | translate }} {{
                    (registrationForm.submitted ? 'CORE.isRequired' : '')
                      | translate
                  }}"
                >
                </ng-select>
              </div>

              <div
                class="box-control"
                [ngClass]="{ 'input--filled--model': farm?.post_code }"
                *ngIf="registration.role_id == 1"
              >
                <input
                  type="text"
                  class="form-control input__field"
                  [ngClass]="{
                    error:
                      serverError && serverError['farms.' + ind + '.post_code']
                  }"
                  name="registration-post_code[{{ ind }}]"
                  #registrationPostCode="ngModel"
                  [(ngModel)]="farm.post_code"
                  autocomplete="off"
                  spellcheck="false"
                  (keydown)="
                    removeValidationError('farms.' + ind + '.post_code')
                  "
                  [readonly]="isRequestSuccess"
                />
                <label class="input__label">
                  <span
                    *ngIf="
                      !(
                        serverError &&
                        serverError['farms.' + ind + '.post_code']
                      )
                    "
                    >{{ "YARDS.postCode" | translate }} ({{
                      "HORSES.optional" | translate
                    }})</span
                  >
                  <span
                    *ngIf="
                      serverError && serverError['farms.' + ind + '.post_code']
                    "
                    >{{ serverError["farms." + ind + ".post_code"] }}</span
                  >
                </label>
              </div>

              <div
                class="box-control"
                *ngIf="registration.role_id == 1 && countries"
              >
                <ng-select
                  [allowClear]="false"
                  id="country-selector"
                  class="country-selector"
                  [(ngModel)]="farm.country"
                  [ngClass]="{
                    danger:
                      serverError && serverError['farms.' + ind + '.country_id']
                  }"
                  required
                  #countrySelector
                  (selected)="
                    setCountryId($event, farm);
                    removeValidationError('farms.' + ind + '.country_id')
                  "
                  [items]="countries"
                  name="country[{{ ind }}]"
                  placeholder="{{ 'YARDS.country' | translate }} {{
                    (registrationForm.submitted ? 'CORE.isRequired' : '')
                      | translate
                  }}"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div
            class="box-control"
            [ngClass]="{ 'input--filled--model': registration?.password }"
            *ngIf="registration.step == 1"
          >
            <div class="control-btn">
              <a
                class="link"
                (touchstart)="showPassword('#signPassword')"
                (touchend)="hidePassword('#signPassword')"
                (mousedown)="showPassword('#signPassword')"
                (mouseup)="hidePassword('#signPassword')"
                (mouseout)="hidePassword('#signPassword')"
              >
                <i class="icon icon-eye icon-middle"></i>
              </a>
            </div>
            <input
              type="password"
              class="form-control input__field"
              style="padding-right: 30px"
              id="signPassword"
              [ngClass]="{ error: serverError?.password }"
              name="registration-password"
              #registrationPassword="ngModel"
              [(ngModel)]="registration.password"
              [readonly]="isRequestSuccess"
              (keydown)="removeValidationError('password')"
              required
            />
            <label class="input__label">
              <span *ngIf="!serverError?.password">{{
                "AUTH.password" | translate
              }}</span>
              <span *ngIf="serverError?.password">{{
                serverError.password
              }}</span>
            </label>
          </div>
          <div
            *ngIf="registration.step == 1"
            class="box-control"
            [ngClass]="{
              'input--filled--model': registration?.password_confirmation
            }"
          >
            <div class="control-btn">
              <a
                class="link"
                (touchstart)="showPassword('#repeatPassword')"
                (touchend)="hidePassword('#repeatPassword')"
                (mousedown)="showPassword('#repeatPassword')"
                (mouseup)="hidePassword('#repeatPassword')"
                (mouseout)="hidePassword('#repeatPassword')"
              >
                <i class="icon icon-eye icon-middle"></i>
              </a>
            </div>
            <input
              class="form-control input__field"
              type="password"
              id="repeatPassword"
              style="padding-right: 30px"
              [ngClass]="{ error: serverError?.password }"
              name="repeat-password"
              #repeatPassword="ngModel"
              (keydown)="removeValidationError('password')"
              [readonly]="isRequestSuccess"
              [(ngModel)]="registration.password_confirmation"
              required
            />
            <label class="input__label"
              ><span>{{ "AUTH.confirmPassword" | translate }}</span></label
            >
          </div>
          <div
            class="box-control"
            *ngIf="registration.role_id != 1 || registration.step == 2"
            style="height: 40px"
          >
            <input
              type="checkbox"
              style="display: block; width: auto"
              id="agree_terms"
              name="agree"
              class="form-control input__field"
              [ngClass]="{
                error: registrationForm.submitted && agree.errors?.required
              }"
              #agree="ngModel"
              [(ngModel)]="registration.agree"
              required
            />
            <label
              for="agree_terms"
              class="agree_terms"
              style="position: absolute; margin-top: -17px; margin-left: 26px"
            >
              <a
                href="https://equinemedirecord.com/emr-terms-conditions/"
                target="_blank"
              >
                {{ "AUTH.agreeWithTerms" | translate }}
              </a>
            </label>
          </div>
          <div class="box-btn text-center">
            <button
              class="btn btn-green btn-full"
              *ngIf="registration.role_id !== 1"
              type="submit"
              [disabled]="
                (registrationForm.submitted && registrationForm.invalid) ||
                isRequestInProgress
              "
            >
              {{ "AUTH.singUp" | translate }}
            </button>
            <button
              *ngIf="registration.role_id == 1 && registration.step == 1"
              type="button"
              class="btn btn-green btn-full"
              (click)="registration.step = 2"
            >
              {{ "AUTH.next" | translate }}
            </button>
            <div *ngIf="isRequestInProgress" class="loader"></div>
            <div *ngIf="registration.role_id == 1 && registration.step == 2">
              <button
                class="btn btn-white btn-semi-full"
                type="submit"
                (click)="registration.step = 1"
              >
                {{ "AUTH.back" | translate }}
              </button>

              <button
                class="btn btn-green btn-semi-full"
                type="submit"
                [disabled]="
                  (registrationForm.submitted && registrationForm.invalid) ||
                  isRequestInProgress ||
                  !registration.agree
                "
              >
                {{ "AUTH.singUp" | translate }}
              </button>
            </div>
          </div>
          <div *ngIf="serverError" class="text-danger box-btn">
            {{ "AUTH.serverValidationErrorsMessage" | translate }}
          </div>
        </form>
      </div>
    </div>
    <app-auth-footer></app-auth-footer>
  </div>
</div>

<div class="modal" id="checkCountry">
  <div class="modal-wrapper" style="overflow: hidden">
    <a class="btn btn-close hide-mobile" (click)="cancelLogin()">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
    </div>
    <div class="modal-body">
      <div class="padding-block">
        <h2 class="confirm-message-h2">
          Northern Irish users you should ensure you sign into the Irish system
          as you comply with IHRB regulations. Please note you are about to
          proceed to the UK system.
        </h2>
        <div class="button-set">
          <button
            class="btn btn-green pull-left btn-confirm-login"
            (click)="sendRegistrationConfirm()"
          >
            Ok
          </button>
          <button
            class="btn btn-green pull-right hide-mobile btn-confirm-login"
            (click)="cancelLogin()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<a
  class="btn btn-border btn-middle open-modal"
  href="#register_success"
  id="show_modal_reg_success"
  style="display: none"
></a>
