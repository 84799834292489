<div class="box-control">
  <ul class="nav-type line">
    <li
      class="change-active"
      *ngFor="let t of treatmentTypes"
      [ngClass]="{
        active: t.type == (treatment.type || selectedTab),
        disabled: isEdit
      }"
    >
      <a
        class="btn"
        [ngClass]="{
          danger: treatmentForm.submitted && !treatment.type
        }"
        (click)="!isEdit ? onTreatmentTypeClick(t.type || selectedTab) : null"
      >
        <div class="name">
          {{ t.title }}
        </div>
      </a>
    </li>
  </ul>
</div>
<br />
<div
  class="box-info"
  [innerHTML]="
    'TREATS.forms.' + (treatment?.type || selectedTab) + '.intro' | translate
  "
></div>
<br />

<ng-container *ngIf="toggleRequired">
  <div [ngClass]="{ 'margin-bottom-25': hasNoTreatment }">
    <input
      type="checkbox"
      id="has-no-treatment"
      [checked]="!!hasNoTreatment"
      (change)="setHasNoTreatment.emit($event.target.checked)"
    />
    <div class="toggle">
      <label for="has-no-treatment">
        <i></i>
      </label>
    </div>
    <span class="toggle__label">{{ toggleLabel }}</span>
  </div>
</ng-container>
