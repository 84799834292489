<div class="modal" id="invite">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(inviteAssistantForm, true)"
      ><i class="icon icon-close"></i
    ></a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(inviteAssistantForm, true)"
          ><i class="icon icon-back icon-middle"></i
        ></a>
      </div>
      <div class="title">{{ "PROFILE.inviteAssisVet" | translate }}</div>
    </div>
    <form
      class="form"
      #inviteAssistantForm="ngForm"
      novalidate
      (ngSubmit)="inviteAssistant(inviteAssistantForm)"
      autocomplete="off"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="box-control"
            id="assistant-invite-email-box"
            [ngClass]="{ 'input--filled--model': assistantInvite?.email }"
          >
            <input
              type="email"
              class="form-control input__field"
              [ngClass]="{
                error:
                  inviteAssistantForm.submitted &&
                  (assistantEmailInput.errors?.required ||
                    assistantEmailInput.errors?.pattern)
              }"
              name="assistant-invite-email"
              #assistantEmailInput="ngModel"
              [(ngModel)]="assistantInvite.email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              spellcheck="false"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !inviteAssistantForm.submitted ||
                  (inviteAssistantForm.submitted && assistantEmailInput.valid)
                "
                >E-mail</span
              >
              <span
                *ngIf="
                  inviteAssistantForm.submitted &&
                  assistantEmailInput.errors?.required
                "
                >{{ "CORE.emailRequired" | translate }}</span
              >
              <span
                *ngIf="
                  inviteAssistantForm.submitted &&
                  assistantEmailInput.errors?.pattern
                "
                >{{ "CORE.emailInvalid" | translate }}</span
              >
            </label>
          </div>
          <div class="box-control input--filled">
            <a
              class="btn btn-dotted btn-code btn-full"
              style="color: #1b1539"
              title="{{ 'PROFILE.copyToClipboard' | translate }}"
              ngxClipboard
              [cbContent]="inviteCode"
              (click)="
                showAnimatedNotify(
                  $event.currentTarget,
                  '.small-success-message.small-success-message_second'
                )
              "
            >
              <div class="box-icon box-right">
                <i class="icon icon-copy icon-middle"></i>
              </div>
              <label class="label" style="cursor: pointer"
                ><span>{{
                  "PROFILE.trainerInvitationCode" | translate
                }}</span></label
              >
              <div class="code">
                {{ inviteCode }}
                <small
                  class="small-success-message small-success-message_second"
                  style="display: none"
                  >{{ "PROFILE.copiedToClipboard" | translate }}
                </small>
              </div>
            </a>
          </div>
        </div>
        <div
          class="box-btn text-center text-success"
          style="margin-bottom: 30px"
          *ngIf="isRequestSuccess"
        >
          {{ "AUTH.invitationSent" | translate }}
          <strong style="font-weight: 700">{{
            assistantInvite.email_snapshot
          }}</strong>
        </div>
        <div *ngIf="serverError" style="margin-bottom: 30px">
          <div *ngFor="let err of serverError">
            <div class="box-btn text-center text-danger">{{ err }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            (inviteAssistantForm.submitted &&
              (assistantEmailInput.errors?.required ||
                assistantEmailInput.errors?.pattern)) ||
            isRequestInProgress
          "
        >
          {{ "OTHER.sendInvite" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="request-trainer">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(requestTrainerForm, true)"
      ><i class="icon icon-close"></i
    ></a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(requestTrainerForm, true)"
          ><i class="icon icon-back icon-middle"></i
        ></a>
      </div>
      <div class="title">{{ "PROFILE.requestTrainer" | translate }}</div>
    </div>
    <form
      class="form"
      #requestTrainerForm="ngForm"
      novalidate
      (ngSubmit)="requestTrainer(requestTrainerForm)"
      autocomplete="off"
    >
      <div class="modal-body">
        <div
          class="box-control"
          id="request-trainer-code-box"
          [ngClass]="{ 'input--filled--model': code }"
        >
          <input
            type="email"
            class="form-control input__field"
            [ngClass]="{
              error:
                requestTrainerForm.submitted &&
                (trainerInviteCode.errors?.required ||
                  trainerInviteCode.errors?.pattern)
            }"
            name="trainer-invite-code"
            #trainerInviteCode="ngModel"
            [(ngModel)]="code"
            maxlength="255"
            spellcheck="false"
            autocomplete="off"
            required
          />
          <label class="input__label">
            <span
              *ngIf="
                !requestTrainerForm.submitted ||
                (requestTrainerForm.submitted && trainerInviteCode.valid)
              "
              >{{ "PROFILE.trainerInvitationCode" | translate }}</span
            >
            <span
              *ngIf="
                requestTrainerForm.submitted &&
                trainerInviteCode.errors?.required
              "
              >{{ "PROFILE.inviteCodeRequired" | translate }}</span
            >
            <span
              *ngIf="
                requestTrainerForm.submitted &&
                trainerInviteCode.errors?.pattern
              "
              >{{ "PROFILE.inviteCode" | translate }}
              {{ "CORE.isInvalid" | translate }}</span
            >
          </label>
        </div>
        <div
          class="box-btn text-center text-success"
          style="margin-bottom: 30px"
          *ngIf="isRequestSuccess"
        >
          {{ "PROFILE.requeastSuccessSent" | translate }}
        </div>
        <div *ngIf="serverError" style="margin-bottom: 30px">
          <div *ngFor="let err of serverError">
            <div class="box-btn text-center text-danger">{{ err }}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            (requestTrainerForm.submitted &&
              (trainerInviteCode.errors?.required ||
                trainerInviteCode.errors?.pattern)) ||
            isRequestInProgress
          "
        >
          {{ "PROFILE.sendRequest" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="change-name">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(changeNameForm, true, true)"
      ><i class="icon icon-close"></i
    ></a>
    <div class="modal-header bg-green">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(changeNameForm, true, true)"
          ><i class="icon icon-back icon-middle"></i
        ></a>
      </div>
      <div class="title">{{ "PROFILE.changeName" | translate }}</div>
    </div>
    <form
      class="form"
      #changeNameForm="ngForm"
      novalidate
      (ngSubmit)="changeName(changeNameForm)"
      autocomplete="off"
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="box-control"
            id="first-name-code-box"
            [ngClass]="{ 'input--filled--model': firstName }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  changeNameForm.submitted &&
                  (firstNameInput.errors?.required ||
                    firstNameInput.errors?.pattern)
              }"
              name="first-name"
              #firstNameInput="ngModel"
              [(ngModel)]="firstName"
              maxlength="20"
              spellcheck="false"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !changeNameForm.submitted ||
                  (changeNameForm.submitted && firstNameInput.valid)
                "
                >{{ "CORE.firstName" | translate }}</span
              >
              <span
                *ngIf="
                  changeNameForm.submitted && firstNameInput.errors?.required
                "
                >{{ "CORE.firstName" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  changeNameForm.submitted && firstNameInput.errors?.pattern
                "
                >{{ "CORE.firstName" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
            </label>
          </div>
          <div
            class="box-control"
            id="last-name-code-box"
            [ngClass]="{ 'input--filled--model': lastName }"
          >
            <input
              type="text"
              class="form-control input__field"
              [ngClass]="{
                error:
                  changeNameForm.submitted &&
                  (lastNameInput.errors?.required ||
                    lastNameInput.errors?.pattern)
              }"
              name="last-name"
              #lastNameInput="ngModel"
              [(ngModel)]="lastName"
              maxlength="20"
              spellcheck="false"
              autocomplete="off"
              required
            />
            <label class="input__label">
              <span
                *ngIf="
                  !changeNameForm.submitted ||
                  (changeNameForm.submitted && lastNameInput.valid)
                "
                >{{ "CORE.lastName" | translate }}</span
              >
              <span
                *ngIf="
                  changeNameForm.submitted && lastNameInput.errors?.required
                "
                >{{ "CORE.lastName" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
              <span
                *ngIf="
                  changeNameForm.submitted && lastNameInput.errors?.pattern
                "
                >{{ "CORE.lastName" | translate }}
                {{ "CORE.isInvalid" | translate }}</span
              >
            </label>
          </div>
          <div *ngIf="serverError" style="margin-bottom: 30px">
            <div *ngFor="let err of serverError">
              <div class="box-btn text-center text-danger">{{ err }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            (changeNameForm.submitted && changeNameForm.invalid) ||
            isRequestInProgress
          "
        >
          {{ "PROFILE.confirm" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal" id="assistant-decline">
  <div class="modal-wrapper">
    <a
      class="btn btn-close close-modal hide-mobile"
      (click)="clearUserInput(declineForm, true)"
      ><i class="icon icon-close"></i
    ></a>
    <div class="modal-header">
      <div class="left-part">
        <a
          class="btn close-modal show-mobile"
          (click)="clearUserInput(declineForm, true)"
          ><i class="icon icon-back dark icon-middle"></i
        ></a>
      </div>
      <div class="title">
        {{ "CORE.decline" | translate }}
        {{ "CORE." + (!isVet ? "assistant" : "vet") | translate }}
      </div>
    </div>
    <form
      class="form"
      #declineForm="ngForm"
      novalidate
      (ngSubmit)="
        changeAssistantStatus(
          isVet ? vetStatus.vet_id : assistantStatus.assistant_id,
          'cancelled',
          declineForm
        )
      "
    >
      <div class="modal-body">
        <div class="padding-block">
          <div
            class="box-control"
            id="comment-box"
            [ngClass]="{ 'input--filled--model': assistantStatus?.comment }"
          >
            <textarea
              class="form-control input__field"
              name="comment"
              [ngClass]="{
                error:
                  declineForm.submitted &&
                  (declineComment.errors?.required ||
                    declineComment.errors?.pattern)
              }"
              #declineComment="ngModel"
              [(ngModel)]="assistantStatus.comment"
              required
            >
            </textarea>
            <label class="input__label">
              <span
                *ngIf="
                  !declineForm.submitted ||
                  (declineForm.submitted && declineComment.valid)
                "
                >{{ "CORE.comment" | translate }}</span
              >
              <span
                *ngIf="declineForm.submitted && declineComment.errors?.required"
                >{{ "CORE.comment" | translate }}
                {{ "CORE.isRequired" | translate }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-green btn-full"
          type="submit"
          [disabled]="
            (declineForm.submitted && declineComment.errors) ||
            isRequestInProgress
          "
        >
          {{ "CORE.decline" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="container">
  <div class="hide-mobile">
    <div class="name-page">
      <span class="name">{{ user?.name }} {{ user?.last_name }}</span>
      <span class="invite-container">
        <app-tooltip
          class="inviteUserTutorial"
          #inviteUserTutorial
          [position]="{ right: '-29px', top: '5px' }"
          *ngIf="getTooltip()"
          (click)="showTooltip('invite-user', '.inviteUserTutorial')"
        ></app-tooltip>
        <a
          class="btn btn-border btn-middle open-modal"
          href="#invite"
          *ngIf="user?.roles[0]?.id == 1"
          >{{ "PROFILE.inviteAssisVet" | translate }}</a
        >

        <a
          class="btn btn-border btn-middle open-modal"
          href="#request-trainer"
          *ngIf="user?.roles[0]?.id == 3"
          >{{ "PROFILE.requestTrainer" | translate }}</a
        >
      </span>
    </div>
  </div>
  <tabset class="nav-tabs nav-left" *ngIf="appMemory.user?.roles[0]?.id == 1">
    <tab
      heading="{{ 'PROFILE.profile' | translate }}"
      [active]="true"
      (select)="(true)"
      (deselect)="(false)"
    ></tab>
    <tab *ngIf="!isAUSCountry"
      heading="{{ 'INVOICES.invoices' | translate }}"
      [active]="false"
      (select)="this.goToInvoice()"
      (deselect)="(true)"
    ></tab>
    <tab *ngIf="!isAUSCountry"
      heading="{{ 'PROFILE.informations' | translate }}"
      [active]="false"
      (select)="this.goToInfo()"
      (deselect)="(true)"
    ></tab>
  </tabset>
  <div class="box-control input--filled" *ngIf="_languages.length">
    <label class="input__label label ng-select-label">
      <span>{{ "PROFILE.language" | translate | titlecase }}</span>
    </label>
    <ng-select
      [allowClear]="false"
      id="language-selector"
      class="language-selector label-value input__field"
      required
      #languagesSelector
      (selected)="setLang($event)"
      [items]="languages"
      [active]="currentLang"
      name="langs"
    >
    </ng-select>
  </div>
  <div class="padding-block box-inline row-half">
    <div class="form form-left">
      <div class="box-control input--filled">
        <div class="label-value input__field">
          <a class="open-modal" href="#change-name">{{
            "PROFILE.changeName" | translate
          }}</a>
        </div>
      </div>
      <div class="box-control input--filled">
        <div class="label-value input__field">
          {{ user?.organization?.name }}
        </div>
        <label class="input__label"
          ><span>{{ "PROFILE.organization" | translate }}</span></label
        >
      </div>
      <div class="box-control input--filled">
        <div class="label-value input__field">
          {{
            "CORE." +
              appMemory?.translates[appMemory.user?.roles[0]?.display_name]
              | translate
          }}
        </div>
        <label class="input__label"
          ><span>{{ "CORE.status" | translate }}</span></label
        >
      </div>
      <div class="box-control input--filled">
        <div class="label-value input__field">{{ user?.email }}</div>
        <label class="input__label"
          ><span>{{ "CORE.emailAddress" | translate }}</span></label
        >
      </div>
      <ng-container *ngIf="user && user.roles[0].id === 3 && isUSCountry">
        <!-- Hisa Id start -->
        <form
          class="form modal-form"
          #vetHisaIdForm="ngForm"
          novalidate
          (ngSubmit)="updateVetHisaId(vetHisaIdForm)"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: large;
            "
          >
            <div
              style="flex: 1"
              class="box-control"
              id="hisa-id-box"
              [ngClass]="{ 'input--filled--model': hisaId }"
            >
              <input
                type="text"
                class="form-control input__field"
                [ngClass]="{
                  error:
                    vetHisaIdForm.submitted &&
                    (vetHisaId.errors?.required ||
                      vetHisaId.errors?.pattern ||
                      vetHisaId.errors?.minlength ||
                      vetHisaId.errors?.maxlength)
                }"
                name="hisa_id"
                #vetHisaId="ngModel"
                [(ngModel)]="hisaId"
                autocomplete="off"
                spellcheck="false"
                required
                minlength="5"
                maxlength="30"
              />
              <label class="input__label">
                <span
                  *ngIf="
                    !vetHisaIdForm.submitted ||
                    (vetHisaIdForm.submitted && vetHisaId.valid)
                  "
                  >{{ "HORSES.hisa_id" | translate }}</span
                >
                <span
                  *ngIf="vetHisaIdForm.submitted && vetHisaId.errors?.required"
                  >{{ "HORSES.hisa_id" | translate }}
                  {{ "CORE.isRequired" | translate }}</span
                >
                <span
                  *ngIf="vetHisaIdForm.submitted && vetHisaId.errors?.pattern"
                  >{{ "HORSES.hisa_id" | translate }}
                  {{ "CORE.isInvalid" | translate }}</span
                >
                <span
                  *ngIf="vetHisaIdForm.submitted && vetHisaId.errors?.minlength"
                  >{{ "HORSES.hisa_id" | translate }}
                  {{ "CORE.mustBeAtLeast5Chars" | translate }}</span
                >
                <span
                  *ngIf="vetHisaIdForm.submitted && vetHisaId.errors?.maxlength"
                  >{{ "HORSES.hisa_id" | translate
                  }}{{ "CORE.isTooBig" | translate }}</span
                >
              </label>
            </div>
            <div>
              <button type="submit" style="cursor: pointer">
                <i class="fa fa-save"></i>
              </button>
            </div>
          </div>
        </form>
        <!-- Hisa Id end -->
      </ng-container>

      <div class="box-control input--filled" *ngIf="inviteCode">
        <a
          class="btn btn-dotted btn-code btn-full"
          title="{{ 'PROFILE.copyToClipboard' | translate }}"
          ngxClipboard
          [cbContent]="inviteCode"
          (click)="
            showAnimatedNotify($event.currentTarget, '.small-success-message')
          "
        >
          <div class="box-icon box-right">
            <i class="icon icon-copy icon-middle"></i>
          </div>
          <label class="label" style="cursor: pointer"
            ><span>{{
              "PROFILE.trainerInvitationCode" | translate
            }}</span></label
          >
          <div class="code">
            {{ inviteCode }}
            <small class="small-success-message" style="display: none">{{
              "PROFILE.copiedToClipboard" | translate
            }}</small>
          </div>
        </a>
      </div>
      <div class="label-group" *ngIf="!isAUSCountry">
        <div class="label">{{ "PROFILE.notifications" | translate }}</div>
      </div>
      <div class="box-control clearfix" *ngIf="!isAUSCountry">
        <input
          type="checkbox"
          id="notify_ios"
          name="notify_ios"
          [checked]="notify.notify_ios"
          (change)="notifyChange('notify_ios')"
        />
        <div class="toggle">
          <label for="notify_ios"><i></i></label>
        </div>
        <div class="toggle__text">
          {{ "PROFILE.notificationOnIos" | translate }}
        </div>
      </div>
      <div class="box-control clearfix" *ngIf="!isAUSCountry">
        <input
          type="checkbox"
          id="notify_email"
          name="notify_email"
          [checked]="notify.notify_email"
          (change)="notifyChange('notify_email')"
        />
        <div class="toggle">
          <label for="notify_email"><i></i></label>
        </div>
        <div class="toggle__text">
          {{ "PROFILE.notificationsOnEmail" | translate }}
        </div>
      </div>
      <div class="box-control input--filled" *ngIf="user?.instructor">
        <div class="label-value input__field">
          {{ user?.instructor[0]?.name + " " + user?.instructor[0]?.last_name }}
        </div>
        <label class="input__label"
          ><span>{{ "PROFILE.yourTrainer" | translate }}</span></label
        >
      </div>
      <div
        class="box-control instructors-vet input--filled"
        *ngIf="user?.instructor_vet"
      >
        <ng-template ngFor let-instructor [ngForOf]="user?.instructor_vet">
          <div class="label-value input__field">
            {{ instructor?.name + " " + instructor?.last_name }}
          </div>
        </ng-template>
        <label class="input__label"
          ><span>{{ "PROFILE.yourTrainers" | translate }}</span></label
        >
      </div>
    </div>

    <div class="box-list-person">
      <div class="form form-left" *ngIf="assistants?.pending.length > 0">
        <div class="box-control">
          <div class="label-group">
            <div class="label">
              {{ "PROFILE.incomingReqAssis" | translate }}
            </div>
          </div>
          <div
            class="item-person"
            *ngFor="let assistant of assistants?.pending"
          >
            <div class="box-table">
              <div class="cell-name item-cell">
                <div class="name">
                  {{ assistant?.name }} {{ assistant?.last_name }}
                </div>
              </div>
              <div class="cell-action item-cell text-right">
                <a
                  class="btn btn-circle open-modal remove"
                  href="#assistant-decline"
                  (click)="
                    isVet = false; assistantStatus.assistant_id = assistant.id
                  "
                >
                  <i class="icon icon-close icon-middle"></i>
                </a>
                <a
                  class="btn btn-circle approve"
                  (click)="
                    isVet = false;
                    assistantStatus.assistant_id = assistant.id;
                    changeAssistantStatus(assistant.id, 'approved')
                  "
                >
                  <i class="icon icon-check icon-middle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form form-left" *ngIf="vets?.pending.length > 0">
        <div class="box-control">
          <div class="label-group">
            <div class="label">{{ "PROFILE.incomingReqVet" | translate }}</div>
          </div>
          <div class="item-person" *ngFor="let vet of vets?.pending">
            <div class="box-table">
              <div class="cell-name item-cell">
                <div class="name">{{ vet?.name }} {{ vet?.last_name }}</div>
              </div>
              <div class="cell-action item-cell text-right">
                <a
                  class="btn btn-circle open-modal remove"
                  href="#assistant-decline"
                  (click)="isVet = true; vetStatus.vet_id = vet.id"
                >
                  <i class="icon icon-close icon-middle"></i>
                </a>
                <a
                  class="btn btn-circle approve"
                  (click)="
                    isVet = true;
                    vetStatus.assistant_id = vet.id;
                    changeAssistantStatus(vet.id, 'approved')
                  "
                >
                  <i class="icon icon-check icon-middle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box-list-person">
      <div class="form form-left" *ngIf="assistants?.approved.length > 0">
        <div class="box-control">
          <div class="label-group">
            <div class="label">{{ "PROFILE.myAssis" | translate }}</div>
          </div>
          <div
            class="box-scroll"
            id="assistants-box-scroll"
            (scroll)="onScrollAssistants($event)"
          >
            <div
              class="item-person"
              *ngFor="let assistant of assistants?.approved"
            >
              <div class="box-table">
                <div class="cell-name item-cell">
                  <div class="name">
                    {{ assistant?.name }} {{ assistant?.last_name }}
                  </div>
                </div>
                <div class="cell-action item-cell text-right">
                  <a
                    class="btn grey open-modal"
                    href="#assistant-decline"
                    (click)="
                      isVet = false; assistantStatus.assistant_id = assistant.id
                    "
                  >
                    <i class="icon icon-close icon-middle"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="text-center">
              <!-- <button class="btn lazy-btn" (click)="addNewData(assistants.approved, 'approved')" *ngIf="!pagination.approved.inProgress && !!pagination.approved.nextPage">{{ 'CORE.showMore' | translate}}</button> -->
              <div *ngIf="pagination.approved.inProgress">
                {{ "CORE.loading" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form form-left" *ngIf="vets?.approved.length > 0">
        <div class="box-control">
          <div class="label-group">
            <div class="label">{{ "PROFILE.myVets" | translate }}</div>
          </div>
          <div
            class="box-scroll"
            id="vets-box-scroll"
            (scroll)="onScrollVets($event)"
          >
            <div class="item-person" *ngFor="let vet of vets?.approved">
              <div class="box-table">
                <div class="cell-name item-cell">
                  <div class="name">{{ vet?.name }} {{ vet?.last_name }}</div>
                </div>
                <div class="cell-action item-cell text-right">
                  <a
                    class="btn grey open-modal"
                    href="#assistant-decline"
                    (click)="isVet = true; vetStatus.vet_id = vet.id"
                  >
                    <i class="icon icon-close icon-middle"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div *ngIf="pagination2.approved.inProgress">
                {{ "CORE.loading" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="padding-block box-inline row-half" *ngIf="!isAUSCountry">
    <div class="form form-left">
      <div class="label-group">
        <div class="label">{{ "PROFILE.notificationsFreq" | translate }}</div>
      </div>
      <div class="table-header hide-mobile">
        <div class="box-table">
          <div class="item-cell cell-space"></div>
          <div class="item-cell cell-space text-center">
            {{ "CORE.daily" | translate }}
          </div>
          <div class="item-cell cell-space text-center">
            {{ "CORE.weekly" | translate }}
          </div>
          <div class="item-cell cell-space text-center">
            {{ "CORE.monthly" | translate }}
          </div>
        </div>
      </div>
      <div class="table-body">
        <div class="box-control clearfix">
          <div class="item-cell cell-space">
            {{ "TREATS.treatments" | translate }}
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="daily"
              name="notifyTreatment"
              [(ngModel)]="notifyFrequency['notifyTreatment']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyTreatment'] === 'daily'"
            />
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="weekly"
              name="notifyTreatment"
              [(ngModel)]="notifyFrequency['notifyTreatment']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyTreatment'] === 'weekly'"
            />
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="monthly"
              name="notifyTreatment"
              [(ngModel)]="notifyFrequency['notifyTreatment']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyTreatment'] === 'monthly'"
            />
          </div>
        </div>
        <div class="box-control clearfix">
          <div class="item-cell cell-space">
            {{ "HORSES.vacs" | translate }}
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="daily"
              name="notifyVaccination"
              [(ngModel)]="notifyFrequency['notifyVaccination']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyVaccination'] === 'daily'"
            />
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="weekly"
              name="notifyVaccination"
              [(ngModel)]="notifyFrequency['notifyVaccination']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyVaccination'] === 'weekly'"
            />
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="monthly"
              name="notifyVaccination"
              [(ngModel)]="notifyFrequency['notifyVaccination']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyVaccination'] === 'monthly'"
            />
          </div>
        </div>
        <div class="box-control clearfix">
          <div class="item-cell cell-space">
            {{ "HORSES.notes" | translate }}
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="daily"
              name="notifyNotes"
              [(ngModel)]="notifyFrequency['notifyNotes']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyNotes'] === 'daily'"
            />
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="weekly"
              name="notifyNotes"
              [(ngModel)]="notifyFrequency['notifyNotes']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyNotes'] === 'weekly'"
            />
          </div>
          <div class="item-cell cell-space text-center">
            <input
              type="radio"
              value="monthly"
              name="notifyNotes"
              [(ngModel)]="notifyFrequency['notifyNotes']"
              (change)="saveNotifyConfigToServer()"
              [checked]="notifyFrequency['notifyNotes'] === 'monthly'"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="item-cell cell-space-name">
          {{ "PROFILE.notificationsFreq" | translate }}
        </div>
        <div class="item-cell cell-space">
          <select
            id="time"
            [(ngModel)]="notifyFrequency.notifyDay"
            (change)="saveNotifyConfigToServer()"
          >
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>
            <option value="7">Sunday</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-button show-mobile" *ngIf="user?.roles[0]?.id == 1">
    <a class="btn btn-green btn-full open-modal" href="#invite">{{
      "PROFILE.inviteAssisVet" | translate
    }}</a>
  </div>
  <div class="footer-button show-mobile" *ngIf="user?.roles[0]?.id == 3">
    <a class="btn btn-green btn-full open-modal" href="#request-trainer">{{
      "PROFILE.requestTrainer" | translate
    }}</a>
  </div>
</div>
