<div class="modal open" id="blocked-warning" style="display: block;">
  <div class="modal-wrapper">
    <a class="btn btn-close close-modal hide-mobile" (click)="disableNotification()">
      <i class="icon icon-close"></i>
    </a>
    <div class="modal-header">
      <div class="left-part">
        <a class="btn close-modal show-mobile">
          <i class="icon icon-back dark icon-middle"></i>
        </a>
      </div>
      <div class="title">{{title}}</div>
    </div>

    <div class="modal-body">
      <div class="padding-block">
        <div class="text-center" style="font-size: 21px; margin: 45px 0 60px 0;">
          {{message}}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div>
        <button class="btn btn-green btn-full close-modal" type="button" (click)="disableNotification()">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>