<div class="tutorial-component">
  <div class="show-mobile">
    <a class="btn" (click)="hideTutorial()">
			<i class="icon icon-back dark icon-middle"></i>
		</a>
    <div *ngFor="let step of steps">
      <span class="tutorial" *ngIf="step.active">
        <div class="url">
          <video controls [innerHTML]="step.url"></video>
        </div>
        <div class="card">
          <div class="title" [innerHTML]="step.title"></div>
          <hr class="separator-line">
          <div class="description" [innerHTML]="step.description"></div>
          <div class="stepper-component">
            <span *ngFor="let step of steps;" [ngClass]="{'bigger': step.active}" (click)="switchStep()" class="stepper"></span>
          </div>
          <div class="buttons-section">
            <div>
              <button class="btn back" [disabled]="currentStep === 0" (click)="backStep()">{{ 'AUTH.back' | translate}}</button>
            </div>
            <div>
              <button class="btn next" *ngIf="currentStep != steps.length - 1" (click)="nextStep()">{{ 'AUTH.next' | translate}}</button>
              <button class="btn next" *ngIf="currentStep === steps.length - 1" (click)="hideTutorial()">{{ 'TUTORIAL.finish' | translate}}</button>
            </div>
          </div>
        </div>
        
      </span>
    </div>
  </div>
  <div class="hide-mobile">
      <div class="video-modal">
        <div class="close-button" (click)="hideTutorial()">&times;</div>
          <div *ngFor="let step of steps">
              <span class="tutorial" *ngIf="step.active" [ngClass]="{'pulse': changingStep}">
                <div class="url">
                  <video controls [innerHTML]="step.url"></video>
                </div>
                <hr class="separator-line">
                <div class="card">
                  <div class="title" [innerHTML]="step.title"></div>
                  <div class="description" [innerHTML]="step.description"></div>
                </div>
                <div class="buttons-section">
                  <div>
                    <button class="btn btn-border back" [disabled]="currentStep === 0" (click)="backStep()">{{ 'AUTH.back' | translate}}</button>
                  </div>
                  <div class="stepper-component">
                    <span *ngFor="let step of steps;" [ngClass]="{'bigger': step.active}" (click)="switchStep()" class="stepper"></span>
                  </div>
                  <div>
                    <button class="btn btn-border" *ngIf="currentStep != steps.length - 1" (click)="nextStep()">{{ 'AUTH.next' | translate}}</button>
                    <button class="btn btn-border" *ngIf="currentStep === steps.length - 1" (click)="hideTutorial()">{{ 'TUTORIAL.finish' | translate}}</button>
                  </div>
                </div>
              </span>
            </div>
      </div>
  </div>
</div>
